import { useState } from 'react';
import { BookmarkIcon, LinkIcon, Cog6ToothIcon, ArrowUpRightIcon, ArrowDownRightIcon } from '@heroicons/react/24/outline';
import SettingsMenu, { ISettingsItem, MenuAlign } from '../baseComponents/SettingsMenu';
import Tippy from '@tippyjs/react';
import 'tippy.js/themes/light.css';
import { TrashIcon, ArrowDownTrayIcon, DocumentTextIcon, PaperClipIcon, PencilSquareIcon } from '@heroicons/react/24/outline';
import { FilterInput, Group_Trending } from '../../generated/graphql';
import 'tippy.js/themes/light.css';
import { TaxonomyGroup } from '../../v2/hooks/GroupHook';
import { BookmarkIcon as BookmarkIconSolid } from '@heroicons/react/24/solid';
import AdjustableLoadingIcon from '../../baseComponents/AdjustableLoadingIcon';
import { getFiltersStatsMode, truncate } from '../../v2/util';
import { useSearchGroupPolling } from '../../v2/hooks/ExploreHook';
import { ReactComponent as Loading } from '../../assets/spinner.svg';
import 'tippy.js/themes/light.css';
import { Events, logEvent } from '../../v2/AnalyticsUtil';
import { useValidTeamAppContext } from '../../v2/contexts/AppContext';

interface TaxonomyFolderProps {
  taxonomy: TaxonomyGroup;
  filterInput: FilterInput | undefined;
  zIndex: string;
  loadingChildrenId: number | undefined;
  children: any[];
  filters: FilterInput;
  handleToggle: (id: number) => void;
  teamId: number;
  assignChildren: (teamId: number, filterInput: FilterInput, parentGroupId: number, childGroupIds: number[], cb?: () => void) => Promise<void>;
  getChildCandidates: (teamId: number, filterInput: FilterInput, groupId: number, query: string, cb: () => void) => Promise<void>;
  childCandidates: any[];
  addChild: (
    teamId: number,
    filterInput: FilterInput,
    parentGroupId: number,
    childGroupId: number,
    type?: 'Parent' | 'Child',
    cb?: () => void
  ) => Promise<void>;
  activeId: number | undefined;
  itemRef: any;
  setActiveId: (id: number | undefined) => void;
  openGroupModal: (groupId: number, openTaxonomy?: boolean) => void;
  groupExport: (groupId: number) => void;
  copyLink: (groupId: number) => void;
  togglePinGroup: (groupId: number, cb?: () => void) => void;
  setAnnouncementId: (groupId: number) => void;
  setCurrentGroupId: (groupId: number) => void;
  updateProgress: (searchGroupId: number, newProgress: number) => void;
  replaceOrAddToSearchGroups: (searchGroup: any) => void;
  setOpenTaxonomy: (openTaxonomy: boolean | undefined) => void;
  trending: Group_Trending | null | undefined;
  depth?: number;
}

export const TaxonomyFolder = ({
  taxonomy,
  loadingChildrenId,
  trending,
  handleToggle,
  children,
  teamId,
  setActiveId,
  itemRef,
  groupExport,
  copyLink,
  openGroupModal,
  togglePinGroup,
  setOpenTaxonomy,
  setAnnouncementId,
  setCurrentGroupId,
  updateProgress,
  filterInput,
  replaceOrAddToSearchGroups,
  zIndex,
  depth,
}: TaxonomyFolderProps) => {
  const { currentTeam, currentOrg } = useValidTeamAppContext();
  const handlePinGroup = (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
    e.stopPropagation();
    setPinLoading(true);
    togglePinGroup(taxonomy.id, () => setPinLoading(false));
  };
  const settings: ISettingsItem[] = [
    {
      name: 'Pin Group',
      id: 1,
      group: 'pin',
      htmlId: 'pin-group',
      icon: <BookmarkIcon className="h-6 w-6" />,
      onClick: () => {
        setPinLoading(true);
        togglePinGroup(taxonomy.id, () => setPinLoading(false));
      },
    },
    {
      name: 'Export to CSV',
      id: 2,
      group: 'actions',
      htmlId: 'export-group-csv',
      icon: <ArrowDownTrayIcon className="h-6 w-6" />,
      onClick: () => {
        groupExport(taxonomy.id);
      },
    },
    {
      name: 'Link to Group',
      id: 3,
      group: 'actions',
      htmlId: 'link-group',
      icon: <PaperClipIcon className="h-6 w-6" />,
      onClick: () => copyLink(taxonomy.id),
    },
    {
      name: 'Announce Feature',
      id: 4,
      group: 'actions',
      htmlId: 'announce-feature',
      icon: <DocumentTextIcon className="h-6 w-6" />,
      onClick: () => setAnnouncementId(taxonomy.id),
    },
    {
      name: 'Edit Taxonomy',
      id: 1,
      group: 'edit',
      htmlId: 'edit-taxonomy',
      icon: <PencilSquareIcon className="h-6 w-6" />,
      onClick: () => {
        setOpenTaxonomy(true);
        openGroupModal(taxonomy.id, true);
      },
    },
    {
      name: 'Delete Group',
      id: 5,
      group: 'delete',
      htmlId: 'delete-group',
      icon: <TrashIcon className="h-6 w-6" />,
      onClick: () => {
        setCurrentGroupId(taxonomy.id);
      },
    },
  ];
  const [pinLoading, setPinLoading] = useState(false);
  useSearchGroupPolling({
    teamId,
    searchGroupId: taxonomy.id ?? -1,
    replaceOrAddToSearchGroups,
    searchProcessing: taxonomy.processing ?? false,
    updateProgress,
    filterInput,
  });
  const filteredStatsMode = getFiltersStatsMode(filterInput);
  return (
    <>
      <div className={`flex flex-row items-center gap-x-3 ${taxonomy.processing ? '' : 'cursor-pointer'} relative group ${zIndex} w-full mt-1`} ref={itemRef}>
        {taxonomy.totalDescendents > 0 ? (
          <div
            onClick={(e) => {
              e.stopPropagation();
              handleToggle(taxonomy.id);
              logEvent(Events.TaxonomyToggled, {
                View_ID: currentTeam.id,
                View_Name: currentTeam.name,
                Org_ID: currentOrg.id,
                Org_Name: currentOrg.name,
                Toggle_Type: taxonomy.showChildren ? 'Close' : 'Open',
                Depth: depth,
              });
            }}
            className="flex items-center absolute -left-16 top-1/2 transform -translate-y-1/2 select-none"
          >
            {taxonomy.totalDescendents > 0 && (
              <div className="flex opacity-100 duration-[150ms] text-blueberry transition-all mr-2 w-[36px] justify-end">({taxonomy.totalDescendents})</div>
            )}
            <div
              className={`text-sm hover:scale-[120%] ${
                taxonomy.showChildren ? 'mt-2' : 'transform -rotate-90'
              } transition-all duration-[100ms] cursor-pointer `}
            >
              {loadingChildrenId === taxonomy.id ? <AdjustableLoadingIcon width={4} height={4} /> : <p>▼</p>}
            </div>
          </div>
        ) : null}
        <div
          id="group-preview-card"
          onClick={() => {
            if (taxonomy.processing) return;
            openGroupModal(taxonomy.id);
          }}
          className={`w-full bg-white text-blueberry ${
            !taxonomy.processing ? 'hover:bg-blueberry hover:text-white hover:shadow-lg' : ''
          } duration-150 transition-colors shadow-[0_1px_2px_rgb(0,0,0,0.1)] border-2 rounded-lg p-3 flex flex-row justify-between items-center gap-x-1`}
        >
          <div className="justify-start flex flex-row items-center gap-x-3">
            <div className="flex flex-col justify-start">
              <div className="flex flex-row gap-x-2 justify-start items-center">
                <h3 className="text-lg font-semibold flex justify-start items-center gap-x-2">
                  {truncate(taxonomy.title ?? '', 75)}{' '}
                  {trending ? (
                    <div className="rounded-full p-1 bg-blueberry border text-white w-5 h-5 flex justify-center items-center">
                      <Tippy
                        theme="light"
                        delay={200}
                        content={<p className="text-center">This group is trending {trending === Group_Trending.Increasing ? 'upwards' : 'downwards'} </p>}
                      >
                        <div>
                          {trending === Group_Trending.Increasing ? (
                            <ArrowUpRightIcon className="h-2.5 w-2.5 focus:outline-none stroke-[3px]" />
                          ) : (
                            <ArrowDownRightIcon className="h-2.5 w-2.5 focus:outline-none stroke-[3px]" />
                          )}
                        </div>
                      </Tippy>
                    </div>
                  ) : null}
                </h3>{' '}
              </div>
              {taxonomy.processing ? null : (
                <h4 className="text-sm">
                  <div className="flex flex-row gap-x-1">
                    {taxonomy.totalEntries.toLocaleString('en-US')} mentions{' '}
                    <Tippy
                      theme="light"
                      delay={200}
                      content={
                        <p className="text-center">
                          {filteredStatsMode ? `${(taxonomy.relativeShare ?? 0).toFixed(2)}% of filtered feedback, ` : ''}
                          {(taxonomy.relativeShareFull ?? 0).toFixed(2)}% of all feedback.
                        </p>
                      }
                    >
                      <p className="">
                        ({((filteredStatsMode ? taxonomy.relativeShare : taxonomy.relativeShareFull) ?? 0).toFixed(2)}% of {filteredStatsMode ? 'filtered' : ''}{' '}
                        feedback)
                      </p>
                    </Tippy>
                  </div>
                </h4>
              )}
            </div>
          </div>

          {taxonomy.processing ? (
            <div className="flex flex-row justify-end">
              <div className="flex flex-col items-center justify-center gap-y-1">
                <Loading />
                <p className="p-0 m-0 text-sm">{taxonomy.progress}%</p>
              </div>
            </div>
          ) : (
            <div className="flex flex-row justify-end gap-x-1">
              <Tippy theme="light" delay={200} content={<p className="text-center">{'Click to pin this group'}</p>}>
                <div className="rounded-full p-2 hover:cursor-pointer hover:bg-slate-900/25 ">
                  {pinLoading ? (
                    <AdjustableLoadingIcon width={5} height={5} color="white" />
                  ) : taxonomy.pinnedByUser ? (
                    <BookmarkIconSolid className="h-5 w-5 self-center" id="unpin-group" onClick={(e) => handlePinGroup(e)} />
                  ) : (
                    <BookmarkIcon className="h-5 w-5 self-center" id="pin-group" onClick={(e) => handlePinGroup(e)} />
                  )}
                </div>
              </Tippy>
              <div className="rounded-full p-2  hover:cursor-pointer hover:bg-slate-900/25 ">
                <Tippy theme="light" delay={200} content={<p className="text-center">Copy group link</p>}>
                  <div id="link-group">
                    <LinkIcon
                      className="h-5 w-5 focus:outline-none "
                      onClick={(e) => {
                        e.stopPropagation();
                        copyLink(taxonomy.id);
                      }}
                    />
                  </div>
                </Tippy>
              </div>
              <div
                className="p-2 rounded-full relative  hover:cursor-pointer hover:bg-slate-900/25 "
                onClick={(e) => {
                  e.stopPropagation();
                  setActiveId(taxonomy.id);
                }}
              >
                <SettingsMenu settings={settings} center={true} align={MenuAlign.RIGHT}>
                  <Cog6ToothIcon className=" h-5 w-5 z-10" />
                </SettingsMenu>
              </div>
            </div>
          )}
        </div>
      </div>

      <div className={`ml-24 mt-2  ${taxonomy.showChildren ? 'opacity-100 h-auto overflow-visible' : 'opacity-0 h-0 overflow-hidden'}`}>{children}</div>
    </>
  );
};
