import { useContext, useEffect, useState } from 'react';
import { IFilterRow, ITableHeader } from '../../sections/Filters/FiltersTypes';
import FilterTable from './FilterTable';
import { SegmentGroupQuery, useSegmentGroupLazyQuery } from '../../../generated/graphql';
import AppContext from '../../../v2/contexts/AppContext';
import { CSVError, HeaderEntry, ParsedCSV, SegmentValue } from '../../../handlers/csvUtils/csvTypes';
import LoadingSpinner from '../../baseComponents/LoadingSpinner';
import { formatCapitalizeAndSpace, formatAlphaNumericLowercase, concatArrayAsString } from '../../../handlers/stringUtils/stringFormatters';

/**
 * @description alias to GQL `feedback_segment_group` type
 */
interface SegmentGroup {
  __typename?: 'feedback_segment_group' | undefined;
  displayName: string;
  id: number;
}

interface SegmentsConfigTableProps {
  /**
   * @description the CSV the user uploaded
   */
  parsedCSV: ParsedCSV;

  /**
   * @description the segment filters used to display data
   */
  filters: IFilterRow[];

  /**
   * @description gateway to updating the segments
   */
  setFilters: React.Dispatch<React.SetStateAction<IFilterRow[]>>;

  /**
   * @description whether or not the table should render
   */
  hasSegmentsConfigs: boolean | undefined;

  /**
   * @description error setter in case the SegmentGroupsQuery Fails
   */
  setErrors: React.Dispatch<React.SetStateAction<CSVError[]>>;
}

type setters = React.Dispatch<React.SetStateAction<IFilterRow[]>>;

export default function SegmentsConfigTable({ parsedCSV, filters, setFilters, hasSegmentsConfigs, setErrors }: SegmentsConfigTableProps): JSX.Element {
  /**
   * @description current team
   */
  const { curTeamId } = useContext(AppContext);

  /**
   * @description boolean for whether or not the Chevron is open
   */
  const [isOpen, setIsOpen] = useState<boolean>(false);

  /**
   * @description the maximum number of characters a preview can be
   */
  const [maxPreviewChars] = useState<number>(30);

  /**
   * @description lazy query for `SegmentGroups`
   */
  const [getSegmentGroups, { data: segGroups, loading: segLoading }] = useSegmentGroupLazyQuery({
    variables: { teamId: curTeamId! },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'network-only',
  });

  /**
   * @description override for Filter Table header
   */
  const tableHeader: ITableHeader[] = [
    { headerName: 'CSV Column', tippy: 'The CSV Column containing your filter' },
    { headerName: 'Custom Fields', tippy: 'The name of the Custom Field which you can filter on' },
    { headerName: 'Preview', tippy: 'A preview of the filter’s value' },
  ];

  /**
   * @description populates the segment config table
   */
  const populateTable = async () => {
    // step 1) query DB
    const queriedGroups: SegmentGroupQuery | undefined = (await getSegmentGroups({ variables: { teamId: curTeamId! } })).data;

    // step 2) populate table with csv columns
    populateSegmentConfigs(parsedCSV, setFilters, maxPreviewChars);

    // step 3) populate SegmentGroups with new or existing values
    populateSegmentGroups(setFilters, queriedGroups);
  };

  /**
   * @description populates table and filters with segment data and auto populated GroupName
   */
  useEffect(() => {
    setIsOpen(false);
    try {
      // when a new CSV is uploaded, get the latest segments (in case of back to back uploads)
      populateTable();
    } catch (e: any) {
      setErrors((prev) => {
        return [...prev, new CSVError('Unexpected Error: Could not get your existing Custom Fields. Please try again.')];
      });
    }
  }, [parsedCSV]);

  // UPDATERS, update a change from the FilterTable
  /**
   * @description updates a filter's SegmentGroup when a user selects the ComboBox dropdown
   * @param newFilterName the new name a filter is to be set
   * @param filterRowToUpdate the filter to update
   */
  function updateFilterName(newFilterName: string, filterRowToUpdate: IFilterRow) {
    setFilters((prevFilters: IFilterRow[]) =>
      prevFilters.map((aFilterRow: IFilterRow) =>
        filterRowToUpdate === aFilterRow
          ? {
              ...aFilterRow,
              filterName: newFilterName,
              isSelected: true,
              // note: the preferred usage is the getSegmentGroups lazy query,
              // however, when this function is called in FilterTable,
              // the Segments have not changed since this is explicitly and `update` function
              doesFilterCategoryExist: doesSegGroupExist(segGroups, newFilterName),
            }
          : aFilterRow
      )
    );
  }

  /**
   * @description chooses the message of the checkbox based on checkbox and filterExisting booleans
   * @param isSelected whether or not the checkbox is selected
   * @param doesFilterCategoryExist true if the filterCategory exists or false if a new filterCategory is being created
   * @returns the message for the checkbox
   */
  function getTippyCheckboxMsg(filter: IFilterRow): string {
    // case 1) return default color (grey) if not selected
    if (!filter.isSelected) {
      return `Not Selected: Will not add the CSV Column "${filter.filterSource}" to a Custom Field`;
    }

    // case 2) selecting existing filter category
    if (filter.doesFilterCategoryExist) {
      return `Adding CSV Column "${filter.filterSource}" to the existing Custom Field named "${filter.filterName}"`;
    }

    // case 3) selecting and writing a new filter category
    return `Adding CSV Column "${filter.filterSource}" to a new Custom Field named "${filter.filterName}"`;
  }

  return (
    <>
      {hasSegmentsConfigs && (
        <div className="mt-4">
          {segLoading ? (
            <LoadingSpinner />
          ) : (
            <FilterTable
              filters={filters}
              setFilters={setFilters}
              updateFilterName={updateFilterName}
              existingFilters={formatSegmentsAsFilters(segGroups)}
              tableHeader={tableHeader}
              truncatePreviewChars={maxPreviewChars}
              getTippyCheckboxMsg={getTippyCheckboxMsg}
            />
          )}
        </div>
      )}
    </>
  );
}
// UPDATER UTILITIES
/**
 * @description checks if a given `groupName` is in a team's `SegmentGroup`
 * @param segmentGroup all segment groups for this team
 * @param groupName the segment group in question
 * @returns true if segmentGroup has a SegmentGroup with the name `groupName` and false otherwise
 */
function doesSegGroupExist(segmentGroup: SegmentGroupQuery | undefined, groupName: string): boolean {
  // case 1) segmentGroup is empty
  if (!segmentGroup || !segmentGroup.segments || segmentGroup.segments.length === 0) {
    return false;
  }

  // case 2) segmentGroups is not empty
  const matchingSegGroups = segmentGroup.segments.filter(
    (eachSeg: SegmentGroup) => eachSeg.displayName.toLowerCase().trim() === groupName.toLowerCase().trim()
  );
  return matchingSegGroups.length != 0;
}

// AUTO POPULATORS, auto populate the rows with appropriate data
/**
 * @description sets the filter rows to each of the segment names
 */
function populateSegmentConfigs(parsedCSV: ParsedCSV, setFilters: setters, maxPreviewChars: number) {
  // case 1) no segments exist (though this segment config table should not be rendered so this is just a precaution)
  if (!parsedCSV.segmentHeader) {
    return;
  }

  // create rows where filterSource is set to the segment names
  setFilters(
    parsedCSV.segmentHeader.segHeader.map((segHeader) => {
      return {
        filterName: '',
        filterSource: segHeader.fieldTitle,
        preview: getPreview(parsedCSV, segHeader, maxPreviewChars),
        isSelected: false,
        doesFilterCategoryExist: false,
      } as IFilterRow;
    })
  );
}

/**
 * @description populates segment groups based on the following criteria
 *          case 1) team has no segmentGroups, then create a new segmentGroup for each segmentConfig
 *          case 2) team has segmentGroups
 *              case 2a) segmentConfig matches segmentsGroup, then map segmentConfig to existing segmentGroup
 *              case 2b) segmentConfig !matches segmentsGroup, then map segmentConfig to new segment Group
 */
function populateSegmentGroups(setFilters: setters, segmentGroups: SegmentGroupQuery | undefined) {
  const teamHasNoSegGroup = !segmentGroups || !segmentGroups.segments || segmentGroups.segments.length === 0;
  // case 1) no segementGroups, create new segment groups
  if (teamHasNoSegGroup) {
    setFilters((prevFilters) => prevFilters.map((aFilter) => createNewSegmentGroup(aFilter)));
    return;
  }

  // case 2) at least 1 segmentGroups exists
  populateSegementGroupWithMatches(setFilters, segmentGroups);
}

/**
 * @description auto-generates a segmentGroup name based on the segmentConfig
 * @param aFilter the filter which does not have a matching segmentGroup
 * @returns a deep copy of the filter with fitlerCategory set to a prettified version of filterSource
 */
function createNewSegmentGroup(aFilter: IFilterRow): IFilterRow {
  return {
    ...aFilter,
    filterName: formatCapitalizeAndSpace(aFilter.filterSource),
  };
}

/**
 * @description matches the csvColumn with an existing segmentGroup or writes a new segmentGroup if no match is found
 */
function populateSegementGroupWithMatches(setFilters: setters, segmentGroups: SegmentGroupQuery | undefined) {
  setFilters((prevFilters: IFilterRow[]) =>
    prevFilters.map((aFilter) => {
      const possibleSegmentGroupMatch: SegmentGroup | null = findSegmentGroupExactMatch(aFilter.filterSource, segmentGroups);

      // case 2a) there is an exact match
      if (possibleSegmentGroupMatch) {
        return {
          ...aFilter,
          filterName: possibleSegmentGroupMatch.displayName,
          isSelected: true,
          doesFilterCategoryExist: true,
        };
      }

      // case 2b) segmentConfigs do not match any segmentGroups
      return createNewSegmentGroup(aFilter);
    })
  );
}

/**
 * @description tries to find an exact match of segmentConfig and segmentGroup after stripping punctuation and capitalization
 * @param segmentConfig the csvColumn specified
 * @param existingSegmentGroups an object holding an array of all segments from useSegmentGroupQuery
 * @returns the SegmentGroup that matches or null if there is no match
 */
function findSegmentGroupExactMatch(segmentConfig: string, existingSegmentGroups: SegmentGroupQuery | undefined): SegmentGroup | null {
  // case 1) no segment groups
  if (!existingSegmentGroups || existingSegmentGroups.segments?.length === 0) return null;

  // format the strings to be in a consistent format
  const formattedFilterName: string = formatAlphaNumericLowercase(segmentConfig);
  const formattedSegGroupNames: string[] = existingSegmentGroups.segments!.map((segGroup) => formatAlphaNumericLowercase(segGroup.displayName));

  // try to find a match
  const possibleMatchIdx: number = formattedSegGroupNames.indexOf(formattedFilterName);

  // case 2) Match exists, return the HeaderSegment column
  if (possibleMatchIdx != -1) {
    return existingSegmentGroups.segments![possibleMatchIdx];
  }

  // case 3) no match exists
  return null;
}

/**
 * @description generate a preview of the segmentConfig column specified
 * @param segmentConfig the csvColumn to pull the preview from
 * @returns a truncated string representing the preview
 */
function getPreview(parsedCSV: ParsedCSV, segmentConfig: HeaderEntry, maxChars: number): string {
  // step 1) get up to first 15 rows of segments
  const maxPreviewRows: number = 15;
  const maxPreviewItems: number = 3;

  const allSegments: SegmentValue[][] = parsedCSV.rows
    .slice(0, maxPreviewRows)
    .filter((row) => row.segments != undefined)
    .map((row) => row.segments!);

  // step 2) get only the segments matching the `segmentConfig.fieldTitle`
  const segmentConfigMatches: SegmentValue[][] = allSegments.map((segmentConfigRow) =>
    segmentConfigRow.filter((segmentValue) => segmentValue.name === segmentConfig.fieldTitle)
  );

  // step 3) convert the segments to an array of strings
  const previewSegments: string[] = segmentConfigMatches
    .map((eachMatch) =>
      // use the `at()` method for error handling
      eachMatch.at(0) ? eachMatch[0].value.trim() : ''
    )
    .filter((aPreview) => aPreview != '');

  // step 4A) concatenate the previews
  const concat = concatArrayAsString(previewSegments, maxChars, ', ', maxPreviewItems);

  // step 4B) in case the segment is longer than `maxChars`, return the first element and let the `FilterTable` truncate
  if (concat === '') {
    return previewSegments.at(0) ?? '';
  }

  return concat;
}

// IFILTER FORMATTERS
function formatSegmentsAsFilters(segs: SegmentGroupQuery | undefined): IFilterRow[] {
  // case 1) segs is undefined
  if (!segs || !segs.segments) return [] as IFilterRow[];

  // case 2) segs exist, map to filter rows
  const filtersAsDropDowns: IFilterRow[] = segs.segments.map((seg: SegmentGroup) => {
    return {
      filterName: seg.displayName,
      filterSource: '',
      doesFilterCategoryExist: true,
      isSelected: false,
    } as IFilterRow;
  });

  return filtersAsDropDowns;
}
