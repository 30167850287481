/* change password component */

export default function InfoCard({ title, data }: { title: string; data: string | JSX.Element | JSX.Element[] }) {
  return (
    <div className="bg-silver text-blueberry">
      <div className="px-4 py-5 sm:p-6">
        <h3 className="text-xl font-semibold leading-6">{title}</h3>
        <div className="text-md mt-2 max-w-xl font-medium">{data}</div>
      </div>
    </div>
  );
}
