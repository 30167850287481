import { PlusIcon } from '@heroicons/react/24/solid';
import { CheckCircleIcon } from '@heroicons/react/24/outline';
import { SetStateAction, useEffect, useState } from 'react';
import { APIV2 } from '../../api/apiv2';
import { Integration_Type, Integration_Type_Requirement } from '../../generated/graphql';
import CSVIntegrationModal from '../components/Modals/CSVIntegrationModal';
import EditIntegrationModal from '../components/Modals/EditIntegrationModal';
import { IIntegrationRedirect } from '../pages/IntegrationsPage';
import { useValidTeamAppContext } from '../../v2/contexts/AppContext';

interface IntegrationCardProps {
  isConnected?: boolean;
  integration: Integration_Type;
  redirect?: IIntegrationRedirect;
  setSuccessModalOpen?: React.Dispatch<SetStateAction<boolean>>;
}

export const IntegrationCard = ({ integration, redirect, setSuccessModalOpen }: IntegrationCardProps): JSX.Element => {
  const { curTeamId: teamId } = useValidTeamAppContext();
  const [modalOpen, setModalOpen] = useState(false);

  const [isLoadingConfirm, setIsLoadingConfirm] = useState(false);
  const [modalErrorMsg, setModalErrorMsg] = useState(null);
  const [modalSuccessMsg, setModalSuccessMsg] = useState<string | null>(null);
  const [modalRawErrorMsg, setModalRawErrorMsg] = useState(null);
  const [requirements, setRequirements] = useState<Integration_Type_Requirement[]>(integration.requirements);

  const confirmIntegration = async ({ csvToUpload, source, sourceName, isCsv, isRedshift }: any) => {
    setIsLoadingConfirm(true);
    try {
      if (isCsv) await APIV2.teams.integrations.createCSVIntegration(teamId, sourceName, csvToUpload);
      else if (isRedshift) await APIV2.teams.integrations.createRedshiftIntegration(teamId, sourceName, csvToUpload);
      else await APIV2.teams.integrations.createIntegration(teamId, source, csvToUpload);
      setModalErrorMsg(null);
      setModalSuccessMsg(`Successfully integrated ${isCsv ? 'CSV' : source}. Items have been added to your dashboard.`);
      setModalOpen(false);
    } catch (err: any) {
      console.error('error uploading data', err.response?.data);
      setModalSuccessMsg(null);
      setModalErrorMsg(err.response?.data?.msg || 'Unknown error');
      setModalRawErrorMsg(err.response?.data?.raw_error);
    }
    setIsLoadingConfirm(false);
  };
  useEffect(() => {
    setModalErrorMsg(null);
    setModalSuccessMsg(null);
    if (redirect && redirect.scraperKey === integration.scraperKey) {
      const requirements: Integration_Type_Requirement[] = integration.requirements.map((req) => {
        if (req.key === 'authToken') {
          return { ...req, value: [{ value: redirect.authToken, id: 0, requirement: req }] };
        }
        if (req.key === 'url_zendesk' && redirect.url_zendesk) {
          return { ...req, value: [{ value: redirect.url_zendesk, id: 0, requirement: req }] };
        }
        if (redirect.additionalObjects[req.key]) {
          return { ...req, value: [{ value: redirect.additionalObjects[req.key], id: 0, requirement: req }] };
        }
        return req;
      });
      setRequirements([...requirements]);
      setModalOpen(true);
    }
  }, []);

  const callbackResetData = () => {
    /*Hack to reset error/success/disabled when another file is selected. Can be improved */
    setModalErrorMsg(null);
    setModalSuccessMsg(null);
  };

  const openModalOrAuthRedirect = (integration: Integration_Type, redirect?: IIntegrationRedirect) => {
    if (
      integration.authRedirect &&
      !integration.title.includes('Zendesk') /*This check should be better than a frontend string check*/ &&
      redirect?.scraperKey !== integration.scraperKey &&
      !integration.requirements.find((req) => req.requiredForAuthRedirect) &&
      integration.teamIntegration.length === 0
    ) {
      const redirect = `${integration.authRedirect}&state=${JSON.stringify({ redirect: window.location.href, teamId: teamId })}`;
      window.location.replace(redirect);
    } else {
      setModalOpen(true);
    }
  };

  return (
    <div>
      {integration.requirements.length > 0 ? (
        <EditIntegrationModal
          integration={integration}
          setRequirements={setRequirements}
          requirements={requirements}
          modalOpen={modalOpen}
          setModalOpen={setModalOpen}
          setSuccessModalOpen={setSuccessModalOpen}
        />
      ) : (
        <CSVIntegrationModal
          modalOpen={modalOpen}
          source={integration.title}
          callbackModal={() => setModalOpen(false)}
          confirmButton={confirmIntegration}
          loadingConfirm={isLoadingConfirm}
          errorMsg={modalErrorMsg}
          successMsg={modalSuccessMsg}
          callbackResetData={callbackResetData}
          rawErrorMsg={modalRawErrorMsg}
        />
      )}

      <div
        className="group col-span-1 flex h-full cursor-pointer select-none flex-col gap-y-3 divide-y divide-gray-300 rounded-3xl bg-silver  text-center text-blueberry duration-300 "
        onClick={() => openModalOrAuthRedirect(integration, redirect)}
      >
        <div className="integration-content flex flex-1 flex-col justify-center gap-y-3 p-8">
          <img className="mx-auto h-auto w-10 flex-shrink-0" src={integration.logoUrl} alt="" />
          <h1 className="text-xl font-semibold">{integration.title}</h1>
          <h1 className="text-md font-normal">{integration.description}</h1>
        </div>
        <div>
          <div className="-mt-px flex divide-x divide-gray-200">
            <div className="flex w-0 flex-1">
              <div className="text-md relative -mr-px inline-flex w-0 flex-1 cursor-pointer items-center justify-center rounded-b-3xl border border-transparent bg-blueberry  py-4 text-sm font-medium  text-white duration-300 group-hover:bg-raspberry ">
                {integration.teamIntegration.length > 0 ? (
                  <>
                    <h1 className="mr-1 font-semibold">Connected</h1>
                    <CheckCircleIcon className="h-4 w-4 " aria-hidden="true" />
                  </>
                ) : (
                  <>
                    <h1 className="mr-1 font-semibold">Connect</h1>
                    <PlusIcon className="h-4 w-4 " aria-hidden="true" />
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IntegrationCard;
