import { useContext, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import {
  FilterInput,
  GroupDataFragment,
  useCreateGroupMutation,
  useEditGroupMutation,
  GroupSentenceDataFragment,
  useApproveGroupMutation,
  Group_Status,
  useGetGroupLazyQuery,
  useGetGroupStatusLazyQuery,
  DataForFiltersDocument,
  useAlertGroupFailedMutation,
} from '../../generated/graphql';
import _, { cloneDeep } from 'lodash';
import AppContext from '../contexts/AppContext';

interface ExploreHookProps {
  teamId: number;
  orgId: number;
  addGroup: (group: GroupDataFragment) => void;
  email?: string;
  filterInput?: FilterInput;
}
export const useExploreHook = ({ teamId, orgId, addGroup, email, filterInput }: ExploreHookProps) => {
  /** State Variables go here  */
  const { app } = useContext(AppContext);
  const [currentSearchId, setCurrentSearchId] = useState<number | undefined>(undefined);
  //I think this could be replaced with a single list that has the "belongs" property, then we filter it on the UI?
  const [inSearchList, setInSearchList] = useState<GroupSentenceDataFragment[]>([]);
  const [notInSearchList, setNotInSearchList] = useState<GroupSentenceDataFragment[]>([]);
  const [searchGroups, setSearchGroups] = useState<GroupDataFragment[]>([]);

  /** Queries and mutations go here */
  const [alertGroupFailed, _alert] = useAlertGroupFailedMutation({});
  const [createGroupMutation, { data: searchData, loading: searchLoading }] = useCreateGroupMutation({});
  const [editGroup, editGroupObj] = useEditGroupMutation({});
  const [approveGroup, approveGroupObj] = useApproveGroupMutation({});
  const [getGroupFull, { loading: loadingFullGroup }] = useGetGroupLazyQuery({});

  /** Use Effects go here */
  useEffect(() => {
    const data = searchData?.createGroup;
    if (data?.isExactMatch) {
      // if the group is an exact match, we don't need to show the sentences we can just add it to the list.
      // fetch the full group so all sentences and unique entries are pulled in and add to the list.
      setInSearchList([]);
      setNotInSearchList([]);
      setCurrentSearchId(undefined);
      getGroupFull({
        variables: { teamId, teamUuid: app?.currentUuid, groupId: data.id, belongs: true, filterInput: filterInput ?? {} },
        onCompleted: (data) => addGroup(data.getGroup),
      });
    } else {
      setInSearchList(
        data?.groupEntries
          ?.filter((s) => s.belongsToGroup)
          ?.flatMap((groupEntry) => {
            const updatedEntry = cloneDeep(groupEntry);
            return updatedEntry.mappedSentences.filter((sentence) => sentence.entry?.text !== updatedEntry.distillateText);
          }) ?? []
      );

      setNotInSearchList(
        data?.groupEntries
          ?.filter((s) => !s.belongsToGroup)
          ?.flatMap((groupEntry) => {
            const updatedEntry = cloneDeep(groupEntry);
            return updatedEntry.mappedSentences.filter((sentence) => sentence.text !== updatedEntry.distillateText);
          }) ?? []
      );
      setCurrentSearchId(data?.id);
    }
  }, [searchData?.createGroup]);

  const moveSentenceToList = (
    id: number,
    originList: GroupSentenceDataFragment[],
    setOriginList: (group: GroupSentenceDataFragment[]) => void,
    destinationList: GroupSentenceDataFragment[],
    setDestinationList: (groups: GroupSentenceDataFragment[]) => void
  ) => {
    const sentence = originList.find((s) => s.id === id);
    if (!sentence) return;
    setOriginList(originList.filter((s) => s.id !== id));
    setDestinationList([...destinationList, sentence]);
  };

  const switchSentenceToInSearch = (id: number) => moveSentenceToList(id, notInSearchList, setNotInSearchList, inSearchList, setInSearchList);
  const switchSentenceToNotInSearch = (id: number) => moveSentenceToList(id, inSearchList, setInSearchList, notInSearchList, setNotInSearchList);

  const executeSearchQuery = async (searchInput: string, onCompletedCallback?: () => void) => {
    let createGroupFailed = false;
    let result = await createGroupMutation({
      variables: {
        teamId,
        teamUuid: app?.currentUuid,
        email: email,
        queryString: searchInput,
      },
      onError(error) {
        //@ts-ignore
        if (error.networkError && error.networkError.statusCode === 503) {
          createGroupFailed = true;
        }
      },
    });

    // retry the failure to create the group
    if (createGroupFailed) {
      await alertGroupFailed({ variables: { teamId, teamUuid: app?.currentUuid, queryString: searchInput, email, wasRetried: false } });
      result = await createGroupMutation({
        variables: {
          teamId,
          teamUuid: app?.currentUuid,
          email: email,
          queryString: searchInput,
        },
        async onError(error) {
          await alertGroupFailed({ variables: { teamId, teamUuid: app?.currentUuid, queryString: searchInput, email, wasRetried: true } });
        },
      });
    }

    onCompletedCallback?.();
  };

  const buildGroup = (onCompletedCallback?: () => void) => {
    const data = searchData?.createGroup;
    //Data hooks shouldn't interact with the UI. Still thinking about how to do this (passing callbacks for each kind of error? sounds messy...)
    if (!inSearchList.length) return toast.error('There must be at least one sentence in the search group');
    if (!currentSearchId) return toast.error('Unknown Error');
    approveGroup({
      variables: {
        teamId,
        teamUuid: app?.currentUuid,
        groupId: currentSearchId,
        belongingSentenceIds: inSearchList.map((s) => s.id),
        differingSentenceIds: notInSearchList.map((s) => s.id),
      },
      refetchQueries: [
        {
          query: DataForFiltersDocument,
          variables: { teamId, orgId },
        },
      ],
      onCompleted(data) {
        addGroup ? addGroup(data.approveGroup) : setSearchGroups((curGroups) => [data.approveGroup, ...curGroups]);
        //searchGroupsQuery.refetch();
        toast.success('Search group created');
        setCurrentSearchId(undefined);
        onCompletedCallback?.();
      },

      onError(error) {},
    });
  };
  const discardSearchGroup = (searchGroupId: number) => {
    editGroup({
      variables: {
        teamId,
        // you can't actually delete a group from the preview page. Removing the teamUuid from here for now.
        // teamUuid: app?.currentUuid,
        groupId: searchGroupId,
        input: {
          status: Group_Status.Archived,
        },
        filterInput: {},
      },
      onCompleted(data) {
        setSearchGroups((curGroups) => curGroups?.filter((sg) => sg.id !== searchGroupId));
        //searchGroupsQuery.refetch();
        toast.success(`Search group discarded`);
      },
    });
  };

  const replaceOrAddToSearchGroups = (searchGroup: GroupDataFragment) => {
    //Check the searchGroups array. If the searchGroup is in there (check by id), replace it with this one.
    //Otherwise, add this at the beginning.
    const index = searchGroups.findIndex((sg) => sg.id === searchGroup.id);
    if (index !== -1) {
      const newSearchGroups = _.cloneDeep(searchGroups);
      newSearchGroups[index] = searchGroup;
      setSearchGroups(newSearchGroups);
    } else {
      setSearchGroups((curGroups) => [searchGroup, ...curGroups]);
    }
  };

  const replaceSentenceMappings = (searchGroupId: number, newSentencesMappings: GroupDataFragment['groupEntries']) => {
    const newSearchGroups: GroupDataFragment[] = _.cloneDeep(searchGroups);
    newSearchGroups.find((sg) => sg.id === searchGroupId)!.groupEntries = newSentencesMappings;
    setSearchGroups(newSearchGroups);
  };

  const updateProgress = (searchGroupId: number, newProgress: number) => {
    const newSearchGroups = [...searchGroups];
    newSearchGroups.find((sg) => sg.id === searchGroupId)!.progress = newProgress;
    setSearchGroups(newSearchGroups);
  };

  const refetchGroupWithAllSentences = async (searchGroupId: number) => {
    await getGroupFull({
      variables: {
        teamId,
        groupId: searchGroupId,
        sentencesTake: 2000, // I see whatcha mean Franco. Ideally we'd just have a getAllSentences flag.
        filterInput: filterInput ?? {},
      },
      onCompleted(data) {
        replaceSentenceMappings(searchGroupId, data.getGroup.groupEntries);
      },
    });
  };

  return {
    searchGroups,
    querySearchInfo: {
      loading: searchLoading,
    },
    executeSearchQuery,
    searchLists: {
      inSearchList,
      notInSearchList,
      switchSentenceToInSearch,
      switchSentenceToNotInSearch,
    },
    currentSearchId,
    buildGroup,
    discardSearchGroup,
    loadingStatuses: {
      buildingGroup: approveGroupObj.loading,
      discardingGroup: editGroupObj.loading,
      loadingAllSentences: loadingFullGroup,
    },
    replaceOrAddToSearchGroups,
    getAllGroupSentences: refetchGroupWithAllSentences,
    updateProgress,
  };
};

export const useSearchGroupPolling = ({
  teamId,
  searchGroupId,
  replaceOrAddToSearchGroups,
  //This searchProcessing is only to return from the hook in already completed searches. Kinda hacky.
  searchProcessing,
  updateProgress,
  filterInput,
}: {
  teamId: number;
  searchGroupId: number;
  replaceOrAddToSearchGroups: (newSearchGroup: GroupDataFragment) => void;
  searchProcessing: boolean;
  updateProgress: (searchGroupId: number, newProgress: number) => void;
  filterInput?: FilterInput;
}) => {
  const { app } = useContext(AppContext);
  const [getGroupQuery] = useGetGroupLazyQuery({});

  const fetchAndAddSearchGroup = (searchGroupId: number) => {
    getGroupQuery({
      variables: { teamId, groupId: searchGroupId, teamUuid: app?.currentUuid, filterInput: filterInput ?? {} },
      onCompleted(data) {
        replaceOrAddToSearchGroups(data?.getGroup);
      },
    });
  };

  const [getSearchGroupStatus, { startPolling, stopPolling }] = useGetGroupStatusLazyQuery({
    variables: { teamId, groupId: searchGroupId, teamUuid: app?.currentUuid },
    onCompleted(data) {
      if (data.getGroup.processing) {
        updateProgress(searchGroupId, data.getGroup.progress);
      } else {
        //Processing finished, fetch and store the SearchGroup
        fetchAndAddSearchGroup(searchGroupId);
        stopPolling();
      }
    },
  });

  useEffect(() => {
    if (searchProcessing) startPolling(2000);
  }, []);
};
