import { useContext, useEffect, useState } from 'react';
import { CheckIcon, ExclamationCircleIcon } from '@heroicons/react/24/solid';
import Button, { ButtonVariant } from '../../baseComponents/Button';
import Form from '../../../baseComponents/Form';
import FormInput from '../../../baseComponents/FormInput';
import Modal from '../../baseComponents/Modal';
import {
  IntegrationConnectedQuery,
  Integration_Type,
  Integration_Type_Requirement,
  useFeedbackIntegrationMutation,
  useIntegrationConnectedLazyQuery,
} from '../../../generated/graphql';
import { ApolloError } from '@apollo/client';
import toast from 'react-hot-toast';
import { useValidTeamAppContext } from '../../../v2/contexts/AppContext';
import Toggle from '../Toggle';
import UserContext from '../../../v2/contexts/UserContext';
import TooltipIcon from './TooltipIcon';

interface EditIntegrationModalProps {
  modalOpen: boolean;
  setModalOpen: (open: boolean) => void;
  integration: Integration_Type;
  requirements: Integration_Type_Requirement[];
  setRequirements: (reqs: Integration_Type_Requirement[]) => void;
  setSuccessModalOpen?: (open: boolean) => void;
}

const EditIntegrationModal = ({ modalOpen, setModalOpen, requirements, setRequirements, integration, setSuccessModalOpen }: EditIntegrationModalProps) => {
  const { curTeamId: teamId } = useValidTeamAppContext();
  const { user } = useContext(UserContext);
  /// test connection
  const [getIntegrationConnected, { loading, error, data }] = useIntegrationConnectedLazyQuery();
  const [setFeedbackIntegration, mutation] = useFeedbackIntegrationMutation();
  const [zdskAdminSettingsShown, setZdskAdminSettingsShown] = useState(false);

  // validation for form. Check that all 'required' Requirements have values and that the connection has been
  // successfully tested
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const [canSubmit, setCanSubmit] = useState(false);
  const [lockSubmit, setLockSubmit] = useState(false); // lock submit button to prevent double clicks
  const [canValidate, setCanValidate] = useState(false);

  useEffect(() => {
    setErrorMessage(error?.message);
  }, [error]);

  const allRequirementsHaveValue = (requirements: Integration_Type_Requirement[]): boolean => {
    requirements.forEach((requirement) => {
      if (requirement.required && requirement.value.length === 0) {
        return false;
      }
    });
    return true;
  };
  const updateRequirementValue = (requirementToUpdate: Integration_Type_Requirement, newValue: string) => {
    setRequirements(
      requirements.map((requirement) => {
        if (requirement.id === requirementToUpdate.id) {
          return {
            ...requirement,
            value:
              requirement.value.length === 1
                ? requirement.value.map((value) => {
                    return {
                      ...value,
                      value: newValue,
                      requirement: requirement,
                    };
                  })
                : [{ value: newValue, id: 0, requirement: requirement }],
          };
        }
        return requirement;
      })
    );

    setCanValidate(allRequirementsHaveValue(requirements));
  };
  const testConnection = async () => {
    try {
      const val = await getIntegrationConnected({
        variables: {
          input: {
            integrationTypeId: integration.id,
            teamId: teamId,
            requirements: requirements
              .filter((req) => req.required || !!req.value?.[0]?.value)
              .map((requirement) => {
                if (requirement.value.length !== 1) {
                  throw new Error('Need an input for ' + requirement.title);
                }
                return {
                  id: requirement.id,
                  key: requirement.key,
                  value: requirement.value[0].value,
                };
              }),
          },
        },
      });
      if (val.data?.testIntegrationConnected?.success) {
        setCanSubmit(allRequirementsHaveValue(requirements));
      }
    } catch (err: any) {
      setErrorMessage(err.message);
    }
  };

  const updateFeedbackIntegration = async () => {
    await setFeedbackIntegration({
      variables: {
        input: {
          // this is going to need to change to handle multiple of the same integration types per team. We'll need to actually find the 'right' integration.
          // for now just make sure to pass in the integrationId otherwise the backend will create another integration for this team.
          feedbackIntegrationId: integration.teamIntegration.length > 0 ? integration.teamIntegration[0].id : undefined,
          integrationTypeId: integration.id,
          teamId: teamId,
          requirements: requirements
            .filter((req) => req.required || !!req.value?.[0]?.value)
            .map((requirement) => {
              if (requirement.value.length !== 1) {
                throw new Error('Need an input for ' + requirement.title);
              }
              return {
                id: requirement.id,
                key: requirement.key,
                value: requirement.value[0].value,
              };
            }),
        },
      },
      // refetch data for the integrations page. This loads in the integration with the id we just updated. This makes it so if the user somehow clicks submit again the request will be submitted with an integration id and not create a new duplicate integration.
      // this also shows the integration as connected.
      refetchQueries: ['Integrations'],
      onCompleted() {
        setModalOpen(false);
        setLockSubmit(false);
        //If you open a modal before the previous modal transition finishes (that's embedded in the Modal CSS code), the scrollbar starts misbehaving.
        setTimeout(() => {
          setSuccessModalOpen?.(true);
        }, 301);
      },
      onError(err: ApolloError) {
        setErrorMessage(err.message);
        setLockSubmit(false);
      },
    });
    setModalOpen(false);
  };

  const getFormButtons = (loading: boolean, enableSubmit: boolean) => {
    return (
      <div className="mt-8 flex flex-row justify-between gap-x-4 text-center">
        <Button variant={ButtonVariant.Tertiary} onClick={() => setModalOpen(false)} text="Cancel"></Button>
        <Button
          variant={ButtonVariant.Primary}
          text="Submit"
          onClick={() => {
            setLockSubmit(true);
            updateFeedbackIntegration();
          }}
          loadingConfirm={loading}
          disabled={!enableSubmit || loading || lockSubmit}
        ></Button>
      </div>
    );
  };

  const getSuccessOrErrorIcon = (error: string | undefined, data: IntegrationConnectedQuery | undefined): JSX.Element => {
    if (data?.testIntegrationConnected?.success) {
      return (
        <div className="flex items-center">
          <CheckIcon className="h-8 w-8" color="green" />
        </div>
      );
    } else if (error) {
      return <ExclamationCircleIcon className="h-8 w-8" color="red" />;
    } else {
      return <div></div>;
    }
  };

  const getSuccessOrError = (integration: Integration_Type, data: IntegrationConnectedQuery | undefined, error: string | undefined): JSX.Element => {
    if (data?.testIntegrationConnected?.success) {
      return (
        <p className="mt-2 text-sm text-green-600">
          Connection to {integration.title} validated. Click <b>Submit</b> to complete the integration
          <br />
        </p>
      );
    } else if (error) {
      return <p className="mt-2 text-sm text-red-600">{error}</p>;
    } else {
      return <p className="mt-2 text-sm"> </p>;
    }
  };

  const oAuthRedirect = () => {
    if (integration.title.includes('Zendesk')) {
      // special case for zendesk
      zendeskRedirect();
    } else {
      // general oAuth redirect
      // get redirect url from integration
      // swap in requirement values necessary for the url to be complete.
      let url = integration.authRedirect!;
      const requirementsForAuthRedirect = requirements.filter((req) => req.requiredForAuthRedirect);
      const stateProps: Record<string, string | number> = { redirect: window.location.href, teamId: teamId, scraperKey: integration.scraperKey };
      requirementsForAuthRedirect.map((req) => {
        if (!req.value || req.value.length === 0) {
          throw new Error(`Cannot complete redirect missing value for ${req.title}.`);
        }
        if (!req.frontendReplacementKey) {
          throw new Error(`Requirement not specified with a replacement key ${req.title}`);
        }
        url = url.replace(req.frontendReplacementKey, req.value[0].value);
        stateProps[req.key] = req.value[0].value;
      });
      const redirect = `${url}&state=${JSON.stringify(stateProps)}`;
      window.location.replace(redirect);
    }
  };

  const zendeskRedirect = () => {
    const zendeskURL = requirements
      .find((req) => req.key === 'url_zendesk')
      ?.value.find((v) => v.requirement.key === 'url_zendesk')
      ?.value.replace(/^https?:\/\//, '');

    if (!zendeskURL || !new RegExp('.+.zendesk.com$').test(zendeskURL)) {
      return toast.error("Invalid Zendesk URL - make sure you've entered a valid URL with the format mycompany.zendesk.com");
    }
    const redirect = `${integration.authRedirect?.replace('replace-with-subdomain', zendeskURL.replace('.zendesk.com', ''))}&state=${encodeURIComponent(
      JSON.stringify({
        teamId: teamId,
        zendeskURL: zendeskURL,
        redirect: window.location.href,
      })
    )}`;
    window.location.replace(redirect);
  };
  return (
    /*
      There are several Zendesk-related conditions here. This is a first attempt at changing the flow a little bit.

      Because Integrations work in different ways, we need a more general approach to them. This needs more brainstorming (TODO), 
      but once we decide a common flow we can improve this section of code.
      
      */
    <Modal title={integration.title} open={modalOpen} setOpen={setModalOpen}>
      <Form bottomRow={getFormButtons(mutation.loading, canSubmit || integration.teamIntegration.length > 0)}>
        <div className="space-y-2">
          {requirements.map((requirement) => {
            if (!zdskAdminSettingsShown) {
              if (requirement.key === 'authToken' && !requirement.value[0]?.value) return;
            }
            if (requirement.key === 'summarize') return;
            if (requirement.key === 'ticket_amount') {
              if (!user?.isUnwrapper) return;
              if (!zdskAdminSettingsShown) return;
            }
            return (
              <FormInput
                disabled={!zdskAdminSettingsShown && integration.title === 'Zendesk' && requirement.key === 'authToken'}
                type={requirement.obfuscate ? 'password' : 'text'}
                description={requirement.description}
                label={requirement.title}
                key={requirement.id}
                placeholder={requirement.example}
                value={requirement.value[0]?.value}
                onChange={(e) => {
                  updateRequirementValue(requirement, e.target.value);
                }}
              />
            );
          })}
          <div className="pt-5">
            <div className="flex flex-row justify-end">
              {user?.isUnwrapper && integration.title.includes('Zendesk') ? (
                <div className="flex flex-row gap-x-1 items-center">
                  <div className="flex flex-row gap-x-1 items-center">
                    <p>Admin Settings</p>
                    <TooltipIcon
                      tooltipContent={
                        'For Unwrap users. This shows admin fields, and lets you enter the API key manually and click VALIDATE without having to Auth. Useful after user authed Zendesk once, then we grab the API key from the DB.'
                      }
                    />
                  </div>
                  <Toggle initialState={false} value={zdskAdminSettingsShown} onSwitch={() => setZdskAdminSettingsShown((prev) => !prev)} />
                </div>
              ) : null}
              <div className="items-right flex justify-end space-x-5 sm:col-span-6">
                {getSuccessOrErrorIcon(errorMessage, data)}

                {(integration.title.includes('Zendesk') ||
                  (integration.requirements.find((req) => req.requiredForAuthRedirect) && integration.authRedirect)) && (
                  <Button
                    variant={ButtonVariant.Secondary}
                    text={'AUTHENTICATE'}
                    onClick={oAuthRedirect}
                    loadingConfirm={loading}
                    disabled={zdskAdminSettingsShown || (!!requirements.find((req) => req.key === 'authToken')?.value[0]?.value ?? false)}
                  />
                )}
                <Button
                  variant={ButtonVariant.Secondary}
                  id={`validate-integration-${integration.title.replace(' ', '-').toLowerCase()}`}
                  text={'VALIDATE'}
                  onClick={testConnection}
                  loadingConfirm={loading}
                  disabled={
                    !canValidate &&
                    !zdskAdminSettingsShown &&
                    !(integration.title !== 'Zendesk' ? true : !!requirements.find((req) => req.key === 'authToken')?.value[0]?.value)
                  }
                />
              </div>
            </div>
            <div className="flex mt-0 space-x-5 sm:col-span-6">{getSuccessOrError(integration, data, errorMessage)}</div>
          </div>
        </div>
      </Form>
    </Modal>
  );
};

export default EditIntegrationModal;
