import { useState } from 'react';
import Button, { ButtonVariant } from '../baseComponents/Button';
import logoUnwrap from '../../assets/unwrap_logo.svg';
import { useCreateSecretMutation } from '../../generated/graphql';

export default function SecretPage() {
  const [secretValue, setSecretValue] = useState<string | undefined>(undefined);
  const [secretTitle, setSecretTitle] = useState<string | undefined>(undefined);
  const [createSecret, { loading, error }] = useCreateSecretMutation();
  const [errorDisplay, setError] = useState<string | undefined>(undefined);
  const [successDisplay, setSuccess] = useState<string | undefined>(undefined);

  const uploadSecret = async (secretTitle: string | undefined, secretValue: string | undefined) => {
    if (!secretTitle || !secretValue) {
      setError('Please enter a secret name and value');
      return;
    }
    const response = await createSecret({
      variables: {
        secretName: secretTitle,
        secretValue: secretValue,
      },
    });
    if (error) {
      setError(error.message);
    } else {
      setSuccess(`Successfully uploaded secret ${response.data?.createSecret}`);
    }
  };

  return (
    <div className="flex min-h-screen bg-milk font-sofiapro">
      <div className="flex flex-1 flex-col justify-center">
        <div className="mx-auto w-full max-w-sm lg:w-96">
          <div>
            <img className="h-12 w-auto" src={logoUnwrap} alt="Workflow" />
          </div>

          <div className="mt-8">
            <div className="mt-6">
              <form
                className="space-y-6"
                onSubmit={(e) => {
                  uploadSecret(secretTitle, secretValue);
                  e.preventDefault();
                }}
              >
                <div>
                  <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                    Secret Name
                  </label>
                  <div className="mt-1">
                    <input
                      id="secret-name"
                      name="text"
                      type="text"
                      required
                      className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                      onChange={(e) => setSecretTitle(e.target.value)}
                    />
                  </div>
                </div>

                <div className="space-y-1">
                  <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                    Secret Value
                  </label>
                  <div className="mt-1">
                    <textarea
                      placeholder={'plain text'}
                      required
                      className="focus:outline-nonebg-white h-full w-full flex-grow resize-none rounded-md border border-gray-300 py-2 pl-3 pr-14 text-blueberry shadow-sm focus:border-gray-300 focus:outline-none focus:ring-0 sm:text-sm"
                      value={secretValue}
                      onChange={(e) => setSecretValue(e.target.value)}
                    />
                  </div>
                </div>
                {errorDisplay && (
                  <div className="mt-4">
                    <h1 id="error-message" className="text-sm font-medium text-red-600">
                      {errorDisplay}
                    </h1>
                  </div>
                )}
                {successDisplay && (
                  <div className="mt-4">
                    <h1 id="error-message" className="text-sm font-medium text-green-600">
                      {successDisplay}
                    </h1>
                  </div>
                )}

                <div className="flex flex-col gap-y-4">
                  <Button id="sign-in-button" variant={ButtonVariant.Primary} text={'Save'} submit loadingConfirm={loading} expandWidth />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
