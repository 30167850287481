import { XMarkIcon } from '@heroicons/react/24/solid';
import { useState } from 'react';

export const CustomerFacingAlert = ({ setCustomerFacingAlertOpen }: { setCustomerFacingAlertOpen?: (bool: boolean) => void }) => {
  const [visible, setVisible] = useState(true);
  return visible ? (
    <div className="flex flex-row items-center justify-between gap-x-2 rounded-md bg-raspberry p-2 text-milk w-full">
      <h1 className="text-sm font-semibold">Customer Facing Team</h1>
      <XMarkIcon
        className="h-4 w-4 cursor-pointer"
        aria-hidden="true"
        onClick={() => {
          setCustomerFacingAlertOpen?.(false);
          setVisible(false);
        }}
      />
    </div>
  ) : (
    <></>
  );
};
