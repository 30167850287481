import { Combobox } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/24/outline';
import { XMarkIcon } from '@heroicons/react/24/solid';
import { useState, RefObject } from 'react';
import { uniqueId } from 'underscore';
import { capitalizeFirstLetter } from '../v2/util';
import AdjustableLoadingIcon from './AdjustableLoadingIcon';
import { IDropDownItem } from './DropDown';

function classNames(...classes: any[]): string {
  return classes.filter(Boolean).join(' ');
}

interface ComboBoxProps {
  comboBoxData: IDropDownItem[];
  selectedItem?: IDropDownItem;
  setSelectedItem: (item: IDropDownItem | undefined) => void;
  useDisplayName?: boolean;
  placeholder?: string;
  defaultOption?: boolean;
  boxRef?: RefObject<HTMLDivElement> | null;
  multiple?: boolean;
  capitalizeOnlyFirstLetter?: boolean;
  /**
   * @description whether or not you want to allow the user to click the X button/select an empty query
   */
  allowEmptySelection?: boolean;
  maxDropDownSize?: string;
  loading?: boolean;
}

export const ComboBox = ({
  comboBoxData,
  selectedItem,
  setSelectedItem,
  placeholder,
  defaultOption,
  boxRef,
  useDisplayName,
  multiple,
  capitalizeOnlyFirstLetter = true,
  loading,
  allowEmptySelection = true,
  maxDropDownSize = 'max-h-60',
}: ComboBoxProps): JSX.Element => {
  const [query, setQuery] = useState<string>('');
  const filteredItems =
    query === ''
      ? comboBoxData
      : comboBoxData.filter((data) => {
          return data.name ? data.name.toLowerCase().includes(query.toLowerCase()) : data.title?.toLowerCase().includes(query.toLowerCase());
        });

  return (
    <Combobox
      multiple={multiple}
      as="div"
      value={selectedItem}
      onChange={(item: IDropDownItem | undefined) => {
        setSelectedItem(item);
        setQuery('');
      }}
      className="w-full"
      ref={boxRef}
    >
      <div className="relative w-full">
        <Combobox.Input
          placeholder={placeholder}
          className="w-full rounded-md border border-gray-300 bg-white py-1.5 pl-3 pr-14 text-blueberry shadow-sm focus:border-blueberry focus:outline-none focus:ring-1 focus:ring-blueberry sm:text-sm"
          onChange={(event) => setQuery(event.target.value)}
          displayValue={(item: IDropDownItem) => {
            if (useDisplayName) {
              return item?.displayName ?? item?.name;
            }
            return item?.name ? item.name : item?.title ? item.title : '';
          }}
        />
        <div className="absolute top-0 flex h-full w-full flex-row justify-end">
          <Combobox.Button className="flex w-full" onClick={() => setQuery('')}></Combobox.Button>
          <div className="flex items-center">
            {loading ? (
              <AdjustableLoadingIcon width={5} height={5} />
            ) : allowEmptySelection && selectedItem ? (
              <XMarkIcon
                onClick={() => {
                  setSelectedItem(undefined);
                  setQuery('');
                }}
                className="h-4 w-4 cursor-pointer text-blueberry"
                aria-hidden="true"
              />
            ) : null}

            <Combobox.Button className="w-8">
              <ChevronUpDownIcon onClick={() => setQuery('')} className="h-6 w-6 text-blueberry" aria-hidden="true" />
            </Combobox.Button>
          </div>
        </div>

        {filteredItems.length > 0 && (
          <Combobox.Options
            className={`absolute z-10 mt-1 ${maxDropDownSize} w-full overflow-auto rounded-md bg-white py-1 text-base text-blueberry shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm`}
          >
            {filteredItems
              .sort((a, b) => {
                const nameA = !a.name ? a.title : a.name;
                const nameB = !b.name ? b.title : b.name;
                if (!nameA || !nameB) return 0;
                return nameA.localeCompare(nameB);
              })
              .map((item) => (
                <Combobox.Option
                  key={item.id}
                  value={item}
                  className={({ active }) =>
                    classNames('relative cursor-pointer select-none py-2 pl-3 pr-9', active ? 'bg-blueberry text-white' : 'text-gray-900')
                  }
                >
                  {({ active, selected }) => (
                    <>
                      {useDisplayName ? (
                        <span className={classNames('block', selected && 'font-semibold')}>
                          {capitalizeOnlyFirstLetter
                            ? capitalizeFirstLetter(item.displayName ? item.displayName : item.name)
                            : item.displayName
                            ? item.displayName
                            : item.name}
                        </span>
                      ) : (
                        <span className={classNames('block', selected && 'font-semibold')}>
                          {capitalizeOnlyFirstLetter ? capitalizeFirstLetter(item.name ? item.name : item.title) : item.name ? item.name : item.title}
                        </span>
                      )}

                      {selected && (
                        <span className={classNames('absolute inset-y-0 right-0 flex items-center pr-4', active ? 'text-white' : 'text-blueberry')}>
                          <CheckIcon className="h-5 w-5" aria-hidden="true" />
                        </span>
                      )}
                    </>
                  )}
                </Combobox.Option>
              ))}
          </Combobox.Options>
        )}
        {defaultOption && filteredItems.length === 0 && query !== '' && (
          <Combobox.Options
            className={`absolute z-10 mt-1 ${maxDropDownSize} w-full overflow-auto rounded-md bg-white py-1 text-base text-blueberry shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm`}
          >
            <Combobox.Option
              value={{ id: uniqueId('option-'), name: query }}
              className={({ active }) => classNames('relative cursor-pointer select-none py-2 pl-3 pr-9', active ? 'bg-blueberry text-white' : 'text-gray-900')}
            >
              {({ active, selected }) => (
                <>
                  <span className={classNames('block', selected && 'font-semibold')}>Create new tag: "{query}"</span>

                  {selected && (
                    <span className={classNames('absolute inset-y-0 right-0 flex items-center pr-4', active ? 'text-white' : 'text-blueberry')}>
                      <CheckIcon className="h-5 w-5" aria-hidden="true" />
                    </span>
                  )}
                </>
              )}
            </Combobox.Option>
          </Combobox.Options>
        )}
      </div>
    </Combobox>
  );
};
