import Tippy from '@tippyjs/react';
import { QuestionMarkCircleIcon } from '@heroicons/react/20/solid';
interface TooltipIconProps {
  tooltipContent: string;
}
const TooltipIcon = ({ tooltipContent }: TooltipIconProps) => {
  return (
    <Tippy content={tooltipContent} duration={300} appendTo={'parent'}>
      <div>
        <QuestionMarkCircleIcon className="h-4 w-4 text-blueberry" aria-hidden="true" />
      </div>
    </Tippy>
  );
};
export default TooltipIcon;
