import { Auth } from 'aws-amplify';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth/lib/types';
import toast, { Toaster } from 'react-hot-toast';
import { organizationManagedByOkta } from '../../../AuthConfigs';

interface IGoogleResponse {
  urlQueryParams?: string;
}

const GoogleResponse = ({ urlQueryParams }: IGoogleResponse) => {
  const [retry, setRetry] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<undefined | string>(undefined);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const signInWithGoogle = async () => {
    await Auth.federatedSignIn({ provider: CognitoHostedUIIdentityProvider.Google, customState: urlQueryParams });
  };

  const getInfo = async () => {
    const session = await Auth.currentSession();
    if (!session.isValid()) {
      Sentry.captureMessage('Failed to get auth session after login', 'fatal');
      toast.error('Something went wrong during login. Please try again.');
      setRetry(true);
    }
    // if the organization is managed through okta force a signout
    const creds = session.getIdToken().payload;
    if (organizationManagedByOkta(creds.email)) {
      setErrorMsg('Error: Your organization is managed through Okta. Please sign in through your Okta portal.');
      setRetry(false);
      setLoading(false);
      return;
    }
    navigate('/');
  };

  useEffect(() => {
    getInfo();
  }, []);
  return (
    <div className="min-h-screen bg-milk font-sofiapro">
      <Toaster
        toastOptions={{
          duration: 4000,
          className: '',
          style: {
            border: '1px solid #292E5B',
            borderRadius: '8px',
          },
        }}
      />
      <div className="flex flex-col gap-y-3 ">
        <div className="mt-[100px] flex h-full w-full flex-col items-center justify-center">
          {errorMsg && (
            <div className="mt-4 mx-4">
              <h1 id="error-message" className="text-lg font-medium text-red-600">
                {errorMsg}
              </h1>
            </div>
          )}
          {retry ? (
            <>
              <h2 className="my-3 text-center text-3xl font-semibold">Retry Signin with Google</h2>
              <div>
                <a
                  href="#"
                  className="inline-flex w-full justify-center gap-x-5 rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-500 shadow-sm hover:bg-gray-50"
                  onClick={() => signInWithGoogle()}
                >
                  <svg className="h-5 w-5" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 48 48">
                    <defs>
                      <path
                        id="a"
                        d="M44.5 20H24v8.5h11.8C34.7 33.9 30.1 37 24 37c-7.2 0-13-5.8-13-13s5.8-13 13-13c3.1 0 5.9 1.1 8.1 2.9l6.4-6.4C34.6 4.1 29.6 2 24 2 11.8 2 2 11.8 2 24s9.8 22 22 22c11 0 21-8 21-22 0-1.3-.2-2.7-.5-4z"
                      />
                    </defs>
                    <clipPath id="b">
                      <use xlinkHref="#a" overflow="visible" />
                    </clipPath>
                    <path clip-path="url(#b)" fill="#FBBC05" d="M0 37V11l17 13z" />
                    <path clip-path="url(#b)" fill="#EA4335" d="M0 11l17 13 7-6.1L48 14V0H0z" />
                    <path clip-path="url(#b)" fill="#34A853" d="M0 37l30-23 7.9 1L48 0v48H0z" />
                    <path clip-path="url(#b)" fill="#4285F4" d="M48 48L17 24l-4-3 35-10z" />
                  </svg>
                  <span className="">Sign In with Google</span>
                </a>
              </div>
            </>
          ) : loading ? (
            <>
              <div className="h-32 w-32 animate-spin rounded-full border-t-2 border-b-2 border-blueberry"></div>
              <h2 className="my-3 text-center text-3xl font-semibold">Loading</h2>
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
};

export default GoogleResponse;
