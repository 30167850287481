import { useEffect, useState } from 'react';
import { ArrayElement } from '../../utilities';
import { Chart, Chart_Type, GetCustomChartsQuery, SeriesData, Y_Axis_Data } from '../../generated/graphql';
import ChartView from '../baseComponents/ChartView';
import { ChartType } from '../../v2/hooks/ChartHook';
import { ChartOptions, ChartTypeRegistry, CoreScaleOptions, Scale, Tick, TooltipItem } from 'chart.js';
import { truncateAndEllipsis } from '../../v2/util';

const isPercentageChart = (yAxisData: Y_Axis_Data) => yAxisData === Y_Axis_Data.Favorability || yAxisData === Y_Axis_Data.RelativeShare;
const colors = [
  '#CD5A76',
  '#F7B731',
  '#6bb776',
  '#B66D0D',
  '#2C3E50',
  '#9B59B6',
  '#F4D03F',
  '#EC7063',
  '#5499C7',
  '#E74C3C',
  '#F5B7B1',
  '#34495E',
  '#7D3C98',
  '#F8C471',
  '#F0B27A',
  '#1ABC9C',
  '#E67E22',
  '#5D6D7E',
  '#AF7AC5',
];

const sentimentColorMap: Record<string, string> = {
  Negative: '#CD5A76',
  Neutral: '#F7B731',
  Positive: '#6bb776',
};

const npsBucketColorMap: Record<string, string> = {
  detractor: '#CD5A76',
  passive: '#F7B731',
  promoter: '#6bb776',
};

const generateDataset = (
  data: SeriesData,
  type: 'normalized' | 'aggregate',
  overallChartData: ArrayElement<GetCustomChartsQuery['getCharts']>,
  index: number,
  fill?: boolean
) => {
  const dataToMap = type === 'normalized' ? data.normalizedData : data.aggregateData;
  return dataToMap?.slice(0, 10).map((curData, idx) => {
    const labelName: string | undefined = data.breakdownLabels?.[idx]?.name;
    const npsNames = ['detractor', 'passive', 'promoter'];
    const isNpsBucket =
      data.breakdownLabels?.length === 3 &&
      data.breakdownLabels
        ?.map((obj) => obj.name)
        .sort()
        .every((name, idx) => name.toLowerCase() === npsNames[idx]);
    let color: string = colors[idx + index];
    if (labelName) {
      if (data.breakdown === 'sentiment') color = sentimentColorMap[labelName];
      else if (data.breakdown === 'segment' && isNpsBucket) color = npsBucketColorMap[labelName.toLowerCase()];
    }

    return {
      label: labelName ?? 'All',
      data: curData,
      backgroundColor: color,
      borderColor: color,
      fill,
    };
  });
};

const getChartTypeRegistry = (type: Chart_Type) => {
  if (type === Chart_Type.StackedBar) return 'bar' as keyof ChartTypeRegistry;
  if (type === Chart_Type.Line) return 'line' as keyof ChartTypeRegistry;
  return 'line';
};

export const CustomChart = ({ customChartData }: { customChartData: ArrayElement<GetCustomChartsQuery['getCharts']> }) => {
  const [chartData, setChartData] = useState<any>();
  const [randKey, setRandKey] = useState<number>(Math.random());
  useEffect(() => {
    setRandKey(Math.random());
  }, [customChartData.type]);

  useEffect(() => {
    if (customChartData.seriesData && customChartData.seriesData.length > 0) {
      let labels: string[] = customChartData.seriesData[0].chartLabels; //Labels should be the same for all. Maybe we can add a check?
      let datasets: any[] = [];
      customChartData.seriesData?.map((data, i) => {
        //Absolute Chart -> Aggregate Data ||| Percentage Chart -> Normalized Data
        if (isPercentageChart(customChartData.y_axis_data!))
          datasets.push(...generateDataset(data as SeriesData, 'normalized', customChartData as Chart, i, customChartData.type === Chart_Type.StackedArea));
        else datasets.push(...generateDataset(data as SeriesData, 'aggregate', customChartData as Chart, i, customChartData.type === Chart_Type.StackedArea));
      });
      const newChartData = { labels, datasets };
      setChartData(newChartData);
    }
  }, [customChartData]);

  return (
    <div className="h-72">
      {chartData ? (
        <ChartView
          key={randKey}
          type={getChartTypeRegistry(customChartData.type)}
          data={chartData}
          options={getChartOptions(
            customChartData.y_axis_data === Y_Axis_Data.RelativeShare
              ? ChartType.CustomPercentage
              : customChartData.y_axis_data === Y_Axis_Data.Favorability
              ? ChartType.Favorability
              : ChartType.CustomAbsolute,
            customChartData.type,
            customChartData.seriesData?.[0]?.tooltipLabels ?? []
          )}
        />
      ) : null}
    </div>
  );
};

const getChartOptions = (chartType: ChartType, chart_type: Chart_Type, fullLabels: string[], disableAnnotations?: boolean): ChartOptions => {
  //Uhh..we got a naming issue, ChartType vs Chart_Type. One represents if it's absolute, relative, etc. The other represents the kind of chart.
  //We need to think about better names.

  return {
    animations: {
      numbers: { duration: 500 },
    },
    layout: {
      padding: {
        top: 0,
        bottom: 0,
      },
    },
    indexAxis: 'x',
    //@ts-ignore
    lineTension: 0.3,
    interaction: {
      ...(chart_type === Chart_Type.StackedArea && {
        mode: 'nearest',
        axis: 'x',
      }),
      intersect: false,
    },
    hover: {
      mode: 'index',
      intersect: false,
    },
    plugins: {
      annotation: {
        interaction: {
          intersect: false,
        },
      },
      legend: {
        onClick: (e, legendItem, legend) => {
          const chart = legend.chart;
          const datasetIndex = legendItem.datasetIndex!;
          chart.getDatasetMeta(datasetIndex).hidden = !chart.getDatasetMeta(datasetIndex).hidden;
          chart.update();
        },
        display: true,
        labels: {
          color: '#292E5B',
          font: {
            size: 13,
            family: 'SofiaPro',
          },
          boxWidth: 10,
          boxHeight: 10,
          generateLabels: function (chart: any) {
            return chart.data.datasets.map(function (dataset: any, index: number) {
              const meta = chart.getDatasetMeta(index);
              let label = dataset.label;
              if (label.length > 25) {
                label = label.substring(0, 25) + '...';
              }
              return {
                text: label,
                fillStyle: dataset.backgroundColor,
                strokeStyle: dataset.backgroundColor,
                lineWidth: 2,
                datasetIndex: index,
                hidden: !meta.visible,
                // Add more properties if needed
              };
            });
          },
        },
      },
      //@ts-ignore
      tooltip: {
        backgroundColor: '#FFF',
        bodyColor: '#292E5B',
        titleColor: '#292E5B',
        footerColor: '#292E5B',
        mode: 'index',
        position: 'custom',
        intersect: false,
        enabled: true,
        titleFont: {
          family: 'SofiaPro',
          size: 10,
        },
        titleMarginBottom: 1,
        bodyFont: {
          family: 'SofiaPro',
          size: 12,
        },
        padding: 10,
        footerFont: {
          size: 10,
          family: 'SofiaPro',
          style: 'italic',
          weight: 'normal',
        },
        borderWidth: 1,
        borderColor: '#292E5B',
        footerMarginTop: 1,
        callbacks:
          chartType === ChartType.CustomAbsolute || chartType === ChartType.CustomPercentage || ChartType.Favorability
            ? {
                title: (tooltipItem: TooltipItem<'bar' | 'line'>[]) => {
                  if (tooltipItem.length > 0) {
                    return fullLabels[tooltipItem[0].dataIndex];
                  }
                  return '';
                },
                label: function (data: TooltipItem<'bar' | 'line'>) {
                  if (chartType === ChartType.CustomPercentage) {
                    let floor = Math.round(parseFloat(data.formattedValue.replace(/,/, '.')) * 10) / 10;
                    if (floor === 0) {
                      floor = Number(data.formattedValue);
                    }
                    return floor + '% of feedback' + ' - ' + data.dataset.label;
                  }
                  if (chartType === ChartType.Favorability) {
                    let floor = Math.round(parseFloat(data.formattedValue.replace(/,/, '.')) * 10) / 10;
                    if (floor === 0) {
                      floor = Number(data.formattedValue);
                    }
                    return floor + '% positive sentiment rate' + ' - ' + data.dataset.label;
                  }

                  if (chartType === ChartType.CustomAbsolute) {
                    let finalVal = data.formattedValue + ' mentions';
                    if (data.dataset.label !== 'All') finalVal += ' - ' + truncateAndEllipsis(data.dataset.label, 60);
                    return finalVal;
                  }
                  return data.formattedValue;
                },
              }
            : {},
      },
    },
    elements: {
      point: {
        radius: 3,
      },
      bar: {
        borderRadius: 4,
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        stacked: chart_type === Chart_Type.StackedBar,
        display: true,
        grid: {
          display: false,
        },
        ticks: {
          display: true,
          color: '#292E5B',
          font: {
            size: 14,
            family: 'SofiaPro',
          },
        },
      },
      ...(chartType === ChartType.CustomAbsolute && {
        mentions: {
          stacked: chart_type === Chart_Type.StackedBar || chart_type === Chart_Type.StackedArea,
          position: 'left',
          grid: {
            display: true,
            color: '#f1f1f1',
            drawBorder: false,
          },
          ticks: {
            padding: 15,
            precision: 0,
            autoSkip: false,
            color: '#292E5B',
            font: {
              size: 14,
              family: 'SofiaPro',
            },
          },
          beginAtZero: true,
        },
      }),
      ...((chartType === ChartType.CustomPercentage || chartType === ChartType.Favorability) && {
        percentage: {
          stacked: chart_type === Chart_Type.StackedBar || chart_type === Chart_Type.StackedArea,
          position: 'left',
          grid: {
            display: true,
            color: '#f1f1f1',
            drawBorder: false,
          },
          display: true,
          ticks: {
            padding: 15,
            autoSkip: false,
            color: '#292E5B',
            font: {
              size: 14,
              family: 'SofiaPro',
            },
            callback: function (value: string | number, index: number, values: Tick[]) {
              return value + '%';
            },
          },
          beginAtZero: true,
          afterDataLimits: (scale: Scale<CoreScaleOptions>) => {
            const datasets = scale.chart.data.datasets;

            //If there's a breakdown, get the max but with each point's values added together
            if (chart_type === Chart_Type.StackedBar || chart_type === Chart_Type.StackedArea) {
              const dataValues = datasets[0].data.map((_: any, index: number) =>
                datasets.reduce((acc: number, cur: any) => {
                  return acc + cur.data[index];
                }, 0)
              );
              const maxDataPoint = +Math.max(...dataValues).toFixed(2);

              scale.max = Math.max(maxDataPoint, 100);
              scale.min = 0;
            } else {
              //If no breakdown, get the max of all datasets
              const dataValues = datasets.flatMap((dataset) => dataset.data) as number[];
              const maxDataPoint = Math.max(...dataValues);
              const minDataPoint = Math.min(...dataValues);

              let padding;
              if (maxDataPoint < 10 && maxDataPoint - minDataPoint < 3) {
                padding = 0.1;
              } else {
                padding = 5;
              }

              scale.max = Math.min(maxDataPoint + padding, 100);
              scale.min = 0;
            }
          },
        },
      }),
    },
  };
};
