import { Fragment, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { TagIcon } from '@heroicons/react/24/solid';
import 'tippy.js/dist/tippy.css';
import DropDown from '../../../baseComponents/DropDown';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function ChooseDropdownModal({ modalOpen, callbackModal, confirmButton, loadingConfirm, data, title, subtitle, ...props }) {
  const [selectedItem, setSelectedItem] = useState(null);

  return (
    <Transition.Root show={modalOpen} as={Fragment}>
      <Dialog as="div" className="fixed inset-0 z-50" onClose={callbackModal}>
        <div className="flex min-h-screen items-end justify-center px-8 pt-4 pb-20 text-center font-sofiapro sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:h-screen sm:align-middle" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="my-4 inline-block transform rounded-md bg-blueberry px-4 pt-5 pb-4 text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-md sm:p-12 sm:align-middle xl:max-w-xl">
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  confirmButton(selectedItem);
                }}
              >
                <div>
                  <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full">
                    <TagIcon className="h-6 w-6 text-raspberry" aria-hidden="true" />
                  </div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-milk">
                      {title}
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-sm text-silver">{subtitle}</p>
                    </div>
                  </div>
                  <div className="mt-4 flex flex-col gap-y-0">
                    <DropDown dropDownData={data} selectedItem={selectedItem} setSelectedItem={(item) => setSelectedItem(item)} />
                  </div>
                </div>
                <div className="mt-8 grid grid-cols-3 items-center justify-center gap-x-4 text-center">
                  <button
                    type="button"
                    className="col-span-1 flex flex-row items-center justify-center gap-x-2 rounded-full bg-milk py-3 px-4 text-sm text-blueberry duration-200 hover:bg-opacity-80"
                    onClick={() => callbackModal()}
                  >
                    <h1 className="text-lg font-semibold">Cancel</h1>
                  </button>
                  <button
                    type="submit"
                    className={classNames(
                      'col-span-2 flex flex-row items-center justify-center gap-x-2 rounded-full bg-raspberry py-3 px-4 text-sm text-milk duration-200',
                      loadingConfirm ? 'opacity-50' : 'hover:bg-raspberry hover:bg-opacity-80 '
                    )}
                    disabled={loadingConfirm}
                  >
                    <h1 className="text-lg font-semibold">Confirm</h1>
                    {loadingConfirm && (
                      <svg className="h-4 w-4 animate-spin" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                        <path
                          className="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        ></path>
                      </svg>
                    )}
                  </button>
                </div>
              </form>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
