import { useState } from 'react';

export const Password = ({ setPasswordInput }: { setPasswordInput: (password: string) => void }) => {
  const [showPassword, setShowPassword] = useState(false);

  const handleShowPasswordToggle = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="space-y-1">
      <label htmlFor="password" className="block text-sm font-medium text-gray-700">
        Password
      </label>
      <div className="mt-1">
        <input
          id="password"
          name="password"
          type={showPassword ? 'text' : 'password'}
          autoComplete="current-password"
          required
          className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
          onChange={(e) => setPasswordInput(e.target.value)}
        />
      </div>
      <div className="flex items-center">
        <input type="checkbox" id="toggle-password-visible" className="hidden" checked={showPassword} onChange={handleShowPasswordToggle} />
        <label htmlFor="toggle-password-visible" className="flex items-center cursor-pointer text-sm mt-3 text-gray-700">
          <div className={`w-4 h-4 mr-1 border flex justify-center items-center ${showPassword ? 'border-blueberry bg-blueberry' : 'border-gray-400'} rounded`}>
            {showPassword && (
              <svg
                className="text-white h-3 w-3"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="3"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path d="M5 13l4 4L19 7" />
              </svg>
            )}
          </div>
        </label>
        <p className="mt-3.5 text-gray-900 text-sm">Show Password</p>
      </div>
    </div>
  );
};
