import { ChevronDoubleUpIcon } from '@heroicons/react/24/solid';
import { useEffect, useState } from 'react';
import { classNames } from '../../v2/util';

export const ScrollToTop = () => {
  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = () => {
    if (window.pageYOffset > 300) setIsVisible(true);
    else setIsVisible(false);
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    window.addEventListener('scroll', toggleVisibility);
    return () => {
      window.removeEventListener('scroll', toggleVisibility);
    };
  }, []);

  return (
    <div className="fixed bottom-[4.5rem] z-[999] right-3.5">
      <button
        type="button"
        id="scroll-to-top"
        className={classNames(
          isVisible ? 'opacity-100' : 'opacity-0',
          'h-full cursor-pointer rounded-full bg-blueberry p-2 text-milk duration-200 hover:bg-blueberry-darker'
        )}
        onClick={scrollToTop}
      >
        <ChevronDoubleUpIcon className="h-6 w-6" aria-hidden="true" />
      </button>
    </div>
  );
};
