import { XCircleIcon } from '@heroicons/react/24/outline';
import { PlusIcon } from '@heroicons/react/24/solid';
import Button, { ButtonSize, ButtonVariant } from '../baseComponents/Button';
import { ConnectionsQuery, useCreateOauthStateSecretMutation } from '../../generated/graphql';
import { ArrayElement } from '../../utilities';
import { useValidTeamAppContext } from '../../v2/contexts/AppContext';
import toast from 'react-hot-toast';
import { useState } from 'react';

interface ConnectionsListProps {
  connections?: ConnectionsQuery['connections'];
  loading: boolean;
  orgId: number;
  removeConnection: (id: number) => void;
  userIsAdmin?: boolean;
}

export default function ConnectionsList({ orgId, connections, loading, removeConnection, userIsAdmin, ...props }: ConnectionsListProps) {
  if (loading) return <div>Loading...</div>;
  return (
    <div className="overflow-hidden bg-white sm:rounded-md">
      <ul role="list" className="divide-y divide-gray-200">
        {connections?.map((connection) => (
          <ConnectionRow key={connection.id} connection={connection} removeConnection={removeConnection} userIsAdmin={userIsAdmin} />
        ))}
      </ul>
    </div>
  );
}

const ConnectionRow = ({
  connection,
  removeConnection,
  userIsAdmin,
}: {
  connection: ArrayElement<ConnectionsQuery['connections']>;
  removeConnection: (id: number) => void;
  userIsAdmin?: boolean;
}) => {
  const { curOrgId: orgId } = useValidTeamAppContext();
  const orgConnection = connection?.orgConnection[0];
  const [loadingOpenConnect, setLoadingOpenConnect] = useState(false);

  const [createOauthSecretMutation, createOauthData] = useCreateOauthStateSecretMutation();

  const handleConnectClick = async (connection: ArrayElement<ConnectionsQuery['connections']>) => {
    setLoadingOpenConnect(true);
    const res = await createOauthSecretMutation({
      variables: {
        orgId,
      },
      onError(error) {
        console.log('Error authenticating:', error);
        toast.error('Failed to authenticate - please try again.');
      },
    });
    const { authUrl } = connection;
    const state = JSON.stringify({
      secret: res.data?.createOauthStateSecret?.secret_state,
      connectionTypeId: connection.id,
      destinationURL: window.location.origin + window.location.pathname,
      orgId,
    });
    //The redirect_uri is needed when local testing. Otherwise it uses the first on the list, which is the prod Connections api.
    //const url = `${authUrl}&state=${state}&redirect_uri=https://qx7fv2t125.execute-api.us-east-2.amazonaws.com/slack/oauthd`;
    const url = `${authUrl}&state=${state}`;
    window.location.href = url;
  };
  return (
    <li key={connection.id}>
      <div className="block">
        <div className="flex items-center py-4">
          <div className="flex min-w-0 flex-1 items-center">
            <div className="flex-shrink-0">{connection.logoUrl && <img className="h-10 w-10 rounded-full" src={connection.logoUrl} alt="" />}</div>
            <div className="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
              <div>
                <h1 className="text-md truncate font-medium text-blueberry">{connection.title}</h1>
                <p className="flex items-center text-sm text-gray-500">
                  <span className="truncate">{connection.description}</span>
                </p>
              </div>
              <div className="flex items-center">
                {orgConnection ? <ConnectedIndicator workspace_name={orgConnection.connected_name} /> : <NotConnectedIndicator />}
              </div>
            </div>
          </div>
          <div>
            {orgConnection ? (
              <Button
                variant={ButtonVariant.Tertiary}
                size={ButtonSize.Small}
                disabled={!userIsAdmin}
                disabledTooltip="Only Admins can unlink connections."
                text="Unlink"
                icon={<XCircleIcon className="mb-0.5 h-4 w-4" />}
                iconPosition="left"
                onClick={() => removeConnection(orgConnection.id)}
              >
                <XCircleIcon className="h-5 w-5" />
                <h1>Unlink</h1>
              </Button>
            ) : (
              <Button
                text={'Connect'}
                variant={ButtonVariant.Tertiary}
                size={ButtonSize.Small}
                icon={loadingOpenConnect ? null : <PlusIcon className="h-3 w-3 stroke-2" />}
                iconPosition="left"
                onClick={() => handleConnectClick(connection)}
                loadingConfirm={loadingOpenConnect}
                loadingText={'Connecting...'}
              />
            )}
          </div>
        </div>
      </div>
    </li>
  );
};

const NotConnectedIndicator = () => (
  <div className="flex flex-col">
    <p className="flex items-center gap-x-1 text-sm text-gray-500">
      <span className="h-1.5 w-1.5 rounded-full bg-gray-500"></span>
      Not connected
    </p>
  </div>
);

const ConnectedIndicator = ({ workspace_name, ...props }: { workspace_name: string }) => (
  <div className="flex flex-col">
    <p className="flex items-center gap-x-1 text-sm text-green-500">
      <span className="h-1.5 w-1.5 rounded-full bg-green-500"></span>
      Connected
    </p>
    <span className="text-xs text-gray-500">
      Workspace: <span className="font-semibold text-blueberry">{workspace_name}</span>
    </span>
  </div>
);
