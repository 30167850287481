import { Dispatch, SetStateAction } from 'react';
import { Dialog } from '@headlessui/react';
import { ExclamationCircleIcon, XCircleIcon } from '@heroicons/react/24/solid';
import Modal from '../../baseComponents/Modal';
import Form from '../../../baseComponents/Form';
import Button, { ButtonVariant } from '../../baseComponents/Button';
import { GroupDependencies } from '../../../generated/graphql';

export default function DeleteGroupModal({
  modalOpen,
  callbackModal,
  confirmButton,
  loadingConfirm,
  dependencies,
}: {
  modalOpen: boolean;
  callbackModal: Dispatch<SetStateAction<boolean>>;
  confirmButton: () => void;
  loadingConfirm?: boolean;
  dependencies?: GroupDependencies;
}) {
  const onClickOk = async () => {
    await confirmButton();
  };
  const getFormButtons = () => {
    return (
      <div className="mt-4 flex flex-row justify-between gap-x-4 text-center">
        <Button variant={ButtonVariant.Tertiary} onClick={() => callbackModal(false)} text="Cancel"></Button>
        <Button
          variant={ButtonVariant.Primary}
          text={'Confirm'}
          disabled={loadingConfirm}
          onClick={() => {
            !!!dependencies ? onClickOk() : callbackModal(false);
          }}
          loadingConfirm={loadingConfirm}
        ></Button>
      </div>
    );
  };
  return (
    <Modal open={modalOpen} setOpen={callbackModal}>
      <div>
        {!!!dependencies ? (
          <>
            <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full ">
              <ExclamationCircleIcon className="h-8 w-8 text-raspberry" aria-hidden="true" />
            </div>
            <div className="mt-3 text-center sm:mt-5">
              <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                {'Are you sure you want to delete this group?'}
              </Dialog.Title>
            </div>
            <div className="mt-3 text-center sm:mt-5">
              <Dialog.Title as="h4" className="text-md font-normal leading-6 text-gray-900">
                {'This action is irreversible.'}
              </Dialog.Title>
            </div>
          </>
        ) : (
          <>
            <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full ">
              <XCircleIcon className="h-8 w-8 text-failure" aria-hidden="true" />
            </div>
            <div className="mt-3 text-center sm:mt-5">
              <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                This group couldn't be deleted because it has active dependencies.
                <br />
                You can try again after clearing all of them:
              </Dialog.Title>
            </div>
            <div className="text-center sm:mt-3">
              <Dialog.Title as="h4" className="text-md font-normal leading-6 text-gray-900">
                {dependencies?.dependencies.length > 0 ? (
                  <div className="flex flex-col gap-x-2 items-center">
                    <p className="text-lg font-bold">Group Dependencies</p>
                    <ul className="flex flex-col gap-y-2 list-disc">
                      {dependencies?.dependencies.map((dep, index) => (
                        <li key={index}>
                          <p className="text-blueberry font-medium ">{dep.message}</p>
                        </li>
                      ))}
                    </ul>
                  </div>
                ) : null}
              </Dialog.Title>
            </div>
          </>
        )}

        <Form
          bottomRow={getFormButtons()}
          onSubmit={(e) => {
            e.preventDefault();
            onClickOk();
          }}
        ></Form>
      </div>
    </Modal>
  );
}
