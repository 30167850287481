import { MutationResult } from '@apollo/client';
import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Button, { ButtonType } from '../../../baseComponents/Button';
import { GrantAccessMutation, useGrantAccessMutation } from '../../../generated/graphql';
import UserContext from '../../contexts/UserContext';

/**
 * Page will check the linkHash if it's non existent page will
 * show an error and prompt user to go home
 *
 * If link is present page will attempt to approve link
 * @returns
 */
const GrantAccess = (): JSX.Element => {
  let navigate = useNavigate();
  const { user } = useContext(UserContext);
  const [grantAccess, accessResult] = useGrantAccessMutation();
  const queryParams = new URLSearchParams(window.location.search);
  const inviteLink = queryParams.get('linkHash');

  useEffect(() => {
    if (user) {
      grantAccess({
        variables: {
          linkHash: inviteLink!,
        },
        fetchPolicy: 'network-only',
        onCompleted: () => {
          redirectWithDelay();
        },
        onError: () => {
          redirectWithDelay();
        },
      });
    } else {
      redirectWithDelay(5000);
    }
  }, []);

  const redirectWithDelay = (number?: number) => {
    setTimeout(() => navigate('/'), number ?? 10000);
  };

  const uppercaseFirstLetter = (input: string | null | undefined): string => {
    if (!input) return '';
    return input[0].toUpperCase() + input.substring(1);
  };

  const handleAccessResult = (accessResult: MutationResult<GrantAccessMutation>) => {
    if (!user) {
      return (
        <div>
          <p className="font-semibold">Please log in before granting access to your org.</p>
        </div>
      );
    }
    if (accessResult.loading) {
      return (
        <>
          <div className="h-32 w-32 animate-spin rounded-full border-t-2 border-b-2 border-blueberry"></div>
          <h2 className="my-3 text-center text-3xl font-semibold">Loading</h2>
        </>
      );
    }
    if (accessResult.error) {
      return (
        <>
          <h2 className="my-3 text-center text-3xl font-semibold">Invalid URL</h2>
          <p className="mb-3">The link you've used has expired or is invalid.</p>
        </>
      );
    }
    return (
      <>
        <h2 className="my-3 text-center text-3xl font-semibold">Granted Access</h2>
        <p>
          Granted {uppercaseFirstLetter(accessResult.data?.grantAccessRequest?.user?.firstName)}{' '}
          {uppercaseFirstLetter(accessResult.data?.grantAccessRequest?.user?.lastName)} access to your organization. You can edit access on your organization's
          page.
        </p>
      </>
    );
  };

  return (
    <div className="min-h-screen bg-milk">
      <div className="flex flex-col gap-y-3 ">
        <div className="mt-[100px] flex h-full w-full flex-col items-center justify-center">
          {handleAccessResult(accessResult)}
          <div className="mt-10">
            <Button buttonType={ButtonType.Primary2} text={'Go Home'} additionalStyles="text-lg" onClick={() => navigate('/')} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default GrantAccess;
