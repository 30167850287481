import { Events, Properties, logEvent } from '../../v2/AnalyticsUtil';
import { classNames } from '../../v2/util';

export interface IBadge {
  text: string;
  id: string;
}

interface BadgeProps {
  badge: IBadge;
  id?: string;
  children?: JSX.Element;
  onRemove?: (badge: IBadge) => void;
  onEdit?: (badge: IBadge) => void;
  disable?: boolean;
  color?: string;
  textColor?: string;
  hoverBg?: string;
  hoverTextColor?: string;
  bigger?: boolean;
  border?: string;
  borderColor?: string;
  display?: string;
  capitalize?: boolean;
  eventName?: Events;
  properties?: Properties;
  logClickEvent?: (eventName: Events, properties: Properties) => void;
  smaller?: boolean;
}

const Badge = ({
  badge,
  id,
  disable,
  onEdit,
  onRemove,
  color,
  textColor,
  children,
  hoverBg,
  hoverTextColor,
  bigger,
  border,
  borderColor,
  display,
  capitalize,
  eventName,
  properties,
  logClickEvent,
  smaller,
}: BadgeProps): JSX.Element => {
  if (!id) {
    id = badge.text?.replaceAll(' ', '-');
  }
  return (
    <span
      className={classNames(
        `inline-flex items-center rounded-3xl font-medium ${color ?? 'bg-blueberry'} ${textColor ?? 'text-milk'} duration-300 ${disable && 'opacity-50'}`,
        hoverBg,
        hoverTextColor,
        bigger ? 'text-md' : smaller ? 'text-xs' : 'text-xs lg:text-sm',
        border,
        borderColor,
        display,
        smaller ? 'px-3 py-2' : 'px-5 py-2.5'
      )}
      id={id}
    >
      <div
        className={`flex flex-row ${disable ? 'cursor-not-allowed' : onEdit ? 'cursor-pointer' : 'cursor-default'} `}
        onClick={() => {
          if (disable) return;
          if (onEdit) onEdit(badge);
          if (eventName && properties) {
            logEvent(eventName, properties);
          }
        }}
      >
        {badge.text !== '' && capitalize ? capitalizeFirstLetter(badge.text) : badge.text}
        {children}
      </div>
      {onRemove && (
        <button
          type="button"
          id={`${id}-remove`}
          name={`${id}-remove`}
          className="text-milk-400 ml-1 inline-flex h-4 w-4 flex-shrink-0 items-center justify-center rounded-full duration-200 hover:bg-red-500 hover:text-white focus:bg-red-500 focus:text-white focus:outline-none"
          onClick={() => {
            if (disable) return;
            onRemove(badge);
          }}
        >
          <svg className="h-2 w-2" id={`${id}-remove`} stroke="currentColor" fill="none" viewBox="0 0 8 8">
            <path strokeLinecap="round" strokeWidth="1.5" d="M1 1l6 6m0-6L1 7" />
          </svg>
        </button>
      )}
    </span>
  );
};

const capitalizeFirstLetter = (text: string) => {
  return text.charAt(0).toUpperCase() + text.substring(1).toLowerCase();
};

export default Badge;
