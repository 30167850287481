import { ArrowTopRightOnSquareIcon, CalendarDaysIcon, UserCircleIcon, NewspaperIcon } from '@heroicons/react/24/outline';
import { ChevronDownIcon, PlusIcon, StarIcon, TrashIcon } from '@heroicons/react/24/solid';
import { StarIcon as StarIconOutline } from '@heroicons/react/24/outline';
import Button, { ButtonVariant } from '../baseComponents/Button';
import { FeedbackEntriesQuery } from '../../generated/graphql';
import { ArrayElement, getSentimentString, useClickOutside } from '../../utilities';
import sourcesMap from '../../v2/other/sourcesMap';
import { alphabeticalSort, classNames, localDateString, truncateAndEllipsis } from '../../v2/util';
import { useRef, useState } from 'react';
import SettingsMenu from '../baseComponents/SettingsMenu';
import { useEditSentimentHook } from '../hooks/EditSentimentHook';
import { useValidTeamAppContext } from '../../v2/contexts/AppContext';
import { SegmentLabel } from './SegmentLabel';
import { FullConversation } from './FullConversation';
import Tippy from '@tippyjs/react';
import 'tippy.js/themes/light.css';
import { capitalize } from 'lodash';
import he from 'he';
import { SentenceEntry } from '../../v2/hooks/GroupHook';
import { ExploreGroupHook } from '../hooks/ExploreGroupHook';
import AdjustableLoadingIcon from '../../baseComponents/AdjustableLoadingIcon';
import { HomeGroupHook } from '../hooks/HomeGroupHook';

interface FeedbackEntryCardProps {
  entry: ArrayElement<FeedbackEntriesQuery['entries']>;
  sentences?: SentenceEntry[];
  compact?: boolean;
  compactAvailable?: boolean;
  groupHook?: ExploreGroupHook | HomeGroupHook;
  addMode?: boolean;
}
const FeedbackEntryCard = ({ entry, sentences, compact, compactAvailable, groupHook, addMode }: FeedbackEntryCardProps) => {
  const [showFullCard, setShowFullCard] = useState<boolean>(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  return (
    <div className={`text-blueberry relative group min-w-0 break-words `}>
      {!!groupHook && !addMode ? (
        loadingDelete ? (
          <div
            data-testid="sentence-remove-loading"
            className="z-10 absolute -right-2 -top-2 duration-100 h-5 w-5 p-0.5 bg-failure rounded-full cursor-pointer text-milk opacity-80"
          >
            <AdjustableLoadingIcon color={'text-milk'} width={4} height={4} />
          </div>
        ) : (
          <TrashIcon
            id="delete-group-sentence"
            onClick={async (e) => {
              e.stopPropagation();
              if (!sentences) return;
              setLoadingDelete(true);
              for (let i = 0; i < sentences.length; i++) {
                await groupHook.deleteSentence(sentences[i].id, groupHook.currentGroup?.id ?? -1, () => {});
              }
              setLoadingDelete(false);
            }}
            className="z-10 absolute -right-2 -top-2 opacity-0 transition-opacity duration-100 h-5 w-5 p-0.5 bg-failure rounded-full cursor-pointer text-milk hover:bg-red-700 group-hover:opacity-80"
          />
        )
      ) : null}
      {!!groupHook?.currentGroup && groupHook.currentGroup.id && addMode ? (
        loadingDelete ? (
          <div
            data-testid="sentence-add-loading"
            className="z-10 absolute text-sm flex flex-row items-center gap-x-2 -right-2 -top-2 duration-100 p-0.5 px-2 bg-blueberry-lighter rounded-full cursor-pointer text-milk opacity-80"
          >
            <p>Adding sentence...</p>
            <AdjustableLoadingIcon color={'text-milk'} width={4} height={4} />
          </div>
        ) : (
          <div
            className="z-10 absolute text-sm flex flex-row items-center gap-x-2 -right-2 -top-2 opacity-0 transition-opacity duration-100  p-0.5 px-2 bg-blueberry-lighter rounded-full cursor-pointer text-milk hover:bg-blueberry group-hover:opacity-80"
            onClick={async (e) => {
              if (!sentences) return;
              const sentence: SentenceEntry = sentences[0];
              e.stopPropagation();
              setLoadingDelete(true);
              await groupHook.addSentence(groupHook.currentGroup?.id ?? -1, sentence, () => setLoadingDelete(false));
              setLoadingDelete(false);
            }}
          >
            <p>Add Sentence to Group</p>
            <PlusIcon id="add-group-sentence" className="h-5 w-5" />
          </div>
        )
      ) : null}
      {compact && !showFullCard ? (
        <div
          onClick={() => setShowFullCard(!showFullCard)}
          className={`feedback-entry-card  flex flex-col  gap-y-3 w-full rounded-3xl bg-silver ${'hover:shadow-lg transition cursor-pointer'}  ${
            !showFullCard ? ' hover:bg-silver-darker ' : ''
          } ${showFullCard ? 'px-0' : 'px-6'} py-5`}
        >
          <CompactFeedbackEntry entry={entry} sentence={sentences?.[0]} />
        </div>
      ) : (
        <div
          onClick={() => (entry.hasConversation || compactAvailable) && setShowFullCard(!showFullCard)}
          className={`feedback-entry-card  flex flex-col  gap-y-3 w-full rounded-3xl bg-silver ${
            entry.hasConversation || compactAvailable ? 'hover:shadow-lg   transition cursor-pointer' : ''
          }  ${(entry.hasConversation || compactAvailable) && !showFullCard ? ' hover:bg-silver-darker ' : ''} ${
            showFullCard && entry.hasConversation ? 'px-0' : 'px-6'
          } py-5`}
        >
          <FeedbackEntryHeader entry={entry} showFullCard={showFullCard} />
          <div>
            {showFullCard ? (
              entry.hasConversation ? (
                <FullConversation entry={entry} height="h-[22rem]" />
              ) : (
                <RegularEntryFullConversation entry={entry} sentences={sentences} />
              )
            ) : entry.hasConversation ? (
              <ConversationPartsConcat entry={entry} />
            ) : sentences ? (
              <RegularEntryFullConversation entry={entry} sentences={sentences} />
            ) : (
              <p className="entry-text text-base">{he.decode(entry.text ?? '')}</p>
            )}
          </div>
          <FeedbackEntryFooter entry={entry} showFullCard={showFullCard} />
        </div>
      )}
    </div>
  );
};

const RegularEntryFullConversation = ({ entry, sentences }: { entry: ArrayElement<FeedbackEntriesQuery['entries']>; sentences?: SentenceEntry[] }) => {
  return (
    <p className="entry-text text-base">
      {entry.sentences.map((entrySentence, index) => {
        let separator = index !== entry.sentences.length - 1 ? ' ' : '';
        if (entrySentence.is_title) return null;
        if (sentences?.find((sentence) => sentence.id === entrySentence.id))
          return (
            <>
              <b>{entrySentence.text}</b>
              {separator}
            </>
          );
        return (
          <>
            {entrySentence.text}
            {separator}
          </>
        );
      })}
    </p>
  );
};

const ConversationPartsConcat = ({ entry }: { entry: ArrayElement<FeedbackEntriesQuery['entries']> }) => {
  return (
    <>
      <p>{he.decode(`${capitalize(entry.submitterType ?? '')} - ${entry.text} `)}</p>
      {entry.conversationParts
        .slice(0, 3)
        .map((conversation) => `${he.decode(`${capitalize(conversation.submitterType ?? '')} - ${conversation.fullText} `)}`)
        .map((part, i) => {
          return (
            <p>
              {part}
              {i === 2 && entry.conversationParts.length > 3 ? '...' : ''}
            </p>
          );
        })}
    </>
  );
};
interface SentimentLabelProps {
  sentiment: number;
  onClick: (sentiment: number) => void;
}

const SentimentLabel = ({ sentiment, onClick }: SentimentLabelProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const [menuOpen, setMenuOpen] = useState(false);
  useClickOutside([ref], () => menuOpen && setMenuOpen(false));
  const sentimentMenu = [
    { id: 0, group: 'sentiment', onClick: () => onClick(1), name: 'Positive' },
    { id: 1, group: 'sentiment', onClick: () => onClick(0.5), name: 'Neutral' },
    { id: 2, group: 'sentiment', onClick: () => onClick(-1), name: 'Negative' },
  ];
  return (
    <SettingsMenu settings={sentimentMenu}>
      <div
        ref={ref}
        onClick={() => setMenuOpen(true)}
        className={classNames('items-scenter group relative flex cursor-pointer flex-row items-baseline gap-x-1 duration-300')}
      >
        <span className={classNames('block h-2.5 w-2.5 rounded-full bg-failure p-1 opacity-70', getSentimentBgColor(sentiment ?? 0))} />
        <h1 className="text-xs">{getSentimentString(sentiment ?? 0)}</h1>
        <ChevronDownIcon className={classNames('w-.2.5 absolute -right-3 mt-1 h-2.5', menuOpen ? 'block' : 'hidden group-hover:block')} />
      </div>
    </SettingsMenu>
  );
};

const getSentimentBgColor = (sentimentScore: number) => (sentimentScore >= 0.9 ? 'bg-success' : sentimentScore <= -0.9 ? 'bg-failure' : 'bg-gray-400');

export default FeedbackEntryCard;

const FeedbackEntryFooter = ({ entry, showFullCard }: { entry: ArrayElement<FeedbackEntriesQuery['entries']>; showFullCard: boolean }) => {
  const { handleEditSentiment } = useEditSentimentHook();
  const { curTeamId: teamId } = useValidTeamAppContext();

  return (
    <div className={`items-center flex flex-row gap-x-8 justify-between text-blueberry ${showFullCard && entry.hasConversation ? 'px-6 pt-2' : 'px-0'}`}>
      <div className="flex flex-row gap-x-4 gap-y-2 justify-start flex-wrap">
        <div className="flex flex-row items-center gap-x-1 whitespace-nowrap">
          <UserCircleIcon className="h-5 w-5" />
          <h1 className="text-xs">{entry.submitter ?? 'Unknown'}</h1>
        </div>
        <div className="flex flex-row items-center gap-x-1 whitespace-nowrap">
          <CalendarDaysIcon className="h-5 w-5" />
          <h1 className="text-xs">{entry.date ? localDateString(new Date(entry.date)) : ''}</h1>
        </div>
        <div className="flex flex-row items-center gap-x-1 whitespace-nowrap" onClick={(e) => e.stopPropagation()}>
          {entry.sentiment || entry.sentiment === 0 ? (
            <SentimentLabel
              sentiment={entry.sentiment}
              onClick={(sentiment) => {
                handleEditSentiment(teamId, entry.id, sentiment);
              }}
            />
          ) : null}
        </div>
      </div>
      <SegmentFooter entry={entry} />
    </div>
  );
};

const CompactFeedbackEntry = ({ entry, sentence }: { entry: ArrayElement<FeedbackEntriesQuery['entries']>; sentence?: SentenceEntry }) => {
  let seenSentence = false;
  let preText = '';
  let postText = '';
  if (!preText && !postText && sentence) {
    entry.sentences.forEach((value) => {
      if (value.id === sentence.id) seenSentence = true;
      else if (seenSentence) postText += value.text + ' ';
      else preText += value.text + ' ';
    });
  }

  const limit = 50;
  const preTextSubString = he.decode(preText.substring(preText.length - limit));
  const postTextSubString = he.decode(postText.substring(0, limit));
  const preTextRender = preText.length > 0 ? (preTextSubString.length >= limit ? '...' : '') + preTextSubString : null;
  const postTextRender = postText.length > 0 ? postTextSubString + (postTextSubString.length >= limit ? '...' : '') : null;

  return (
    <div className="flex flex-col gap-y-1">
      <div className="flex flex-row justify-between">
        <div className="flex flex-row items-center gap-x-2">
          {entry.source ? <SourceImage entrySource={entry.source} /> : null}
          {entry.stars ? <StarsDisplay starCount={entry.stars} /> : null}
          {entry.source_permalink ? <PermalinkDisplay permalink={entry.source_permalink} /> : null}
          {entry.date ? <DateDisplay timestamp={entry.date} /> : null}
          <Tippy content="This is a summarized version of the text. You can click to expand the full entry." theme="light">
            <div className="bg-slate-200 opacity-80 flex flex-row items-center gap-x-1 rounded-md px-2 py-1">
              <NewspaperIcon className="h-3 w-3 stroke-1" />
              <p className="text-xxs">Summarized</p>
            </div>
          </Tippy>
        </div>
      </div>
      <div className="flex flex-row justify-between gap-x-2 items-start">
        {entry.distillateText && entry.hasConversation ? (
          <p>{entry.distillateText}</p>
        ) : sentence ? (
          <p>
            {preTextRender} <b>{he.decode(sentence.text ?? '')}</b> {postTextRender}
          </p>
        ) : (
          <p>{truncateAndEllipsis(entry.text, 80)}</p>
        )}{' '}
        <SegmentFooter entry={entry} compact={true} />
      </div>
    </div>
  );
};

const SegmentFooter = ({ entry, compact }: { entry: ArrayElement<FeedbackEntriesQuery['entries']>; compact?: boolean }) => {
  const sliceAmount = compact ? 0 : 2;
  return (
    <div className="flex flex-row gap-x-3  gap-y-2 justify-end flex-wrap">
      {entry.segments && entry.segments.length !== 0
        ? [...entry.segments]
            .sort((a, b) => alphabeticalSort(a.groupName, b.groupName))
            .map((segment, index) => {
              if (index >= sliceAmount) return null;
              return <SegmentLabel key={index} segment={segment} />;
            })
        : null}
      <div>{entry.segments && entry.segments.length > sliceAmount ? <SegmentLabel remainingSegments={entry.segments.slice(sliceAmount)} /> : null}</div>
    </div>
  );
};

const FeedbackEntryHeader = ({ entry, showFullCard }: { entry: ArrayElement<FeedbackEntriesQuery['entries']>; showFullCard: boolean }) => {
  return (
    <div className={`flex select-none flex-row items-center gap-x-2  ${showFullCard && entry.hasConversation ? 'px-6 ' : 'px-0'}`}>
      {entry.source ? <SourceImage entrySource={entry.source} /> : null}
      <EntryTitleDisplay entry={entry} />
      {entry.stars ? <StarsDisplay starCount={entry.stars} /> : null}
      {entry.source_permalink ? <PermalinkDisplay permalink={entry.source_permalink} /> : null}
      {entry.distillateText ? <SummaryDisplay distillateText={entry.distillateText} /> : null}
    </div>
  );
};

const SourceImage = ({ entrySource }: { entrySource: string }) => {
  const source = sourcesMap[entrySource.toLowerCase()];
  if (!source) return null;
  return <img className="h-4 w-4" src={sourcesMap[entrySource.toLowerCase()]?.logo} alt={sourcesMap[entrySource]?.name} />;
};

const StarsDisplay = ({ starCount }: { starCount: number }) => {
  return (
    <div className="flex flex-row gap-x-1">
      {[1, 2, 3, 4, 5].map((el) => {
        return (
          <div key={el}>
            {el <= starCount ? <StarIcon key={el} className="h-4 w-4 text-yellow-500" /> : <StarIconOutline key={el} className="h-4 w-4 text-yellow-500" />}
          </div>
        );
      })}
    </div>
  );
};

const DateDisplay = ({ timestamp }: { timestamp: number }) => {
  return (
    <div className="flex flex-row items-center gap-x-0.5 whitespace-nowrap">
      <CalendarDaysIcon className="h-4 w-4" />
      <h1 className="text-xs">{timestamp ? localDateString(new Date(timestamp)) : ''}</h1>
    </div>
  );
};
const PermalinkDisplay = ({ permalink }: { permalink: string }) => {
  return (
    <div>
      <a
        href={permalink ?? 'https://google.com'}
        id="entry-permalink-clicked"
        target="_blank"
        rel="noreferrer"
        className="p-0.5 rounded-full hover:bg-gray-300 duration-100 block"
      >
        <Button variant={ButtonVariant.IconRaw} icon={<ArrowTopRightOnSquareIcon className="h-3.5 w-3.5 cursor-pointer duration-100 hover:stroke-[2]" />} />
      </a>
    </div>
  );
};

const SummaryDisplay = ({ distillateText }: { distillateText: string }) => {
  return (
    <Tippy
      theme="light"
      content={
        <div className="text-blueberry p-1">
          <p className="text-lg mb-2 font-semibold">Summary:</p>
          <p>{distillateText}</p>
        </div>
      }
    >
      <div className="justify-center items-center flex flex-row">
        <NewspaperIcon className="h-4 w-4 stroke-1" />
      </div>
    </Tippy>
  );
};

const EntryTitleDisplay = ({ entry }: { entry: ArrayElement<FeedbackEntriesQuery['entries']> }) => {
  return entry.title && entry.title !== '' ? (
    <p className="entry-title">"{entry.title}"</p>
  ) : (
    <p>
      {entry.source
        ? (sourcesMap[entry.source]?.name || entry.source.charAt(0).toUpperCase() + entry.source.slice(1)) +
          ' ' +
          (sourcesMap[entry.source]?.itemName || 'Entry')
        : null}
    </p>
  );
};
