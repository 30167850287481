import { useRef, useState } from 'react';
import { Combobox } from '@headlessui/react';
import classNames from 'classnames';
import { useVirtualizer } from '@tanstack/react-virtual';
import { ChevronUpDownIcon, XMarkIcon } from '@heroicons/react/24/solid';
import { IDropDownItem } from '../baseComponents/DropDown';

interface VirtualizedComboBoxProps {
  comboBoxData: IDropDownItem[];
  selectedItem?: IDropDownItem;
  setSelectedItem: (item: IDropDownItem | undefined) => void;
  useDisplayName?: boolean;
  placeholder?: string;
}

export function VirtualizedComboBox({ comboBoxData, selectedItem, setSelectedItem, placeholder, useDisplayName }: VirtualizedComboBoxProps) {
  const [query, setQuery] = useState('');
  const filteredItems =
    query === ''
      ? comboBoxData
      : comboBoxData.filter((data) => {
          return data.name ? data.name.toLowerCase().includes(query.toLowerCase()) : data.title?.toLowerCase().includes(query.toLowerCase());
        });

  function checkAndClearInput() {
    if (query != '' && filteredItems.length == 0) {
      setQuery('');
    }
  }

  return (
    <Combobox
      className={'h-full'}
      as="div"
      value={selectedItem}
      onChange={(item: IDropDownItem | undefined) => {
        setSelectedItem(item);
        setQuery('');
      }}
      onFocus={() => checkAndClearInput()}
    >
      <div className="relative">
        <div className={'relative'}>
          <Combobox.Input
            autoComplete="off"
            placeholder={placeholder}
            className="w-full rounded-md border border-gray-300 bg-white pl-3 pr-14 text-blueberry shadow-sm focus:border-blueberry focus:outline-none focus:ring-1 focus:ring-blueberry sm:text-sm"
            displayValue={(item: IDropDownItem) => {
              if (useDisplayName) {
                return item?.displayName ?? item?.name;
              }
              return item?.name ? item.name : item?.title ? item.title : '';
            }}
            onChange={(event) => setQuery(event.target.value)}
          />
          <div className="absolute top-0 flex h-full w-full flex-row justify-end">
            <Combobox.Button className="flex w-full"></Combobox.Button>
            <div className="flex items-center">
              {selectedItem ? (
                <XMarkIcon
                  onClick={() => {
                    setSelectedItem(undefined);
                    setQuery('');
                  }}
                  className="h-4 w-4 cursor-pointer text-blueberry"
                  aria-hidden="true"
                />
              ) : null}
              <Combobox.Button className="w-8">
                <ChevronUpDownIcon onClick={() => setQuery('')} className="h-6 w-6 text-blueberry" aria-hidden="true" />
              </Combobox.Button>
            </div>
          </div>
        </div>

        <Combobox.Options>
          <VirtualizedList
            items={
              filteredItems.sort((a, b) => {
                const nameA = !a.name ? a.title : a.name;
                const nameB = !b.name ? b.title : b.name;
                if (!nameA || !nameB) return 0;
                return nameA.localeCompare(nameB);
              }) ?? []
            }
          />
        </Combobox.Options>
      </div>
    </Combobox>
  );
}

function VirtualizedList({ items }: { items: IDropDownItem[] }) {
  const parentRef = useRef<HTMLDivElement>(null);

  const count = items.length;
  const virtualizer = useVirtualizer({
    count,
    getScrollElement: () => parentRef.current,
    estimateSize: () => 45,
  });

  const itemsToRender = virtualizer.getVirtualItems();

  return (
    <div className="">
      <div
        ref={parentRef}
        className={
          'w-full absolute max-h-60 overflow-y-auto z-40 mt-1 rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm'
        }
      >
        <div
          style={{
            height: virtualizer.getTotalSize(),
            width: '100%',
            position: 'relative',
          }}
        >
          <div
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              transform: `translateY(${itemsToRender[0]?.start ?? 0}px)`,
            }}
            className="divide-y-2 divide-gray-100"
          >
            {itemsToRender.map((virtualRow) => (
              <div key={virtualRow.key} data-index={virtualRow.index} ref={virtualizer.measureElement}>
                <Combobox.Option
                  className={({ active }) =>
                    classNames(`duration-100 transition relative cursor-pointer select-none py-2 pl-2 pr-4 ${active ? 'bg-indigo-50' : 'text-gray-900'}`)
                  }
                  value={items?.[virtualRow.index]}
                >
                  {({ selected, active }) => (
                    <span className={`flex flex-row line-clamp-3 items-center gap-x-2 ${selected ? 'font-medium' : 'font-normal'}`}>
                      {items?.[virtualRow.index]?.imageSrc ? <img className="w-3 h-3" src={items?.[virtualRow.index]?.imageSrc} /> : null}
                      {items?.[virtualRow.index].displayName ?? items?.[virtualRow.index].name}
                    </span>
                  )}
                </Combobox.Option>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
