import { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';

interface ModalProps {
  children: JSX.Element;
  title?: string;
  setOpen: (open: boolean) => void;
  open: boolean;
  width?: string;
}

//const Modal = (props: ModalProps): JSX.Element => {
const Modal = ({ title, setOpen, open, children, width = 'w-[42rem]' }: ModalProps): JSX.Element => {
  //return <div>{props.children}</div>

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-50 overflow-y-auto font-sofiapro"
        // can assign focus to a specific element you just assing the reference
        //initialFocus={cancelButtonRef}
        onClose={() => setOpen(false)}
      >
        <div className="flex min-h-screen items-end justify-center px-8 pt-4 pb-20 text-center font-sofiapro sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>
          <span className="hidden sm:inline-block sm:h-screen sm:align-middle" aria-hidden="true">
            &#8203;
          </span>

          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className={`inline-block ${width} transform rounded-xl bg-milk py-6 px-4 text-left align-bottom shadow-xl transition-all sm:align-middle`}>
              {title ? (
                <div className="mt-3 text-center sm:mt-5">
                  <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                    {title}
                  </Dialog.Title>
                </div>
              ) : (
                ''
              )}

              {children}
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default Modal;
