import toast from 'react-hot-toast';
import {
  AlertType,
  CadenceType,
  CreateAlertMutationVariables,
  EditAlertMutationVariables,
  FilterInput,
  FilterType,
  New_Alerts,
  useCreateAlertMutation,
  useDeleteAlertMutation,
  useEditAlertMutation,
  useGetAlertsLazyQuery,
} from '../../generated/graphql';
import { useEffect, useState } from 'react';
import { getTotalPageLoadEvent } from '../../latencyTracker';

interface AlertHookProps {
  teamId: number;
  preventDefaultLoad?: boolean;
}

export const useAlertHook = ({ teamId, preventDefaultLoad }: AlertHookProps) => {
  const [alerts, setAlerts] = useState<New_Alerts[]>([]);

  const [getAlertsQuery, alertsQuery] = useGetAlertsLazyQuery({ notifyOnNetworkStatusChange: true, fetchPolicy: 'no-cache' });

  const loadAlerts = async () => {
    await getAlertsQuery({
      variables: {
        teamId,
      },
      onCompleted: (data) => {
        setAlerts(data.getAlerts ?? []);
        const event = getTotalPageLoadEvent({ view: 'alerts' });
        window.dispatchEvent(event);
      },
    });
  };

  useEffect(() => {
    if (!preventDefaultLoad) loadAlerts();
  }, [teamId]);

  const [createAlertMutation, createAlertObj] = useCreateAlertMutation();
  const [deleteAlertMutation, deleteAlertObj] = useDeleteAlertMutation();
  const [editAlertMutation, editAlertObj] = useEditAlertMutation();

  const createAlert = async ({ createAlertInput, successCb, failureCb }: CreateAlertParams) => {
    await createAlertMutation({
      variables: {
        ...createAlertInput,
        teamId,
      },
      onCompleted: (data) => {
        setAlerts((prevAlerts) => [...prevAlerts, data.createAlert]);
        successCb?.();
      },
      onError: () => failureCb?.(),
    });
  };

  const editAlert = async ({ editAlertInput, successCb, failureCb }: EditAlertParams) => {
    await editAlertMutation({
      variables: {
        ...editAlertInput,
        teamId,
      },
      onCompleted: (data) => {
        if (data.editAlert) {
          const existingAlertIndex = alerts.findIndex((alert) => alert.id === data.editAlert?.id);
          const newAlerts = [...alerts];
          newAlerts[existingAlertIndex] = data.editAlert;
          setAlerts(newAlerts);
        }
        successCb?.();
      },
      onError: () => {
        failureCb?.();
      },
    });
  };

  const editStandardAlert = async (alertId: number, editStandardAlertParams: CreateStandardAlertParams) => {
    await editAlert({
      editAlertInput: {
        ...editStandardAlertParams,
        teamId,
        alertId,
      },
      successCb: () => toast.success('Volume Alarm edited successfully!'),
      failureCb: () => toast.error('Failed to edit Volume Alarm.'),
    });
  };

  const createStandardAlert = async (createStandardAlertParams: CreateStandardAlertParams) => {
    await createAlert({
      createAlertInput: {
        ...createStandardAlertParams,
        filterInput: createStandardAlertParams.filterInput ?? {},
        type: AlertType.Standard,
        teamId,
      },
      successCb: () => {
        toast.success('Volume Alarm created successfully!');
      },
      failureCb: () => {
        toast.error('Failed to Volume Alarm.');
      },
    });
  };

  const createSubscription = async ({ groupId, cadence, emails, slackChannelId, slackChannelName }: CreateSubscriptionParams) => {
    const filterInput: FilterInput = { groupFilter: [{ filterCondition: FilterType.And, group: [{ id: groupId }] }] };
    await createAlert({
      createAlertInput: {
        teamId,
        filterInput,
        type: AlertType.Subscription,
        emails,
        cadence,
        slackChannelId,
        slackChannelName,
      },
      successCb: () => {
        toast.success('Group Subscription created successfully! You can edit it from the Alerts page');
      },
      failureCb: () => {
        toast.error('Failed to create Group Subscription');
      },
    });
  };

  const editSubscription = async (alertId: number, editSubscriptionParams: EditSubscriptionParams) => {
    await editAlert({
      editAlertInput: {
        ...editSubscriptionParams,
        teamId,
        alertId,
      },
      successCb: () => {
        toast.success('Group Subscription edited successfully!');
      },
      failureCb: () => {
        toast.error('Failed to edit Group Subscription');
      },
    });
  };

  const deleteAlert = async ({ alertId, successCb, failureCb }: DeleteAlertParams) => {
    await deleteAlertMutation({
      variables: {
        alertId,
        teamId,
      },
      onCompleted: () => {
        successCb?.();
        setAlerts((prevAlerts) => prevAlerts.filter((alert) => alert.id !== alertId));
      },
      onError: () => failureCb?.(),
    });
  };

  return {
    allAlerts: alerts,
    standardAlerts: alerts.filter((alert) => alert.type === AlertType.Standard),
    groupSubscriptions: alerts.filter((alert) => alert.type === AlertType.Subscription),
    createAlert,
    createStandardAlert,
    createSubscription,
    deleteAlert,
    editStandardAlert,
    editSubscription,
    loadingStatuses: {
      creatingAlert: createAlertObj.loading,
      deletingAlert: deleteAlertObj.loading,
      loadingAlerts: alertsQuery.loading,
      editingAlert: editAlertObj.loading,
    },
  };
};

export interface CreateStandardAlertParams {
  filterInput?: FilterInput;
  alertName?: string | null;
  period: number;
  threshold: number;
  emails?: string[];
  slackChannelId?: string | null;
  slackChannelName?: string | null;
}

type DeleteAlertParams = {
  alertId: number;
} & Cbs;

type CreateAlertParams = {
  createAlertInput: CreateAlertMutationVariables;
} & Cbs;

type EditAlertParams = {
  editAlertInput: EditAlertMutationVariables;
} & Cbs;

interface Cbs {
  successCb?: () => void;
  failureCb?: () => void;
}

type CreateSubscriptionParams = {
  groupId: number;
  cadence: CadenceType;
  emails?: string[];
  slackChannelId?: string | null;
  slackChannelName?: string | null;
};

export type EditSubscriptionParams = Omit<CreateSubscriptionParams, 'groupId'>;
