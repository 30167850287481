import Popup from '../../../../baseComponents/Popup';
import Button, { ButtonType } from '../../../../baseComponents/Button';
import { Dispatch, SetStateAction } from 'react';
export const DeleteAnnotationModal = ({
  x,
  y,
  deleteAnnotation,
  setDeleteModalOpen,
  closeAll,
}: {
  x: number;
  y: number;
  deleteAnnotation: () => void;
  setDeleteModalOpen: Dispatch<SetStateAction<boolean>>;
  closeAll?: () => void;
}) => {
  const getFormButtons = () => {
    return (
      <div className="mt-4 flex w-full flex-row items-center justify-end gap-x-2">
        <Button buttonType={ButtonType.PopupCancel} onClick={() => setDeleteModalOpen(false)} text={'Cancel'} />
        <Button buttonType={ButtonType.PopupConfirm} onClick={handleSubmit} text="Yes, Delete" />
      </div>
    );
  };
  const handleSubmit = () => {
    deleteAnnotation();
    setDeleteModalOpen(false);
    closeAll && closeAll();
  };
  return (
    <Popup title={'Delete Note?'} bottomRow={getFormButtons()} inlineStyles={{ top: `${y}px`, left: `${x}px` }}>
      <p>This action cannot be undone.</p>
    </Popup>
  );
};

export default DeleteAnnotationModal;
