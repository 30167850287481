import { Link } from 'react-router-dom';
import LoadingSpinner from '../baseComponents/LoadingSpinner';
import Button, { ButtonVariant } from '../baseComponents/Button';
import DropDown from '../baseComponents/DropDown';
import Tippy from '@tippyjs/react';
import { FilterManager, FilterManagerDisplayMode } from '../sections/Filters/FilterManager';
import { classNames } from '../../v2/util';
import toast from 'react-hot-toast';
import YesCancelModal from './Modals/YesCancelModal';
import { useFilterHook } from '../hooks/FilterHook';
import { useEffect, useState } from 'react';
import { Chart_Bin_Type } from '../../generated/graphql';
import { useValidTeamAppContext } from '../../v2/contexts/AppContext';
import { useChartsPageHook } from '../hooks/ChartsPageHook';
import { useInView } from 'react-intersection-observer';
import { ChartBinTypes } from '../pages/ChartsPage';
import { CustomChartCard } from './CustomChartCard';

const pageSize = 5;

export const ChartListSection = () => {
  const { curTeamId: teamId, curOrgId: orgId } = useValidTeamAppContext();
  const filterHook = useFilterHook({ teamId, orgId, filterKey: 'chart' });
  const [binType, setBinType] = useState(Chart_Bin_Type.Dynamic);
  const chartsPageHook = useChartsPageHook({ teamId, orgId, filterInput: filterHook.filters, pageSize, binType });
  const { ref, inView } = useInView({ threshold: 0 });
  const [paginating, setPaginating] = useState(false);
  const [deleteChartModalOpen, setDeleteChartModalOpen] = useState(false);
  const [curDeleteChartId, setCurDeleteChartId] = useState<number>();

  useEffect(() => {
    if (inView) loadMoreCharts();
  }, [inView]);

  const loadMoreCharts = async () => {
    setPaginating(true);
    await chartsPageHook.fetchMoreCharts(chartsPageHook.customCharts.length);
    setPaginating(false);
  };

  const deleteChart = () => {
    if (curDeleteChartId)
      chartsPageHook
        .deleteCustomChart(curDeleteChartId)
        .then(() => {
          toast.success('Chart deleted');
        })
        .finally(() => {
          setDeleteChartModalOpen(false);
          setCurDeleteChartId(undefined);
        });
    else {
      setDeleteChartModalOpen(false);
      setCurDeleteChartId(undefined);
    }
  };

  return (
    <div className="flex flex-col">
      {deleteChartModalOpen && (
        <YesCancelModal
          text={'Are you sure you want to delete this chart?'}
          subtext={'This action cannot be undone.'}
          confirmText="Yes, delete"
          callbackModal={setDeleteChartModalOpen}
          modalOpen={deleteChartModalOpen}
          idToPass={curDeleteChartId}
          confirmButton={deleteChart}
          closeOnConfirm={false}
          understandCheck={false}
          loadingConfirm={chartsPageHook.loadingStatues.deletingChart || chartsPageHook.loadingStatues.fetchingCharts}
        />
      )}
      <div className="flex flex-row justify-between gap-x-2">
        <div className={classNames('border-b border-gray-200 pb-4')}>
          <FilterManager pageName={''} filterHook={filterHook} dataTypeToFilter={'chart'} displayMode={FilterManagerDisplayMode.ChartsPage} />
        </div>
        <div className="flex flex-row gap-x-1">
          <Tippy theme="dark" content="Click here to change the x-axis range.">
            <div>
              <DropDown
                id="chart-bin-type-dropdown"
                selectedItem={ChartBinTypes.find((item) => item.value === binType)}
                dropDownData={ChartBinTypes}
                setSelectedItem={(item) => {
                  if (item.value) {
                    setBinType(item.value as Chart_Bin_Type);
                  }
                }}
              />
            </div>
          </Tippy>
        </div>
      </div>
      <div className="flex flex-col">
        <div className="flex flex-col mt-4">
          <div className="justify-center flex">
            <Link to={'creator'}>
              <Button variant={ButtonVariant.Tertiary} text="Add new chart +" />
            </Link>
          </div>
        </div>
        <div className="flex flex-col mt-4 gap-y-12">
          {!paginating && chartsPageHook.loadingStatues.fetchingCharts ? (
            <div className="flex flex-col items-center h-64 justify-center">
              <LoadingSpinner />
            </div>
          ) : (
            chartsPageHook.customCharts.map((chart, idx) => {
              const show = idx === chartsPageHook.customCharts.length - 1;
              return (
                <CustomChartCard
                  key={chart.id}
                  customChartData={chart}
                  filterInput={filterHook.filters}
                  refProp={show ? ref : undefined}
                  newCard={false}
                  deleteChart={() => {
                    setCurDeleteChartId(chart.id);
                    setDeleteChartModalOpen(true);
                  }}
                />
              );
            })
          )}
          {paginating && chartsPageHook.loadingStatues.fetchingCharts ? (
            <div className="flex flex-col items-center h-64 justify-center">
              <LoadingSpinner />
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};
