import { Auth } from 'aws-amplify';
import axios from 'axios';
import { logError } from './applicationTelemetry';
import { clearQueryParam, getQueryParam, logEvent } from './v2/AnalyticsUtil';
import { SELECTED_ORG_KEY, SELECTED_TEAM_KEY, getOrganizationsFromLocalStorage } from './v2/contexts/AppContext';
import { getFCP, getLCP, getFID } from 'web-vitals';

let pageLoadEventFired = false;

export const getTotalPageLoadEvent = ({ view }: { view: string }): CustomEvent => {
  return new CustomEvent('total-page-load', { detail: { view } });
};
export const getTotalGroupLoadEvent = ({ view, duration }: { view: string; duration: number }): CustomEvent => {
  return new CustomEvent('total-group-modal-load', { detail: { view, duration } });
};

//This array is for actions captured by this hook that don't trigger a network request but we still want to log (so we force log it inside captureEvent)
const manuallyTrackedEventSources = ['entry-permalink-clicked', 'link-group'];

//This array is for sources manually tracked from application mode, just so we prevent tracking them twice on Mixpanel.
const ignoredEventSources = ['full-text-search', 'set-filters-confirm'];

/*
We got a lot of ids we dont want to track.
We need to 1) filter out ids we don't need (after looking at ids sent to mixpanel), and 2) remap relevant ids to be more descriptive
*/
const shouldExcludeId = (id: string) => {
  return (
    id.startsWith('headlessui') ||
    id.includes('carouselRootElement') ||
    id.includes('page-wrapper') ||
    id.startsWith('react-joyride') ||
    id.includes('section') ||
    id.includes('email') ||
    id.includes('password') ||
    id.includes('root') ||
    id.includes('inner-shadow-content') ||
    id.includes('comment') ||
    id.includes('badge-remove')
  );
};

const mapIdToName = (id: string | undefined) => {
  if (!id) return;
  if (id.startsWith('Group-Title:-')) {
    return 'group-title-filter';
  }
  if (id.startsWith('Group-Type:-')) {
    return 'group-type-filter';
  }
  if (id.startsWith('Custom-Field:-') || id.startsWith('Segment:-')) {
    return 'custom-field-filter';
  }
  if (id.startsWith('Source:-')) {
    return 'source-filter';
  }
  if (id.startsWith('Sentiment:-')) {
    return 'sentiment-filter';
  }
  if (id.startsWith('Tag:-')) {
    return 'tag-filter';
  }
  if (id.includes('accept-search-create-group')) {
    return 'accept-search-group';
  }
  if (id.includes('group-create-search-button')) {
    return 'create-search-group';
  }
  return id;
};

const getCurOrgAndTeamId = () => {
  const orgIdString = localStorage.getItem(SELECTED_ORG_KEY);
  const orgId = orgIdString ? Number(orgIdString) : null;
  const teamIdString = localStorage.getItem(SELECTED_TEAM_KEY);
  const teamId = teamIdString ? Number(teamIdString) : null;
  const orgs = getOrganizationsFromLocalStorage();
  const team = orgs
    .filter((org) => Array.isArray(org?.teams))
    .flatMap((org) => org.teams)
    .find((team) => team.id === teamId);
  const org = orgs.find((org) => org.id === orgId);

  return { orgId, teamId, orgName: org?.name, teamName: team?.name };
};

const getUserInfo = async () => {
  const { orgId, teamId, orgName, teamName } = getCurOrgAndTeamId();
  let user;
  try {
    user = (await Auth.currentSession())?.getIdToken()?.payload;
  } catch {
    return { orgId, teamId, orgName, teamName };
  }
  return { orgId, teamId, orgName, teamName, user };
};
/**
 * What do we want to track?
 *
 * Any time a user takes action we want to know
 * (1) - what action they took.
 * (2) - how long it took to complete that action.
 *
 * I'm assuming for any action to complete we need to wait for all requests to service.api.production.unwrap.ai to complete.
 * So after a user takes action - time how long it takes for all request to unwrap.ai to complete
 *
 * Open Questions:
 * (1) Could we tie this into mix panel? We'd be able to infer events based on component name and button name and such. This way
 *      we'd get tracking for free.
 *
 * Tracking actions:
 * - click
 * - key down
 * - refresh
 * - drag -> drop
 *
 * on any action you could reset the timer and if any network requests fire you wait for all requests to complete then log the
 * action being completed
 *
 * to know what was clicked you need to use the component's id. This becomes application code responsibility to specify the component id somewhere in the html tree.
 * This code will take the first acceptable id it finds in the html tree as the name of the action.
 */

export const trackUserActions = () => {
  const logEvent = async (duration: number, source: string | undefined, requests: Record<string, IRequest>) => {
    const { orgId, teamId, orgName, teamName, user } = await getUserInfo();
    let unwrap_referrer;
    if (source === 'initial-page-load') {
      unwrap_referrer = getQueryParam(window.location.href, 'unwrap_referrer');
      clearQueryParam('unwrap_referrer');
    }

    // console.log(`total page load time is: ${duration} for source: ${source} team: ${teamId}, and org: ${orgId} and user: ${user?.email}`);
    const payload: IUserEvent = {
      timeStamp: new Date().toISOString(),
      action: source,
      userEmail: user?.email,
      orgId: orgId,
      orgName: orgName,
      teamId: teamId,
      teamName: teamName,
      duration: duration,
      page: window.location.pathname,
      host: window.location.host,
      ...(unwrap_referrer && { unwrap_referrer: unwrap_referrer }),
      requests: Object.keys(requests).map((key) => {
        const request = requests[key];
        return {
          operationName: key,
          duration: request.duration,
          variables: request.variables,
          size: request.size,
        };
      }),
    };
    if (source && !ignoredEventSources.includes(source)) await writeToMixPanel(payload);

    await writeToOpenSearch(payload);
  };

  /** this is an initial crack and likely needs a lot of improvement.
   * Ideally this tracks all actions a user can take.
   * Ideally this isn't in a useEffect but I want to capture user context and organization / team data
   */
  let requests: Record<string, IRequest> = {};
  let startOfLoad = 0;
  let source: string | undefined = undefined;
  let trackingAction = false;

  /**
   * When an event is triggered by clicking, refreshing or keypressing... There's probably others.
   *
   * This finds the event name by crawiling the html tree to find the first element with an acceptable `id`
   * This list of acceptable ids may grow over time but for now acceptable just means the id doesn't start with headlessui
   *
   * This then sets tracking properties and waits for requests to fire
   */
  const captureEvent = (event: Event, eventName?: string) => {
    try {
      if (eventName) {
        source = eventName;
      } else {
        const path = event.composedPath();
        const value = path.find((path: any) => {
          if (path.id && !shouldExcludeId(path.id)) return true;
          return false;
        });
        //@ts-ignore
        source = mapIdToName(value?.id);
      }
      if (source && manuallyTrackedEventSources.includes(source)) {
        logEvent(0, source, requests); // no network request is fired for manually tracked events, so we log this as 0 duration
      }
      startOfLoad = performance.now();
      trackingAction = true;
      requests = {};
    } catch (e) {
      logError(e);
    }
  };

  /** Global click listener - this is less useful because it's hard to tell if events are going to fire when clicked
   * It's also difficult to tell what was clicked in a human readable way.
   */
  window.addEventListener('load', (event: Event) => {
    captureEvent(event, 'initial-page-load');
  });

  window.addEventListener('total-page-load', (event: Event) => {
    if (pageLoadEventFired) return;
    const details = (event as CustomEvent).detail;
    sendMetricsToOpenSearch('total-page-load:view-' + details.view, performance.now());
    pageLoadEventFired = true;
  });

  window.addEventListener('total-group-modal-load', (event: Event) => {
    const details = (event as CustomEvent).detail;
    const duration = performance.now() - details.duration;
    sendMetricsToOpenSearch('total-group-modal-load:view-' + details.view, duration);
  });

  window.document.addEventListener('mouseup', (event: MouseEvent) => {
    captureEvent(event);
  });

  window.addEventListener('keypress', (event: KeyboardEvent) => {
    captureEvent(event);
  });

  /** This catches any request made via fetch */
  window.fetch = new Proxy(window.fetch, {
    apply(fetch, that, args) {
      let requestStart = 0;
      let operationName: string | undefined = undefined;

      if (callIsToUnwrap(args)) {
        requestStart = performance.now();
        const operation = JSON.parse(args[1].body);
        operationName = operation.operationName;
        requests[operationName!] = { running: true, variables: operation.variables };
      }
      //@ts-ignore
      const result = fetch.apply(that, args);

      // Do whatever you want with the resulting Promise
      if (callIsToUnwrap(args)) {
        result
          .then((response: any) => {
            requests[operationName!] = {
              ...requests[operationName!],
              running: false,
              duration: performance.now() - requestStart,
              size: Number(response.headers.get('content-length')),
            };
            /** Check if there's any unfinished requests */
            if (
              !Object.values(requests)
                .map((val) => val.running)
                .includes(true) &&
              trackingAction
            ) {
              logEvent(performance.now() - startOfLoad, source, requests);
              requests = {};
              trackingAction = false;
              source = undefined;
            }
          })
          .catch(() => {});
      }

      return result;
    },
  });
  /** This fires every time we navigate to a new page */
};

const callIsToUnwrap = (args: any[]) => {
  try {
    return args[0] === process.env.REACT_APP_GRAPHQL_API;
  } catch {
    return false;
  }
};

const writeToMixPanel = async (payload: IUserEvent) => {
  if (payload.action) {
    logEvent(payload.action, {
      //@ts-ignore
      View_ID: payload.teamId!,
      //@ts-ignore
      View_Name: payload.teamName,
      //@ts-ignore
      Org_ID: payload.orgId,
      //@ts-ignore
      Org_Name: payload.orgName,
      Page: payload.page,
      Email: payload.userEmail,
      ...(payload.unwrap_referrer && { Unwrap_Referrer: payload.unwrap_referrer }),
    });
  }
};

/** this should batch operations right now this just dumps to open search. */
const writeToOpenSearch = async (payload: IUserEvent, index: string = 'frontend-index') => {
  const { requests, ...rest } = payload;
  const requestPayloads = requests?.map((request) => ({
    ...rest,
    action: request.operationName,
    duration: request.duration,
    size: request.size,
  }));
  if (Array.isArray(requestPayloads)) {
    return axios.post(process.env.REACT_APP_OS_DOMAIN!, {
      index: 'frontend-requests-index',
      payload: requestPayloads,
    });
  } else {
    return axios.post(process.env.REACT_APP_OS_DOMAIN!, { index: index, payload: payload });
  }
};
const sendMetricsToOpenSearch = async (name: string, delta: number) => {
  const { orgId, teamId, orgName, teamName, user } = await getUserInfo();
  const payload: IUserEvent = {
    timeStamp: new Date().toISOString(),
    action: name,
    userEmail: user?.email,
    orgId: orgId,
    orgName: orgName,
    teamId: teamId,
    teamName: teamName,
    duration: delta,
    page: window.location.pathname,
    host: window.location.host,
  };
  writeToOpenSearch(payload, 'frontend-performance-index');
};

// First Contentful Paint
getFCP((metric) => sendMetricsToOpenSearch(metric.name, metric.delta));

// Largest Contentful Paint
getLCP((metric) => sendMetricsToOpenSearch(metric.name, metric.delta));

// First Input Delay
getFID((metric) => sendMetricsToOpenSearch(metric.name, metric.delta));

// DOM Content Loaded
document.addEventListener('DOMContentLoaded', function (event) {
  let perfEntries = performance.getEntriesByType('navigation');
  if (perfEntries && perfEntries.length) {
    const navigationEntry = perfEntries[0] as PerformanceNavigationTiming;
    const loadTime = navigationEntry.domContentLoadedEventEnd;
    sendMetricsToOpenSearch('DCL', loadTime);
  }
});

interface IRequest {
  running: boolean;
  duration?: number;
  size?: number;
  variables: any;
}

interface IUserEvent {
  timeStamp: string;
  action: string | undefined;
  userEmail: string | undefined;
  orgId: number | null;
  orgName?: string | null;
  teamName?: string | null;
  teamId: number | null;
  duration: number;
  page: string;
  host: string;
  unwrap_referrer?: string | null;
  requests?: {
    operationName: string;
    duration: number | undefined;
    variables: any;
    size: number | undefined;
  }[];
}
