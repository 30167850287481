import { cloneDeep } from 'lodash';
import { useContext, useEffect, useState } from 'react';

import { CampaignFragment, Campaign_Status, useGetCampaignLazyQuery, useUpdateCampaignTreatmentMutation } from '../../generated/graphql';
import UserContext from '../../v2/contexts/UserContext';
export const useCampaignHook = ({ pageName }: { pageName: string }) => {
  const { user } = useContext(UserContext);
  const [campaign, setCampaign] = useState<CampaignFragment | undefined | null>(null);
  const [getCampaignQuery] = useGetCampaignLazyQuery({ variables: { pageName: pageName.toLowerCase() }, fetchPolicy: 'no-cache' });
  const [updateCampaignStatusMutation] = useUpdateCampaignTreatmentMutation();
  /**
   * Sort the pages in ascending order.
   *
   * So we can traverse pages by index rather than by `order` property.
   * @param campaign
   * @returns
   */
  const sortPages = (campaign: CampaignFragment | undefined | null) => {
    const sortedCampaign = cloneDeep(campaign);
    sortedCampaign?.campaignPages.sort((a, b) => {
      return a.order - b.order;
    });
    return sortedCampaign;
  };
  useEffect(() => {
    if (user) {
      getCampaignQuery({
        variables: { pageName },
        onCompleted(data) {
          setCampaign(sortPages(data.getCampaign));
        },
      });
    }
  }, [pageName]);

  const updateCampaignTreatment = (campaignId: number, status: Campaign_Status) => {
    updateCampaignStatusMutation({
      variables: { campaignId, status },
      onCompleted(data) {
        setCampaign(sortPages(data.updateCampaignTreatment));
      },
    });
  };
  const handleDismissed = (campaignId: number, cb?: () => void) => {
    updateCampaignTreatment(campaignId, Campaign_Status.Dismissed);
    cb?.();
  };
  const handleAcknowledged = (campaignId: number, cb?: () => void) => {
    updateCampaignTreatment(campaignId, Campaign_Status.Acknowledged);
    cb?.();
  };
  return { campaign, handleAcknowledged, handleDismissed };
};
