import parse from 'html-react-parser';
import AdjustableLoadingIcon from '../../../../baseComponents/AdjustableLoadingIcon';
import { useContext } from 'react';
import UserContext from '../../../../v2/contexts/UserContext';

interface AnnouncementEmailPreviewProps {
  emailPreview: string | undefined;
  emailPreviewLoading: boolean;
  subject: string;
  orgName: string;
}
const AnnouncementEmailPreview = ({ emailPreview, emailPreviewLoading, subject, orgName }: AnnouncementEmailPreviewProps): JSX.Element => {
  const { user } = useContext(UserContext);
  return (
    <div className="min-h-[24rem] w-full">
      <h2 className="text-md mb-3 font-semibold text-blueberry">The following email will be sent to users in this cluster:</h2>

      {emailPreviewLoading ? (
        <div className="flex h-full w-full flex-row items-center justify-center">
          <AdjustableLoadingIcon width={20} height={20} />
        </div>
      ) : (
        <div className="max-h-[24rem] overflow-y-auto overflow-x-hidden bg-milk">
          <p className="mt-2 ml-2 mb-2 font-semibold">
            From: {user?.firstName} from {orgName}
          </p>
          <hr className="w-[101%]" />
          <p className="mt-2 ml-2 -mb-2 text-sm">Subject: {subject}</p>
          {parse(emailPreview ?? '', { trim: true })}
        </div>
      )}
    </div>
  );
};

export default AnnouncementEmailPreview;
