import { useEffect, useState } from 'react';

import { FilterInput, useGetCustomChartsLazyQuery, GetCustomChartsQuery, useDeleteChartMutation, Chart_Bin_Type } from '../../generated/graphql';
import { getTotalPageLoadEvent } from '../../latencyTracker';

interface ChartsPageHookProps {
  teamId: number;
  orgId: number;
  userId?: number;
  chartId?: number;
  filterInput?: FilterInput;
  dateFilterInput?: FilterInput;
  pageSize?: number;
  mode?: 'editor' | 'creator';
  binType?: Chart_Bin_Type;
}

export const useChartsPageHook = ({ teamId, filterInput, pageSize, binType }: ChartsPageHookProps) => {
  const [customCharts, setCustomCharts] = useState<GetCustomChartsQuery['getCharts']>([]);
  const [deleteChart, deletedChart] = useDeleteChartMutation();

  const [getCustomChartsQuery, customChartsQuery] = useGetCustomChartsLazyQuery({
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'no-cache',
    variables: { teamId, take: pageSize, filterInput },
  });

  const deleteCustomChart = async (chartId: number) => {
    if (!chartId) return;
    await deleteChart({
      variables: {
        teamId,
        chartId,
      },
    });
    await getCustomChartsQuery({
      variables: { teamId, skip: 0, take: customCharts.length },
      onCompleted(data) {
        setCustomCharts(data.getCharts);
      },
    });
  };

  useEffect(() => {
    setCustomCharts([]);
    getCustomChartsQuery({
      //Without the variables, this is keeping the "skip" value from the loadMore...not sure why.
      variables: { teamId, take: pageSize, filterInput, skip: 0, chartBinType: binType },
      onCompleted(data) {
        setCustomCharts(data.getCharts);
        const event = getTotalPageLoadEvent({ view: 'charts' });
        window.dispatchEvent(event);
      },
    });
  }, [teamId, filterInput, binType]);

  const fetchMoreCharts = async (startIndex: number) => {
    const res = await getCustomChartsQuery({
      variables: { teamId, skip: startIndex, take: pageSize, chartBinType: binType },
      onCompleted(data) {
        setCustomCharts((curCharts) => [...curCharts, ...data.getCharts]);
      },
    });
    return res.data?.getCharts;
  };

  return {
    customCharts: customCharts,
    loadingStatues: {
      fetchingCharts: customChartsQuery.loading,
      deletingChart: deletedChart.loading,
    },
    fetchMoreCharts,
    deleteCustomChart,
    getCustomChartsQuery,
  };
};
