import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/solid';
import Tippy from '@tippyjs/react';
import { GroupSentenceDataFragment } from '../../generated/graphql';
import { classNames } from '../util';
import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import { FullConversation } from '../../v3/components/FullConversation';
import { FullEntry } from '../../v3/components/GroupSentenceEntry';
import { GroupSentenceEntryFooter } from '../../v3/components/GroupSentenceEntry';
import { getSentence, Sentence } from '../hooks/GroupHook';
const scrollListToBottom = (elemId: string) => {
  const groupList = document.getElementById(elemId);
  if (groupList) groupList.scrollTop = groupList.scrollHeight;
};

const useScrollOnUpdate = (list: any[], elementId: string, switchFunc?: (id: number) => void) => {
  const [shouldScroll, setShouldScroll] = useState(false);

  const handleSwitch = useCallback(
    (id: number) => {
      switchFunc?.(id);
      setShouldScroll(true);
    },
    [switchFunc]
  );

  useEffect(() => {
    if (shouldScroll) {
      scrollListToBottom(elementId);
      setShouldScroll(false);
    }
  }, [list, shouldScroll, elementId]);

  return handleSwitch;
};

export const SearchGroupDualLists = ({ leftList, rightList, switchToLeft, switchToRight }: TwoColumnDataListProps) => {
  const handleSwitchToRight = useScrollOnUpdate(rightList ?? [], 'group-list-exclude-feedback', switchToRight);
  const handleSwitchToLeft = useScrollOnUpdate(leftList ?? [], 'group-list-similar-feedback', switchToLeft);
  return (
    <div className="grid w-full grid-cols-2 gap-x-8 divide-x-2 divide-silver rounded-3xl border border-gray-200 text-center">
      <DataColumn
        id={'group-list-similar-feedback'}
        title={'Include in Group'}
        data={leftList ?? []}
        icon={<ChevronIconWithBackground direction={'right'} tooltipText={'Remove sentence from search group'} />}
        iconPlacement={'right'}
        onSwitchClick={handleSwitchToRight}
        titleColor="text-green-600/90"
      />
      <DataColumn
        title={'Exclude from Group'}
        id={'group-list-exclude-feedback'}
        data={rightList ?? []}
        icon={<ChevronIconWithBackground direction={'left'} tooltipText={'Add sentence to search group'} />}
        iconPlacement={'left'}
        onSwitchClick={handleSwitchToLeft}
        decreaseTextOpacity
        dimBackground
        titleColor="text-red-600/90"
      />
    </div>
  );
};

const DataColumn = ({ title, data, icon, iconPlacement, decreaseTextOpacity, onSwitchClick, dimBackground, id, titleColor }: DataColumnProps) => {
  return (
    <div className={classNames('flex max-h-96 flex-col gap-y-4 divide-y px-8 pt-3 ', dimBackground && 'bg-gray-50 bg-opacity-40')}>
      <h1 className={`text-lg font-semibold ${titleColor}`}>{title}</h1>
      <div id={id} className={classNames(iconPlacement === 'right' ? 'text-left' : 'text-right', 'scroll-shadows flex flex-col divide-y overflow-y-auto pr-2')}>
        {data.map((sentenceData) => (
          <SentenceWithIcon
            key={sentenceData.id}
            icon={icon}
            iconPlacement={iconPlacement}
            decreaseTextOpacity={decreaseTextOpacity}
            sentence={sentenceData}
            onSwitchClick={onSwitchClick}
          />
        ))}
      </div>
    </div>
  );
};
const SentenceWithIcon = ({ sentence, icon, iconPlacement, decreaseTextOpacity, onSwitchClick }: SentenceWithIconProps) => {
  const [expanded, setExpanded] = useState(false);
  const entry = sentence.entry;
  return (
    <div
      className={`flex flex-row items-center justify-between gap-x-2 ${expanded ? 'py-1' : 'py-4'} text-sm text-blueberry ${
        expanded ? 'hover:bg-gray-200 bg-gray-100' : 'hover:bg-gray-100'
      }  cursor-pointer transition-colors duration-150`}
      onClick={() => setExpanded((prev) => !prev)}
    >
      <>
        {iconPlacement === 'left' ? <div onClick={() => onSwitchClick?.(sentence.id)}>{icon}</div> : undefined}
        {entry &&
          (!expanded || !entry == null ? (
            <h1 className={classNames(decreaseTextOpacity && 'opacity-70', 'break-words, w-10/12')}>
              {sentence.preceedingText} <b className="break-words">{sentence.text}</b> {sentence.proceedingText}{' '}
            </h1>
          ) : entry && entry.hasConversation ? (
            <div className="flex flex-col flex-grow w-0">
              <FullConversation includeTitle entry={entry} title={entry.title ?? ''} condensed />
              <GroupSentenceEntryFooter entry={entry} condensed />
            </div>
          ) : (
            <FullEntry sentences={[getSentence(sentence)].filter(Boolean) as Sentence[]} entry={entry} condensed />
          ))}
        {iconPlacement === 'right' ? <div onClick={() => onSwitchClick?.(sentence.id)}>{icon}</div> : undefined}{' '}
      </>
    </div>
  );
};

const ChevronIconWithBackground = ({ direction, tooltipText }: { direction: 'left' | 'right'; tooltipText?: string }) => {
  const iconClassName = 'h-5 w-5 stroke-current text-gray-300 hover:text-blueberry duration-300';
  return (
    <Tippy content={<p className="text-xs">{tooltipText}</p>} delay={200}>
      <div className={'cursor-pointer rounded-full p-1 duration-200 hover:bg-opacity-20'}>
        {direction === 'left' ? <ChevronLeftIcon className={iconClassName} /> : <ChevronRightIcon className={iconClassName} />}
      </div>
    </Tippy>
  );
};

interface TwoColumnDataListProps {
  leftList?: GroupSentenceDataFragment[];
  rightList?: GroupSentenceDataFragment[];
  switchToLeft?: (id: number) => void;
  switchToRight?: (id: number) => void;
}

interface DataColumnProps {
  title: string;
  data: GroupSentenceDataFragment[];
  icon: JSX.Element;
  iconPlacement: 'left' | 'right';
  decreaseTextOpacity?: boolean;
  onSwitchClick?: (id: number) => void;
  dimBackground?: boolean;
  id?: string;
  shouldScroll?: boolean;
  setShouldScroll?: Dispatch<SetStateAction<boolean>>;
  titleColor: string;
}

interface SentenceWithIconProps {
  sentence: GroupSentenceDataFragment;
  icon: JSX.Element;
  iconPlacement?: 'left' | 'right';
  decreaseTextOpacity?: boolean;
  onSwitchClick?: (id: number) => void;
}
