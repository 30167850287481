import { useLaunchNlpMutation } from '../../generated/graphql';
import { useCsvParserHook } from '../../handlers/csvUtils/csvParser';
import { CSVError, CSVRow, HeaderCSVRow, ParsedCSV } from '../../handlers/csvUtils/csvTypes';
import { useCsvValidatorHook } from '../../handlers/csvUtils/csvValidator';
import { useCSVUploaderHook } from '../../handlers/csvUtils/csvUploader';
import { cloneDeep } from 'lodash';
import { IFilterRow } from '../sections/Filters/FiltersTypes';

/**
 * @description handles the 3 steps: parsing, data validation, and backend upload
 * @returns handleFileUploadFunction which returns a file to preview or an error
 */
export const useCSVHook = () => {
  // csv util hooks
  const validator = useCsvValidatorHook();
  const parser = useCsvParserHook();
  const uploader = useCSVUploaderHook();

  // Upload GQL Hook
  const [kickOffNLP] = useLaunchNlpMutation();

  /**
   * @description used to parse the file in CSVIntegrationModal for Preview Display and the `upload` method
   * @param csvFile the file to be parsed
   * @returns a ParsedCSV to be displayed in the preview table and used for uploads
   * @throws CSVError if any parsing error occured
   */
  async function parse(csvFile: File): Promise<ParsedCSV> {
    try {
      const parseResult = await parser.parse({ rawFile: csvFile, csvHeaders: getReadOnlySampleCSV().header });
      return parseResult;
    } catch (e) {
      throw e;
    }
  }

  /**
   * @description an async function responsible for handling the parsing, validation, and upload of the csv
   * @param csvFile the File dragged or selected by the user
   * @param csvSourceName source name specified by user in the source name text field
   * @param teamId the current teamID
   * @returns a promise with the Parsed CSV and number of rows uploaded or an array of CSV Errors
   * @throws the validation results created by the validator
   */
  async function upload(parseResult: ParsedCSV, csvSourceName: string, teamId: number, segmentFilters?: IFilterRow[]): Promise<[ParsedCSV, number]> {
    // shallow copy of errors to be passed to CSVIntegrationModal
    const immediateErrorsCopy = validator.validate({ unvalidatedCSV: parseResult, sourceName: csvSourceName });
    // case 1A) validation errors occurred, do not proceed to upload
    if (immediateErrorsCopy.length != 0) {
      throw immediateErrorsCopy;
    }

    // CSV to upload which will be modified in step 2A and 2B if CSV contains segments
    let validatedCSV: ParsedCSV = cloneDeep(parseResult);

    // case 1B) file is valid, now upload to backend
    try {
      // check if csv should handle Segments in the upload
      const shouldProcessSegments: boolean = !!segmentFilters && segmentFilters.length != 0 && segmentFilters.filter((seg) => seg.isSelected).length !== 0;

      // step 2A) Handle Segment Groups
      if (shouldProcessSegments) {
        validatedCSV = await uploader.uploadSegmentGroups(validatedCSV, teamId, segmentFilters!);
      }

      // step 2B) handle FeedbackIntegration (may or may not contain segments)
      validatedCSV = await uploader.uploadIntegrationAndConfigIds(validatedCSV, teamId);

      // step 2C) upload the rows
      const numRowsUploaded = await uploader.uploadCSVRows(validatedCSV, csvSourceName, teamId);

      // returns the parsedCSV with either the number of rows uploaded

      // kickoff nlp only if there are new ids to upload
      if (numRowsUploaded != 0) {
        kickOffNLP({ variables: { teamId: teamId } });
      }
      return [parseResult, numRowsUploaded ?? 0]; // if data is null, that means no data was uploaded
    } catch (error: any) {
      // case 3) file upload failed
      // Generic Error wrapping
      throw [new CSVError(`Unexpected Error: ${error.message}`)];
    }
  }

  /**
   * @description exposes a SampleCSV that is readOnly. Use _.deepClone(getReadOnlySampleCSV()) to use this CSV
   * @returns a sample ParsedCSV that is immutable
   */
  function getReadOnlySampleCSV() {
    // Designed to be the default header which you can modify for tooltip/ellipsis rendering
    const csvHeaders: HeaderCSVRow = {
      title: {
        fieldTitle: 'Title',
        toolTipDescription: 'Optional: The title of the feedback received',
        csvIndex: Number.MAX_SAFE_INTEGER,
        required: false,
        charsTillEllipsis: 19,
      },
      details: {
        fieldTitle: 'Details',
        toolTipDescription: 'Required: The text body of the feedback',
        csvIndex: Number.MAX_SAFE_INTEGER,
        required: true,
        charsTillEllipsis: 31,
      },
      date: {
        fieldTitle: 'Date',
        toolTipDescription: 'Required: The date in format: "MM/DD/YY" or "MM/DD/YY HH:MM:SS"',
        csvIndex: Number.MAX_SAFE_INTEGER,
        required: true,
        charsTillEllipsis: 20,
      },
      user: {
        fieldTitle: 'User',
        toolTipDescription: 'Optional: The name/username of the customer',
        csvIndex: Number.MAX_SAFE_INTEGER,
        required: false,
        charsTillEllipsis: 8,
      },
      id: {
        fieldTitle: 'ID',
        toolTipDescription: 'Optional: An ID that uniquely references each feedback entry you are uploading (e.g. survey response ID, support ticket ID)',
        csvIndex: Number.MAX_SAFE_INTEGER,
        required: false,
        charsTillEllipsis: 15,
      },
      stars: {
        fieldTitle: 'Stars',
        toolTipDescription: 'Optional: Rating of feedback as an integer between 0-5',
        csvIndex: Number.MAX_SAFE_INTEGER,
        required: false,
        charsTillEllipsis: 3,
      },
      source_url: {
        fieldTitle: 'Source URL',
        toolTipDescription: 'Optional: Link back to the entry in the source platform to allow for easy viewing and response',
        csvIndex: Number.MAX_SAFE_INTEGER,
        required: false,
        charsTillEllipsis: 13,
      },
    };

    // External Dummy Data
    const fillerBody: CSVRow[] = [
      {
        title: 'Small portion sizes',
        details: 'App orders are smaller portions than they used to be. This is quite dissatisfying and makes me not want to come again.',
        date: new Date().toLocaleDateString(undefined, { year: '2-digit', month: '2-digit', day: '2-digit' }),
        user: 'John D.',
        id: 'd6ah8d39d',
        stars: 3,
        source_url: 'subdomain.platform.com/d6ah8d39d',
      },
    ];

    // make all objects read only
    const sampleCSV: ParsedCSV = { header: csvHeaders, rows: fillerBody, numRows: -1 };

    // freeze header
    Object.entries(csvHeaders).forEach(([_, headerField]) => {
      Object.freeze(headerField);
    });

    // freeze body
    fillerBody.map((row) => {
      Object.freeze(row);
      Object.entries(row).forEach(([_, rowProperty]) => Object.freeze(rowProperty));
    });
    return sampleCSV;
  }

  return {
    upload,
    parse,
    getReadOnlySampleCSV,
  };
};
