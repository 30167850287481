import { useEffect, useState } from 'react';
import { IFilterRow, ITableHeader } from '../../sections/Filters/FiltersTypes';
import Tippy from '@tippyjs/react';
import { IDropDownItem } from '../../../baseComponents/DropDown';
import { ComboBox } from '../../../baseComponents/ComboBox';
import { formatCapitalizeAndSpace } from '../../../handlers/stringUtils/stringFormatters';
import { truncateAndEllipsis } from '../../../v2/util';

interface FilterTableProps {
  /**
   * @description List of filters which user will verify and select the specificFilterValue and isSelected
   */
  filters: IFilterRow[];

  /**
   * @description gateway to editing the filters state
   */
  setFilters: React.Dispatch<React.SetStateAction<IFilterRow[]>>;

  /**
   * @description gateway to editing the filterName (ex update the SegmentGroup)
   */
  updateFilterName: (newFilterName: string, filterRow: IFilterRow) => void;

  /**
   * @description a list of filters created from previous interactions (ex existing `SegmentGroups` not in the `filters` state)
   */
  existingFilters: IFilterRow[];

  /**
   * @description an override for the table header
   */
  tableHeader?: ITableHeader[];

  /**
   * @description max number of characters to render for preview column
   */
  truncatePreviewChars?: number;

  /**
   * @description a getter for the checkbox messages in the Filter Table
   */
  getTippyCheckboxMsg?: (filter: IFilterRow) => string;
}

/**
 * @description create an interface that allows users to configure how to filter their uploaded data. This includes
 *                  1) the filter category (ex SegmentGroup such as US_State)
 *                   2) the specific filter value (ex SegmentConfig such as California)
 * @returns returns a Table to configure the ConfigItems passed in
 */
export default function FilterTable({
  filters,
  setFilters,
  updateFilterName,
  tableHeader = [],
  truncatePreviewChars = 30,
  existingFilters,
  getTippyCheckboxMsg,
}: FilterTableProps) {
  /**
   * @description true when all rows are selected
   */
  const [allChecked, setAllChecked] = useState(false);

  /**
   * @description representation of `filters` and `existingFilters` as a DropDownItem[]
   */
  const [dropDown, setDropDown] = useState<IDropDownItem[]>([]);

  /**
   * @description the bottomMargin which can be expanded when the bottom 2 Comboboxes are opened
   */
  const [bottomMargin, setBottomMargin] = useState<string>('');

  /**
   * @description toggles the master checkbox and makes the rows reflect that state
   */
  function toggleAll() {
    const toggledAllChecked = !allChecked;
    filters.map((aFilter) => handleToggleRow(toggledAllChecked, aFilter));
    setAllChecked(toggledAllChecked);
  }

  /**
   * @description toggles one row and sets allChecked to false if we're toggling off
   * @param newCheckStatus the new input from the user
   * @param aFilter the filter to toggle
   */
  function handleToggleRow(newCheckStatus: boolean, aFilter: IFilterRow): void {
    // set aFilter.isSelected to true
    setFilters((prevFilters: IFilterRow[]) =>
      prevFilters.map((aPrevFilter: IFilterRow) => (aPrevFilter === aFilter ? { ...aPrevFilter, isSelected: newCheckStatus } : aPrevFilter))
    );
  }

  /**
   * @description update the newest dropdowns in case `filters` or `existingFilters` is updated
   *              note: we do not recommend placing getFiltersAsDropdowns directly inside the comoboboxData prop since
   *              the function will call getFiltersAsDropdowns even when no data has changed
   */
  useEffect(() => {
    const allFilters = getFiltersAsDropdowns(filters, existingFilters);
    setDropDown(allFilters);
  }, [filters, existingFilters]);

  /**
   * @description handle the master checkbox when any of the filters are updated
   */
  useEffect(() => {
    const numChecked = filters.filter((aFilter) => aFilter.isSelected).length;
    setAllChecked(numChecked === filters.length);
  }, [filters]);

  return (
    <>
      <div className="flow-root">
        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8 mb-3">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <div className={`overflow-y-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg`}>
              <table className={`min-w-full divide-y divide-gray-300 ${bottomMargin}`}>
                <thead className="bg-blueberry">
                  {/* Header Row */}
                  <tr>
                    {/* Master Checkbox */}
                    <th scope="col" className="relative px-7 sm:w-12 sm:px-6">
                      <Tippy content={allChecked ? 'Deselect All Filters' : 'Select All Filters'}>
                        <input
                          type="checkbox"
                          className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-blueberry focus:ring-milk"
                          checked={allChecked}
                          onChange={toggleAll}
                        />
                      </Tippy>
                    </th>

                    {/* Render the Header override or the keys of the filter Table */}
                    {tableHeader.length === 0 &&
                      Object.entries(filters[0])
                        .filter(([key, value]) => key != 'isSelected' && key != 'doesFilterCategoryExist')
                        .map(([key, value]) => {
                          return (
                            <th key={key} scope="col" className="py-3 pl-3 text-left text-xs font-semibold tracking-wide text-milk">
                              {formatCapitalizeAndSpace(key.split(/(?=[A-Z])/).join(' '))}
                            </th>
                          );
                        })}
                    {tableHeader.map((header) => (
                      <th key={header.headerName} scope="col" className="py-3 pl-3 text-left text-xs font-semibold tracking-wide text-milk">
                        <Tippy content={header.tippy}>
                          <p>{header.headerName}</p>
                        </Tippy>
                      </th>
                    ))}
                  </tr>
                </thead>

                {/* Table Body */}
                <tbody className="divide-y divide-gray-200 overflow-auto">
                  {filters.map((aFilter, idx) => (
                    // Each Segment row
                    <tr
                      key={idx}
                      className={`${getColor(aFilter.isSelected, aFilter.doesFilterCategoryExist)}
                                        border-b border-l border-r
                                        `}
                    >
                      {/* Checkbox */}
                      <td className="relative px-7 sm:w-12 sm:px-6">
                        <Tippy disabled={!getTippyCheckboxMsg} content={getTippyCheckboxMsg ? getTippyCheckboxMsg(aFilter) : ''}>
                          <input
                            type="checkbox"
                            className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-blueberry focus:ring-blueberry"
                            checked={aFilter.isSelected}
                            onChange={(e) => handleToggleRow(e.target.checked, aFilter)}
                          />
                        </Tippy>
                      </td>

                      {/* Specific Filter (ex CSV Segment Column header) */}
                      <td className="whitespace-nowrap px-3 py-4 text-sm font-light overflow-y-clip origin-top-left z-1 text-blueberry">
                        {truncateAndEllipsis(aFilter.filterSource, 30)}
                      </td>

                      {/* Filter Category (ex CSV Segment Group) */}
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-blueberry font-light">
                        <div onFocus={() => expandBottomMargin(idx, filters.length, setBottomMargin)} onBlur={() => setBottomMargin('mb-[0%]')}>
                          <ComboBox
                            comboBoxData={dropDown}
                            selectedItem={createDropDownItem(aFilter.filterName, idx)}
                            setSelectedItem={(selectedItem?: IDropDownItem) => {
                              updateFilterName(selectedItem!.name, aFilter);
                            }}
                            defaultOption={true}
                            allowEmptySelection={false}
                            maxDropDownSize={getMaxDropDownHeight(idx, filters.length)}
                          />
                        </div>
                      </td>

                      {/* Preview */}
                      <td className="whitespace-nowrap px-3 py-4 text-sm font-light overflow-y-clip origin-top-left z-1 text-blueberry">
                        {getPreviewMessage(aFilter.preview, truncatePreviewChars)}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

/**
 * @description chooses the color of the row based on checkbox and filterExisting booleans
 * @param isSelected whether or not the checkbox is selected
 * @param doesFilterCategoryExist true if the filterCategory exists or false if a new filterCategory is being created
 * @returns the color className
 */
function getColor(isSelected: boolean, doesFilterCategoryExist: boolean): string {
  // case 1) return default color (grey) if not selected
  if (!isSelected) {
    return '';
  }

  // case 2) selecting and writing a new filter category
  if (!doesFilterCategoryExist) {
    return 'bg-blue-50';
  }

  // case 3) selecting exiting filter category, do notchange anything
  return '';
}

/**
 * @description takes a string representing a preview and formats it accordingly
 * @param preview arbitrary string
 * @param truncatePreviewChars number of characters to be shown until truncating with ellipsis
 * @returns
 */
function getPreviewMessage(preview: string, truncatePreviewChars: number) {
  // case 1) preview is empty
  if (preview.length === 0) {
    return '[this column is empty]';
  }
  // case 2) preview exists, truncate and return message
  return truncateAndEllipsis(preview, truncatePreviewChars);
}

// DROP DOWN Wrappers
/**
 * @description creates an IDropDownItem
 * @param filterName the name of the filter to be added to Combobox dropdown
 * @param idx arbitrary index required for `IDropDownItem`
 * @returns
 */
export function createDropDownItem(filterName: string, idx: number): IDropDownItem {
  return {
    name: filterName,
    id: idx,
  } as IDropDownItem;
}

/**
 * @description combines the `populatedFilters` and `existingFilters` as Combobox Selections
 * @param populatedFilters the auto populated filters passed in from parent component
 * @param existingFilters any filters that may exist, can have overlap with populatedFilters
 * @returns a list of drop down items that can be selected in the Combobox
 */
function getFiltersAsDropdowns(populatedFilters: IFilterRow[], existingFilters: IFilterRow[]): IDropDownItem[] {
  // step 1A) remove filter duplicates
  const allFiltersWithPossibleDupes: string[] = [...populatedFilters.map((filter) => filter.filterName), ...existingFilters.map((filter) => filter.filterName)];
  const allFiltersUnique: string[] = Array.from(new Set<string>(allFiltersWithPossibleDupes));

  // step 1B) cast Filters to DropDowns
  const allFiltersAsDropDowns: IDropDownItem[] = allFiltersUnique.map((filterName, idx) => createDropDownItem(filterName, idx));

  return allFiltersAsDropDowns;
}

/**
 * @description tells the Combobox how many drop down items to show
 * @param rowIdx the current row
 * @param totalRows total rows in filter table
 * @returns a string representing how many drop downs should be displayed
 */
function getMaxDropDownHeight(rowIdx: number, totalRows: number): string {
  let numRowsBelow = totalRows - rowIdx - 1;

  // case 1) rows 0 to length-4, allow 4 rows of view
  if (numRowsBelow > 3) {
    return `max-h-[450%]`;
  }
  // case 2) last three rows show 3 drop downs
  return `max-h-[350%]`;
}

/**
 * @description adds a margin to the bottom of the table to make space for the drop down
 * @param idx the current row's index
 * @param totalRows the total number of rows
 * @param setBottomMargin setter to set the bottom margin
 */
function expandBottomMargin(idx: number, totalRows: number, setBottomMargin: (value: React.SetStateAction<string>) => void) {
  if (idx === totalRows - 2) {
    setBottomMargin('mb-[5%]');
  }
  if (idx === totalRows - 1) {
    setBottomMargin('mb-[15%]');
  }
}
