import { Chart } from 'react-chartjs-2';
import { ChartAnnotationOptions } from '../../v2/hooks/ChartHook';
import { ChartOptions, ChartData, ChartTypeRegistry, Filler } from 'chart.js';
import { memo } from 'react';
import { isEqual } from 'underscore';

const ChartView = ({ data, options, type }: { data: ChartData; options: ChartOptions; type?: keyof ChartTypeRegistry }) => {
  return <Chart type={type ?? 'line'} data={data} options={options} plugins={[Filler]} />;
};

/**
 * Returns true if:
 *  - If the number of annotations on the chart are the same
 *  - That each array of annotations have objects w/ the same annotationId, title, date, description, and value
 *  - The data has not changed
 * @param prevProps
 * @param nextProps
 * @returns bool
 */
const arePropsEqual = (prevProps: Readonly<{ data: ChartData; options: ChartOptions }>, nextProps: Readonly<{ data: ChartData; options: ChartOptions }>) => {
  const areAnnotationsEqual = () => {
    const prevAnnotations = Object.values(prevProps.options.plugins?.annotation?.annotations ?? {});
    const nextAnnotations = Object.values(nextProps.options.plugins?.annotation?.annotations ?? {});
    /**
     * Returns a copy of an annotation containing only the properties to be checked for equality
     * @param annotation
     * @returns
     */
    const comparableAnnotation = (annotation: Partial<ChartAnnotationOptions>) => {
      const updatedAnnotation = {
        annotationId: annotation?.annotationId,
        date: annotation?.date,
        title: annotation?.title,
        description: annotation?.description,
        value: annotation?.value,
      };
      return updatedAnnotation;
    };
    return prevAnnotations.length
      ? prevAnnotations.every((prev, i) => {
          const prevA = comparableAnnotation(prev as ChartAnnotationOptions);
          const nextA = comparableAnnotation(nextAnnotations[i] as ChartAnnotationOptions);
          return isEqual(prevA, nextA);
        }) && prevAnnotations.length === nextAnnotations.length
      : prevAnnotations.length === nextAnnotations.length;
  };
  const areDatasetsEqual = () => {
    const prevData = prevProps.data.datasets.map((data) => data.data);
    const nextData = nextProps.data.datasets.map((data) => data.data);
    return isEqual(prevData, nextData);
  };

  return areAnnotationsEqual() && areDatasetsEqual();
};

// returns a memoized version of the chart to prevent excessive re-renders
export default memo(ChartView, arePropsEqual);
