import { EllipsisVerticalIcon } from '@heroicons/react/24/solid';
import { IUser } from '../../v2/contexts/UserContext';
import Tippy from '@tippyjs/react';
import {
  GetOrganizationDocument,
  Organization_Users_Role,
  OrganizationUser,
  useUpdateOrganizationUserMutation,
  useRemoveOrganizationUserMutation,
  useResendInviteMutation,
} from '../../generated/graphql';
import DropDown from '../../baseComponents/DropDown';
import { AddUserToOrg } from '../../generated/graphql';
import SettingsMenu, { MenuAlign } from '../../baseComponents/SettingsMenu';
import AdjustableLoadingIcon from '../../baseComponents/AdjustableLoadingIcon';
import toast from 'react-hot-toast';
import 'tippy.js/themes/light.css';
import Badge, { BadgeSize } from '../../baseComponents/Badge';

export const MemberRow = ({
  user,
  selectedOrg,
  userIsAdmin,
  currentUser,
  numberOfAdmins,
}: {
  user: OrganizationUser;
  selectedOrg: number;
  userIsAdmin: boolean;
  currentUser: IUser | undefined;
  numberOfAdmins: number | undefined;
}) => {
  const capitalize = (word: string) => {
    return word.charAt(0).toUpperCase() + word.slice(1);
  };
  const dropdownItems = [
    { id: 1, name: 'Admin' },
    { id: 2, name: 'Member' },
  ];
  const settingsItems = [
    { id: 0, htmlId: 'remove-user', group: 'remove', name: 'Remove User', onClick: () => removeMember(user) },
    ...(!user.user ? [{ id: 1, htmlId: 'resend-invite', group: 'resend', name: 'Resend Invite', onClick: () => resendInvite() }] : []),
  ];
  const resendInviteUser = { orgRole: user.role, userEmail: user.email } as AddUserToOrg;
  const [resendInviteMutation] = useResendInviteMutation({ variables: { orgId: selectedOrg, userToReInvite: resendInviteUser } });
  const [removeUser, removeResult] = useRemoveOrganizationUserMutation({
    refetchQueries: [{ query: GetOrganizationDocument, variables: { orgId: selectedOrg } }],
  });
  const resendInvite = () => {
    const resendInviteToUser = async () => {
      await resendInviteMutation({
        onCompleted: () => toast.success('Invite message resent!'),
        onError: (error) => toast.error(error.message),
      });
    };
    resendInviteToUser();
  };
  const removeMember = async (user: OrganizationUser) => {
    await removeUser({
      variables: {
        orgId: selectedOrg,
        userToDeleteEmail: user.email,
      },
      onCompleted: () => toast.success('User was successfully removed from this organization.'),
      onError: (error) => toast.error(error.message),
    });
  };
  const [updateMembership, updateResult] = useUpdateOrganizationUserMutation({
    refetchQueries: [{ query: GetOrganizationDocument, variables: { orgId: selectedOrg } }],
  });
  const handleMembershipChange = (membershipType: string) => {
    const role = membershipType === 'Admin' ? Organization_Users_Role.Admin : Organization_Users_Role.Member;
    updateMembership({ variables: { orgId: selectedOrg, role: role, userToUpdateEmail: user.email } });
  };
  const rowIsCurrentUser = currentUser?.email === user?.email;
  const userCanEditOthers = userIsAdmin;
  const userCanEditSelf = userIsAdmin && numberOfAdmins && numberOfAdmins > 1;
  const isEditable = (rowIsCurrentUser && userCanEditSelf) || (!rowIsCurrentUser && userCanEditOthers);

  const content = (
    <div id="org-member-card" className={`my-4 flex flex-row flex-wrap justify-between rounded-xl bg-silver p-4`}>
      <div className="flex flex-row flex-wrap items-center justify-start w-1/2">
        <div className="flex flex-col w-full items-start justify-center">
          {!user.confirmed ? (
            <div>
              <Badge badge={{ id: '1', text: 'Pending' }} color="bg-blueberry-lighter" textColor="text-white" capitalize={true} size={BadgeSize.Small} />
            </div>
          ) : null}
          {user.user && (
            <h1>
              {user?.user?.firstName} {user?.user?.lastName}
            </h1>
          )}
          <div className="max-w-full">
            <Tippy content={user?.email} delay={300}>
              <h2 className={`${user.user ? 'text-xs' : ''}`} style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                {user?.email}
              </h2>
            </Tippy>
          </div>
        </div>
      </div>
      <div className="flex flex-wrap">
        <div className="flex flex-row gap-x-1">
          <div className="flex flex-row items-center justify-end">
            {isEditable ? (
              <DropDown
                dropDownData={dropdownItems}
                selectedItem={dropdownItems.find((item) => {
                  return item.name.toLowerCase() === user.role;
                })}
                setSelectedItem={(data) => handleMembershipChange(data.name)}
              />
            ) : (
              <div>{capitalize(user.role)}</div>
            )}
          </div>
          {isEditable && (
            <div className="flex flex-row items-center justify-center">
              {updateResult.loading || removeResult.loading ? (
                <AdjustableLoadingIcon height={4} width={4} />
              ) : (
                <SettingsMenu settings={settingsItems} align={MenuAlign.RIGHT}>
                  <EllipsisVerticalIcon className="h-4 w-4" />
                </SettingsMenu>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );

  return isEditable ? (
    content
  ) : !userCanEditOthers ? (
    <Tippy content={<p className="text-center">You must be an administrator to edit users.</p>} theme="light">
      {content}
    </Tippy>
  ) : (
    <Tippy content={<p className="text-center">There must be at least one administrator in an organization.</p>} theme="light">
      {content}
    </Tippy>
  );
};
