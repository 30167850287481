import React, { useState, useRef } from 'react';
import { Dialog } from '@headlessui/react';
import 'tippy.js/dist/tippy.css';
import Button, { ButtonVariant } from '../../baseComponents/Button';
import Form from '../../../baseComponents/Form';
import Modal from '../../baseComponents/Modal';
import Badge from '../../../baseComponents/Badge';
import DropDown from '../../../baseComponents/DropDown';
import { Organization_Users_Role } from '../../../generated/graphql';
import toast from 'react-hot-toast';
import { validateEmail } from '../../../v2/util';
import { useValidTeamAppContext } from '../../../v2/contexts/AppContext';

interface NewAlertModalProps {
  modalOpen: boolean;
  loadingConfirm: boolean;
  callbackModal: () => void;
  confirmButton: (emails: string[], role: Organization_Users_Role) => void;
}
export default function InviteUsersModal({ modalOpen, loadingConfirm, callbackModal, confirmButton }: NewAlertModalProps) {
  const { currentOrg } = useValidTeamAppContext();
  const dropdownItems = [
    { id: 1, name: 'Admin' },
    { id: 2, name: 'Member' },
  ];
  const formRef = useRef<HTMLFormElement | null>(null);

  const [emailList, setEmailList] = useState<string[]>([]);
  const [currentEmail, setCurrentEmail] = useState('');
  const [role, setRole] = useState(Organization_Users_Role.Member);

  const handleEmailInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = (e.target as HTMLInputElement).value;
    setCurrentEmail(value);
  };
  const handleDeleteEmailClick = (idx: number) => {
    const list = [...emailList];
    list.splice(idx, 1);
    setEmailList(list);
  };

  const handleAddNewEmailClick = () => {
    if (!emailList.includes(currentEmail)) {
      setEmailList([...emailList, currentEmail]);
    } else {
      toast.error('Please enter a unique email address.');
    }
    setCurrentEmail('');
  };

  const getFormButtons = () => {
    return (
      <div className="mt-8 flex flex-row justify-between text-center">
        <Button variant={ButtonVariant.Tertiary} onClick={() => callbackModal()} text="Cancel"></Button>
        <Button
          id="invite-member-button"
          variant={ButtonVariant.Primary}
          text="Invite"
          loadingConfirm={loadingConfirm}
          onClick={() => {
            confirmButton([...emailList, ...(validateEmail(currentEmail) ? [currentEmail] : [])], role);
          }}
        ></Button>
      </div>
    );
  };

  return (
    <Modal open={modalOpen} setOpen={callbackModal}>
      <Form
        formRef={formRef}
        bottomRow={getFormButtons()}
        onSubmit={(e) => {
          e.preventDefault();
          handleAddNewEmailClick();
        }}
      >
        <div>
          <div>
            <Dialog.Title as="h3" className="text-xl font-medium leading-6 text-gray-900">
              Invite people to "{currentOrg.name}"
            </Dialog.Title>
          </div>
          <div className="mt-4 flex flex-col flex-wrap">
            <h1 className="text-md mb-2 font-medium">Email addresses:</h1>
            <div className="mb-2 flex flex-row flex-wrap">
              {emailList.map((email: string, i: number) => {
                return (
                  <div className="mr-2">
                    <Badge
                      badge={{ id: `${i}`, text: `${email}` }}
                      capitalize={false}
                      onRemove={(badge) => {
                        handleDeleteEmailClick(i);
                      }}
                    />
                  </div>
                );
              })}
            </div>
            <div className="flex flex-row gap-x-2">
              <input
                type="email"
                id="invite-member-email-input"
                value={currentEmail}
                className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm sm:text-sm"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleEmailInputChange(e)}
                onKeyDown={(e: React.KeyboardEvent<HTMLDivElement>) => {
                  if (e.key === ' ' || e.key === 'Enter' || e.key === ',') {
                    e.preventDefault();
                    e.stopPropagation();
                    if (validateEmail(currentEmail)) {
                      formRef.current?.requestSubmit();
                    } else {
                      toast.error('Please enter a valid email address.');
                    }
                  }
                }}
              />
              <DropDown
                dropDownData={dropdownItems}
                selectedItem={dropdownItems.find((item) => {
                  return item.name.toLowerCase() === role;
                })}
                setSelectedItem={(data) => setRole(data.name === 'Admin' ? Organization_Users_Role.Admin : Organization_Users_Role.Member)}
              />
            </div>
          </div>

          <div className="mt-2">
            <p className="text-xs">
              Your teammates will get an email that gives them access to your organization. This new teammate will have access to all the views inside this
              organization.
            </p>
          </div>
        </div>
      </Form>
    </Modal>
  );
}
