import { RefObject, useEffect } from 'react';

export const useClickOutside = (refs: RefObject<any>[], callback: () => void) => {
  useEffect(() => {
    // Runs callback when user clicks outside all of the ref objects in the array
    // ex: user clicks outside of nested menus
    const handleClickOutside = (event: any) => {
      if (!refs.some((ref) => ref.current && ref.current.contains(event.target))) {
        callback();
      }
    };
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [refs]);
};
