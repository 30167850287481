import { IBadge } from '../../../baseComponents/Badge';
import { IDropDownItem } from '../../../baseComponents/DropDown';
import { ClusterUserRole, FilterType, Group_Type, SentimentType } from '../../../generated/graphql';

export interface IFilter {
  filterCategory: FilterCategory;
  filterCategoryId?: number;
  filterCondition: FilterType;
  filterCategoryTitle: string;
  uiId: string;
  values: IFilterValue[];
  teamName?: string | null;
}

export interface IFilterValue {
  title: string;
  uiId: string;
  id?: number;
  segment?: string;
  sentiment?: SentimentType;
  source?: string;
  role?: ClusterUserRole;
  type?: Group_Type;
  displayName?: string;
  name?: string;
}

export enum FilterCategory {
  Source = 'Source',
  Sentiment = 'Sentiment',
  Segment = 'Segment',
  GroupType = 'Group Type',
  Tag = 'Tag',
  GroupTitle = 'Group Title',
  Owner = 'Owner',
  MinStars = 'Min Stars',
  MaxStars = 'Max Stars',
}

export interface IBadgeFilter extends IBadge {
  filter: IFilter;
}

export interface ICategoryItem extends IDropDownItem {
  // this is the real id of the category item. i.e. id of FeedbackSegmentGroup.
  realId?: number;
  categoryType: FilterCategory;
}

export enum DenominatorOptions {
  AllFeedback = 'All Feedback',
  ClusteredFeedback = 'Clustered Feedback',
  FilteredFeedback = 'Filtered Feedback',
  FilteredClusteredFeedback = 'Filtered Clustered Feedback',
  DateFilteredFeedback = 'Date Filtered Feedback',
}

export const denominatorOptionsDropdown: IDropDownItem[] = [
  { name: DenominatorOptions.AllFeedback, id: 0 },
  { name: DenominatorOptions.ClusteredFeedback, id: 1 },
  { name: DenominatorOptions.FilteredFeedback, id: 2 },
  { name: DenominatorOptions.FilteredClusteredFeedback, id: 3 },
  { name: DenominatorOptions.DateFilteredFeedback, id: 4 },
];

export interface IFilterRow {
  /**
   * @description the name of the filter category (ex SegmentGroup such as US_State)
   */
  filterName: string;

  /**
   * @description where the filter is coming from (ex SegmentConfig such as California)
   */
  filterSource: string;

  /**
   * @description preview of the `specificFilterValue` (ex first 3 rows on the CSV)
   */
  preview: string;

  /**
   * @description whether or not the user would like this filter to be applied in post-processing
   */
  isSelected: boolean;

  /**
   * @description false when a new SegmentGroup is being created true when using an exsiting SegmentGroup
   */
  doesFilterCategoryExist: boolean;
}

export interface ITableHeader {
  headerName: string;
  tippy: string;
}
