import { Menu, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import { WrenchScrewdriverIcon, InformationCircleIcon } from '@heroicons/react/24/outline';
import { StatusIndicator } from '../../../v2/sections/Navigation/StatusIndicator';
import { TeamFlagsQueryResult, OrganizationsLightFragment } from '../../../generated/graphql';
import { ArrowPathIcon } from '@heroicons/react/24/outline';
import Tippy from '@tippyjs/react';
import { classNames } from '../../../v2/util';

export const StatusMenu = ({
  flags,
  selectedTeam,
}: {
  flags: TeamFlagsQueryResult | undefined;
  selectedTeam: OrganizationsLightFragment['teams'][0] | undefined | null;
}) => {
  const inProgress = flags?.data?.teamFlags?.currently_tokenizing;
  return (
    <Menu as="div" className="relative flex h-full w-full items-center justify-center">
      <Menu.Button className="flex h-full w-full items-center justify-center hover:bg-raspberry hover:bg-opacity-10">
        <div className="relative flex flex-row items-center justify-between gap-x-1 rounded-md px-2 py-2 ">
          <WrenchScrewdriverIcon className="h-5 w-5 bg-transparent stroke-gray-300" aria-hidden="true" />
          {inProgress ? (
            <div className="absolute top-1 right-2 flex items-center">
              <span className="absolute h-3 w-3 animate-ping rounded-full bg-green-600 "></span>
              <span className="absolute inline-flex h-3 w-3 rounded-full bg-green-700"></span>
            </div>
          ) : undefined}
        </div>
      </Menu.Button>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute bottom-0 right-0 z-50 mb-12 max-h-96 w-max origin-bottom-right overflow-y-auto rounded-md border-2 border-blueberry bg-milk py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="mx-4 mb-2 flex flex-row items-center justify-between border-b-2 border-indigo-100 py-1">
            <h1 className="text-sm font-semibold">Team Status</h1>
            <div className="flex flex-row gap-x-1">
              <Tippy
                content={
                  <h1 className="text-xs">This list shows the status of our NLP processing. Hover over an Idle status to see the last time it was run.</h1>
                }
                placement="right"
              >
                <InformationCircleIcon className="h-4 w-4" aria-hidden="true" />
              </Tippy>
              <ArrowPathIcon
                className={classNames('h-4 w-4 cursor-pointer ring-0', flags?.loading ? 'animate-spin' : '')}
                onClick={() => flags?.refetch({ teamId: selectedTeam?.id })}
                aria-hidden="true"
              />
            </div>
          </div>
          {/*Refactor this with a list instead of many items*/}
          <Menu.Item>
            <div className={'flex flex-row items-center justify-between py-2 px-4 text-sm text-gray-700 hover:bg-gray-100'} onClick={() => {}}>
              <h1 className="font-semibold">Data Processing:</h1>
              <StatusIndicator
                active={flags?.data?.teamFlags?.currently_tokenizing}
                loading={flags?.loading}
                lastDate={flags?.data?.teamFlags?.tokenizing_last_run}
              />
            </div>
          </Menu.Item>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};
