import Badge, { BadgeShape, BadgeSize } from '../../baseComponents/Badge';
import { ChartType, getChartFromData, getChartOptions } from '../../v2/hooks/ChartHook';
import { GroupFull } from '../../v2/hooks/GroupHook';
import Button, { ButtonSize, ButtonVariant } from '../baseComponents/Button';
import ChartView from '../baseComponents/ChartView';
import { Icon } from '@iconify/react';
import { ChartData } from 'chart.js';
import { Sentence } from '../../v2/hooks/GroupHook';
import { truncateAndEllipsis } from '../../v2/util';
import { CheckCircleIcon, ExclamationCircleIcon } from '@heroicons/react/24/outline';
import moment from 'moment';

const InsightCard = ({ group, onClick }: { group: GroupFull; onClick: () => void }) => {
  const { insight } = group;

  const options = getChartOptions(ChartType.Mixed, group.tooltipLabels ?? [], false);
  const data = getChartFromData(group.aggregateData ?? [], group.normalizedData ?? [], group.tooltipLabels ?? [], group.chartLabels ?? [], false);
  const transformedData = {
    labels: data.labels,
    datasets: data.datasets,
  } as ChartData;
  const toTitleCase = (str: string) => {
    return str
      .toLowerCase()
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };
  return insight ? (
    <div id="group-preview-card" className="bg-silver text-blueberry rounded-2xl min-h-80 cursor-pointer " onClick={onClick}>
      <div className="bg-blueberry px-6 flex row justify-start items-center gap-x-4 pt-3 text-milk rounded-t-xl w-full h-16">
        <h3 className="text-2xl font-semibold leading-6">{insight.insightTitle ?? group.title}</h3>
        {insight.digestSection ? (
          <Badge
            size={BadgeSize.Small}
            badge={{ id: '0', text: toTitleCase(insight.digestSection.toString().replace('_', ' ')) }}
            capitalize={false}
            color={insight.digestSection === 'needs_improvement' ? 'bg-raspberry' : 'bg-success'}
            shape={BadgeShape.Pill}
            icon={
              insight.digestSection === 'needs_improvement' ? (
                <ExclamationCircleIcon className="h-5 w-5 stroke-2" />
              ) : (
                <CheckCircleIcon className="h-5 w-5 stroke-2" />
              )
            }
          />
        ) : null}
      </div>
      <div className="px-6 py-2 flex flex-col gap-x-6 gap-y-1 min-h-[80%]">
        <div className="grid grid-cols-3 gap-x-10 h-full">
          <div className="col-span-2 grid grid-rows-[auto_0.5fr] gap-y-1">
            <div className="flex flex-col gap-y-2 ">
              <p className="font-medium text-md md:text-md ">{insight.insightDescription}</p>
              <div className="grid grid-cols-[0.5rem_auto] gap-x-3 min-h-[8rem]">
                <div className="bg-blueberry w-[0.175rem] h-full relative">
                  <Icon
                    icon="gridicons:quote"
                    className="py-1 text-blueberry bg-silver absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
                    width={28}
                    height={28}
                  />
                </div>
                <div className="flex flex-col gap-y-2 justify-evenly">
                  {group.entries
                    .filter((entry, index) => index < 3)
                    .map((entry, index) => {
                      const sentences = group.sentences.filter((sentence) => entry.sentences.map((s) => s.id).includes(sentence.id));
                      const firstSentence = sentences[0];
                      if (!firstSentence) return null;
                      return <Review key={entry.id} sentence={firstSentence} date={group.entries[index]?.date} />;
                    })}
                </div>
              </div>
            </div>
            <div className="flex flex-row items-start justify-center w-full">
              <Button text={'See all mentions'} variant={ButtonVariant.Secondary} size={ButtonSize.XSmall} />
            </div>
          </div>
          <div className="h-[15rem]">
            <ChartView data={transformedData} options={options} />
          </div>
        </div>
      </div>
    </div>
  ) : null;
};

const Review = ({ sentence, date }: { sentence: Sentence; date: number | undefined | null }) => {
  var width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

  let limit = width <= 768 ? 90 : 160; //  Tailwind's md breakpoint
  return (
    <div className="text-xs md:text-sm">
      <p>
        {truncateAndEllipsis(sentence.text, limit)}
        {date ? <i className="font-semibold "> - {moment(date).format('MMM DD')}</i> : null}
      </p>
    </div>
  );
};
export default InsightCard;
