import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import {
  AnnouncementRecipient,
  useGetGroupEmailsQuery,
  useGetAnnouncementTemplateQuery,
  useSendAnnouncementEmailMutation,
  FilterInput,
} from '../../generated/graphql';
import DOMPurify from 'dompurify';
import { validateEmail, validateImage } from '../util';

export const useAnnouncement = (groupId: number, teamId: number, orgId: number, closeModal: () => void, filterInput?: FilterInput) => {
  const { loading: emailsLoading, data: clusterEmails } = useGetGroupEmailsQuery({
    variables: { groupId: groupId, teamId: teamId, filterInput: filterInput },
  });
  const { data: template } = useGetAnnouncementTemplateQuery({ variables: { teamId: teamId } });
  const [sendAnnouncementMutation, mutation] = useSendAnnouncementEmailMutation();
  const [emailList, setEmailList] = useState<AnnouncementRecipient[]>([]);
  const [warning, setWarning] = useState(false);
  const [image, setImage] = useState<string>();
  const [emailPreview, setEmailPreview] = useState<string>();
  const [subject, setSubject] = useState("We've made improvements to {{feature}}");
  const [body, setBody] = useState(`Hi,
    
Thanks for your feedback about {{feedback}}. Your feedback is very valuable to us, and we wanted to let you know we recently made improvements to address it. 
      
We recently {{explain changes made}}, and think you will benefit from the new changes.
      
If you have any questions or additional feedback, don't hesitate to reach out to us at {{support email}}.
      
Thanks,
{{Sender name}} at {{company name}}
      `);

  useEffect(() => {
    // loads validated emails in cluster from db
    if (!emailsLoading) {
      setEmailList(clusterEmails?.getGroupEmails ?? []);
    }
  }, [emailsLoading, clusterEmails]);
  /**
   * Sends the response email and closes the modal.
   * @returns
   */
  const sendResponseEmail = () => {
    if (!emailPreview) return;
    const recipients = emailList?.map((user) => user.userEmail);
    if (recipients && recipients.length > 0) {
      sendAnnouncementMutation({
        variables: {
          body: stringToHtml(body),
          groupId,
          orgId,
          recipients,
          subject,
          teamId,
          image,
        },
        onCompleted: () => {
          toast.success(`Announcement sent to ${emailList.length} customer${emailList.length > 1 ? 's' : ''}.`);
          closeModal();
        },
        onError: (err) => console.log(err.message),
      });
    }
  };
  /**
   * Removes email from emailList
   * @param email
   */
  const removeEmail = (email: string) => {
    setEmailList((prevList) => prevList.filter((user) => user.userEmail !== email) ?? []);
  };
  /**
   * Validates email and either a) adds it to the email list or b) toasts an error
   * @param email
   * @returns
   */
  const addEmail = (email: string) => {
    if (!validateEmail(email)) {
      toast.error('Error: please enter a valid email address.');
      return;
    }
    if (emailList?.some((user) => user.userEmail === email)) {
      toast.error('Error: cannot add duplicate emails to this list.');
      return;
    }
    const user = { userEmail: email, entryText: 'No entry text for this user.' };
    setEmailList((prevList) => [...prevList, user]);
  };
  /**
   * Copies a list of the emails currently in emailList, separated by a comma.
   * @returns
   */
  const copyEmailsToClipboard = () => {
    if (!emailList) return;
    const allEmails = emailList?.map((submitter) => submitter.userEmail).join(', ');
    navigator.clipboard.writeText(allEmails).then(() => {
      toast.success('Emails copied to clipboard');
    });
  };

  const stringToHtml = (str: string) => {
    const html = str.replaceAll('\n', '<br>');
    const sanitizedHtml = DOMPurify.sanitize(html);
    return sanitizedHtml;
  };
  /**
   * Substitutes <br> for \n, substitutes {{image}} w/ image string or '', and sanitizes body state variable for previewing/sending to users.
   * @returns
   */
  const createOutboundEmailPreview = () => {
    const htmlBody = stringToHtml(body);
    // replace body template
    let email = template?.getAnnouncementTemplate.replace('{{{body}}}', htmlBody);
    // remove image template conditional
    email = email?.replaceAll(/{{[\#\/]if(?: image)?}}/g, '');
    // if we have an image, replace the image template with the image string
    // else remove the first image tag entirely
    image ? (email = email?.replace('{{image}}', image)) : (email = email?.replace(/<img .*?>/, ''));
    setEmailPreview(email);
  };

  const addImage = (image: string) => {
    if (image === '' || validateImage(image)) {
      setImage(image);
    } else {
      toast.error('Error: string input is not a valid image url');
    }
  };
  return {
    emailList,
    warning,
    subject,
    body,
    image,
    setSubject,
    setBody,
    setWarning,
    addImage,
    emailPreview,
    emailInProgress: mutation.loading,
    addEmail,
    removeEmail,
    sendResponseEmail,
    createOutboundEmailPreview,
    copyEmailsToClipboard,
  };
};
