interface CardProps {
  title: string;
  subTitle?: string;
  children?: JSX.Element | JSX.Element[];
}

const Card = ({ title, subTitle, children }: CardProps) => {
  return (
    <div className="max-w-sm overflow-hidden overflow-visible rounded border border-gray-200 bg-milk">
      <div className="px-6 py-4">
        <div className="mb-2 text-xl font-bold">{title}</div>
        {subTitle && <p className="text-base text-gray-700">{subTitle}</p>}
      </div>
      {children}
    </div>
  );
};

export default Card;
