import 'tippy.js/themes/light.css';
import { ExplorePageView } from '../hooks/ExploreGroupHook';

export const TaxonomyToggle = ({ view, setView }: { view: ExplorePageView; setView: (view: ExplorePageView) => void }) => {
  return (
    <div className="w-full flex justify-end text-blueberry ">
      <div className="h-8 flex flex-row justify-center items-center border-2 border-blueberry rounded">
        <div
          className={`cursor-pointer h-full w-32 flex justify-center items-center border-blueberry b-2 ${
            view === ExplorePageView.Taxonomy ? ' bg-blueberry text-white shadow-xl' : 'bg-white text-blueberry'
          }`}
          onClick={() => setView(ExplorePageView.Taxonomy)}
        >
          <p>Tree View</p>
        </div>
        <div className="h-full bg-blueberry w-0.5"></div>
        <div
          className={`cursor-pointer h-full w-32 flex justify-center items-center border-blueberry b-2 ${
            view === ExplorePageView.Flat ? ' bg-blueberry text-white shadow-xl' : 'bg-white text-blueberry'
          }`}
          onClick={() => setView(ExplorePageView.Flat)}
        >
          <p>List View</p>
        </div>
      </div>
    </div>
  );
};
