import axios from 'axios';
import queryString from 'query-string';
import { getAuthToken } from '../v2/contexts/UserContext';

const API_URL = process.env.REACT_APP_API_ENDPOINT_V2;
const defaultHeaders = {
  'Content-Type': 'application/json',
};

export const APIV2 = {
  apiFetch: async ({ method, path, body, query, formData, isWindowOpen }) => {
    const builtQuery = query ? `?${queryString.stringify(query)}` : '';
    const url = `${API_URL}${path}${builtQuery}`;
    let accessToken = await getAuthToken();

    let contentType = 'application/json';
    if (formData) contentType = 'multipart/form-data';
    const headers = {
      'Content-Type': contentType,
      Authorization: `${accessToken}`,
    };

    const config = {
      url,
      method,
      headers,
      data: body,
      formData,
    };

    if (isWindowOpen) config['responseType'] = 'blob';

    return axios(config)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.log('api source', err.response);
        throw err;
      });
  },

  apiGet: (path, query, isWindowOpen) => {
    return APIV2.apiFetch({ method: 'get', path, query, isWindowOpen });
  },

  apiPost: (path, body, query, formData) => {
    return APIV2.apiFetch({ method: 'post', path, body, query, formData });
  },

  apiDelete: (path, body, query) => {
    return APIV2.apiFetch({ method: 'delete', path, body, query });
  },

  apiPut: (path, body, query, formData) => {
    return APIV2.apiFetch({ method: 'put', path, body, query, formData });
  },

  teams: {
    getTeamById: (teamId) => {
      return APIV2.apiGet(`teams/${teamId}`)
        .then((r) => r)
        .catch((e) => {
          throw e;
        }); //The then/catch thing can be improved with a general custom error handler
    },

    createTeam: (name, logo_url, creator_id) => {
      return APIV2.apiPost('teams/', { name, logo_url, creator_id })
        .then((r) => r)
        .catch((e) => {
          throw e;
        });
    },

    deleteTeam: (teamId) => {
      return APIV2.apiDelete(`teams/${teamId}`)
        .then((r) => r)
        .catch((e) => {
          throw e;
        });
    },

    getTeamInfo: (teamId) => {
      return APIV2.apiGet(`teams/${teamId}/info`)
        .then((r) => r)
        .catch((e) => {
          throw e;
        });
    },

    addMember: (teamId, email) => {
      return APIV2.apiPost(`teams/${teamId}/members`, { email })
        .then((r) => r)
        .catch((e) => {
          throw e;
        });
    },

    removeMember: (teamId, email) => {
      return APIV2.apiDelete(`teams/${teamId}/members`, { email })
        .then((r) => r)
        .catch((e) => {
          throw e;
        });
    },

    getStatusFlags: (teamId) => {
      return APIV2.apiGet(`teams/${teamId}/flags`)
        .then((r) => r)
        .catch((e) => {
          throw e;
        });
    },

    downloadCsv: (teamId) => {
      let isWindowOpen = true;
      APIV2.apiGet(`teams/${teamId}/exportCsv`, null, isWindowOpen)
        .then((response) => response.data)
        .then((blob) => {
          let _url = window.URL.createObjectURL(blob);
          window.open(_url, '_blank').focus();
        });
    },

    feedback: {
      getFeedback: (teamId) => {
        return APIV2.apiGet(`teams/${teamId}/feedback`)
          .then((r) => r)
          .catch((e) => {
            throw e;
          });
      },

      getClusters: (teamId) => {
        return APIV2.apiGet(`teams/${teamId}/feedbackClusters`)
          .then((r) => r)
          .catch((e) => {
            throw e;
          });
      },

      getThemes: (teamId) => {
        return APIV2.apiGet(`teams/${teamId}/themes`)
          .then((r) => r)
          .catch((e) => {
            throw e;
          });
      },

      getThemesWithStats: (teamId) => {
        return APIV2.apiGet(`teams/${teamId}/themesWithStats`)
          .then((r) => r)
          .catch((e) => {
            throw e;
          });
      },

      addTheme: (teamId, themeName, categorizeMethodId, parentId, viewOnly) => {
        return APIV2.apiPost(`teams/${teamId}/themes`, { name: themeName, categorizeMethodId, parentId, viewOnly })
          .then((r) => r)
          .catch((e) => {
            throw e;
          });
      },

      deleteTheme: (teamId, themeId) => {
        return APIV2.apiDelete(`teams/${teamId}/themes/${themeId}`)
          .then((r) => r)
          .catch((e) => {
            throw e;
          });
      },

      editTheme: (teamId, themeId, categorizeMethodId, parentId, aliases, viewOnly) => {
        return APIV2.apiPut(`teams/${teamId}/themes/${themeId}`, { categorizeMethodId, parentId, aliases, viewOnly })
          .then((r) => r)
          .catch((e) => {
            throw e;
          });
      },

      removeEntryFromCluster: (teamId, entryId, clusterId) => {
        return APIV2.apiDelete(`teams/${teamId}/clusters/${clusterId}/entries/${entryId}`)
          .then((r) => r)
          .catch((e) => {
            throw e;
          });
      },

      toggleClusterAccepted: (teamId, clusterId) => {
        return APIV2.apiPut(`teams/${teamId}/clusters/${clusterId}/accepted`)
          .then((r) => r)
          .catch((e) => {
            throw e;
          });
      },

      addSentenceToCluster: (teamId, sentenceId, clusterId) => {
        return APIV2.apiPut(`teams/${teamId}/sentences/${sentenceId}`, { cluster_id: clusterId })
          .then((r) => r)
          .catch((e) => {
            throw e;
          });
      },

      fetchFeedbackSuggestions: (teamId) => {
        return APIV2.apiGet(`teams/${teamId}/themes/suggestions?num_results=20`)
          .then((r) => r)
          .catch((e) => {
            throw e;
          });
      },

      requestCategorization: (teamId) => {
        return APIV2.apiGet(`teams/${teamId}/categorization`)
          .then((r) => r)
          .catch((e) => {
            throw e;
          });
      },

      getClustersBasic: (teamId) => {
        return APIV2.apiGet(`teams/${teamId}/clustersBasic`)
          .then((r) => r)
          .catch((e) => {
            throw e;
          });
      },

      deleteEntry: (teamId, entryId) => {
        return APIV2.apiDelete(`teams/${teamId}/entries/${entryId}`)
          .then((r) => r)
          .catch((e) => {
            throw e;
          });
      },

      changeClusterTitle: (teamId, clusterId, newTitle) => {
        return APIV2.apiPut(`teams/${teamId}/clusters/${clusterId}`, { title: newTitle })
          .then((r) => r)
          .catch((e) => {
            throw e;
          });
      },
    },

    analytics: {
      charts: {
        /*  getTopNegativeFeedbackChart: (teamId) => {
           return API.apiGet(`teams/${teamId}/analytics/top_negative_feedback_chart`).then(r => r).catch(e => { throw e });
         }, */

        getFeedbackLabelDeepDiveChart: (teamId, themeId) => {
          let queryParams = themeId ? `?themeId=${themeId}` : '';
          return APIV2.apiGet(`teams/${teamId}/analytics/feedback_label_deep_dive_chart${queryParams}`)
            .then((r) => r)
            .catch((e) => {
              throw e;
            });
        },

        getFeedbackPositiveSentimentRateChart: (teamId) => {
          return APIV2.apiGet(`teams/${teamId}/analytics/feedback_positive_sentiment_rate`)
            .then((r) => r)
            .catch((e) => {
              throw e;
            });
        },

        getOverallFavorabilityChart: (teamId, period) => {
          //period = monthly (default), weekly.
          let queryObj = {
            period: period,
          };
          return APIV2.apiGet(`teams/${teamId}/analytics/overall_favorability`, queryObj)
            .then((r) => r)
            .catch((e) => {
              throw e;
            });
        },
        getThemeFavorabilityChart: (teamId, period, themeId) => {
          let queryObj = {
            period: period,
            themeId: themeId,
          };
          return APIV2.apiGet(`teams/${teamId}/analytics/theme_favorability`, queryObj)
            .then((r) => r)
            .catch((e) => {
              throw e;
            });
        },
        getThemePositiveNegativeChart: (teamId, period, themeId) => {
          let queryObj = {
            period: period,
            themeId: themeId,
          };
          return APIV2.apiGet(`teams/${teamId}/analytics/theme_positive_negative`, queryObj)
            .then((r) => r)
            .catch((e) => {
              throw e;
            });
        },

        getThemeContributionChart: (teamId, period, sentiment, includeDescendants) => {
          let queryObj = {
            period: period,
            sentiment: sentiment,
            descendants: includeDescendants,
          };
          return APIV2.apiGet(`teams/${teamId}/analytics/theme_contribution`, queryObj)
            .then((r) => r)
            .catch((e) => {
              throw e;
            });
        },

        getClusterDeepDiveChart: (teamId, period, clusterId) => {
          let queryObj = {
            period: period,
            clusterId: clusterId,
          };
          return APIV2.apiGet(`teams/${teamId}/analytics/cluster_deep_dive`, queryObj)
            .then((r) => r)
            .catch((e) => {
              throw e;
            });
        },
      },
    },

    alerts: {
      getDataForAlerts: (teamId) => {
        return APIV2.apiGet(`teams/${teamId}/dataForAlerts`)
          .then((r) => r)
          .catch((e) => {
            throw e;
          });
      },

      createAlert: (teamId, typeId, themeId, clusterId, thresholdAmount, periodDays, sentiment, emails) => {
        return APIV2.apiPost(`teams/${teamId}/alerts/`, { typeId, themeId, clusterId, thresholdAmount, periodDays, sentiment, emails })
          .then((r) => r)
          .catch((e) => {
            throw e;
          });
      },

      getAlerts: (teamId) => {
        return APIV2.apiGet(`teams/${teamId}/alerts/`)
          .then((r) => r)
          .catch((e) => {
            throw e;
          });
      },

      deleteAlert: (teamId, alertId) => {
        return APIV2.apiDelete(`teams/${teamId}/alerts/${alertId}`)
          .then((r) => r)
          .catch((e) => {
            throw e;
          });
      },

      /*  getAlertTypes: () => {
         return APIV2.apiGet(`alerts/`).then(r => r).catch(e => { throw e });
       },
 
       createAlert: (teamId, type_id, label_id, threshold_amount, channel, emails) => {
         return APIV2.apiPost(`teams/${teamId}/alerts`, { type_id, label_id, threshold_amount, channel, emails }).then(r => r).catch(e => { throw e });
       },
 
       deleteAlert: (teamId, alertId) => {
         return APIV2.apiDelete(`teams/${teamId}/alerts/${alertId}`).then(r => r).catch(e => { throw e });
       },
 
       testEmail: (teamId, alertId) => {
         return APIV2.apiPost(`teams/${teamId}/alerts/${alertId}/triggerEmail`).then(r => r).catch(e => { throw e });
       }, */
    },

    integrations: {
      /* saveZendeskSubdomain: (teamId, subdomain) => {
        return APIV2.apiPost(`teams/${teamId}/integrations/zendesk/subdomain`, { subdomain }).then(r => r).catch(e => { throw e });
      }, */

      createIntegration: (teamId, source, csv) => {
        const formData = new FormData();
        const urlSource = source.toLowerCase();
        formData.append('file', csv);
        return APIV2.apiPost(`teams/${teamId}/integrations/${urlSource}/csv`, formData, null, true)
          .then((r) => r)
          .catch((e) => {
            throw e;
          });
      },

      createCSVIntegration: (teamId, sourceName, csv) => {
        const formData = new FormData();
        formData.append('file', csv);
        if (sourceName) formData.append('sourceName', sourceName);
        return APIV2.apiPost(`teams/${teamId}/integrations/csv/csv`, formData, null, true)
          .then((r) => r)
          .catch((e) => {
            throw e;
          });
      },

      createRedshiftIntegration: (teamId, sourceName, csv) => {
        const formData = new FormData();
        formData.append('file', csv);
        if (sourceName) formData.append('sourceName', sourceName);
        return APIV2.apiPost(`teams/${teamId}/integrations/redshift/csv`, formData, null, true)
          .then((r) => r)
          .catch((e) => {
            throw e;
          });
      },
    },
  },

  auth: {
    login: (email, password) => {
      return axios
        .post(`${API_URL}auth/login`, { withCredentials: true }, { auth: { username: email, password } })
        .then((r) => r)
        .catch((e) => {
          throw e;
        });
    },

    signup: (email, password, first_name, last_name, phone_number) => {
      return axios
        .post(`${API_URL}auth/signup`, { email, password, first_name, last_name, phone_number }, { defaultHeaders })
        .then((r) => r)
        .catch((e) => {
          throw e;
        });
    },

    getPasswordResetEmail: (email, staging) => {
      return axios
        .get(`${API_URL}auth/resetPassword?email=${email}&staging=${staging | 0}`, { defaultHeaders })
        .then((r) => r)
        .catch((e) => {
          throw e;
        });
    },

    resetPassword: (resetToken, password, userId) => {
      //password is THE NEW password set by the user
      return axios
        .post(`${API_URL}auth/resetPassword`, { resetToken, password, userId }, { defaultHeaders })
        .then((r) => r)
        .catch((e) => {
          throw e;
        });
    },
  },

  users: {
    getTeams: () => {
      return APIV2.apiGet(`users/teams`)
        .then((r) => r)
        .catch((e) => {
          throw e;
        });
    },

    changePassword: async (user_id, password, new_password) => {
      return APIV2.apiPost(`users/${user_id}/settings/password`, { password, new_password })
        .then((r) => r)
        .catch((e) => {
          throw e;
        });
    },
  },
};

/* const errorHandler = (response) => {
  if (response.errKey || response.errors) {
    throw response;
  } else {
    return response;
  }
} */
