import { useState } from 'react';
import Modal from '../../baseComponents/Modal';
import Button, { ButtonVariant } from '../../baseComponents/Button';
// make this generic for orgs and teams and also typescript bc i don't need all this repetition.

const LogoModal = ({
  modalOpen,
  callbackModal,
  confirmButton,
  image,
}: {
  modalOpen: boolean;
  callbackModal: () => void;
  image?: string;
  confirmButton?: (logoUrl: string) => void;
}) => {
  const [logoUrl, setLogoUrl] = useState<string>(image ?? '');
  return (
    <Modal open={modalOpen} setOpen={callbackModal}>
      <div>
        <div>
          <div className="flex flex-col gap-y-0">
            <h1 className="mb-2 text-lg font-bold text-blueberry">Enter Url of Your Logo:</h1>
            <input
              className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 focus:border-blueberry focus:outline-none focus:ring-blueberry sm:text-sm"
              value={logoUrl}
              onChange={(e) => setLogoUrl(e.target.value)}
            />
          </div>
        </div>

        <div className="mt-8 grid grid-cols-3 justify-end gap-x-12 text-center">
          <div className="col-span-1">
            <Button expandWidth variant={ButtonVariant.Tertiary} onClick={() => callbackModal()} text="Cancel" />
          </div>
          <div className="col-span-2">
            <Button
              expandWidth
              variant={ButtonVariant.Primary}
              text="Confirm"
              onClick={() => {
                confirmButton && confirmButton(logoUrl);
                callbackModal();
              }}
            ></Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};
export default LogoModal;
