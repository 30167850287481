import { useEffect } from 'react';

export const useExitIntent = (callback: () => void, triggerExitIntent: boolean) => {
  useEffect(() => {
    // sets a cookie for 30 days after the current date
    const setCookie = () => {
      const expiryDate = new Date(Date.now() + 30 * (1000 * 60 * 60 * 24));
      document.cookie = 'preview_exit_modal_seen' + '=true; expires=' + expiryDate.toUTCString();
    };
    // if a user has scrolled off the page, conditions for triggering exit intent have been met, and the cookie has expired
    // then trigger the callback and set a cookie
    const handleMouseLeave = (event: any) => {
      if (event.clientY < 0 && triggerExitIntent && document.cookie.indexOf('preview_exit_modal_seen=true') < 0) {
        callback();
        setCookie();
        document.removeEventListener('mouseout', handleMouseLeave);
      }
    };
    document.addEventListener('mouseout', handleMouseLeave);
    return () => {
      document.removeEventListener('mouseout', handleMouseLeave);
    };
  }, [triggerExitIntent]);
};
