import { Fragment, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';

import logo from '../../../assets/unwrap_logo_red.svg';
import fullLogoMain2 from '../../../assets/unwrap_full_logo_2_MAIN_2.svg';

export default function EnterEmailModal({
  modalOpen,
  onConfirm,
  afterSubmit,
  defaultEmail,
  defaultName,
  teamName,
}: {
  modalOpen: boolean;
  onConfirm: (email: string, name: string) => void;
  afterSubmit: boolean;
  defaultEmail: string | null;
  defaultName: string | null;
  teamName: string;
}) {
  const [email, setEmail] = useState<string | null>(defaultEmail);
  const [fullName, setFullName] = useState<string | null>(defaultName);
  return (
    <Transition.Root show={modalOpen} as={Fragment}>
      <Dialog as="div" className="fixed inset-0 z-50 overflow-y-auto" onClose={() => {}}>
        <div className="relative flex min-h-screen items-center justify-center px-4 pt-4 pb-20 text-center font-sofiapro sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:h-screen sm:align-middle" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="relative inline-block max-w-4xl transform overflow-hidden rounded-3xl text-left align-bottom shadow-xl transition-all sm:align-middle">
              <div className="fade-in-up-2 absolute right-0 top-0 flex flex-row gap-x-2"></div>
              {!afterSubmit ? (
                <div className="grid grid-cols-2">
                  <div className="col-span-2 flex flex-col gap-y-2 bg-blueberry px-8 py-6 lg:col-span-1 lg:gap-y-5 lg:py-16">
                    <div className="flex flex-col items-center lg:items-start gap-y-3">
                      <img className="block w-16" src={logo} alt="Unwrap" />
                      <h1 className="text-2xl font-bold text-milk lg:text-3xl">See Unwrap in action for {teamName}!</h1>
                    </div>
                    <div className="flex flex-col gap-y-2 text-justify text-sm lg:gap-y-8 lg:text-base">
                      <p className="text-milk"></p>
                      <p className="text-center text-milk">Enter your name and email address to get started.</p>
                    </div>
                  </div>
                  <div className="col-span-2 bg-milk px-8 py-6 lg:col-span-1 lg:py-20">
                    <form
                      onSubmit={(e) => {
                        e.preventDefault();
                        onConfirm(email!, fullName!);
                      }}
                    >
                      <div className="flex flex-col gap-y-4">
                        <div className="flex flex-col">
                          <h1 className="text-sm font-semibold">Name</h1>
                          <input
                            autoFocus={false}
                            autoComplete="new-off"
                            aria-autocomplete="none"
                            required
                            defaultValue={defaultName ?? ''}
                            className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm sm:text-sm"
                            onChange={(e) => setFullName(e.target.value)}
                          />
                        </div>
                        <div className="flex flex-col">
                          <h1 className="text-sm font-semibold">Work Email</h1>
                          <input
                            type={'email'}
                            autoFocus={false}
                            autoComplete="new-off"
                            aria-autocomplete="none"
                            required
                            defaultValue={defaultEmail ?? ''}
                            className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm sm:text-sm"
                            onChange={(e) => setEmail(e.target.value)}
                          />
                        </div>
                        <button
                          type="submit"
                          className={'text-md mt-4 rounded-full bg-raspberry py-3 px-8 text-milk duration-200 hover:scale-105 hover:bg-blueberry'}
                        >
                          <h1 className="text-lg font-semibold">Submit</h1>
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              ) : (
                <div>
                  <div className="flex flex-col items-center gap-y-3 bg-blueberry px-6 py-14 lg:px-20">
                    <img className="block w-1/2 lg:w-1/3 " src={fullLogoMain2} alt="Unwrap" />
                    <h1 className="text-center text-lg font-medium text-milk lg:text-2xl ">
                      Thanks for your interest!
                      <br />A team member will get in touch with you within 24 hours.
                    </h1>
                  </div>
                </div>
              )}
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
