import { useEffect, useState, useContext } from 'react';
import _, { capitalize, cloneDeep, groupBy } from 'lodash';
import { logEvent, Events } from '../AnalyticsUtil';
import { ExportToCsv } from 'export-to-csv';
import { toast } from 'react-hot-toast';
import {
  EntryFragment,
  SentenceEntryFragment,
  IChartSeries,
  IChartableItem,
  GetChildrenToAssignQuery,
  GetChildrenToAssignQueryVariables,
  useGetChildrenToAssignLazyQuery,
  ClusterSort,
  GroupDataFragment,
  useGetGroupLazyQuery,
  useEditGroupMutation,
  useGetPreviewPageGroupsLazyQuery,
  Group_Status,
  Group_Type,
  useFeedbackSentencesLazyQuery,
  Insights_Time_Window,
  useRemoveTagFromGroupMutation,
  SegmentsSourcesClustersAndThemesDocument,
  useCreateTagMutation,
  useTagGroupMutation,
  GetTagsDocument,
  useTogglePinGroupMutation,
  EditGroupMutationFn,
  DeleteChildMutationFn,
  TogglePinGroupMutationFn,
  GetGroupQueryResult,
  TagGroupMutationFn,
  RemoveTagFromGroupMutationFn,
  useLogGroupViewMutation,
  CreateTagMutationFn,
  FeedbackSentencesQueryVariables,
  FeedbackSentencesQuery,
  GetGroupQueryVariables,
  GetGroupSentencesQueryVariables,
  GetGroupQuery,
  GetGroupSentencesQuery,
  useUpdateGroupOwnerMutation,
  useRemoveGroupOwnerMutation,
  FilterType,
  UpdateGroupOwnerMutationFn,
  RemoveGroupOwnerMutationFn,
  useGetTagsLazyQuery,
  DataForFiltersDocument,
  GroupDependencies,
  useGetGroupSentencesLazyQuery,
  useGetChildCandidatesLazyQuery,
  GetChildCandidatesQuery,
  GetChildCandidatesQueryVariables,
  useAssignChildMutation,
  useAssignChildrenMutation,
  useGetChildrenLazyQuery,
  GetChildrenQueryVariables,
  GetChildrenQuery,
  AssignChildMutationFn,
  useGetOrphansLazyQuery,
  AssignChildrenMutationFn,
  useDeleteChildMutation,
  GroupTaxonomyFragment,
  useRemoveChildMutation,
  RemoveChildMutationFn,
  Group_Trending,
  useGetGroupsLazyQuery,
  TaxonomyElement,
  GroupInsightFragment,
  useTeamGroupsTaxonomyLazyQuery,
  useTeamGroupsListLazyQuery,
  Submitter_Type,
  GroupSentenceDataFragment,
  useGetGroupForExportLazyQuery,
  GetGroupForExportQuery,
  GetGroupForExportQueryVariables,
} from '../../generated/graphql';
import { FilterInput } from '../../generated/graphql';
import AppContext from '../contexts/AppContext';
import { IDropDownItem } from '../../baseComponents/DropDown';
import { LazyQueryExecFunction } from '@apollo/client';
import { localDateString } from '../util';
import { logError } from '../../applicationTelemetry';
import moment from 'moment';
import { getTotalGroupLoadEvent } from '../../latencyTracker';
import he from 'he';
import { AppRoutes } from '../../Routes';

export type Entry = {
  date?: number | null;
  id: number;
  title?: string | null;
  sentences: Sentence[];
  source_permalink?: string | null;
  source?: string | null;
  text?: string | null;
  submitter?: string | null;
  hasConversation: boolean;
  conversationParts: {
    fullText?: string | null | undefined;
    submitterType: Submitter_Type;
    submitter?: string | null | undefined;
    date?: number | undefined | null;
  }[];
  distillateText?: string | undefined | null;
  segments: {
    groupName: string;
    value: string;
  }[];
  stars?: number | null;
  sentiment?: number | null;
};
export type Sentence = {
  id: number;
  is_distillate: boolean;
  text?: string | null | undefined;
  preceedingText?: string | null | undefined;
  proceedingText?: string | null | undefined;
};

export type SentenceEntry = Sentence & {
  entry: Entry;
};

export interface GroupBaseProps {
  id: number;
  title?: string | null | undefined;
  totalEntries: number;
  denominator: number;
  approved: boolean | null;
  processing?: boolean;
  progress?: number;
  pinnedByUser: boolean;
  centroid: string;
  status: Group_Status;
  isExactMatch: boolean;
  type: Group_Type;
  sentences: SentenceEntry[];
  entries: Entry[];
  tags?: ITag[] | undefined | null;
  isNew?: boolean | null | undefined;
  relativeShare?: number;
  relativeShareFull?: number;
  date: number;
  dateCreated?: number | null | undefined;
  centroidText?: string;
  uniqueEntries?: number;
  statistics?: any;
  totalDescendents: number;
  isPinnedByUser?: boolean;
  insight?: GroupInsightFragment | null;
}
export class GroupBase {
  id: number;
  title?: string | null | undefined;
  totalEntries: number;
  denominator: number;
  approved: boolean | null;
  pinnedByUser: boolean;
  centroid: string;
  status: Group_Status;
  isExactMatch: boolean;
  type: Group_Type;
  processing?: boolean;
  progress?: number;
  sentences: Sentence[];
  entries: Entry[];
  tags?: ITag[] | undefined | null;
  /** Is this even used anymore it's referenced in code but I haven't seen this show up at all. */
  isNew?: boolean | null | undefined;
  /** This represents the percentage of filtered feedback, with the filter applied to the denominator */
  relativeShare?: number;
  /** This represent the percentage or unfiltered feedback, all feedback is in the denominator */
  relativeShareFull?: number;
  date: number;
  totalDescendents: number;
  isPinnedByUser?: boolean;
  insight?: GroupInsightFragment | null;
  constructor(props: GroupBaseProps) {
    this.id = props.id;
    this.title = props.title;
    this.totalEntries = props.uniqueEntries !== undefined ? props.uniqueEntries : props.totalEntries;
    this.denominator =
      props.statistics?.denominator?.denominatorUnfiltered !== undefined ? props.statistics?.denominator?.denominatorUnfiltered : props.denominator;
    this.pinnedByUser = props.isPinnedByUser !== undefined ? props.isPinnedByUser : props.pinnedByUser;
    this.processing = props.processing;
    this.progress = props.progress;
    this.centroid = props.centroidText || props.centroid;
    this.status = props.status;
    this.isExactMatch = props.isExactMatch;
    this.approved = props.approved;
    this.type = props.type;
    this.sentences = props.sentences;
    this.entries = props.entries;
    this.tags = props.tags;
    this.isNew = props.isNew;
    this.relativeShare = props.relativeShare;
    this.relativeShareFull = props.relativeShareFull;
    this.date = props.dateCreated ? props.dateCreated : props.date;
    this.totalDescendents = props.totalDescendents;
    this.insight = props.insight;
  }
}

export interface TaxonomyGroupProps extends GroupBaseProps {
  showChildren: boolean;
  parentId: number | null;
  children: TaxonomyGroup[] | null; // null means we haven't loaded the children yet
  trending: Group_Trending | null | undefined;
  canAddChildren: boolean | undefined | null;
}
export class TaxonomyGroup extends GroupBase {
  showChildren: boolean;
  parentId: number | null;
  totalDescendents: number;
  children: TaxonomyGroup[] | null; // null means we haven't loaded the children yet
  trending: Group_Trending | null | undefined;
  canAddChildren: boolean | undefined | null;

  constructor(props: TaxonomyGroupProps) {
    super(props);
    this.showChildren = props.showChildren;
    this.totalDescendents = props.totalDescendents;
    this.children = props.children;
    this.trending = props.trending;
    this.canAddChildren = props.canAddChildren;
    this.parentId = props.parentId;
  }
}

export interface GroupFullProps extends GroupBaseProps {
  ownerId?: number;
  centroid: string;
  title?: string | null;
  percentChange?: number | null;
  percentChangeTimeRange?: Insights_Time_Window | null;
  aggregateData?: number[];
  normalizedData?: number[];
  tooltipLabels?: string[];
  chartLabels?: string[];
  filterInput?: FilterInput;
  users: null;
  date: number;
  chartData?: any;
  chartOptions?: any;
  parentId?: number | null;
}

export class GroupFull extends GroupBase {
  ownerId?: number;
  isNew?: boolean;
  percentChange?: number | null;
  percentChangeTimeRange?: Insights_Time_Window | null;
  aggregateData?: number[];
  normalizedData?: number[];
  tooltipLabels?: string[];
  chartLabels?: string[];
  sentences: SentenceEntry[];
  entries: Entry[];
  users: null;
  date: number;
  chartData?: any;
  chartOptions?: any;
  parentId?: number | null;
  filterInput?: FilterInput;
  constructor(props: GroupFullProps) {
    super(props);
    this.parentId = props.parentId;
    this.ownerId = props.ownerId;
    this.percentChange = props.percentChange;
    this.percentChangeTimeRange = props.percentChangeTimeRange;
    this.aggregateData = props.aggregateData;
    this.normalizedData = props.normalizedData;
    this.tooltipLabels = props.tooltipLabels;
    this.chartLabels = props.chartLabels;
    this.sentences = props.sentences;
    this.entries = props.entries;
    this.users = props.users;
    this.date = props.date;
    this.chartData = props.chartData;
    this.chartOptions = props.chartOptions;
    this.filterInput = props.filterInput;
  }
}

export interface ITag {
  id: number;
  name: string;
}
export enum GroupType {
  Cluster,
  Search,
}

export interface GroupHookProps {
  teamId: number;
  orgId: number;
  teamName: string;
  orgName: string;
  pageName: string;
  ignoreDateFilters?: boolean;
  selectedDenominator?: IDropDownItem | undefined;
  filterInput?: FilterInput;
  sentenceFilterInput?: FilterInput;
  status?: Group_Status;
  pageSize?: number;
  clusterSort?: ClusterSort;
  sentenceIdToSearch?: number;
  teamUuid?: string;
  email?: string;
  sentencesTake?: number;
  preventDefaultGroupsLoad?: boolean;
}

/**
 *  Rules for hook
 * All state definitions go at the top including all 'Mutation' and 'Query' types.
 *
 * All useEffects follow the state definitions.
 *
 * All function definitions go below
 * @returns
 */
export const useGroupHook = ({
  teamId,
  orgId,
  teamName,
  orgName,
  pageName,
  ignoreDateFilters,
  selectedDenominator,
  filterInput,
  status,
  pageSize,
  email,
  sentencesTake,
  preventDefaultGroupsLoad,
}: GroupHookProps) => {
  const { app } = useContext(AppContext);
  // groups
  const [groups, setGroups] = useState<(GroupBase | GroupFull | TaxonomyGroup)[]>([]);
  const [taxonomy, setTaxonomy] = useState(new Map<number, TaxonomyGroup>());
  const [currentGroup, setCurrentGroup] = useState<GroupBase | GroupFull>();
  const [togglePinGroupMutation] = useTogglePinGroupMutation();
  const [getGroupFull, groupQuery] = useGetGroupLazyQuery({ fetchPolicy: 'network-only', notifyOnNetworkStatusChange: true });
  const [getGroupForExport, groupForExportQuery] = useGetGroupForExportLazyQuery({ fetchPolicy: 'network-only', notifyOnNetworkStatusChange: true });
  const [editGroup, editResult] = useEditGroupMutation({ fetchPolicy: 'no-cache', notifyOnNetworkStatusChange: true });
  const [getOrphans, orphansQuery] = useGetOrphansLazyQuery({
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    variables: {
      teamId,
      belongs: true,
      take: pageSize,
      filterInput: filterInput ?? {},
      teamUuid: app?.currentUuid ?? undefined,
      status,
    },
  });
  const [getListQuery, listQuery] = useTeamGroupsListLazyQuery({ fetchPolicy: 'no-cache', notifyOnNetworkStatusChange: true });

  const [getGroupsQuery, groupsQuery] = useGetGroupsLazyQuery({
    // setting this to allow caching - We may not be using the apollo source
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    variables: {
      teamId,
      belongs: true,
      sentencesTake: sentencesTake != null ? sentencesTake : 3,
      take: pageSize,
      filterInput: filterInput ?? {},
      teamUuid: app?.currentUuid ?? undefined,
      status,
    },
  });
  const [getChildrenToAssignQuery, childrenToAssignResult] = useGetChildrenToAssignLazyQuery({});
  const [getChildCandidatesQuery, childCandidatesQuery] = useGetChildCandidatesLazyQuery({
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
  });
  const [getChildrenQuery, childrenQuery] = useGetChildrenLazyQuery({ fetchPolicy: 'network-only', notifyOnNetworkStatusChange: true });
  const [assignChildMutation, assignChildResult] = useAssignChildMutation({ fetchPolicy: 'network-only', notifyOnNetworkStatusChange: true });
  const [deleteChildMutation, deleteChildResult] = useDeleteChildMutation({ fetchPolicy: 'network-only', notifyOnNetworkStatusChange: true });
  const [assignChildrenMutation, assignChildrenResult] = useAssignChildrenMutation({ fetchPolicy: 'network-only', notifyOnNetworkStatusChange: true });
  const [removeChildMutation, removeChildResult] = useRemoveChildMutation({ fetchPolicy: 'network-only', notifyOnNetworkStatusChange: true });
  const [childCandidates, setChildCandidates] = useState<TaxonomyGroup[]>([]);
  const [children, setChildren] = useState<TaxonomyGroup[]>([]);
  const [childrenToAssign, setChildrenToAssign] = useState<TaxonomyGroup[]>([]);
  const [getGroupSentencesQuery] = useGetGroupSentencesLazyQuery({ fetchPolicy: 'network-only', notifyOnNetworkStatusChange: true });
  const [logGroupView] = useLogGroupViewMutation({ fetchPolicy: 'no-cache', notifyOnNetworkStatusChange: true });
  const [getTaxonomyQuery, taxonomyQuery] = useTeamGroupsTaxonomyLazyQuery({
    variables: { teamId, filterInput: filterInput ?? {} },
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
  });
  const [getPreviewSearches, previewSearchesQuery] = useGetPreviewPageGroupsLazyQuery({
    notifyOnNetworkStatusChange: true,
    variables: {
      teamId,
      email: email ?? '',
      filterInput: filterInput ?? {},
      sentencesTake: sentencesTake != null ? sentencesTake : 3,
      take: 10,
      teamUuid: app?.currentUuid ?? '',
    },
  });
  const [updateGroupOwner] = useUpdateGroupOwnerMutation({ fetchPolicy: 'no-cache', notifyOnNetworkStatusChange: true });
  const [removeGroupOwner] = useRemoveGroupOwnerMutation({ fetchPolicy: 'no-cache', notifyOnNetworkStatusChange: true });

  // tags
  const [getAllTags, tags] = useGetTagsLazyQuery({
    variables: { teamId },
  });
  const [removeTag] = useRemoveTagFromGroupMutation({ fetchPolicy: 'no-cache', notifyOnNetworkStatusChange: true });
  const [createTag] = useCreateTagMutation({
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    refetchQueries: [
      { query: SegmentsSourcesClustersAndThemesDocument, variables: { teamId } },
      { query: GetTagsDocument, variables: { teamId } },
    ],
  });
  const [tagGroup] = useTagGroupMutation({ fetchPolicy: 'no-cache', notifyOnNetworkStatusChange: true });

  // sentences
  const [sentencesQuery, sentencesResult] = useFeedbackSentencesLazyQuery({ fetchPolicy: 'no-cache', notifyOnNetworkStatusChange: true });
  const [similarSentences, setSimilarSentences] = useState<SentenceEntryFragment[]>();

  //The currentGroupFilter is the filter that comes from the Modal.
  //Because we have a date picker in the modal, we need to use that FilterInput instead of the page-level one.
  //This filter is cloned inside the GroupModal, then the dates are changed from the in-modal date picker.
  const [currentGroupFilter, setCurrentGroupFilter] = useState<FilterInput | undefined>(filterInput);
  //This state is to distinguish from reloading group just for entries vs reloading for all data
  const [reloadingCurrentGroup, setReloadingCurrentGroup] = useState<boolean>(false);

  useEffect(() => {
    if (!app?.isPreviewMode) {
      getAllTags();
    }
  }, []);

  useEffect(() => {
    setCurrentGroup(undefined);
  }, [filterInput]);

  useEffect(() => {
    if (!currentGroup) setCurrentGroupFilter(filterInput);
  }, [selectedDenominator, filterInput]);

  const loadListView = async (teamId: number, filterInput: FilterInput, teamUuid?: string) => {
    const usedVariables = groupsQuery.variables;
    if (groupsQuery.called && teamId === usedVariables?.teamId && filterInput === usedVariables?.filterInput && filterInput === usedVariables?.teamUuid) return;
    setGroups([]);
    getListQuery({
      variables: {
        teamId,
        belongs: true,
        sentencesTake: sentencesTake != null ? sentencesTake : 3,
        take: pageSize,
        skip: 0,
        filterInput: filterInput ?? {},
        teamUuid: app?.currentUuid ?? undefined,
        status,
      },
    });
  };
  const loadTaxonomyView = async (teamId: number, filterInput: FilterInput) => {
    const usedVariables = taxonomyQuery.variables;

    if (taxonomyQuery.called && teamId === usedVariables?.teamId && filterInput === usedVariables?.filterInput) return;
    setTaxonomy(new Map<number, TaxonomyGroup>());
    if (!app?.currentUuid) {
      getTaxonomyQuery({
        variables: {
          teamId,
          filterInput: filterInput ?? {},
        },
      });
    }
  };

  useEffect(() => {
    const abortController = new AbortController();
    const initialModalLoaded = performance.now();
    const updateData = async () => {
      if (currentGroup) {
        setReloadingCurrentGroup(true);
        const sentencesQuery = getGroupSentencesQuery({
          variables: {
            groupId: currentGroup.id,
            teamId,
            belongs: true,
            sentencesTake: 20,
            sentencesSkip: 0,
            filterInput: currentGroupFilter ?? {},
            teamUuid: app?.currentUuid ?? undefined,
            includeDescendantsOnMappings: true,
          },
        });
        const dataGroupQuery = getGroupFull({
          variables: {
            groupId: currentGroup.id,
            teamId,
            belongs: true,
            sentencesTake: 0,
            sentencesSkip: 0,
            filterInput: currentGroupFilter ?? {},
            teamUuid: app?.currentUuid ?? undefined,
            includeDescendantsOnMappings: true,
          },
        });
        const [dataGroup, sentences] = await Promise.all([dataGroupQuery, sentencesQuery]);
        let dataGroupData = cloneDeep(dataGroup.data?.getGroup);
        let sentencesGroupData = cloneDeep(sentences.data?.getGroup);
        let merged;
        if (dataGroupData && sentencesGroupData) {
          merged = {
            ...dataGroupData,
            groupEntries: [...(dataGroupData?.groupEntries ?? []), ...(sentencesGroupData?.groupEntries ?? [])],
          };
        }
        if (!abortController.signal.aborted && merged) {
          setCurrentGroup(getGroups([merged])[0]);
          setReloadingCurrentGroup(false);
          const event = getTotalGroupLoadEvent({ view: pageName.includes('Home') ? 'home' : 'list', duration: initialModalLoaded });
          window.dispatchEvent(event);
        }
      }
    };
    updateData();
    return () => {
      // this is a cleanup function that React will run when a component unmounts
      abortController.abort(); // aborting any remaining operations by calling .abort()
    };
  }, [currentGroupFilter]);
  useEffect(() => {
    if (!currentGroup) {
      setCurrentGroupFilter(filterInput);
    } else {
      logGroupView({
        variables: {
          groupId: currentGroup.id,
          teamId,
        },
      });
    }
  }, [currentGroup?.id]);

  return {
    groups,
    taxonomy,
    filteredAmountOfGroups: listQuery.called ? listQuery.data?.teamGroups?.amountOfGroups.amount : taxonomyQuery.data?.teamGroups?.amountOfGroups.amount,
    tags: (tags.data?.getTags as ITag[]) ?? [],
    currentGroup,
    similarSentences,
    childrenToAssign,
    childCandidates,
    children,
    app,
    previewSearchesQuery,
    groupsQuery,
    orphansQuery,
    groupQuery,
    taxonomyQuery,
    listQuery,
    loadingStatuses: {
      fetchingGroups: listQuery.loading,
      fetchingMoreGroups: groupsQuery.loading,
      loadingAllSentences: groupQuery.loading,
      editResultLoading: editResult.loading,
      loadingSimilarSentences: sentencesResult.loading,
      loadingCurrentGroup: reloadingCurrentGroup,
      discardingGroup: editResult.loading,
      fetchingParents: taxonomyQuery.loading,
      fetchingOrphans: orphansQuery.loading,
      fetchingChildren: childrenQuery.loading,
      fetchingChildCandidates: childCandidatesQuery.loading,
      assigningChild: assignChildResult.loading,
      assignChildren: assignChildrenResult.loading,
      gettingChildrenToAssign: childrenToAssignResult.loading,
    },

    /** Raw GQL Mutations */
    setCurrentGroup,
    setGroups,
    setTaxonomy,
    getGroupsQuery,
    getOrphans,
    getPreviewSearches,
    editGroup,
    togglePinGroupMutation,
    getTaxonomyQuery,
    getChildrenToAssign,
    /** Group Hook specific Functionality */
    loadListView,
    loadTaxonomyView,
    replaceOrAddToSearchGroups: (group: GroupDataFragment) =>
      replaceOrAddToSearchGroups(
        group,
        groups,
        setGroups,
        (groupData) => addGroup(groupData, setGroups, taxonomy, setTaxonomy, getTaxonomy),
        taxonomy,
        setTaxonomy,
        //@ts-ignore
        getTaxonomy
      ),
    addGroup: (group: GroupDataFragment) => addGroup(group, setGroups, taxonomy, setTaxonomy, getTaxonomy),
    addSentence: (groupId: number, sentence: SentenceEntry, cb: () => void, groupsOverride?: GroupBase[], setGroupsOverride?: (groups: GroupBase[]) => void) =>
      addSentence(
        groupId,
        sentence,
        cb,
        teamId,
        teamName,
        orgId,
        orgName,
        pageName,
        getActiveFilter(groupId, currentGroup?.id, currentGroupFilter, filterInput),
        groupsOverride ?? groups,
        editGroup,
        setGroupsOverride ?? setGroups,
        similarSentences,
        setSimilarSentences,
        currentGroup,
        setCurrentGroup
      ),
    deleteSentence: (sentenceId: number, groupId: number, cb: () => void, groupsOverride?: GroupBase[], setGroupsOverride?: (groups: GroupBase[]) => void) =>
      deleteSentence(
        sentenceId,
        groupId,
        cb,
        teamId,
        teamName,
        orgId,
        orgName,
        pageName,
        getActiveFilter(groupId, currentGroup?.id, currentGroupFilter, filterInput),
        groupsOverride ?? groups,
        editGroup,
        setGroupsOverride ?? setGroups,
        setCurrentGroup,
        currentGroup
      ),
    findSimilarSentences: (query: string, page: number, pageSize: number) =>
      findSimilarSentences(
        query,
        currentGroup,
        teamId,
        getActiveFilter(currentGroup?.id, currentGroup?.id, currentGroupFilter, filterInput),
        page,
        pageSize,
        sentencesQuery,
        setSimilarSentences
      ),
    refetchSimilarSentences: (query: string, page: number, pageSize: number, endOfDataSetDb?: () => void, cb?: () => void, queryChanged?: boolean) =>
      refetchSimilarSentences(
        query,
        currentGroup,
        teamId,
        getActiveFilter(currentGroup?.id, currentGroup?.id, currentGroupFilter, filterInput),
        page,
        pageSize,
        sentencesQuery,
        similarSentences ?? [],
        setSimilarSentences,
        endOfDataSetDb,
        cb,
        queryChanged
      ),
    loadMoreSentences: (groupId: number, page: number, pageSize: number, endOfDataSetDb: () => void, cb: () => void) =>
      refetchGroupWithPaginatedSentences(
        groupId,
        teamId,
        getActiveFilter(groupId, currentGroup?.id, currentGroupFilter, filterInput),
        page,
        pageSize,
        currentGroup,
        setCurrentGroup,
        getGroupSentencesQuery,
        app?.currentUuid,
        endOfDataSetDb,
        cb
      ),
    clearSimilarSentences: () => setSimilarSentences([]),
    clearCurrentGroupEntries: () => {
      if (currentGroup) setCurrentGroup({ ...currentGroup, sentences: [], entries: [] });
    },
    loadAllSentences: (groupId: number) =>
      refetchGroupWithAllSentences(
        groupId,
        teamId,
        getActiveFilter(groupId, currentGroup?.id, currentGroupFilter, filterInput),
        groups,
        setGroups,
        getGroupFull,
        app?.currentUuid
      ),
    updateProgress: (groupId: number, newProgress: number) => updateProgress(groupId, newProgress, groups, setGroups, taxonomy, setTaxonomy),
    groupExport: (groupId: number) =>
      groupExport(groupId, teamId, orgId, teamName, orgName, getActiveFilter(groupId, currentGroup?.id, currentGroupFilter, filterInput), getGroupForExport),
    editTitle: (groupId: number, title: string) =>
      editTitle(
        groupId,
        teamId,
        title,
        groups,
        groupQuery,
        getActiveFilter(groupId, currentGroup?.id, currentGroupFilter, filterInput),
        editGroup,
        setGroups,
        taxonomy,
        (taxonomy) => setTaxonomy(taxonomy)
      ),
    updateOwner: (groupId: number, userId: number, cb?: () => void) =>
      updateOwner(
        groupId,
        teamId,
        orgId,
        userId,
        groups,
        groupQuery,
        getActiveFilter(groupId, currentGroup?.id, currentGroupFilter, filterInput),
        updateGroupOwner,
        setGroups,
        currentGroup as GroupFull,
        setCurrentGroup,
        cb
      ),
    removeOwner: (groupId: number, cb?: () => void) =>
      removeOwner(
        groupId,
        teamId,
        groups,
        groupQuery,
        getActiveFilter(groupId, currentGroup?.id, currentGroupFilter, filterInput),
        removeGroupOwner,
        setGroups,
        currentGroup as GroupFull,
        setCurrentGroup,
        cb
      ),
    copyGroupLink: (groupId: number, filterInput?: FilterInput) =>
      copyGroupLink(groupId, getActiveFilter(groupId, currentGroup?.id, currentGroupFilter, filterInput) ?? {}, teamId, orgId, 'group'),
    handleCreateTag: (groupId: number, name: string, cb?: () => void) =>
      handleCreateTag(
        groupId,
        teamId,
        orgId,
        name,
        getActiveFilter(groupId, currentGroup?.id, currentGroupFilter, filterInput),
        createTag,
        groupQuery,
        currentGroup,
        setCurrentGroup,
        cb
      ),
    handleRemoveTag: (groupId: number, tagId: number, cb?: () => void) =>
      handleRemoveTag(groupId, tagId, teamId, removeTag, groupQuery, currentGroup, setCurrentGroup, cb),
    handleTagGroup: (groupId: number, tagId: number, cb?: () => void) =>
      handleTagGroup(
        groupId,
        tagId,
        teamId,
        getActiveFilter(groupId, currentGroup?.id, currentGroupFilter, filterInput),
        tagGroup,
        groupQuery,
        currentGroup,
        setCurrentGroup,
        cb
      ),
    discardGroup: (groupId: number, cb?: () => void): Promise<GroupDependencies | void> =>
      discardGroup(groupId, teamId, groups, taxonomy, setTaxonomy, setGroups, editGroup, setCurrentGroup, cb),
    togglePinGroup: (groupId: number, cb?: () => void) =>
      togglePinGroup(groupId, teamId, groups, taxonomy, setTaxonomy, currentGroup, setCurrentGroup, setGroups, togglePinGroupMutation, cb),
    updateCurrentGroupFilter: (currentGroupFilter: FilterInput) => setCurrentGroupFilter(currentGroupFilter),
    getChildCandidates: async (teamId: number, filterInput: FilterInput, groupId: number, query: string, cb: () => void) =>
      await getChildCandidates(teamId, filterInput, groupId, query, children, getChildCandidatesQuery, (candidates) => setChildCandidates(candidates), cb),
    getChildren: async (teamId: number, filterInput: FilterInput, groupId: number, cb: () => void) =>
      await getChildren(teamId, filterInput, groupId, getChildrenQuery, (children) => setChildren(children), cb),
    openParent: (teamId: number, filterInput: FilterInput, groupId: number, cb?: () => void) =>
      openParent(teamId, filterInput, groupId, taxonomy, (taxonomy) => setTaxonomy(taxonomy), getChildrenQuery, cb),
    assignChild: (teamId: number, filterInput: FilterInput, parentGroupId: number, childGroupId: number, type?: 'Parent' | 'Child', cb?: () => void) =>
      assignChild(
        teamId,
        filterInput,
        parentGroupId,
        childGroupId,
        taxonomy,
        childCandidates,
        (candidates) => setChildCandidates(candidates),
        children,
        (children) => setChildren(children),
        (taxonomy) => setTaxonomy(taxonomy),
        assignChildMutation,
        type,
        cb
      ),
    deleteChild: (teamId: number, filterInput: FilterInput, parentGroupId: number, childGroupId: number, cb?: () => void) =>
      deleteChild(
        teamId,
        filterInput,
        parentGroupId,
        childGroupId,
        taxonomy,
        children,
        (children) => setChildren(children),
        (taxonomy) => setTaxonomy(taxonomy),
        deleteChildMutation,
        cb
      ),
    assignChildren: (teamId: number, filterInput: FilterInput, parentGroupId: number, childGroupIds: number[], cb?: () => void) =>
      assignChildren(
        teamId,
        filterInput,
        parentGroupId,
        taxonomy,
        (taxonomy) => setTaxonomy(taxonomy),
        assignChildrenMutation,
        childGroupIds,
        children,
        (children) => setChildren(children),
        childrenToAssign.filter((child) => childGroupIds.includes(child.id)),
        (children) => setChildrenToAssign(children),
        cb
      ),
    removeChildFromParent: (teamId: number, filterInput: FilterInput, parentGroupId: number, childGroupId: number, cb?: () => void) =>
      removeChildFromParent(teamId, filterInput, parentGroupId, childGroupId, taxonomy, (taxonomy) => setTaxonomy(taxonomy), removeChildMutation, cb),
    getCurrentGroup: (teamId: number, filterInput: FilterInput, groupId: number, cb?: () => void, redirect?: () => void) =>
      getCurrentGroup(teamId, filterInput, groupId, getGroupFull, (group) => setCurrentGroup(group), 10, cb, redirect),
    getPotentialChildren: (teamId: number, filterInput: FilterInput, groupId: number, cb?: () => void) =>
      getChildrenToAssign(teamId, filterInput, groupId, taxonomy, (children) => setChildrenToAssign(children), getChildrenToAssignQuery, cb),
    expandAll: () => expandAll(taxonomy, setTaxonomy),
    contractAll: () => contractAll(taxonomy, setTaxonomy),
  };
};

//Even though all actions that require Filters are applied to the currentGroup, this function exists for future changes/reference.
//This basically returns which filter the functions exported by the hook should use (if the page-level one or the group modal one)
const getActiveFilter = (
  groupId: number | undefined,
  currentGroupId: number | undefined,
  currentGroupFilter: FilterInput | undefined,
  filterInput: FilterInput | undefined
) => {
  if (groupId === currentGroupId) return currentGroupFilter;
  return filterInput;
};

/**
 * Updates progress on a search group type
 * @param searchGroupId
 * @param newProgress
 */
const updateProgress = (
  searchGroupId: number,
  newProgress: number,
  groups: GroupBase[],
  setGroups: (groups: GroupBase[]) => void,
  taxonomy: Map<number, TaxonomyGroup>,
  setTaxonomy: (taxonomy: Map<number, TaxonomyGroup>) => void
) => {
  const updatedGroups = _.cloneDeep(groups);
  const updatedGroup: GroupFull = updatedGroups.find((sg) => sg.id === searchGroupId) as GroupFull;
  if (updatedGroup) {
    updatedGroup.progress = newProgress;
  }
  setGroups(updatedGroups);
  if (taxonomy.get(searchGroupId)) {
    const updatedTaxonomy = new Map(taxonomy);
    updatedTaxonomy.set(searchGroupId, { ...updatedTaxonomy.get(searchGroupId)!, progress: newProgress });
    setTaxonomy(updatedTaxonomy);
  }
};

const refetchGroupWithAllSentences = async (
  groupId: number,
  teamId: number,
  filterInput: FilterInput | undefined,
  groups: GroupBase[],
  setGroups: (groups: GroupBase[]) => void,
  getGroupFull: LazyQueryExecFunction<GetGroupQuery, GetGroupQueryVariables>,
  teamUuid?: string,
  cb?: (data: GroupBase) => void
) => {
  await getGroupFull({
    variables: {
      teamId,
      groupId: groupId,
      teamUuid: teamUuid,
      filterInput: filterInput ?? {},
      sentencesTake: 2000,
    },
    onCompleted(data) {
      cb ? cb(getGroups([data.getGroup])[0]) : replaceSentenceMappings(groupId, data.getGroup.groupEntries, groups, setGroups);
    },
  });
};

const refetchGroupWithPaginatedSentences = async (
  groupId: number,
  teamId: number,
  filterInput: FilterInput | undefined,
  page: number,
  pageSize: number,
  currentGroup: GroupBase | undefined,
  setCurrentGroup: (group: GroupBase) => void,
  getGroupSentencesQuery: LazyQueryExecFunction<GetGroupSentencesQuery, GetGroupSentencesQueryVariables>,
  teamUuid?: string,
  endOfDataSetCb?: () => void,
  cb?: () => void
) => {
  await getGroupSentencesQuery({
    variables: {
      teamId,
      groupId: groupId,
      teamUuid: teamUuid,
      filterInput: filterInput,
      sentencesTake: pageSize,
      sentencesSkip: page,
      includeDescendantsOnMappings: true,
    },
    onCompleted(data) {
      if (!currentGroup) {
        cb?.();
        return;
      }
      const updatedGroup = getGroupSentences(data);
      if (updatedGroup.sentences.length === 0 || updatedGroup.sentences.length === currentGroup.totalEntries) {
        endOfDataSetCb?.();
      }
      const newGroup = {
        ...currentGroup,
        sentences: [
          ...currentGroup.sentences,
          ...updatedGroup.sentences.filter((updatedSentence) => !currentGroup.sentences.find((currentSentence) => currentSentence.id === updatedSentence.id)),
        ],
        entries: [
          ...currentGroup.entries,
          ...updatedGroup.entries.filter((updatedEntry) => !currentGroup.entries.find((currentEntry) => currentEntry.id === updatedEntry.id)),
        ],
      };
      setCurrentGroup(newGroup);
      cb?.();
    },
  });
};

export const replaceSentenceMappings = (
  searchGroupId: number,
  newSentencesMappings: GroupDataFragment['groupEntries'],
  groups: GroupBase[],
  setGroups: (groups: GroupBase[]) => void
) => {
  if (!newSentencesMappings) {
    return;
  }
  const updatedGroups = _.cloneDeep(groups);
  const updatedGroup = updatedGroups.find((sg) => sg.id === searchGroupId);
  if (!updatedGroup) return;
  // we need to sort the sentences by date because the query doesn't return them in order
  updatedGroup.sentences = newSentencesMappings
    .flatMap((sm) => sm.mappedSentences.map((s) => getSentence(s)))
    .filter(Boolean)
    .sort((a, b) => (b?.entry?.date ?? 0) - (a?.entry?.date ?? 0)) as Sentence[];
  updatedGroup.entries = newSentencesMappings
    .map((sm) => sm.mappedSentences[0].entry)
    .sort((a, b) => (b?.date ?? 0) - (a?.date ?? 0))
    .filter((entry) => entry) as Entry[];
  setGroups(updatedGroups);
};

/**
 * Deletes a single sentence
 * cb() is meant to be some sort of loading state,
 * because there is a noticable delay from when the query finishes loading and the state updates.
 * @param sentenceId
 * @param groupId
 * @param cb
 */
const deleteSentence = async (
  sentenceId: number,
  groupId: number,
  cb: () => void,
  teamId: number,
  teamName: string,
  orgId: number,
  orgName: string,
  pageName: string,
  filterInput: FilterInput | undefined,
  groups: GroupBase[],
  editGroup: EditGroupMutationFn,
  setGroups: (groups: GroupBase[]) => void,
  setCurrentGroup: (group: GroupBase) => void,
  currentGroup: GroupBase | undefined
) => {
  logEvent(Events.SentenceRemoved, { View_ID: teamId, View_Name: teamName, Org_ID: orgId, Org_Name: orgName, Page: pageName });
  // we call edit group with the filter input to get the updated group with the correct count of unique entries as well as the correct amount of entries over time.
  // Because of pagination, there's a chance the edited group will have a different number of sentences than what's currently shown on the UI.
  // This code takes the sentences that are currently loaded in `currentGroup` and removes the sentence that was deleted from the group, and puts those sentences in the new group.
  await editGroup({
    variables: {
      groupId,
      filterInput: filterInput ?? {},
      teamId: teamId,
      input: {
        sentenceIdToRemove: sentenceId,
      },
    },

    onCompleted: async (data) => {
      if (!data.editGroup.success || !data.editGroup.group) return toast.error('Error removing sentence.');
      const updatedGroups = _.cloneDeep(groups);
      const index = groups.findIndex((group) => group.id === groupId);
      //@ts-ignore
      const newGroup = getGroups([data.editGroup.group])[0];
      // I don't love the that we're having to keep track of two data structures here.
      // Would it make sense to put the highlighted sentence in the entry object and then just reference the entries in the group object?
      newGroup.sentences = currentGroup!.sentences.filter((sentence) => sentence.id !== sentenceId);
      newGroup.entries = currentGroup!.entries.filter((entry) => entry.sentences.findIndex((sentence) => sentence.id === sentenceId) === -1);
      if (index !== -1) {
        updatedGroups[index] = newGroup!;
        //setGroups(updatedGroups);
        setCurrentGroup(newGroup);
      } else {
        if (currentGroup) {
          setCurrentGroup({
            ...newGroup,
            sentences: currentGroup!.sentences.filter((sentence) => sentence.id !== sentenceId),
            entries: currentGroup!.entries.filter((entry) => entry.sentences.findIndex((sentence) => sentence.id === sentenceId) === -1),
          });
        }
      }
      toast.success('Sentence successfully removed');

      cb();
    },
    onError: (error) => {
      toast.error('Error removing sentence');
    },
  });
};

/**
 * Adds a single sentence
 * cb() is meant to be some sort of loading state
 * because there is a noticable delay from when the query finishes loading and the state updates.
 * @param sentence
 * @param groupId
 * @param cb
 */
const addSentence = async (
  groupId: number,
  sentence: SentenceEntry,
  cb: () => void,
  teamId: number,
  teamName: string,
  orgId: number,
  orgName: string,
  pageName: string,
  filterInput: FilterInput | undefined,
  groups: GroupBase[],
  editGroup: EditGroupMutationFn,
  setGroups: (groups: GroupBase[]) => void,
  currentSentences: SentenceEntryFragment[] | undefined,
  setSimilarSentences: (sentences: SentenceEntryFragment[]) => void,
  currentGroup: GroupBase | undefined,
  setCurrentGroup: (group: GroupBase) => void
) => {
  logEvent(Events.SentenceAdded, { View_ID: teamId, View_Name: teamName, Org_ID: orgId, Org_Name: orgName, Page: pageName });
  await editGroup({
    variables: { groupId, teamId, filterInput: filterInput ?? {}, input: { sentenceIdToAdd: sentence.id } },
    onCompleted: async (data) => {
      if (!data.editGroup.success || !data.editGroup.group) return toast.error('Error adding sentence.');
      const updatedGroups = _.cloneDeep(groups);
      const index = groups.findIndex((group) => group.id === groupId);
      if (index !== -1) {
        updatedGroups[index] = getGroups([data.editGroup.group])[0];
        const fragmentToSentence = getSentence(sentence) as Sentence;
        const fragmentToEntry = getEntry(sentence.entry) as Entry;

        // Because the added sentence may not come back in the groups query, we need to add it to the current group so when the user goes to the entries tab, they see the added sentence.
        // add sentences, remove dupes
        updatedGroups[index].sentences = [fragmentToSentence, ...updatedGroups[index].sentences].filter(
          (sentence, index, self) => self.findIndex((s) => s.id === sentence.id) === index
        );
        // add entries, remove dupes
        updatedGroups[index].entries = [fragmentToEntry, ...updatedGroups[index].entries].filter(
          (entry, index, self) => self.findIndex((e) => e.id === entry.id) === index
        );
        // setGroups(updatedGroups);
        setCurrentGroup(updatedGroups[index]);
        setSimilarSentences(currentSentences?.filter((s) => s.id !== sentence.id) ?? []);
        writeToastMessage('Sentence successfully added to group');
      } else {
        if (currentGroup) {
          writeToastMessage('Sentence successfully added to group');
          setSimilarSentences(currentSentences?.filter((s) => s.id !== sentence.id) ?? []);
          const fragmentToSentence = getSentence(sentence) as Sentence;
          const fragmentToEntry = getEntry(sentence.entry) as Entry;
          setCurrentGroup({
            ...getGroups([data.editGroup.group])[0],
            sentences: [fragmentToSentence, ...currentGroup.sentences].filter((sentence, index, self) => self.findIndex((s) => s.id === sentence.id) === index),
            entries: [fragmentToEntry, ...currentGroup.entries].filter((entry, index, self) => self.findIndex((e) => e.id === entry.id) === index),
          });
        }
      }
      cb();
    },
  });
};

/**
 * Adds a new cluster/group to the beginning of the group list.
 * @param group
 */
export const addGroup = (
  group: GroupDataFragment,
  setGroups: React.Dispatch<React.SetStateAction<GroupBase[]>>,
  taxonomy: Map<number, TaxonomyGroup>,
  setTaxonomy: (taxonomy: Map<number, TaxonomyGroup>) => void,
  getTaxonomy: (groups: GroupDataFragment[] | null | undefined) => Map<number, TaxonomyGroup>
) => {
  setGroups((prev) => [...getGroups([group]), ...prev]);
  const updatedTaxonomy = new Map(taxonomy);
  updatedTaxonomy.set(group.id, getTaxonomy([group]).get(group.id)!);
  setTaxonomy(updatedTaxonomy);
};

/**
 * //Check the searchGroups array. If the searchGroup is in there (check by id), replace it with this one.
 * //Otherwise, add this at the beginning.
 */
export const replaceOrAddToSearchGroups = (
  searchGroup: GroupDataFragment,
  groups: GroupBase[] | undefined,
  setGroups: (groups: GroupBase[]) => void,
  addGroup: (group: GroupDataFragment) => void,
  taxonomy: Map<number, TaxonomyGroup>,
  setTaxonomy: (taxonomy: Map<number, TaxonomyGroup>) => void,
  getTaxonomy: (groups: GroupTaxonomyFragment[] | null | undefined) => Map<number, TaxonomyGroup>
) => {
  const index = groups?.findIndex((sg) => sg.id === searchGroup.id);
  const searchToGroup = getGroups([searchGroup])[0];
  const updatedTaxonomy = new Map(taxonomy);
  /** Is this correct that we force this case? canAddChildren true and totalDescendents 0? */
  updatedTaxonomy.set(searchGroup.id, getTaxonomy([{ ...searchGroup, canAddChildren: true, totalDescendents: 0 }]).get(searchToGroup.id)!);
  setTaxonomy(updatedTaxonomy);
  if (index !== undefined && index !== -1) {
    const updatedGroups = _.cloneDeep(groups);
    if (searchToGroup && updatedGroups) {
      updatedGroups[index] = searchToGroup;
      setGroups(updatedGroups);
    }
  } else {
    addGroup(searchGroup);
  }
};

/**
 * If we have a current group set (so the drawer is open)
 * fetch groups, then update the mappings in that group
 * @param query
 */
export const findSimilarSentences = (
  query: string,
  currentGroup: GroupBase | undefined,
  teamId: number,
  filterInput: FilterInput | undefined,
  page: number,
  pageSize: number,
  sentencesQuery: LazyQueryExecFunction<FeedbackSentencesQuery, FeedbackSentencesQueryVariables>,
  setSimilarSentences: (sentences: SentenceEntryFragment[]) => void
) => {
  if (currentGroup) {
    sentencesQuery({
      variables: {
        teamId,
        sortByClusterId: currentGroup.id,
        filterInput: { queryString: query ? [query] : [] },
        take: pageSize,
        skip: page,
      },
      onCompleted(data) {
        const currentSentenceIds = currentGroup.sentences.map((sentence) => sentence.id);
        setSimilarSentences(data.sentences?.filter((sentence) => !currentSentenceIds.includes(sentence.id)) ?? []);
      },
    });
  }
};

const refetchSimilarSentences = async (
  query: string,
  currentGroup: GroupBase | undefined,
  teamId: number,
  filterInput: FilterInput | undefined,
  page: number,
  pageSize: number,
  sentencesQuery: LazyQueryExecFunction<FeedbackSentencesQuery, FeedbackSentencesQueryVariables>,
  similarSentences: SentenceEntryFragment[],
  setSimilarSentences: (sentences: SentenceEntryFragment[]) => void,
  endOfDataSetCb?: () => void,
  cb?: () => void,
  queryChanged?: boolean
) => {
  if (currentGroup) {
    await sentencesQuery({
      variables: {
        teamId,
        sortByClusterId: currentGroup.id,
        take: pageSize,
        skip: page,
        filterInput: { queryString: query ? [query] : [] },
      },
      onCompleted(data) {
        if (data?.sentences?.length === 0) endOfDataSetCb?.();
        const currentSentenceIds = similarSentences.map((sentence) => sentence.id);
        setSimilarSentences([
          ...(queryChanged ? [] : similarSentences),
          ...(data.sentences?.filter((sentence) => !currentSentenceIds.includes(sentence.id)) ?? []),
        ]);
        cb?.();
      },
    });
  }
};

const handleCreateTag = (
  groupId: number,
  teamId: number,
  orgId: number,
  name: string,
  filterInput: FilterInput | undefined,
  createTag: CreateTagMutationFn,
  groupQuery: GetGroupQueryResult,
  currentGroup: GroupBase | undefined,
  setCurrentGroup: (group: GroupBase) => void,
  cb?: () => void
) => {
  createTag({
    variables: { groupId, teamId, name },
    refetchQueries: [
      {
        query: DataForFiltersDocument,
        variables: { teamId: teamId, orgId: orgId },
      },
    ],

    async onCompleted(data) {
      const group = await groupQuery.refetch({ teamId, groupId, filterInput: filterInput });
      if (currentGroup) {
        currentGroup.tags = group.data.getGroup.tags as ITag[];
        setCurrentGroup(currentGroup);
      }
      cb && cb();
    },
  });
};

export const togglePinGroup = (
  groupId: number,
  teamId: number,
  groups: GroupBase[],
  taxonomy: Map<number, TaxonomyGroup>,
  setTaxonomy: (taxonomy: Map<number, TaxonomyGroup>) => void,
  currentGroup: GroupBase | undefined,
  setCurrentGroup: (group: GroupBase | undefined) => void,
  setGroups: (groups: GroupBase[]) => void,
  togglePinGroupMutation: TogglePinGroupMutationFn,
  cb?: () => void
) => {
  togglePinGroupMutation({
    variables: { teamId, groupId },
    onCompleted: async (data) => {
      /** why doesn't this use data.togglePinnedGroup.isPinnedByUser as the source of truth? */
      /** why are these different -
       * there's two sections for setting the current group
       * and both sections do this in a different way...
       *
       * why isn't it just
       * setCurrentGroup({...currentGroup, data.togglePinGroup.isPinnedByUser})
       *
       * we get this data back from the backend and it _should_ reflect the truth of the pinned state, right?
       * Ahhh... cuz the data on the backend does not reflect the pinned state.
       */
      let pinResult = undefined;
      if (taxonomy.get(groupId)) {
        const updatedTaxonomy = new Map(taxonomy);
        const current = taxonomy.get(groupId)!;
        pinResult = !current.pinnedByUser;
        updatedTaxonomy.set(groupId, { ...current, pinnedByUser: !current.pinnedByUser });
        setTaxonomy(updatedTaxonomy);
        if (currentGroup) {
          setCurrentGroup({ ...currentGroup, pinnedByUser: pinResult });
        }
      }
      const index = groups.findIndex((g) => g.id === data.togglePinGroup.id);
      if (index !== -1) {
        const group = groups[index];
        group.pinnedByUser = !group.pinnedByUser;
        pinResult = group.pinnedByUser;
        groups[index] = group;
        setGroups(groups);
        if (currentGroup) {
          setCurrentGroup({ ...currentGroup, pinnedByUser: pinResult ?? data.togglePinGroup.isPinnedByUser });
        }
      }

      if (pinResult != null) {
        writeToastMessage(`Group ${pinResult ? 'Pinned' : 'Unpinned'}`);
      }
      cb && cb();
    },
  });
};

const handleTagGroup = (
  groupId: number,
  tagId: number,
  teamId: number,
  filterInput: FilterInput | undefined,
  tagGroup: TagGroupMutationFn,
  groupQuery: GetGroupQueryResult,
  currentGroup: GroupBase | undefined,
  setCurrentGroup: (group: GroupBase) => void,
  cb?: () => void
) => {
  tagGroup({
    variables: { groupId, teamId, tagId },
    async onCompleted(data) {
      const group = await groupQuery.refetch({ teamId, groupId, filterInput: filterInput });
      if (currentGroup) {
        currentGroup.tags = group.data.getGroup.tags as ITag[];
        setCurrentGroup(currentGroup);
      }
      cb && cb();
    },
  });
};

export const handleRemoveTag = (
  groupId: number,
  tagId: number,
  teamId: number,
  removeTag: RemoveTagFromGroupMutationFn,
  groupQuery: GetGroupQueryResult,
  currentGroup: GroupBase | undefined,
  setCurrentGroup: (group: GroupBase) => void,
  cb?: () => void
) => {
  removeTag({
    variables: { groupId, teamId, tagId },
    async onCompleted(data) {
      if (currentGroup) {
        const index = currentGroup.tags?.findIndex((tag) => tag.id === tagId) ?? -1;
        if (index !== -1) {
          currentGroup.tags?.splice(index, 1);
          setCurrentGroup(currentGroup);
        }
      }
      cb && cb();
    },
  });
};

export const discardGroup = async (
  groupId: number,
  teamId: number,
  groups: GroupBase[],
  taxonomy: Map<number, TaxonomyGroup>,
  setTaxonomy: (taxonomy: Map<number, TaxonomyGroup>) => void,
  setGroups: (groups: GroupBase[]) => void,
  editGroup: EditGroupMutationFn,
  setCurrentGroup: (group?: GroupBase) => void,
  cb?: () => void
): Promise<GroupDependencies | void> => {
  const { data } = await editGroup({
    variables: { groupId, teamId: teamId, input: { status: Group_Status.Archived }, filterInput: {} },
  });
  if (!data?.editGroup.success) {
    if (data?.editGroup.dependencies) return data?.editGroup.dependencies as GroupDependencies;
    toast.error('Error deleting group');
    return;
  }

  const newGroups = groups?.filter((group) => group.id !== groupId);
  const parentId = taxonomy.get(groupId)?.parentId;
  const updatedTaxonomy = new Map(taxonomy);
  if (parentId && updatedTaxonomy.get(parentId) != null) {
    const parent = updatedTaxonomy.get(parentId) as TaxonomyGroup;
    const children = parent.children?.filter((c) => c.id !== groupId) ?? [];
    updatedTaxonomy.set(parentId, { ...parent, children: children, totalDescendents: parent.totalDescendents - 1 });
  }
  updatedTaxonomy.delete(groupId);
  setTaxonomy(updatedTaxonomy);
  setGroups(newGroups);
  writeToastMessage('Group deleted successfully');
  cb && cb();
};
/**
 * Below are functions that don't need state variable access in groupHook
 */

export const sortGroups = (a: GroupBase, b: GroupBase) => {
  if (a.processing !== b.processing) {
    return b.date - a.date;
  } else {
    return b.totalEntries - a.totalEntries;
  }
};

/**
 * converts SentenceFragment into a Sentence type
 * @param sentence
 * @returns
 */

export const getSentence = (sentence: SentenceEntryFragment | GroupSentenceDataFragment | undefined | null): SentenceEntry | undefined => {
  if (sentence == null) return undefined;

  return {
    id: sentence.id,
    text: sentence?.text,
    is_distillate: sentence.__typename === 'Sentence' ? false : (sentence as SentenceEntryFragment).is_distillate,
    entry: (sentence as SentenceEntryFragment).entry,
  };
};
/**
 * converts EntryFragment into an Entry type
 * @param entry
 * @returns
 */
const getEntry = (entry: EntryFragment | null | undefined): Entry | undefined => {
  if (entry == null) return undefined;
  return {
    date: entry.date,
    id: entry.id,
    source_permalink: entry.source_permalink,
    source: entry.source,
    text: entry.text,
    submitter: entry.submitter,
    hasConversation: entry.hasConversation,
    conversationParts: entry.conversationParts,
    distillateText: entry.distillateText ?? null,
    title: entry.title,
    segments: entry.segments.map((s) => {
      return { groupName: s.groupName, value: s.value };
    }),
    sentences: entry.sentences
      .filter((s) => !s.is_distillate)
      .map((s) => {
        return { id: s.id, is_distillate: s.is_distillate, text: s?.text };
      }),
    sentiment: entry.sentiment,
    stars: entry.stars,
  };
};

/**
 * Converts search group data into a time series chart
 * @param searchId
 * @param searchTitle
 * @param seriesData
 * @returns
 */
const getTimeSeriesForGroup = (searchId: number, searchTitle: string, seriesData: IChartableItem[] | undefined): IChartSeries => {
  return {
    seriesId: searchId,
    seriesTitle: searchTitle ?? '',
    totalValue: seriesData?.length ?? 0,
    seriesData: seriesData ?? [],
  };
};

export const getGroupSentences = (groups: GetGroupSentencesQuery) => {
  const group = groups?.getGroup;
  if (group == null) {
    return { sentences: [], entries: [] };
  }
  return {
    sentences: group?.groupEntries?.flatMap((sm) => sm.mappedSentences.map((sentence) => getSentence(sentence))).filter((item) => item) as Sentence[],
    entries: group?.groupEntries
      ?.flatMap((sm) => sm.mappedSentences.map((sentence) => getEntry(sentence.entry)))
      .filter((item, index, self) => index === self.findIndex((obj) => obj && item && obj.id === item.id)) as Entry[],
  };
};

export const getTaxonomy = (
  groups: GroupDataFragment[] | GroupTaxonomyFragment[] | GroupBase[] | TaxonomyElement[] | null | undefined,
  currentTaxonomy?: Map<number, TaxonomyGroup>,
  parentId?: number
): Map<number, TaxonomyGroup> => {
  if (groups == null) return new Map();
  // Initialize the map
  const taxonomy: Map<number, TaxonomyGroup> = new Map();

  if (groups.length > 0 && 'childrenIds' in groups[0]) {
    // initial taxonomy load

    const savedIds = getIdsFromLocalStorage();
    // change this to typename
    const taxonomyElements = groups as TaxonomyElement[];

    taxonomyElements.forEach((group: TaxonomyElement) => {
      group.childrenIds?.forEach((child) => {
        if (taxonomyElements.find((c: TaxonomyElement) => c.groupId === child) != undefined) {
          const childNode = taxonomyElements.find((c: TaxonomyElement) => c.groupId === child)?.node;
          if (!childNode) {
            logError('childNode is undefined');
            // go to next iteration
            return;
          }
          taxonomy.set(
            child,
            new TaxonomyGroup({
              ...taxonomyElements.find((c: TaxonomyElement) => c.groupId === child)?.node,
              parentId: group.groupId,
              showChildren: savedIds.includes(child) ? true : false,
              children: [],
              trending: childNode.trending,
              canAddChildren: childNode.canAddChildren,
              id: childNode.id,
              title: childNode.title,
              totalEntries: childNode.uniqueEntries,
              denominator: childNode.statistics.denominator.denominatorUnfiltered,
              approved: childNode.status === Group_Status.Monitored,
              processing: childNode.processing,
              progress: childNode.progress,
              pinnedByUser: childNode.isPinnedByUser,
              centroid: childNode.centroidText,
              status: childNode.status,
              isExactMatch: childNode.isExactMatch,
              type: childNode.type,
              sentences: [],
              entries: [],
              tags: childNode.tags,
              relativeShare:
                childNode.statistics.denominator.denominatorFiltered != 0
                  ? (childNode.uniqueEntries * 100) / group.node.statistics.denominator.denominatorFiltered
                  : 0,
              relativeShareFull:
                childNode.statistics.denominator.denominatorUnfiltered != 0
                  ? (childNode.uniqueEntries * 100) / group.node.statistics.denominator.denominatorUnfiltered
                  : 0,
              date: childNode.dateCreated ?? 0,
              totalDescendents: childNode.totalDescendents,
            })
          );
        }
      });
      taxonomy.set(
        group.groupId!,
        // THIS SHOULD NOT BE TS_IGNORED! The whole point of TS is so that the typing system tells you that the data shapes are different!!!
        // group.node does not fit TaxonomyGroupProps!
        new TaxonomyGroup({
          ...taxonomy.get(group.groupId),
          parentId: parentId ? parentId : taxonomy.get(group.groupId)?.parentId ?? null,
          showChildren: currentTaxonomy ? currentTaxonomy.get(group.groupId)?.showChildren ?? false : savedIds.includes(group.groupId) ? true : false,
          children: group.childrenIds.map((id) => taxonomy.get(id)!).filter((c) => c) as TaxonomyGroup[],
          trending: group.node.trending,
          canAddChildren: group.node.canAddChildren,
          id: group.node.id,
          title: group.node.title,
          totalEntries: group.node.uniqueEntries,
          denominator: group.node.statistics.denominator.denominatorUnfiltered,
          approved: group.node.status === Group_Status.Monitored,
          processing: group.node.processing,
          progress: group.node.progress,
          pinnedByUser: group.node.isPinnedByUser,
          centroid: group.node.centroidText,
          status: group.node.status,
          isExactMatch: group.node.isExactMatch,
          type: group.node.type,
          sentences: [],
          entries: [],
          tags: group.node.tags,
          relativeShare:
            group.node.statistics.denominator.denominatorFiltered != 0
              ? (group.node.uniqueEntries * 100) / group.node.statistics.denominator.denominatorFiltered
              : 0,
          relativeShareFull:
            group.node.statistics.denominator.denominatorUnfiltered != 0
              ? (group.node.uniqueEntries * 100) / group.node.statistics.denominator.denominatorUnfiltered
              : 0,
          date: group.node.dateCreated ?? 0,
          totalDescendents: group.node.totalDescendents,
        })
      );
    });
    return taxonomy;
  }

  // this assumes taxonomy returned is from orphaned query

  // Check if groups is an array of TaxonomyElement
  if (isGroupTaxonomyFragmentArray(groups)) {
    const taxonomyGroups = groups as GroupTaxonomyFragment[];
    // put your handling logic for TaxonomyElement array here...
    taxonomyGroups.forEach((group) => {
      taxonomy.set(
        group.id,
        new TaxonomyGroup({
          ...group,
          parentId: null,
          showChildren: false,
          children: [],
          trending: group.trending,
          canAddChildren: group.canAddChildren,
          id: group.id,
          title: group.title,
          totalEntries: group.uniqueEntries,
          denominator: group.statistics.denominator.denominatorUnfiltered,
          approved: group.status === Group_Status.Monitored,
          processing: group.processing,
          progress: group.progress,
          pinnedByUser: group.isPinnedByUser,
          centroid: group.centroidText,
          status: group.status,
          isExactMatch: group.isExactMatch,
          type: group.type,
          sentences: [],
          entries: [],
          tags: group.tags,
          relativeShare:
            group.statistics.denominator.denominatorFiltered != 0 ? (group.uniqueEntries * 100) / group.statistics.denominator.denominatorFiltered : 0,
          relativeShareFull:
            group.statistics.denominator.denominatorUnfiltered != 0 ? (group.uniqueEntries * 100) / group.statistics.denominator.denominatorUnfiltered : 0,
          date: group.dateCreated ?? 0,
          totalDescendents: group.totalDescendents,
        })
      );
    });
  }

  // Check if groups is an array of TaxonomyGroup
  else if (isTaxonomyGroupArray(groups)) {
    const taxonomyGroups = groups as TaxonomyGroup[];
    // put your handling logic for TaxonomyGroup array here...
    taxonomyGroups.forEach((group: TaxonomyGroup) => {
      taxonomy.set(group.id, group);
    });
  }

  return taxonomy;
};

/**
 * Converts groups into an IGroup
 * @param groups
 * @returns
 */
export const getGroups = (groups: (GroupDataFragment & { insight?: GroupInsightFragment | undefined | null })[] | null | undefined): GroupBase[] => {
  if (groups == null) {
    return [];
  }
  return groups.map((group) => {
    return new GroupFull({
      type: group.type,
      id: group.id,
      ownerId: group.owner?.id,
      isExactMatch: group.isExactMatch,
      //@ts-ignore
      isNew: group.isNew,
      centroid: group.centroidText,
      pinnedByUser: group.isPinnedByUser,
      title: group.title,
      sentences: group?.groupEntries?.flatMap((entry) => entry.mappedSentences.map((s) => getSentence(s))).filter((item) => item) as SentenceEntry[],
      entries: group?.groupEntries
        ?.flatMap((entry) => entry.mappedSentences.map((s) => getEntry(s.entry)))
        .filter((item, index, self) => index === self.findIndex((obj) => obj && item && obj.id === item.id)) as Entry[],
      aggregateData: group.mentionsOverTime?.aggregateData[0],
      normalizedData: group.mentionsOverTime?.normalizedData[0],
      denominator: group.statistics.denominator.denominatorUnfiltered,
      tooltipLabels: group.mentionsOverTime?.tooltipLabels,
      chartLabels: group.mentionsOverTime?.chartLabels,
      filterInput: group.mentionsOverTime?.filterInput
        ? { startDate: new Date(group?.mentionsOverTime?.filterInput.startDate), endDate: new Date(group.mentionsOverTime?.filterInput.endDate) }
        : {},
      relativeShare: group.statistics.denominator.denominatorFiltered != 0 ? (group.uniqueEntries * 100) / group.statistics.denominator.denominatorFiltered : 0,
      relativeShareFull:
        group.statistics.denominator.denominatorUnfiltered != 0 ? (group.uniqueEntries * 100) / group.statistics.denominator.denominatorUnfiltered : 0,
      status: group.status,
      totalEntries: group.uniqueEntries,
      users: null,
      processing: group.processing,
      approved: group.status === Group_Status.Monitored,
      progress: group.progress,
      date: group.dateCreated ?? 0,
      totalDescendents: group.totalDescendents,
      insight: group.insight,
      tags: group.tags
        ? group.tags.map((tag) => {
            return { id: tag.id, name: tag.name };
          })
        : undefined,
    });
  });
};

/**
 * Edits the title of the group at the specified id
 * @param groupId
 * @param teamId
 * @param title
 * @param groups
 * @param groupQuery
 * @param denominator
 * @param editGroup
 * @param setGroups
 */
const editTitle = (
  groupId: number,
  teamId: number,
  title: string,
  groups: GroupBase[],
  groupQuery: GetGroupQueryResult,
  filterInput: FilterInput | undefined,
  editGroup: EditGroupMutationFn,
  setGroups: (groups: GroupBase[]) => void,
  taxonomy: Map<number, TaxonomyGroup>,
  setTaxonomy: (taxonomy: Map<number, TaxonomyGroup>) => void
) => {
  editGroup({
    variables: { teamId, groupId, input: { title }, filterInput: filterInput ?? {} },
    async onCompleted(data) {
      const groupData = (await groupQuery.refetch({ teamId, groupId, filterInput: filterInput })).data.getGroup;
      const updatedGroups = _.cloneDeep(groups);
      const index = updatedGroups.findIndex((group) => group.id === groupId);
      if (index !== -1) {
        updatedGroups[index] = getGroups([groupData])[0];
        setGroups(updatedGroups);
      }
      if (taxonomy.get(groupId) !== undefined) {
        const updatedTaxonomy = new Map(taxonomy);
        updatedTaxonomy.set(groupId, {
          ...taxonomy.get(groupId)!,
          title: title,
        });
        setTaxonomy(updatedTaxonomy);
      }
    },
  });
};
/**
 * Updates owner to the specified user ID.
 * @param groupId
 * @param teamId
 * @param orgId
 * @param userId
 * @param groups
 * @param groupQuery
 * @param denominator
 * @param updateGroupOwner
 * @param setGroups
 */
const updateOwner = (
  groupId: number,
  teamId: number,
  orgId: number,
  userId: number,
  groups: GroupBase[],
  groupQuery: GetGroupQueryResult,
  filterInput: FilterInput | undefined,
  updateGroupOwner: UpdateGroupOwnerMutationFn,
  setGroups: (groups: GroupBase[]) => void,
  currentGroup: GroupFull | undefined,
  setCurrentGroup: (group: GroupFull) => void,
  cb?: () => void
) => {
  updateGroupOwner({
    variables: { groupId, teamId, orgId, userId },
    async onCompleted(data) {
      const groupData = (await groupQuery.refetch({ teamId, groupId, filterInput: filterInput })).data.getGroup;
      const updatedGroups = _.cloneDeep(groups);
      const index = groups.findIndex((group) => group.id === groupId);
      if (index !== -1) {
        updatedGroups[index] = getGroups([groupData])[0];
        setGroups(updatedGroups);
        if (currentGroup) {
          currentGroup.ownerId = groupData.owner?.id;
          setCurrentGroup(currentGroup);
        }
      }
      writeToastMessage('Owner updated successfully');
      cb && cb();
    },
  });
};
/**
 * Removes the current group owner at specified group ID.
 * @param groupId
 * @param teamId
 * @param groups
 * @param groupQuery
 * @param removeGroupOwner
 * @param setGroups
 */
const removeOwner = (
  groupId: number,
  teamId: number,
  groups: GroupBase[],
  groupQuery: GetGroupQueryResult,
  filterInput: FilterInput | undefined,
  removeGroupOwner: RemoveGroupOwnerMutationFn,
  setGroups: (groups: GroupBase[]) => void,
  currentGroup: GroupFull | undefined,
  setCurrentGroup: (group: GroupFull) => void,
  cb?: () => void
) => {
  removeGroupOwner({
    variables: { groupId, teamId },
    async onCompleted(data) {
      const groupData = (await groupQuery.refetch({ teamId, groupId, filterInput: filterInput })).data.getGroup;
      const updatedGroups = _.cloneDeep(groups);
      const index = groups.findIndex((group) => group.id === groupId);
      if (index !== -1) {
        updatedGroups[index] = getGroups([groupData])[0];
        setGroups(updatedGroups);
        if (currentGroup) {
          currentGroup.ownerId = groupData.owner?.id;
          setCurrentGroup(currentGroup);
        }
      }
      writeToastMessage('Owner removed successfully');
      cb && cb();
    },
  });
};

/**
 * Exports the specified group to a CSV
 * @param groupId
 * @param teamId
 * @param orgId
 * @param teamName
 * @param orgName
 * @param getGroupForExport
 */
const groupExport = (
  groupId: number,
  teamId: number,
  orgId: number,
  teamName: string,
  orgName: string,
  filterInput: FilterInput | undefined,
  getGroupForExport: LazyQueryExecFunction<GetGroupForExportQuery, GetGroupForExportQueryVariables>
) => {
  // query for the full group to avoid issues w/ local cache differences
  writeToastMessage('Your CSV is being generated...this can take a few seconds.');
  getGroupForExport({
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    variables: { groupId, teamId, belongs: true, sentencesTake: 10000, sentencesSkip: 0, filterInput: filterInput ?? {} },
    onCompleted(data) {
      let outData: any[] = [];
      function processGroupEntries(
        curGroup:
          | GetGroupForExportQuery['getGroup']
          | GetGroupForExportQuery['getGroup']['children'][0]
          | GetGroupForExportQuery['getGroup']['children'][0]['children'][0]
          | GetGroupForExportQuery['getGroup']['children'][0]['children'][0]['children'][0]
      ) {
        // make sure we have a list of unique entries. Apparently this is a bit redundant as the entries from the
        // backend are unique. We only return the first sentence mapping from the backend. Keeping this here just incase something
        // changes with that logic.
        const uniqueMappings = groupBy(curGroup.groupEntries, (mapping) => mapping.mappedSentences[0].entry!.id);

        Object.values(uniqueMappings).map((grouped) => {
          const mapping = grouped[0];
          const entry = mapping.mappedSentences[0].entry;

          if (entry) {
            let entryText = entry.text;
            if (entry.hasConversation) {
              entryText = he.decode(`${capitalize(entry.submitterType ?? '')} - ${entry.text} `);
              entry.conversationParts.forEach((part) => {
                entryText += '\n' + he.decode(`${capitalize(part.submitterType ?? '')} - ${part.fullText} `);
              });
            }

            let newDataEntry = {
              'Group Title': curGroup.title,
              'Group Id': curGroup.id,
              'Entry Source': entry.source,
              'Entry Text': entryText,
              'Entry Date': entry.date ? localDateString(new Date(entry.date), 'YYYY-MM-DD HH:MM:SS Z') : '',
              'Entry Id': entry.id,
              'Entry Permalink': entry.source_permalink,
              'Entry Summary': entry.distillateText,
              User: entry.submitter,
            };

            const existingIndex = outData.findIndex((e) => e['Entry Id'] === entry.id);
            if (existingIndex > -1) outData[existingIndex] = newDataEntry; // Replace the old entry with the new one from the deeper group
            else outData.push(newDataEntry);
          }
        });
      }

      processGroupEntries(data.getGroup);

      if (data.getGroup.children) {
        for (const child of data.getGroup.children) {
          processGroupEntries(child);
          if (child.children) {
            for (const grandchild of child.children) {
              processGroupEntries(grandchild);
              if (grandchild.children) {
                for (const greatgrandchild of grandchild.children) {
                  processGroupEntries(greatgrandchild);
                }
              }
            }
          }
        }
      }

      outData = outData.sort((a, b) => Date.parse(b['Entry Date']) - Date.parse(a['Entry Date']));
      const csvExporter = new ExportToCsv({
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true,
        showTitle: false,
        filename: `Unwrap Group: ${data.getGroup?.title?.slice(0, 20) + '...'}`,
        useTextFile: false,
        useBom: true,
        useKeysAsHeaders: true,
      });
      //If no entries have summary, remove that column
      const hasSummary = outData.some((entry) => !!entry['Entry Summary']);
      if (!hasSummary) outData.forEach((entry) => delete entry['Entry Summary']);
      writeToastMessage("CSV ready! It'll download automatically.");
      csvExporter.generateCsv(outData);
      logEvent(Events.GroupExported, { View_ID: teamId, View_Name: teamName, Org_ID: orgId, Org_Name: orgName, Page: 'Explore Page' });
    },
  });
};

/**
 * Creates a filter set containing GroupTitle, startDate, and endDate and copies that filter to the clipboard
 * @param groupId
 * @param teamId
 * @param orgId
 * @param urlKey
 */
const copyGroupLink = (groupId: number, filterInput: FilterInput, teamId: number, orgId: number, urlKey?: string) => {
  const groupLink = getGroupLink(groupId, filterInput, teamId, orgId, urlKey);
  navigator.clipboard.writeText(groupLink);
  writeToastMessage('Link successfully copied!');
};

export const getGroupLink = (groupId: number, filterInput: FilterInput, teamId: number, orgId: number, urlKey?: string) => {
  const filter: FilterInput = {
    startDate: filterInput.startDate,
    ...(!moment().endOf('day').isSame(filterInput.endDate) ? { endDate: filterInput.endDate } : {}),
  };
  const url = new URL(window.location.href);
  const urlParams = new URLSearchParams(url.search);

  urlParams.set('teamId', teamId.toString());
  urlParams.set('orgId', orgId.toString());
  urlParams.set(urlKey ?? 'filters', encodeURIComponent(JSON.stringify(filter)));

  // if on homepage, then change pathname to explore page
  // if on group page, don't change pathname
  const pathname = window.location.pathname.replace('home', 'explore') + (window.location.pathname.includes('/group') ? '' : '/group/' + groupId);
  const newUrl = window.location.protocol + '//' + window.location.host + pathname + '?' + urlParams.toString();
  return newUrl;
};

export const writeToastError = (message: string) => {
  toast.error(message);
};
export const writeToastMessage = (message: string) => {
  toast.success(message);
};

export const getChildCandidates = async (
  teamId: number,
  filterInput: FilterInput,
  groupId: number,
  query: string,
  children: TaxonomyGroup[],
  getChildCandidatesQuery: LazyQueryExecFunction<GetChildCandidatesQuery, GetChildCandidatesQueryVariables>,
  setChildCandidates: (childCandidates: TaxonomyGroup[]) => void,
  cb?: () => void
) => {
  await getChildCandidatesQuery({
    variables: {
      teamId,
      parentGroupId: groupId,
      groupTitleSearchString: query,
    },
    onCompleted(data) {
      const childCandidates = Array.from(getTaxonomy(data.getChildCandidates).values());
      setChildCandidates(childCandidates.filter((c) => c.id !== groupId && c.canAddChildren && !children.find((child) => child.id === c.id)));

      cb?.();
    },
  });
};

export const getChildren = async (
  teamId: number,
  filterInput: FilterInput,
  groupId: number,
  getChildren: LazyQueryExecFunction<GetChildrenQuery, GetChildrenQueryVariables>,
  setChildren: (children: TaxonomyGroup[]) => void,
  cb?: () => void
) => {
  await getChildren({
    variables: {
      teamId,
      filterInput: filterInput ?? {},
      parentGroupId: groupId,
    },
    onCompleted(data) {
      const children = Array.from(getTaxonomy(data.getChildren).values());
      setChildren(children.filter((c) => c.canAddChildren));
      cb?.();
    },
  });
};

/**
 * This opens generates children for the next level of the taxonomy
 * @param teamId
 * @param filterInput
 * @param groupId
 * @param taxonomy
 * @param setTaxonomy
 * @param getChildren
 */

export const openParent = async (
  teamId: number,
  filterInput: FilterInput,
  groupId: number,
  taxonomy: Map<number, TaxonomyGroup>,
  setTaxonomy: (taxonomy: Map<number, TaxonomyGroup>) => void,
  getChildren: LazyQueryExecFunction<GetChildrenQuery, GetChildrenQueryVariables>,
  cb?: () => void
) => {
  // open/close parent
  if (taxonomy.get(groupId) != null) {
    const updatedTaxonomy = new Map(taxonomy);
    updatedTaxonomy.set(groupId, {
      ...taxonomy.get(groupId)!,
      showChildren: !taxonomy.get(groupId)!.showChildren ?? false,
    });
    setTaxonomy(updatedTaxonomy);
    // Save all open ids to localstorage
    const openIds = [...Array.from(updatedTaxonomy.values())].filter((group) => group.showChildren).map((group) => group.id);
    saveIdsToLocalStorage(openIds);

    cb?.();
    return;
  }
  cb?.();
};

// Expand All
export const expandAll = (taxonomy: Map<number, TaxonomyGroup>, setTaxonomy: (taxonomy: Map<number, TaxonomyGroup>) => void) => {
  const updatedTaxonomy = new Map(taxonomy);
  // Setting showChildren to true to all groups
  for (let group of Array.from(updatedTaxonomy.values())) {
    group.showChildren = true;
  }
  setTaxonomy(updatedTaxonomy);

  // Save all Ids to localstorage
  const allIds = Array.from(updatedTaxonomy.values()).map((group) => group.id);
  saveIdsToLocalStorage(allIds);
};

// Contract All
export const contractAll = (taxonomy: Map<number, TaxonomyGroup>, setTaxonomy: (taxonomy: Map<number, TaxonomyGroup>) => void) => {
  const updatedTaxonomy = new Map(taxonomy);
  // Setting showChildren to false to all groups
  for (let group of Array.from(updatedTaxonomy.values())) {
    group.showChildren = false;
  }
  setTaxonomy(updatedTaxonomy);

  // Clear open Ids from localstorage as no items are open
  saveIdsToLocalStorage([]);
};

export const getChildrenToAssign = async (
  teamId: number,
  filterInput: FilterInput,
  groupId: number,
  taxonomy: Map<number, TaxonomyGroup>,
  setChildrenToAssign: (childrenToAssign: TaxonomyGroup[]) => void,
  getChildrenToAssign: LazyQueryExecFunction<GetChildrenToAssignQuery, GetChildrenToAssignQueryVariables>,
  cb?: () => void
) => {
  await getChildrenToAssign({
    variables: {
      teamId,
      filterInput: filterInput ?? {},
      groupId: groupId,
    },
    onError(err) {
      writeToastError(err.message);
      cb?.();
    },

    onCompleted(data) {
      const children = Array.from(getTaxonomy(data.getChildrenToAssign).values());
      if (children) {
        const currentGroup = taxonomy.get(groupId);
        if (currentGroup) {
          const childrenIds = currentGroup.children?.map((c) => c.id);
          setChildrenToAssign(children.filter((c) => childrenIds?.includes(c.id) === false));
        } else {
          setChildrenToAssign(children);
        }
        cb?.();
        return;
      }
    },
  });
};

export const assignChild = async (
  teamId: number,
  filterInput: FilterInput,
  parentGroupId: number,
  childGroupId: number,
  taxonomy: Map<number, TaxonomyGroup>,
  childCandidates: TaxonomyGroup[],
  setChildCandidates: (childCandidates: TaxonomyGroup[]) => void,
  children: TaxonomyGroup[],
  setChildren: (childCandidates: TaxonomyGroup[]) => void,
  setTaxonomy: (taxonomy: Map<number, TaxonomyGroup>) => void,
  assignChild: AssignChildMutationFn,
  type?: 'Parent' | 'Child',
  cb?: () => void
) => {
  await assignChild({
    variables: {
      teamId,
      filterInput: filterInput ?? {},
      parentGroupId: parentGroupId,
      childGroupId: childGroupId,
    },
    onError(err) {
      writeToastError(err.message);
      cb?.();
    },

    onCompleted() {
      const parent = taxonomy.get(parentGroupId);
      const child = childCandidates.find((ch) => ch.id === childGroupId)
        ? getTaxonomy([childCandidates.find((ch) => ch.id === childGroupId)!]).get(childGroupId)!
        : taxonomy.get(childGroupId);
      const parentChildren = [...(parent?.children ?? []), ...[child ? child : taxonomy.get(childGroupId)!]];
      const updatedTaxonomy = new Map(taxonomy);
      const oldParent = taxonomy.get(updatedTaxonomy.get(childGroupId)?.parentId ?? -1);
      if (parentGroupId && taxonomy.get(parentGroupId)) {
        updatedTaxonomy.set(parentGroupId, {
          ...parent!,
          children: parentChildren,
          totalDescendents: parent!.totalDescendents + child!.totalDescendents + 1,
        });
      }
      if (oldParent) {
        updatedTaxonomy.set(oldParent.id, {
          ...oldParent,
          totalDescendents: oldParent.totalDescendents! - child!.totalDescendents - 1,
          showChildren: (oldParent.totalDescendents ?? 0) - child!.totalDescendents - 1 > 0,
          children: (oldParent.children ?? [])
            .filter((c) => c.id !== childGroupId && c.id !== parentGroupId && c.id !== oldParent.id)
            .filter((c, i, a) => a.findIndex((ch) => ch.id === c.id) === i),
        });
      }
      updatedTaxonomy.set(childGroupId, {
        ...child!,
        children: updatedTaxonomy.get(childGroupId)?.children ?? child!.children,
        parentId: parentGroupId,
      });
      setTaxonomy(updatedTaxonomy);
      setChildCandidates(childCandidates.filter((c) => c.id !== childGroupId));
      if (type === 'Child') setChildren(parentChildren);
      cb?.();
      writeToastMessage(` ${type ? type : 'Child'} successfully assigned!`);
    },
  });
};

export const removeChildFromParent = async (
  teamId: number,
  filterInput: FilterInput,
  parentGroupId: number,
  childGroupId: number,
  taxonomy: Map<number, TaxonomyGroup>,
  setTaxonomy: (taxonomy: Map<number, TaxonomyGroup>) => void,
  removeChild: RemoveChildMutationFn,
  cb?: () => void
) => {
  await removeChild({
    variables: {
      teamId,
      filterInput: filterInput ?? {},
      parentGroupId: parentGroupId,
      childGroupId: childGroupId,
    },
    onCompleted() {
      const updatedTaxonomy = new Map(taxonomy);
      const parent = taxonomy.get(parentGroupId);
      const child = taxonomy.get(childGroupId)!;
      if (parent) {
        updatedTaxonomy.set(parentGroupId, {
          ...parent!,
          showChildren: parent!.totalDescendents - child.totalDescendents - 1 > 0,
          totalDescendents: parent!.totalDescendents - child.totalDescendents - 1,
          children: (taxonomy.get(parentGroupId)?.children ?? []).filter((c) => c.id != childGroupId),
        });
      }
      if (child) {
        updatedTaxonomy.set(childGroupId, {
          ...taxonomy.get(childGroupId)!,
          parentId: null,
        });
      }
      setTaxonomy(updatedTaxonomy);
      cb?.();
      writeToastMessage('Child successfully removed!');
    },
  });
};

export const deleteChild = async (
  teamId: number,
  filterInput: FilterInput,
  parentGroupId: number,
  childGroupId: number,
  taxonomy: Map<number, TaxonomyGroup>,
  children: TaxonomyGroup[],
  setChildren: (childCandidates: TaxonomyGroup[]) => void,
  setTaxonomy: (taxonomy: Map<number, TaxonomyGroup>) => void,
  deleteChild: DeleteChildMutationFn,
  cb?: () => void
) => {
  await deleteChild({
    variables: {
      teamId,
      filterInput: filterInput ?? {},
      parentGroupId: parentGroupId,
      childGroupId: childGroupId,
    },
    onCompleted(data) {
      const currentParent = taxonomy.get(parentGroupId);
      const currentChild = taxonomy.get(childGroupId);
      const updatedTaxonomy = new Map(taxonomy);
      updatedTaxonomy.set(parentGroupId, {
        ...currentParent!,
        children: currentParent?.children?.filter((c) => c.id !== childGroupId) ?? [],
        totalDescendents: currentParent!.totalDescendents - (currentChild?.totalDescendents ?? 0) - 1,
        showChildren: currentParent!.totalDescendents - 1 > 0,
      });
      if (updatedTaxonomy.get(childGroupId)) {
        updatedTaxonomy.set(childGroupId, {
          ...taxonomy.get(childGroupId)!,
          parentId: null,
        });
      }
      setTaxonomy(updatedTaxonomy);
      setChildren(currentParent?.children?.filter((c) => c.id !== childGroupId) ?? []);
      cb?.();
      writeToastMessage('Child successfully removed!');
    },
  });
};
export const assignChildren = async (
  teamId: number,
  filterInput: FilterInput,
  parentGroupId: number,
  taxonomy: Map<number, TaxonomyGroup>,
  setTaxonomy: (taxonomy: Map<number, TaxonomyGroup>) => void,
  assignChildren: AssignChildrenMutationFn,
  childGroupIds: number[],
  children: TaxonomyGroup[],
  setChildren: (childCandidates: TaxonomyGroup[]) => void,
  childrenToAssign: TaxonomyGroup[],
  setChildrenToAssign: (childCandidates: TaxonomyGroup[]) => void,
  cb?: () => void
) => {
  writeToastMessage('Assigning Children Automatically...');
  await assignChildren({
    variables: {
      teamId,
      filterInput: filterInput ?? {},
      groupId: parentGroupId,
      childGroupIds: childGroupIds,
    },
    onError(err) {
      writeToastMessage(err.message);
      cb?.();
    },
    onCompleted(data) {
      const parent = taxonomy.get(parentGroupId);
      const parentChildren = childrenToAssign;
      if (parentChildren.length === 0) {
        cb?.();
        writeToastMessage('No Children to Assign');
        return;
      }
      if (taxonomy.get(parentGroupId)) {
        const childrenTaxonomy: Map<number, TaxonomyGroup> = getTaxonomy(parentChildren ?? [], undefined, parentGroupId);
        const updatedTaxonomy = new Map([...Array.from(taxonomy.entries()), ...Array.from(childrenTaxonomy.entries())]);
        updatedTaxonomy.set(parentGroupId, {
          ...parent!,
          children: parentChildren,
          totalDescendents: parent!.totalDescendents + parentChildren.length,
        });
        setTaxonomy(updatedTaxonomy);
      }
      setChildren([...children, ...parentChildren]);
      setChildrenToAssign(childrenToAssign.filter((c) => !parentChildren.map((pc) => pc.id).includes(c.id)));
      writeToastMessage('Children successfully assigned!');
      cb?.();
    },
  });
};

export const GROUP_ENTRIES_PAGE_SIZE = 20;
export const getCurrentGroup = async (
  teamId: number,
  filterInput: FilterInput,
  groupId: number,
  getGroup: LazyQueryExecFunction<GetGroupQuery, GetGroupQueryVariables>,
  setCurrentGroup: (group: GroupBase) => void,
  sentencesTake?: number,
  cb?: () => void,
  redirect?: () => void
) => {
  const initialLoadTime = performance.now();
  await getGroup({
    fetchPolicy: 'no-cache',
    variables: {
      teamId,
      filterInput: filterInput ?? {},
      groupId: groupId,
      sentencesSkip: 0,
      sentencesTake: GROUP_ENTRIES_PAGE_SIZE,
      includeDescendantsOnMappings: true,
    },
    onCompleted(data) {
      const group = data.getGroup;
      setCurrentGroup(getGroups([group])[0]);
      // this is only ever used on the explore page to fetch the entire group on the taxonomy
      const event = getTotalGroupLoadEvent({ view: 'taxonomy', duration: initialLoadTime });
      window.dispatchEvent(event);
      cb?.();
    },
    onError(err) {
      if (err.message.includes('Cannot find search')) {
        redirect?.();
      }
      writeToastError("Can't find the selected group. Make sure the link is correct or switch to the corresponding view.");
      cb?.();
    },
  });
};

export const getGroupPageUrl = (teamId: number, orgId: number, groupId: number, baseRoute: string, extraParams?: string[]) => {
  return `${baseRoute}/group/${groupId}?teamId=${teamId}&orgId=${orgId}` + (extraParams ? `&${extraParams.join('&')}` : '');
};

const LOCAL_STORAGE_KEY = 'ids';

const saveIdsToLocalStorage = (ids: number[]) => {
  try {
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(ids));
  } catch (e) {
    console.error('Error saving to localstorage', e);
  }
};

const getIdsFromLocalStorage = () => {
  try {
    const items = localStorage.getItem(LOCAL_STORAGE_KEY);
    if (items) {
      return JSON.parse(items);
    }
    return [];
  } catch (e) {
    console.error('Error getting data from localstorage', e);
    return [];
  }
};

// Type checking functions
function isGroupTaxonomyFragmentArray(arr: any[]): arr is GroupTaxonomyFragment[] {
  return arr && arr.length > 0 && 'statistics' in arr[0];
}

function isTaxonomyGroupArray(arr: any[]): arr is TaxonomyGroup[] {
  return arr && arr.length > 0 && 'denominator' in arr[0];
}
