import { NavLink, useNavigate } from 'react-router-dom';
import { Fragment, useContext, useState } from 'react';
import { Dialog, Disclosure, Transition } from '@headlessui/react';
import {
  Bars3Icon,
  UsersIcon,
  XMarkIcon,
  Square3Stack3DIcon,
  HomeIcon,
  MagnifyingGlassIcon,
  BellAlertIcon,
  Squares2X2Icon,
  ChartBarSquareIcon,
  SparklesIcon,
  WrenchIcon,
} from '@heroicons/react/24/outline';
import {
  Square3Stack3DIcon as Square3Stack3DIconSolid,
  MagnifyingGlassIcon as MagnifyingGlassIconSolid,
  HomeIcon as HomeIconSolid,
  BellAlertIcon as BellAlertIconSolid,
  UsersIcon as UsersIconSolid,
  Squares2X2Icon as Squares2X2IconSolid,
  UserCircleIcon,
  QuestionMarkCircleIcon,
  ArrowRightOnRectangleIcon,
  ChartBarSquareIcon as ChartBarSquareIconSolid,
  WrenchIcon as WrenchIconSolid,
} from '@heroicons/react/24/solid';
import { classNames } from '../../v2/util';
import unwrapLightLogo from '../../assets/unwrap_full_logo_2_MAIN.svg';
import { TeamFlagsQueryResult } from '../../generated/graphql';
import UserContext from '../../v2/contexts/UserContext';
import NewGroupModal from '../components/Modals/NewGroupModal';
import { StatusMenu } from '../sections/Navigation/StatusMenu';
import ChooseDropdownModal from '../components/Modals/ChooseDropdownModal';
import { Auth } from 'aws-amplify';
import { useApolloClient } from '@apollo/client';
import { AppRoutes, V3_DASHBOARD } from '../../Routes';
import { ViewDropdownRevamp } from '../sections/Navigation/ViewDropdownRevamp';
import AppContext from '../../v2/contexts/AppContext';
import { CustomerFacingAlert } from '../../v2/sections/Navigation/CustomerFacingAlert';

const navigation: INavigation[] = [
  {
    name: 'Home',
    path: AppRoutes.v3FullPath.home,
    href: '#',
    icon: HomeIcon,
    selectedIcon: HomeIconSolid,
    current: true,
    unwrapperRequired: false,
    teamRequired: false,
  },
  {
    name: 'Feedback',
    path: AppRoutes.v3FullPath.feedback,
    href: '#',
    icon: Square3Stack3DIcon,
    selectedIcon: Square3Stack3DIconSolid,
    current: false,
    unwrapperRequired: false,
    teamRequired: true,
  },
  {
    name: 'Explore',
    path: AppRoutes.v3FullPath.explore,
    href: '#',
    icon: MagnifyingGlassIcon,
    selectedIcon: MagnifyingGlassIconSolid,
    current: true,
    unwrapperRequired: false,
    teamRequired: true,
  },
  {
    name: 'Charts',
    path: AppRoutes.v3FullPath.charts,
    href: '#',
    icon: ChartBarSquareIcon,
    selectedIcon: ChartBarSquareIconSolid,
    current: true,
    unwrapperRequired: false,
    showNewBadge: true,
    teamRequired: true,
  },
  {
    name: 'Alerts',
    path: AppRoutes.v3FullPath.alerts,
    href: '#',
    icon: BellAlertIcon,
    selectedIcon: BellAlertIconSolid,
    current: false,
    unwrapperRequired: false,
    teamRequired: true,
  },
  {
    name: 'Integrations',
    path: AppRoutes.v3FullPath.integrations,
    href: '#',
    icon: Squares2X2Icon,
    selectedIcon: Squares2X2IconSolid,
    current: false,
    unwrapperRequired: false,

    teamRequired: true,
  },
  {
    name: 'Organizations',
    path: AppRoutes.v3FullPath.organizations,
    href: '#',
    icon: UsersIcon,
    selectedIcon: UsersIconSolid,
    current: false,
    unwrapperRequired: false,
    teamRequired: false,
  },
  {
    name: 'Admin',
    path: AppRoutes.v3FullPath.admin,
    href: '#',
    icon: WrenchIcon,
    selectedIcon: WrenchIconSolid,
    current: false,
    unwrapperRequired: true,
    teamRequired: false,
  },
];

//https://tailwindui.com/components/application-ui/application-shells/sidebar

export default function DashboardLayout({
  children,
  customerFacingAlertOpen,
  setCustomerFacingAlertOpen,
  flags,
  addTeam,
  addOrganization,
}: DashboardLayoutProps) {
  const { organizations, currentOrg, clearAppContext, setCurOrgId, curTeamId, currentTeam } = useContext(AppContext);
  const { user, setCurrentUser } = useContext(UserContext);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  let navigate = useNavigate();

  const [queryString, setQueryString] = useState<string>('');
  const client = useApolloClient();

  const [switchOrgModalOpen, setSwitchOrgModalOpen] = useState(false);
  const [newTeamModalOpen, setNewTeamModalOpen] = useState(false);
  const [newOrgModalOpen, setNewOrgModalOpen] = useState(false);

  const signOut = async () => {
    await Auth.signOut();
    await setCurrentUser();
    clearAppContext();
    // this needs to be after the current user is signed out
    await client.resetStore();
    navigate(AppRoutes.login);
  };

  return (
    <div className="min-h-screen font-sofiapro">
      {switchOrgModalOpen && (
        <ChooseDropdownModal
          modalOpen={switchOrgModalOpen}
          callbackModal={() => setSwitchOrgModalOpen(false)}
          title="Select Which Organization To Switch To:"
          subtitle={''}
          confirmButton={(org: any) => {
            setCurOrgId(org.id);
            //changeOrg(org.id);
            setSwitchOrgModalOpen(false);
            navigate(AppRoutes.v3FullPath.home);
          }}
          loadingConfirm={false}
          data={organizations}
        />
      )}
      {newTeamModalOpen && (
        <NewGroupModal
          modalOpen={newTeamModalOpen}
          callbackModal={() => setNewTeamModalOpen(false)}
          confirmButton={(teamName: string, logoUrl: string, demoMode: string) => {
            addTeam(teamName, logoUrl, demoMode, currentOrg?.id!);
            setNewTeamModalOpen(false);
          }}
          type="View"
        />
      )}
      {newOrgModalOpen && (
        <NewGroupModal
          modalOpen={newOrgModalOpen}
          callbackModal={() => setNewOrgModalOpen(false)}
          confirmButton={(orgName: string, logoUrl: string, demoMode: string) => {
            addOrganization(orgName);
            setNewOrgModalOpen(false);
          }}
          type="Organization"
        />
      )}
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog as="div" className="relative z-40 lg:hidden" onClose={setSidebarOpen}>
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
          </Transition.Child>

          <div className="fixed inset-0 z-40 flex">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <Dialog.Panel className="relative flex w-full max-w-xs flex-1 flex-col bg-blueberry">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute top-0 right-0 -mr-12 pt-2">
                    <button
                      type="button"
                      className="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                      onClick={() => setSidebarOpen(false)}
                    >
                      <span className="sr-only">Close sidebar</span>
                      <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
                    </button>
                  </div>
                </Transition.Child>
                <div className="flex flex-col overflow-y-auto pt-5 h-full">
                  <a href="https://unwrap.ai" target="_blank" rel="noreferrer">
                    <div className="flex flex-shrink-0 items-center px-4">
                      <img className="h-8 w-auto" src={unwrapLightLogo} alt="Unwrap" />{' '}
                    </div>
                  </a>
                  <nav className="mt-5 space-y-1 px-2 font-sofiapro">
                    {navigation
                      .filter((item) => !(item.unwrapperRequired && !user?.isUnwrapper))
                      .map((item, index) => (
                        <NavLink
                          to={`${item.path}`}
                          id={`navigation-${item.name.replace(' ', '-').toLowerCase()}`}
                          key={index}
                          className={({ isActive }) =>
                            classNames(
                              item.teamRequired && !curTeamId ? 'disabled opacity-60' : '',
                              'group flex select-none items-center rounded-md border-l-4 border-transparent px-4 py-3 text-base font-medium text-milk',
                              !(item.teamRequired && !curTeamId)
                                ? isActive
                                  ? 'bg-black bg-opacity-20'
                                  : 'transition-colors duration-100 hover:border-l-4 hover:border-l-raspberry hover:bg-raspberry hover:bg-opacity-10 '
                                : ''
                            )
                          }
                        >
                          {({ isActive }) => (
                            <>
                              {isActive ? (
                                <item.selectedIcon className={classNames('mr-4 h-7 w-7 flex-shrink-0 text-raspberry')} aria-hidden="true" />
                              ) : (
                                <item.icon className={classNames('mr-5 h-6 w-6 flex-shrink-0 stroke-2 text-milk')} aria-hidden="true" />
                              )}
                              {item.name}
                            </>
                          )}
                        </NavLink>
                      ))}
                  </nav>
                  <div className="mt-auto">
                    <div className="flex mb-2 mx-4">{currentOrg?.isCustomerFacing ? <CustomerFacingAlert /> : null}</div>
                    <div className="flex min-h-[4.5rem] items-center justify-between bg-black bg-opacity-20">
                      <ViewDropdownRevamp
                        queryString={queryString}
                        setQueryString={setQueryString}
                        setSwitchOrgModalOpen={setSwitchOrgModalOpen}
                        setNewOrgModalOpen={setNewOrgModalOpen}
                        setNewTeamModalOpen={setNewTeamModalOpen}
                      />
                      <div className={classNames(curTeamId ? 'block' : 'hidden', 'h-full w-1/4')}>
                        {curTeamId && <StatusMenu flags={flags} selectedTeam={currentTeam} />}
                      </div>
                    </div>
                    <div className="flex flex-shrink-0 items-center justify-between bg-black bg-opacity-30 p-4">
                      <NavLink
                        id="navigation-profile"
                        to={`/${V3_DASHBOARD}${AppRoutes.profile}`}
                        className={
                          'flex flex-row items-center gap-x-1.5 rounded-full bg-blueberry-light p-2 px-3 text-gray-200 transition duration-200 hover:cursor-pointer hover:bg-blueberry-lighter'
                        }
                        onClick={() => {}}
                      >
                        <UserCircleIcon className="h-5 w-5" />
                        <h1 className="textEllipsis text-ellipsis text-sm">{user?.firstName}</h1>
                      </NavLink>

                      <a
                        id="navigation-docs"
                        href="https://docs.unwrap.ai"
                        target="_blank"
                        rel="noreferrer"
                        className="rounded-full bg-blueberry-light p-2 transition duration-200 hover:cursor-pointer hover:bg-blueberry-lighter"
                      >
                        <QuestionMarkCircleIcon className="h-5 w-5 text-milk" />
                      </a>
                      <div
                        id="navigation-logout"
                        onClick={() => signOut()}
                        className="rounded-full bg-blueberry-light p-2 transition duration-200 hover:cursor-pointer hover:bg-blueberry-lighter"
                      >
                        <ArrowRightOnRectangleIcon className="h-5 w-5 text-milk" />
                      </div>
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
            <div className="w-14 flex-shrink-0">{/* Force sidebar to shrink to fit close icon */}</div>
          </div>
        </Dialog>
      </Transition.Root>

      <Disclosure>
        {/* Static sidebar for desktop */}
        <div className="hidden lg:fixed lg:inset-y-0 lg:flex lg:w-60 lg:flex-col">
          {/* Sidebar component, swap this element with another sidebar if you like */}

          <div className="flex min-h-0 flex-1 flex-col bg-blueberry">
            <div className="flex flex-1 flex-col gap-y-4 overflow-y-auto pt-5 pb-4">
              <a href="https://app.unwrap.ai" target="_blank" rel="noreferrer">
                <div className="flex flex-shrink-0 items-center px-5">
                  <img className="h-8 w-auto" src={unwrapLightLogo} alt="Unwrap" />
                </div>
              </a>
              <nav className="containerScrollbar mt-5 flex-1 space-y-1">
                {navigation
                  .filter((item) => !(item.unwrapperRequired && !user?.isUnwrapper))
                  .map((item, index) => (
                    <NavLink
                      to={{ pathname: `${item.path}`, search: '' }}
                      id={`navigation-${item.name.replace(' ', '-').toLowerCase()}`}
                      key={index}
                      className={({ isActive }) =>
                        classNames(
                          item.teamRequired && !curTeamId ? 'disabled opacity-60' : '',
                          'group flex select-none items-center rounded-md border-l-4 border-transparent px-4 py-3 text-base font-medium text-milk',
                          !(item.teamRequired && !curTeamId)
                            ? isActive
                              ? 'bg-black bg-opacity-20'
                              : 'transition-colors duration-100 hover:border-l-4 hover:border-l-raspberry hover:bg-raspberry hover:bg-opacity-10 '
                            : ''
                        )
                      }
                    >
                      {({ isActive }) => (
                        <div className="flex flex-row gap-x-3 items-center">
                          <div className="flex flex-row items-center">
                            {isActive ? (
                              <item.selectedIcon className={classNames('mr-4 h-7 w-7 flex-shrink-0 text-raspberry')} aria-hidden="true" />
                            ) : (
                              <item.icon className={classNames('mr-5 h-6 w-6 flex-shrink-0 stroke-2 text-milk')} aria-hidden="true" />
                            )}
                            <p>{item.name}</p>
                          </div>
                          {item.showNewBadge ? (
                            <div className="flex flex-row gap-x-1 bg-blueberry-lighter px-3 py-1 rounded-md items-center justify-center">
                              <SparklesIcon className="h-3 w-3 text-white" />
                              <p className="text-xs">New</p>
                            </div>
                          ) : null}
                        </div>
                      )}
                    </NavLink>
                  ))}
              </nav>
            </div>
            <div className="flex mb-2 mx-4">{user?.isUnwrapper && currentOrg?.isCustomerFacing ? <CustomerFacingAlert /> : null}</div>
            <div className="flex min-h-[4.5rem] items-center justify-between bg-black bg-opacity-20">
              <ViewDropdownRevamp
                queryString={queryString}
                setQueryString={setQueryString}
                setSwitchOrgModalOpen={setSwitchOrgModalOpen}
                setNewOrgModalOpen={setNewOrgModalOpen}
                setNewTeamModalOpen={setNewTeamModalOpen}
              />
              <div className={classNames(curTeamId ? 'block' : 'hidden', 'h-full w-1/4')}>
                {curTeamId && <StatusMenu flags={flags} selectedTeam={currentTeam} />}
              </div>
            </div>
            <div className="flex flex-shrink-0 items-center justify-between bg-black bg-opacity-30 p-4">
              <NavLink
                id="navigation-profile"
                to={`/${V3_DASHBOARD}${AppRoutes.profile}`}
                className={
                  'flex flex-row items-center gap-x-1.5 rounded-full bg-blueberry-light p-2 px-3 text-gray-200 transition duration-200 hover:cursor-pointer hover:bg-blueberry-lighter'
                }
                onClick={() => {}}
              >
                <UserCircleIcon className="h-5 w-5" />
                <h1 className="textEllipsis text-ellipsis text-sm">{user?.firstName}</h1>
              </NavLink>

              <a
                id="navigation-docs"
                href="https://docs.unwrap.ai"
                target="_blank"
                rel="noreferrer"
                className="rounded-full bg-blueberry-light p-2 transition duration-200 hover:cursor-pointer hover:bg-blueberry-lighter"
              >
                <QuestionMarkCircleIcon className="h-5 w-5 text-milk" />
              </a>
              <div
                id="navigation-logout"
                onClick={() => signOut()}
                className="rounded-full bg-blueberry-light p-2 transition duration-200 hover:cursor-pointer hover:bg-blueberry-lighter"
              >
                <ArrowRightOnRectangleIcon className="h-5 w-5 text-milk" />
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-1 flex-col lg:pl-60 ">
          <div className="sticky top-0 z-10 bg-gray-100 pl-1 pt-1 sm:pl-3 sm:pt-3 lg:hidden">
            <button
              type="button"
              className="-ml-0.5 -mt-0.5 inline-flex h-12 w-12 items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
              onClick={() => setSidebarOpen(true)}
            >
              <span className="sr-only">Open sidebar</span>
              <Bars3Icon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <main className="flex-1 relative min-h-screen">
            <div className="py-4">
              <div className="font-sofiapro">{children}</div>
            </div>
          </main>
        </div>
      </Disclosure>
    </div>
  );
}

interface DashboardLayoutProps {
  children?: JSX.Element;
  customerFacingAlertOpen: boolean;
  setCustomerFacingAlertOpen: (bool: boolean) => void;
  flags: TeamFlagsQueryResult | undefined;
  addTeam: (teamName: string, logoUrl: string, demoMode: string, id: number) => void;
  addOrganization: (orgName: string) => void;
}

interface INavigation {
  name: string;
  path: string;
  href: string;
  current: boolean;
  title?: string;
  icon?: any;
  selectedIcon?: any;
  /** Only show nav link if user is an unwrapper */
  unwrapperRequired: boolean;
  showNewBadge?: boolean;
  teamRequired?: boolean;
}
