import { FeedbackEntriesQuery } from '../../generated/graphql';
import FeedbackEntryCard from './FeedbackEntryCard';

interface FeedbackEntriesListProps {
  entries: FeedbackEntriesQuery['entries'];
}

export const FeedbackEntriesList = ({ entries }: FeedbackEntriesListProps) => {
  return (
    <div>
      <ul role="list" className="flex w-full flex-col gap-y-3">
        {entries?.map((entry) => {
          return (
            <li key={entry.id}>
              <FeedbackEntryCard entry={entry} compact={entry.hasConversation} />
            </li>
          );
        })}
      </ul>
    </div>
  );
};
