import mixpanel from 'mixpanel-browser';
import { useContext } from 'react';
import { useGetUserAttributesLazyQuery } from '../generated/graphql';
import UserContext from './contexts/UserContext';
import { FilterCategory } from '../v3/sections/Filters/FiltersTypes';
import { logError } from '../applicationTelemetry';
export enum PreviewModalOrigin {
  date = 'Date',
  getStarted = 'Get Started',
  other = 'Other',
}
export enum Events {
  PageLoaded = 'Page Loaded',
  FilterClicked = 'Filter Clicked',
  TextSearchEntered = 'Text Search Entered',
  FeedbackDeleted = 'Feedback Deleted',
  PermalinkClicked = 'Permalink Clicked',
  SentenceRemoved = 'Sentence Removed From Cluster',
  SentenceAdded = 'Sentence Added To Cluster',
  FindMoreSentences = 'Find More Sentences Search',
  DenominatorToggleClicked = 'Denominator Toggle Clicked',
  AlertSaved = 'Alert Saved',
  CreatedView = 'New View Created',
  InvitedMembers = 'New Members Invited To View',
  RemovedView = 'View Removed',
  RequestDemo = 'Request Demo Submit',
  PreviewPageLoaded = 'Preview Page Loaded',
  TaxonomyToggled = 'Taxonomy Folder Toggled',
  InsightCardClicked = 'Insight Card Clicked',
  CarouselButtonClicked = 'Carousel Button Clicked',
  GroupExported = 'Group Exported',
}

// Ideally we'd derive this type from FilterInput however this info isn't straightforward to extract. For now just keep these
// as a castable type for reference in code and to lock down the string type.
export type AnalyticsFilterType = 'theme' | 'cluster' | 'sentiment' | 'source' | 'custom_segment' | 'ownership' | 'date';

export type Properties = {
  View_ID?: number | null;
  View_Name?: string;
  Org_ID?: number | null;
  Org_Name?: string;
  Page?: string;
  Cluster_ID?: number;
  Filter_Type?: AnalyticsFilterType;
  Toggle_Type?: string;
  Text_Searched?: string;
  Name?: string;
  Email?: string;
  Filter_Category?: FilterCategory;
  Depth?: number;
};

/** for any hostname other than our prod hostname set devMode to true */
export const isDevMode = window.location.hostname !== 'app.unwrap.ai';

//this is for logging events that don't trigger network queries (those are captured by the latencyTracker)
export const logEvent = (eventName: Events | PreviewModalOrigin | string, options: Properties) => {
  if (!isDevMode) mixpanel.track(eventName, options);
};
// mix panel things
const campaignParams = () => {
  const campaign_keywords = 'utm_source utm_medium utm_campaign utm_content utm_term'.split(' ');
  let kw: string = '';
  let params: {
    [key: string]: string;
  } = {};
  let first_params: {
    [key: string]: string;
  } = {};
  let index;
  for (index = 0; index < campaign_keywords.length; ++index) {
    kw = getQueryParam(document.URL, campaign_keywords[index]);
    if (kw.length) {
      params[campaign_keywords[index] + ' [last touch]'] = kw;
    }
  }
  for (index = 0; index < campaign_keywords.length; ++index) {
    kw = getQueryParam(document.URL, campaign_keywords[index]);
    if (kw.length) {
      first_params[campaign_keywords[index] + ' [first touch]'] = kw;
    }
  }
  mixpanel.people.set(params);
  mixpanel.people.set_once(first_params);
  mixpanel.register(params);
};
// gets utm from url
export const getQueryParam = (url: string, param: string) => {
  param = param.replace(/[[]/, '[').replace(/[]]/, ']');
  var regexS = '[?&]' + param + '=([^&#]*)',
    regex = new RegExp(regexS),
    results = regex.exec(url);
  if (results === null || (results && typeof results[1] !== 'string')) {
    return '';
  } else {
    return decodeURIComponent(results[1]).replace(/\W/gi, ' ');
  }
};

export const clearQueryParam = (param: string) => {
  const url = new URL(window.location.href);
  url.searchParams.delete(param);
  window.history.replaceState({}, '', url.toString());
};

export const AnalyticsTracking = () => {
  const { user } = useContext(UserContext);
  const [getUserAttributes] = useGetUserAttributesLazyQuery();

  const getUserId = async () => {
    return getUserAttributes()
      .then((user) => user?.data?.getUserAttributes.id)
      .catch((e) => {
        logError(e);
      });
  };

  const identifyUser = async () => {
    // user id is not received through context, so you need to query use the user's email to access their id
    // used to get the tie first/last utm information to a user's profile.
    if (isDevMode) return;
    let userId;
    if (user) {
      userId = await getUserId();
      const identity = {
        $name: `${user?.firstName} ${user?.lastName}`,
        $firstName: user?.firstName,
        $lastName: user?.lastName,
        $email: user?.email,
        $is_employee: user?.isUnwrapper,
        $user_id: userId,
      };
      mixpanel.people.set_once(identity);
      campaignParams();
      mixpanel.identify(userId?.toString());
      // I think __insp never gets defined.
      window.__insp?.push(['tagSession', identity]);
    }
  };

  return {
    identifyUser,
  };
};
