import { useEffect, useState, useContext } from 'react';
import LoadingSpinner from '../../v2/components/LoadingSpinner';
import UserContext from '../../v2/contexts/UserContext';
import InfoCard from '../components/InfoCard';
import { PageWrapper } from './PageWrapper';

export function ProfilePage({ ...props }) {
  const { user } = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(false);
  }, []);

  return (
    <PageWrapper title='Account Settings'>
      <div className="flex flex-col gap-y-3">
        {isLoading || !user ? (
          <LoadingSpinner />
        ) : (
          <>
            <div className="mb-10 flex flex-col gap-x-5 gap-y-4">
              {/*Change Password Component*/}
              <InfoCard title={'Full Name'} data={user?.firstName + ' ' + user?.lastName} />
              <InfoCard title={'Email'} data={user?.email} />
              {/* <ChangePassword userId={user?.id} /> */}
              <InfoCard
                title={'Terms & Privacy'}
                data={
                  <>
                    <a href="https://app.unwrap.ai/terms" target="_blank" rel="noreferrer" className="text-blue-500 hover:text-blue-700">
                      {' Terms of Service '}
                    </a>
                    and
                    <a href="https://app.unwrap.ai/privacy" target="_blank" rel="noreferrer" className="text-blue-500 hover:text-blue-700">
                      {' Privacy Policy '}
                    </a>
                  </>
                }
              />
            </div>
          </>
        )}
      </div>
    </PageWrapper>
  );
}
