import { IDropDownItem } from './DropDown';
import { Menu, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import { groupBy } from 'underscore';
import React from 'react';
import { LockClosedIcon } from '@heroicons/react/24/solid';

export interface ISettingsItem extends IDropDownItem {
  icon?: JSX.Element;
  onClick: () => void;
  group: string;
}

export enum MenuAlign {
  RIGHT = 0,
  LEFT = 1,
}

interface SettingsMenuProps {
  settings: ISettingsItem[];
  children: JSX.Element;
  align?: MenuAlign;
  disable?: boolean;
  showLock?: boolean;
}

const SettingsMenu: React.FC<SettingsMenuProps> = ({ settings, align, disable, children, showLock }) => {
  const groupedSettings = groupBy(settings, (setting) => setting.group);

  // example for how to capture menu closed https://github.com/tailwindlabs/headlessui/issues/239
  // We might want to do this to darken the screen behind the menu and undarken when menu is closed.

  return (
    <div className="flex" onClick={(e) => e.stopPropagation()}>
      <div className="text-right" aria-hidden="true">
        <Menu data-testid="settings-menu" as="div" className="relative inline-block text-left">
          <div>
            <Menu.Button className="inline-flex justify-center" disabled={disable}>
              {children}
            </Menu.Button>
          </div>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items
              className={`${
                align === MenuAlign.RIGHT || !align ? 'right-0 ' : ''
              } absolute z-50 mt-2 w-48 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-xl ring-1 ring-black ring-opacity-5 focus:outline-none lg:w-56 `}
            >
              {Object.keys(groupedSettings).map((key, index) => {
                return (
                  <div key={index} className="px-1 py-1">
                    {groupedSettings[key].map((setting, idx) => {
                      return (
                        <Menu.Item key={idx}>
                          {({ active }) => (
                            <button
                              data-testid={setting.htmlId}
                              name={setting.htmlId}
                              className={`${
                                active ? 'bg-raspberry fill-white stroke-raspberry text-white' : 'stroke-white text-gray-900'
                              } group flex w-full select-none items-center justify-between rounded-md px-2 py-2 text-sm`}
                              onClick={(e) => {
                                e.stopPropagation();
                                setting.onClick();
                              }}
                              key={idx}
                            >
                              <div className="flex flex-row items-center text-xs lg:text-sm">
                                {setting.icon && (
                                  <div id={`${setting.htmlId}-icon`} className={`${active ? 'text-white' : 'text-blueberry'} mr-2`}>
                                    {setting.icon}
                                  </div>
                                )}
                                {setting.name}
                              </div>
                              {showLock ? <LockClosedIcon className="h-4 w-4 opacity-60" /> : undefined}
                            </button>
                          )}
                        </Menu.Item>
                      );
                    })}
                  </div>
                );
              })}
            </Menu.Items>
          </Transition>
        </Menu>
      </div>
    </div>
  );
};

export default SettingsMenu;
