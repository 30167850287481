import { DocumentDuplicateIcon, ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import Tippy from '@tippyjs/react';
import { useState, useRef } from 'react';
import AdjustableLoadingIcon from '../../../../baseComponents/AdjustableLoadingIcon';
import Badge from '../../../../baseComponents/Badge';
import { AnnouncementRecipient } from '../../../../generated/graphql';
import 'tippy.js/themes/light.css';

interface AnnouncementEmailFormProps {
  announcementRecipients: AnnouncementRecipient[];
  warning: boolean;
  subject: string;
  body: string;
  setSubject: (subject: string) => void;
  setBody: (subject: string) => void;
  copyEmailsToClipboard: () => void;
  addEmail: (email: string) => void;
  removeEmail: (email: string) => void;
}

const AnnouncementEmailForm = ({
  announcementRecipients,
  subject,
  body,
  warning,
  setSubject,
  setBody,
  copyEmailsToClipboard,
  addEmail,
  removeEmail,
}: AnnouncementEmailFormProps): JSX.Element => {
  const [currentEmail, setCurrentEmail] = useState<string>('');
  const inputRef = useRef<HTMLInputElement | null>(null);
  return (
    <div className="flex min-h-[24rem] cursor-text flex-col gap-y-2">
      {warning && (
        <div className="flex flex-row items-center justify-start">
          <ExclamationTriangleIcon className="h-4 w-4 stroke-2" />
          <p className="mt-0.5 ml-0.5 text-sm font-semibold text-blueberry">Email recipient list must contain at least one user.</p>
        </div>
      )}
      <div
        className="min-h-11 max-h-[4rem] w-full overflow-y-auto rounded-md border border-gray-300 bg-white py-2 pl-3 pr-14 text-blueberry shadow-sm focus:outline-none sm:text-sm"
        onClick={() => inputRef.current?.focus()}
      >
        <div className="flex flex-row flex-wrap gap-x-1 gap-y-2">
          {announcementRecipients ? (
            announcementRecipients.map((user, i) => (
              <Tippy theme="light" content={user.entryText} delay={[500, 0]}>
                <div>
                  <Badge badge={{ id: i.toString(), text: user.userEmail }} onRemove={() => removeEmail(user.userEmail)} capitalize={false} />
                </div>
              </Tippy>
            ))
          ) : (
            <AdjustableLoadingIcon height={5} width={5} />
          )}
          {announcementRecipients.length > 0 && (
            <DocumentDuplicateIcon
              className="h-5 w-5 cursor-pointer"
              onClick={(e) => {
                e.stopPropagation();
                copyEmailsToClipboard();
              }}
            />
          )}
          <input
            ref={inputRef}
            className="min-h-12 max-w-full flex-grow border-none focus:outline-none "
            value={currentEmail}
            placeholder={announcementRecipients.length === 0 ? 'Email: ' : ''}
            onChange={(e) => {
              setCurrentEmail(e.target.value);
            }}
            onKeyDown={(e) => {
              if (e.key === ' ' || e.key === 'Enter' || e.key === ',') {
                e.preventDefault();
                addEmail(currentEmail);
                setCurrentEmail('');
              }
            }}
          />
        </div>
      </div>
      <input
        value={subject}
        onChange={(e) => setSubject(e.target.value)}
        placeholder="Subject"
        className="min-h-11 w-full rounded-md border border-gray-300 bg-white py-2 pl-3 pr-14 text-blueberry shadow-sm focus:outline-none sm:text-sm"
      />
      <textarea
        placeholder={'Body:'}
        className="focus:outline-nonebg-white h-full w-full flex-grow resize-none rounded-md border border-gray-300 py-2 pl-3 pr-14 text-blueberry shadow-sm focus:border-gray-300 focus:outline-none focus:ring-0 sm:text-sm"
        value={body}
        onChange={(e) => setBody(e.target.value)}
      />
    </div>
  );
};

export default AnnouncementEmailForm;
