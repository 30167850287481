import Button, { ButtonType } from '../../baseComponents/Button';
export default function InvalidLinkPage({ ...props }) {
  return (
    <div className="flex flex-col gap-y-3">
      <div className="mt-[100px] flex h-full w-full flex-col items-center justify-center">
        <div className="flex flex-row items-center justify-center">
          <BrokenLink />
        </div>
        <h2 className="my-3 text-center text-3xl font-semibold">Invalid URL</h2>
        <p className="mb-3">The link you've used has expired or is invalid.</p>
        <Button
          buttonType={ButtonType.Primary2}
          text={'Go to Homepage'}
          additionalStyles="text-lg"
          onClick={() => (window.location.href = 'https://www.unwrap.ai')}
        />
      </div>
    </div>
  );
}

const BrokenLink = (className: any) => {
  return (
    <svg
      version="1.1"
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width="250px"
      height="250px"
      viewBox="0 0 872 872"
      enableBackground="new 0 0 872 872"
      style={{ transform: 'rotate(-85deg)' }}
    >
      <g>
        <g>
          <path
            d="M289.1,351.3h61.8c7.301,0,13.101-5.9,13.101-13.1v-97.4c0-4.5-1.8-8.9-5-12.1c-3.2-3.2-7.5-5-12.101-5H188.4
                c-50.3,0-97.6,19.6-133.2,55.2S0,361.8,0,412.1v2.9c0,50.299,19.6,97.6,55.2,133.2s82.9,55.199,133.2,55.199H347
                c4.5,0,8.899-1.799,12.1-5c3.2-3.199,5-7.5,5-12.1v-84.199c0-7.301-5.899-13.1-13.1-13.1h-61.8c-7.301,0-13.101,5.898-13.101,13.1
                v13.1h-87.6c-55.4,0-100.2-44.9-100.2-100.2v-2.9c0-55.399,44.9-100.2,100.2-100.2h87.6v26.4C276,345.5,281.899,351.3,289.1,351.3
                z"
          />
          <path
            d="M602.1,109.6c-4.3-2.3-9.1-3.5-13.899-3.5c-11.2,0-21.4,6.1-26.601,16l-52.1,99c-7.7,14.6-2.101,32.8,12.6,40.5
                c4.3,2.3,9.101,3.5,13.9,3.5c11.2,0,21.399-6.1,26.6-16l52.101-99c3.699-7.1,4.5-15.2,2.1-22.9
                C614.399,119.6,609.2,113.3,602.1,109.6z"
          />
          <path
            d="M431.899,97.3C428,84.7,416.5,76.2,403.3,76.2c-3,0-6,0.5-8.9,1.4c-15.8,4.9-24.699,21.8-19.8,37.6L407.8,222
                c3.9,12.6,15.4,21.1,28.6,21.1c3,0,6-0.5,8.9-1.4c7.7-2.4,13.9-7.6,17.7-14.7c3.7-7.1,4.5-15.2,2.1-22.9L431.899,97.3z"
          />
          <path
            d="M584.6,315c3.9,12.6,15.4,21.1,28.601,21.1c3,0,6-0.5,8.899-1.4l106.8-33.2c15.801-4.9,24.7-21.8,19.7-37.5
                c-3.899-12.6-15.399-21.1-28.6-21.1c-3,0-6,0.5-8.9,1.4l-106.8,33.1C588.5,282.3,579.7,299.2,584.6,315z"
          />
        </g>
        <path
          d="M567.3,579.101l-43.7-43.701c-5.1-5.1-13.5-5.1-18.6,0l-68.8,68.9c-3.2,3.201-5,7.5-5,12.1c0,4.5,1.8,8.9,5,12.102
            l112.1,112.1c35.6,35.6,82.9,55.199,133.2,55.199s97.6-19.6,133.2-55.199l2.1-2.1c35.6-35.602,55.2-82.9,55.2-133.201
            c0-50.299-19.601-97.6-55.2-133.199l-112.2-112c-3.2-3.2-7.5-5-12.1-5c-4.5,0-8.9,1.8-12.101,5l-59.5,59.5
            c-5.1,5.101-5.1,13.5,0,18.6l43.7,43.699c5.101,5.102,13.5,5.102,18.601,0l9.3-9.299l62,62c39.1,39.1,39.1,102.6,0,141.799
            l-2.101,2.102c-39.1,39.1-102.6,39.1-141.8,0l-62-62l18.7-18.701C572.5,592.601,572.5,584.3,567.3,579.101z"
        />
      </g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
    </svg>
  );
};
