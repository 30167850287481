import { classNames } from '../../v2/util';

interface TableProps<T> {
  data?: T[];
  columns: { title: string; render: (item: T) => JSX.Element | string; extraTdClassName?: string }[];
}

export default function AlertsTable<T>({ data, columns }: TableProps<T>) {
  return (
    <div className="overflow-visible rounded-xl">
      <table className="min-w-full divide-y divide-blueberry overflow-y-visible">
        <thead className="bg-blueberry">
          <tr>
            {columns.map((column, idx) => (
              <th key={idx} scope="col" className="py-3 pl-3 text-left text-xs font-semibold tracking-wide text-milk">
                {column.title}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200 bg-silver">
          {data?.map((item, idx) => (
            <tr key={idx}>
              {columns.map((column, idx) => (
                <td className={classNames('whitespace-nowrap py-4 px-3 pl-3 text-sm text-gray-500', column.extraTdClassName)} key={idx}>
                  {column.render(item)}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
