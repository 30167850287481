import Tippy from '@tippyjs/react';
import { QuestionMarkCircleIcon } from '@heroicons/react/20/solid';
interface TooltipIconProps {
  tooltipContent: string;
  tooltipHtml?: JSX.Element;
}
const TooltipIcon = ({ tooltipContent, tooltipHtml }: TooltipIconProps) => {
  return (
    <Tippy content={tooltipHtml ?? tooltipContent} duration={300}>
      <div>
        <QuestionMarkCircleIcon className="h-4 w-4" aria-hidden="true" />
      </div>
    </Tippy>
  );
};
export default TooltipIcon;
