import { EnvelopeIcon } from '@heroicons/react/24/outline';
import { XCircleIcon } from '@heroicons/react/24/solid';
import { VirtualizedComboBox } from './VirtualizedComboBox';
import { IDropDownItem } from '../baseComponents/DropDown';
import { classNames } from '../../v2/util';

export const EmailSelector = ({
  totalList,
  selectedList,
  removeEmailFromList,
  addEmailToList,
  inline,
}: {
  totalList?: string[];
  selectedList: string[];
  removeEmailFromList: (email: string) => void;
  addEmailToList: (email: string) => void;
  inline?: boolean;
}) => {
  return (
    <div className="flex flex-col gap-y-2">
      <div className={classNames(inline ? 'flex-row gap-x-7' : 'flex-col gap-y-2', 'flex')}>
        <div className="flex flex-row gap-x-2 items-center">
          <EnvelopeIcon className="h-4 w-4" />
          <h1 className="text-sm font-semibold">Emails</h1>
        </div>
        <div className="flex">
          <VirtualizedComboBox
            placeholder="Select an email"
            comboBoxData={
              totalList
                ?.slice()
                .sort((a, b) => a.localeCompare(b))
                ?.filter((uEmail) => !selectedList.includes(uEmail))
                .map((email, idx) => {
                  return { title: email, id: idx, name: email };
                }) ?? []
            }
            setSelectedItem={(selectedItem?: IDropDownItem) => {
              selectedItem && addEmailToList(selectedItem.name);
            }}
          />
        </div>
      </div>
      <div className="flex w-full flex-row flex-wrap gap-x-2 gap-y-2">
        {selectedList.map((curEmail, idx) => {
          return (
            <div
              key={idx}
              className={classNames('group relative flex select-none items-center rounded-md bg-blueberry px-2 py-2 text-milk', inline ? 'order-2' : 'order-1')}
            >
              {/*X Icon on top right that deltes the email */}
              <XCircleIcon
                className="absolute -top-1.5 -right-1.5 hidden h-5 w-5 cursor-pointer text-red-500 duration-300 group-hover:block"
                onClick={() => removeEmailFromList(curEmail)}
              />
              <h1 className="text-xs">{curEmail}</h1>
            </div>
          );
        })}
      </div>
    </div>
  );
};
