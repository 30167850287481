import { Dispatch, SetStateAction, useState, useEffect, useRef } from 'react';
import Tippy from '@tippyjs/react';
import AddAnnotationModal from './AddAnnotationModal';
import DeleteAnnotationModal from './DeleteAnnotationModal';
import { useClickOutside } from '../../../hooks/ClickOutside';
import Popup from '../../../../baseComponents/Popup';
import { IAnnotation } from '../../../hooks/ChartHook';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline';
import moment from 'moment';

const AnnotationsSettingsMenu = ({
  x,
  y,
  value,
  range,
  addModalOpen,
  addAnnotation,
  setAddModalOpen,
  setEditModalOpen,
  setDeleteModalOpen,
  setSettingsMenuOpen,
  editModalOpen,
  deleteModalOpen,
  editAnnotation,
  deleteAnnotation,
  annotations,
  currentAnnotation,
  setCurrentAnnotation,
}: {
  x: number;
  y: number;
  value: number;
  range: number[];
  addModalOpen: boolean;
  addAnnotation: (title: string, date: Date, description: string) => void;
  setAddModalOpen: Dispatch<SetStateAction<boolean>>;
  setEditModalOpen: Dispatch<SetStateAction<boolean>>;
  setDeleteModalOpen: Dispatch<SetStateAction<boolean>>;
  setSettingsMenuOpen: Dispatch<SetStateAction<boolean>>;
  editModalOpen: boolean;
  deleteModalOpen: boolean;
  editAnnotation: (title: string, date: Date, description: string) => void;
  deleteAnnotation: () => void;
  annotations: IAnnotation[] | undefined;
  currentAnnotation: IAnnotation | undefined;
  setCurrentAnnotation: Dispatch<SetStateAction<IAnnotation | undefined>>;
}) => {
  const filterAnnotations = (annotations: IAnnotation[] | undefined, value: number | undefined) => {
    if (!annotations || !Number.isInteger(value)) return;
    return annotations.filter((annotation) => annotation.value === value);
  };
  const [page, setPage] = useState(0);
  const [filteredAnnotations, setFilteredAnnotations] = useState<IAnnotation[] | undefined>(filterAnnotations(annotations, value));

  useEffect(() => {
    setFilteredAnnotations(filterAnnotations(annotations, value));
  }, [annotations, value]);

  const menuItems = [
    {
      name: 'Add new note',
      onClick: () => {
        setCurrentAnnotation(undefined);
        setAddModalOpen(true);
      },
    },
    {
      name: 'Edit note',
      onClick: () => {
        setCurrentAnnotation(filteredAnnotations![page]);
        setEditModalOpen(true);
      },
    },
    {
      name: 'Delete note',
      onClick: () => {
        setCurrentAnnotation(filteredAnnotations![page]);
        setDeleteModalOpen(true);
      },
    },
  ];
  const menuRef = useRef<HTMLDivElement | null>(null);
  const tippyRef = useRef<HTMLDivElement | null>(null);
  const closeAll = () => {
    editModalOpen && setEditModalOpen(false);
    deleteModalOpen && setDeleteModalOpen(false);
    addModalOpen && setAddModalOpen(false);
    setSettingsMenuOpen(false);
  };
  const closeCurrent = () => {
    if (editModalOpen) {
      setEditModalOpen(false);
    } else if (deleteModalOpen) {
      setDeleteModalOpen(false);
    } else if (addModalOpen) {
      setAddModalOpen(false);
    } else {
      setSettingsMenuOpen(false);
    }
  };
  useClickOutside([menuRef, tippyRef], closeAll);
  const editOrAddModalOpen = editModalOpen || addModalOpen;
  return (
    <Tippy
      appendTo={document.body}
      visible={editModalOpen || addModalOpen || deleteModalOpen}
      interactive={true}
      placement="top-start"
      onClickOutside={() => {
        closeCurrent();
      }}
      render={(attrs) => (
        <div ref={tippyRef} {...attrs}>
          {editOrAddModalOpen && (
            <AddAnnotationModal
              mode={editModalOpen ? 'Edit' : 'Add'}
              x={x}
              y={y}
              setModalOpen={editModalOpen ? setEditModalOpen : setAddModalOpen}
              editAnnotation={editModalOpen ? editAnnotation : undefined}
              addAnnotation={editModalOpen ? undefined : addAnnotation}
              annotation={currentAnnotation}
              closeAll={closeAll}
              startDate={editModalOpen ? undefined : range[value]}
            />
          )}
          {deleteModalOpen && (
            <DeleteAnnotationModal x={x} y={y} setDeleteModalOpen={setDeleteModalOpen} deleteAnnotation={deleteAnnotation} closeAll={closeAll} />
          )}
        </div>
      )}
    >
      <div>
        <Popup popupRef={menuRef} inlineStyles={{ top: `${y}px`, left: `${x}px` }} title={`Note Settings`} width="w-64" setOpen={setSettingsMenuOpen}>
          <>
            {filteredAnnotations?.length !== 0 && <h3 className="-mt-1 ml-2 py-1 text-sm">Note: {filteredAnnotations![page]?.title}</h3>}
            {filteredAnnotations?.[page]?.feedbackIntegrationId && (
              <div className="-mt-1 ml-2 text-sm">
                <p>Date: {moment(filteredAnnotations![page].date).format('MMMM Do YYYY')}</p>
              </div>
            )}
            {menuItems.map((item, i) => {
              if (item.name !== 'Add new note' && (filteredAnnotations?.length === 0 || filteredAnnotations?.[page]?.feedbackIntegrationId)) {
                return null;
              } else {
                return (
                  <div
                    key={i}
                    onClick={item.onClick}
                    className="flex w-full select-none items-center justify-between rounded-md px-2 py-2 text-sm hover:bg-raspberry hover:fill-white hover:stroke-raspberry hover:text-white"
                  >
                    {item.name}
                  </div>
                );
              }
            })}
            {filteredAnnotations && filteredAnnotations.length > 1 ? (
              <div className="flex w-full flex-row items-center justify-between">
                <ChevronLeftIcon
                  className="h-4 w-4 cursor-pointer"
                  onClick={() => setPage((prev) => (prev === 0 ? filteredAnnotations!!.length - 1 : prev - 1))}
                />
                <ChevronRightIcon
                  className="h-4 w-4 cursor-pointer"
                  onClick={() => setPage((prev) => (prev === filteredAnnotations!!.length - 1 ? 0 : prev + 1))}
                />
              </div>
            ) : null}
          </>
        </Popup>
      </div>
    </Tippy>
  );
};

export default AnnotationsSettingsMenu;
