//@ts-nocheck
import './index.css';
import { ApolloClient, createHttpLink } from '@apollo/client';
import { cache } from './cache';
import { setContext } from '@apollo/client/link/context';
import { getAuthToken } from './v2/contexts/UserContext';

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_GRAPHQL_API,
});

const authLink = setContext(async (_, { headers }) => {
  let token = await getAuthToken();
  const timezoneOffset = new Date().getTimezoneOffset();
  return {
    headers: {
      ...headers,
      Authorization: token,
      'X-Timezone-Offset': timezoneOffset,
    },
  };
});

export const client = new ApolloClient({
  cache,
  link: authLink.concat(httpLink),
  connectToDevTools: true,
});
