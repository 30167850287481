import { RefObject, useEffect, useRef } from 'react';

// this <T> is a generic type. This means this function will return
// the type of whatever was passed in as an arguement
export const usePrevious = <T>(value: T): T | undefined => {
  const ref = useRef<T>();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
};

export const wait = function (ms = 1000) {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
};

export type ArrayElement<A> = A extends readonly (infer T)[] ? T : never;

export const getSentimentString = (sentimentScore: number) => (sentimentScore >= 0.9 ? 'Positive' : sentimentScore <= -0.9 ? 'Negative' : 'Neutral');

export const useClickOutside = (refs: RefObject<any>[], callback: () => void) => {
  useEffect(() => {
    // Runs callback when user clicks outside all of the ref objects in the array
    // ex: user clicks outside of nested menus
    const handleClickOutside = (event: any) => {
      if (!refs.some((ref) => ref.current && ref.current.contains(event.target))) {
        callback();
      }
    };
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [refs]);
};
export const setCookie = (name: string, value: string) => {
  const date = new Date();
  date.setTime(date.getTime() + 365 * 24 * 60 * 60 * 1000); // Expires in 1 year
  const expires = 'expires=' + date.toUTCString();
  document.cookie = name + '=' + value + ';' + expires + ';path=/';
};

// Function to get a cookie
export const getCookie = (name: string) => {
  const cname = name + '=';
  let decodedCookie = decodeURIComponent(document.cookie);
  let cookieArray = decodedCookie.split(';');
  for (let i = 0; i < cookieArray.length; i++) {
    let c = cookieArray[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(cname.length, c.length);
    }
  }
  return '';
};
