import moment from 'moment';
import { useEffect, useState } from 'react';
import {
  EditGroupMutationFn,
  FilterInput,
  GetPinnedGroupsQueryResult,
  GroupDataFragment,
  Group_Status,
  TogglePinGroupMutationFn,
  useGetInsightGroupsLazyQuery,
  useGetPinnedGroupsLazyQuery,
} from '../../generated/graphql';
import { useGroupHook, getGroups, sortGroups, GroupBase, writeToastMessage, SentenceEntry } from '../../v2/hooks/GroupHook';
import { getTotalPageLoadEvent } from '../../latencyTracker';

export type HomeGroupHook = ReturnType<typeof useHomeGroupHook>;

interface HomeGroupHookProps {
  teamId: number;
  orgId: number;
  teamName: string;
  orgName: string;
  pageName: string;
  ignoreDateFilters?: boolean;
  filterInput?: FilterInput;
  status?: Group_Status;
  page?: number;
  pageSize?: number;
  teamUuid?: string;
  email?: string;
  homePage?: boolean;
  sentencesTake?: number;
  preventDefaultGroupsLoad?: boolean;
}
export const useHomeGroupHook = (props: HomeGroupHookProps) => {
  const groupHook = useGroupHook(props);
  const [insightGroups, setInsightGroups] = useState<GroupBase[]>([]);
  const [initialLoadComplete, setInitialLoadComplete] = useState(false);
  const [getPinnedGroupsQuery, pinnedGroupsQuery] = useGetPinnedGroupsLazyQuery({
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    variables: {
      teamId: props.teamId,
      belongs: true,
      sentencesTake: 0,
      take: props.pageSize,
      filterInput: props.filterInput ?? {},
      status: Group_Status.Monitored,
    },
  });
  const [getInsightsGroupsQuery, insightGroupsQuery] = useGetInsightGroupsLazyQuery({
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    variables: {
      teamId: props.teamId,
      belongs: true,
      sentencesTake: 3,
      take: props.pageSize,
      filterInput: props.filterInput ?? { startDate: moment().subtract(90, 'days').startOf('day').toDate(), endDate: new Date() },
      status: Group_Status.Monitored,
    },
  });
  useEffect(() => {
    const loadGroups = async () => {
      // this clears the current groups so that the loading indicator shows
      groupHook.setGroups([]);
      setInsightGroups([]);
      const [pinnedQuery, insightsQuery] = await Promise.all([
        //@ts-ignore
        getPinnedGroupsQuery({ variables: { ...groupHook.groupsQuery.variables, sentencesTake: 0, teamId: props.teamId, skip: 0 } }),
        getInsightsGroupsQuery({
          variables: {
            ...groupHook.groupsQuery.variables,
            sentencesTake: props.sentencesTake,
            teamId: props.teamId,
            filterInput: props.filterInput ?? { startDate: moment().subtract(90, 'days').startOf('day').toDate(), endDate: new Date() },
          },
        }),
      ]);
      groupHook.setGroups([...getGroups(pinnedQuery.data?.getGroups).sort(sortGroups)]);
      setInsightGroups([...getGroups(insightsQuery.data?.getGroups)]);
    };
    loadGroups().then(() => {
      setInitialLoadComplete(true);
      const event = getTotalPageLoadEvent({ view: 'home' });
      window.dispatchEvent(event);
      return;
    });
  }, [props.teamId, props.orgId]);

  return {
    insightGroups,
    isLoading: !pinnedGroupsQuery.called || pinnedGroupsQuery.loading || !insightGroupsQuery.called || insightGroupsQuery.loading,
    loadingPins: pinnedGroupsQuery.loading,
    ...groupHook,
    addSentence: async (groupId: number, sentence: SentenceEntry, cb: () => void) => {
      // if it's not an insight group, just add the sentence
      if (groupHook.groups.find((group) => group.id === groupId)) {
        await groupHook.addSentence(groupId, sentence, cb);
      } else {
        await groupHook.addSentence(groupId, sentence, cb, insightGroups, (groups) => setInsightGroups(groups));
      }
    },
    deleteSentence: async (sentenceId: number, groupId: number, cb: () => void) => {
      // if it's not an insight group, just add the sentence
      if (groupHook.groups.find((group) => group.id === groupId)) {
        await groupHook.deleteSentence(sentenceId, groupId, cb);
      } else {
        await groupHook.deleteSentence(sentenceId, groupId, cb, insightGroups, (groups) => setInsightGroups(groups));
      }
    },
    loadMore: async (startIndex: number, pageSize: number) => {
      //@ts-ignore
      const pinnedQuery = await getPinnedGroupsQuery({ variables: { ...groupHook.groupsQuery.variables, take: pageSize, skip: startIndex } });
      groupHook.setGroups((prev) => [...(prev ?? []), ...getGroups(pinnedQuery?.data?.getGroups).sort(sortGroups)]);
    },

    togglePinGroup: (groupId: number, cb?: () => void) =>
      togglePinGroupHome(
        groupId,
        props.teamId,
        groupHook.setGroups,
        groupHook.currentGroup,
        groupHook.setCurrentGroup,
        pinnedGroupsQuery,
        groupHook.togglePinGroupMutation,
        groupHook.groups,
        cb
      ),
    discardGroup: (groupId: number, cb?: () => void) =>
      discardHomeGroup(
        groupId,
        props.teamId,
        groupHook.groups,
        insightGroupsQuery.data?.getGroups,
        groupHook.setGroups,
        setInsightGroups,
        groupHook.editGroup,
        cb
      ),
    initialLoadComplete,
  };
};

/**
 *  Todo both togglePinGroupHome and discardHomeGroup need to reference 'groups' instead of the 'GetPinnedGroupsQueryResult' object.
 */
const togglePinGroupHome = (
  groupId: number,
  teamId: number,
  setPinnedGroups: (groups: GroupBase[]) => void,
  currentGroup: GroupBase | undefined,
  setCurrentGroup: (group: GroupBase) => void,
  getPinnedGroups: GetPinnedGroupsQueryResult,
  togglePinGroupMutation: TogglePinGroupMutationFn,
  pinnedGroups: GroupBase[],
  cb?: () => void
) => {
  togglePinGroupMutation({
    variables: { teamId, groupId },
    onCompleted: async (data) => {
      if (currentGroup) {
        /** remove the group from the home page pinned groups */
        const pinned = !currentGroup.pinnedByUser;
        setCurrentGroup({ ...currentGroup, pinnedByUser: pinned });
        const groups = await getPinnedGroups.refetch({ ...getPinnedGroups.variables, skip: 0, take: pinnedGroups.length });
        writeToastMessage(`Group ${pinned ? 'Pinned' : 'Unpinned'}`);
        setPinnedGroups([...getGroups(groups.data.getGroups).sort(sortGroups)]);
      }
      cb?.();
    },
  });
};

/** If you archive a group on the home page this will look at both the insights sections _and_ the pinned sections and remove the group from both.
 *
 * This reflects how the backend will treat the data. If you reload the page the group wouldn't appear in either section.
 */
const discardHomeGroup = async (
  groupId: number,
  teamId: number,
  pinnedGroups: GroupBase[],
  insightGroups: GroupDataFragment[] | undefined,
  setPinnedGroups: (groups: GroupBase[]) => void,
  setInsightGroups: (groups: GroupBase[]) => void,
  editGroup: EditGroupMutationFn,
  cb?: () => void
) => {
  await editGroup({
    variables: { groupId, teamId: teamId, input: { status: Group_Status.Archived }, filterInput: {} },
  });
  const newPinned = pinnedGroups?.filter((group) => group.id !== groupId);
  setPinnedGroups(newPinned);
  const insights = insightGroups?.filter((group) => group.id !== groupId);
  setInsightGroups([...getGroups(insights).sort(sortGroups)]);
  writeToastMessage('Group discarded');
  cb && cb();
};
