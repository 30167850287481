import { useContext, useEffect, useState } from 'react';
import SearchInput from '../baseComponents/SearchInput';
import { SearchGroupDualLists } from '../../v2/components/SearchGroupDualLists';
import { SmallWhiteSpinner } from '../../v2/components/SmallWhiteSpinner';
import { useExploreHook } from '../../v2/hooks/ExploreHook';
import { classNames } from '../../v2/util';

import { Sentence, Group_Status } from '../../generated/graphql';
import { FilterHook, useFilterHook } from '../hooks/FilterHook';
import { FilterManager } from '../sections/Filters/FilterManager';
import { DenominatorOptions, denominatorOptionsDropdown } from '../sections/Filters/FiltersTypes';
import LoadingSpinner from '../../v2/components/LoadingSpinner';
import { ExplorePageView, useExploreGroupHook, ExploreGroupHook } from '../hooks/ExploreGroupHook';
import { PageWrapper } from './PageWrapper';
import GroupPreview from '../components/GroupPreview';
import { useInView } from 'react-intersection-observer';
import Tippy from '@tippyjs/react';
import AnnouncementModal from '../components/Modals/AnnouncementModal';
import { useValidTeamAppContext } from '../../v2/contexts/AppContext';
import { GroupDeleter } from '../components/GroupDeleter';
import { GroupFull, getGroupPageUrl, writeToastError } from '../../v2/hooks/GroupHook';
import { useLocation, useParams } from 'react-router-dom';

import { TaxonomyToggle } from '../components/TaxonomyToggle';
import { TaxonomyChildrenToggle } from '../components/TaxonomyChildrenToggle';

import { TaxonomyView } from '../components/TaxonomyView';
import UserContext from '../../v2/contexts/UserContext';
import { Routes, Route, useNavigate } from 'react-router-dom';
import GroupPage from './GroupPage';
import { AppRoutes } from '../../Routes';
export enum GroupUIType {
  Card,
  Modal,
  PreviewPage,
}
interface ExplorePageRouterProps {
  pageName: string;
}
interface ExplorePageProps {
  pageName: string;
  groupHook: ExploreGroupHook;
  filterHook: FilterHook;
}

const pageSize = 10;

export const ExplorePageRouter = ({ pageName }: ExplorePageRouterProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { curTeamId: teamId, currentTeam, curOrgId: orgId, currentOrg } = useValidTeamAppContext();

  const urlSearchParams = new URLSearchParams(location.search);
  const expanded = urlSearchParams.get('expanded');

  const filterHook = useFilterHook({ teamId, orgId, disableGroupFilterFromUrl: expanded === "drawer"});
  const groupHook = useExploreGroupHook({
    teamId,
    orgId,
    teamName: currentTeam?.name,
    orgName: currentOrg?.name,
    pageName,
    ignoreDateFilters: false,
    filterInput: filterHook.filters,
    status: Group_Status.Monitored,
    selectedDenominator: denominatorOptionsDropdown.find((d) => d.name === DenominatorOptions.DateFilteredFeedback),
    pageSize: pageSize,
    sentencesTake: 0,
  });

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(location.search);
    const filtersFromUrl = JSON.parse(decodeURIComponent(urlSearchParams.get('group') ?? '{}'));
    const expanded = urlSearchParams.get('expanded');
    if (expanded === 'drawer' && filtersFromUrl.groupFilter && filtersFromUrl.groupFilter[0] && filtersFromUrl.groupFilter[0].group[0].id) {
      const groupId = filtersFromUrl.groupFilter[0].group[0].id;
      const filtersToForward = { ...filtersFromUrl, groupFilter: undefined };
      navigate(getGroupPageUrl(teamId, orgId, groupId, AppRoutes.v3FullPath.explore, [`&group=${JSON.stringify(filtersToForward)}`]), {
        replace: true,
      });
    }
  }, []);

  useEffect(() => {
    // we need to unset group when we navigate away from the subroute via any method
    if (!location.pathname.includes('group')) {
      groupHook.setCurrentGroup(undefined);
    }
  }, [location.pathname, groupHook.currentGroup]);

  return (
    <div>
      <ExplorePage pageName={pageName} groupHook={groupHook} filterHook={filterHook} />
      <Routes>
        <Route path="/group/:groupId" element={<GroupPage groupHook={groupHook} currentFilters={filterHook.filters} />} />
      </Routes>
    </div>
  );
};
export const ExplorePage = ({ pageName, groupHook, filterHook }: ExplorePageProps) => {
  const { curTeamId: teamId, currentTeam, curOrgId: orgId, currentOrg } = useValidTeamAppContext();
  const { user } = useContext(UserContext);
  const {
    groups,
    tags,
    loadAllSentences,
    getPotentialChildren,
    childrenToAssign,
    loadingStatuses,
    deleteSentence,
    addSentence,
    findSimilarSentences,
    loadMore,
    currentGroup,
    setCurrentGroup,
    addGroup,
    similarSentences,
    replaceOrAddToSearchGroups,
    updateProgress,
    handleCreateTag,
    loadMoreOrphans,
    handleRemoveTag,
    handleTagGroup,
    discardGroup,
    groupExport,
    updateOwner,
    removeOwner,
    copyGroupLink,
    expandAll,
    contractAll,
    editTitle,
    hasParents,
    togglePinGroup,
    totalAmountOfGroups,
    filteredAmountOfGroups,
    loadMoreSentences,
    clearSimilarSentences,
    refetchSimilarSentences,
    updateCurrentGroupFilter,
    getChildCandidates,
    getChildren,
    children,
    childCandidates,
    assignChild,
    assignChildren,
    view,
    setView,
    taxonomy,
    openParent,
    deleteChild,
    getCurrentGroup,
    removeChildFromParent,
    loadListView,
    loadTaxonomyView,
  } = groupHook;

  const exploreHook = useExploreHook({ teamId, orgId, filterInput: filterHook.filters, addGroup: (group) => addGroup(group) });
  const [paginating, setPaginating] = useState(false);
  const { ref, inView } = useInView({ threshold: 0 });
  const [announcementId, setAnnouncementId] = useState<number | undefined>();
  const [curGroupIdToDelete, setCurGroupIdToDelete] = useState<number | null>(null);

  useEffect(() => {
    if (inView) {
      // load more groups
      loadMoreGroups();
    }
  }, [inView]);

  useEffect(() => {
    if (view === ExplorePageView.Flat) {
      loadListView(teamId, filterHook.filters);
    } else {
      loadTaxonomyView(teamId, filterHook.filters);
    }
  }, [view, teamId, filterHook.filters]);

  //This helps us reset the search component without ref / calling child props.
  const [resetSearchKey, setResetSearchKey] = useState<number>(1);
  const onBuildGroup = () => exploreHook.buildGroup(() => setResetSearchKey((prev) => prev + 1));

  //This is modified by SearchInput - used to change the text on the search button.
  const [isExactMatchQuery, setIsExactMatchQuery] = useState<boolean>(false);
  const handleIsExactMatch = (newValue: boolean) => setIsExactMatchQuery(newValue);

  const onConfirmSearch = (query?: string) => {
    if (query == null) {
      return;
    } else if (query.trim() === '') {
      writeToastError('Please enter a search query.');
      return;
    } else {
      !exploreHook.querySearchInfo.loading &&
        exploreHook.executeSearchQuery(query, isExactMatchQuery ? () => setResetSearchKey((prev) => prev + 1) : undefined);
    }
  };

  const loadMoreGroups = async () => {
    setPaginating(true);
    await loadMore(groups.length, pageSize);
    setPaginating(false);
  };
  const navigate = useNavigate();

  return (
    <>
      {announcementId != null && (
        <AnnouncementModal
          groupId={announcementId}
          modalOpen={true}
          setModalOpen={() => setAnnouncementId(undefined)}
          teamId={teamId}
          orgId={orgId}
          orgName={currentOrg?.name}
          filterInput={filterHook.filters}
        />
      )}

      {curGroupIdToDelete !== null ? (
        <GroupDeleter
          groupToDelete={curGroupIdToDelete}
          closeCallback={() => {
            setCurGroupIdToDelete(null);
          }}
          deleteGroup={discardGroup}
          deleteCallback={() => {
            setCurrentGroup(undefined);
            navigate('/dashboard/explore', { replace: true });
          }}
          loadingDelete={loadingStatuses.discardingGroup}
        />
      ) : null}

      <PageWrapper title={'Explore'} styles={`${window.location.pathname.includes('/group/') && 'hidden'}`}>
        <div>
          <div className={`flex flex-col items-center justify-center border-b `}>
            <div className="flex w-4/5 flex-col gap-y-1">
              <h1 className="text-blueberry">Create a group of feedback talking about...</h1>
              <SearchSection
                key={resetSearchKey}
                onConfirmSearch={onConfirmSearch}
                setExactMatchWarning={handleIsExactMatch}
                loading={exploreHook.querySearchInfo.loading}
              />
            </div>
            <div className="mt-2 flex w-10/12 flex-col items-center gap-y-4">
              {!exploreHook.currentSearchId && !exploreHook.querySearchInfo.loading ? (
                <div className="my-4 text-blueberry">
                  <h1>Execute a search query to start creating a group.</h1>
                </div>
              ) : exploreHook.querySearchInfo.loading ? (
                <div className="my-2 flex items-center justify-center gap-x-4 text-blueberry">
                  <div className="h-10 w-10 animate-spin rounded-full border-t-2 border-blueberry"></div>
                  <h1 className="text-xl">{isExactMatchQuery ? 'Building Group' : 'Generating search preview...'}</h1>
                </div>
              ) : (
                <>
                  <h1 className="font-semibold text-blueberry">Teach Unwrap what belongs in your group</h1>
                  <SearchGroupDualLists
                    leftList={exploreHook.searchLists.inSearchList as Sentence[]}
                    rightList={exploreHook.searchLists.notInSearchList as Sentence[]}
                    switchToLeft={exploreHook.searchLists.switchSentenceToInSearch}
                    switchToRight={exploreHook.searchLists.switchSentenceToNotInSearch}
                  />
                  <button
                    id="accept-search-create-group"
                    className={classNames(
                      exploreHook.loadingStatuses.buildingGroup ? 'disabled cursor-default bg-blueberry-lighter' : 'bg-blueberry',
                      'mt-4 flex flex-row items-center justify-center gap-x-2 rounded-full py-3 px-6 text-sm text-milk duration-200 hover:bg-blueberry-lighter'
                    )}
                    onClick={() => !exploreHook.loadingStatuses.buildingGroup && onBuildGroup()}
                  >
                    {exploreHook.loadingStatuses.buildingGroup ? <SmallWhiteSpinner /> : undefined}
                    {exploreHook.loadingStatuses.buildingGroup ? 'Building group from this search...' : 'Preview results look good! Create the group.'}
                  </button>
                </>
              )}
            </div>
          </div>
        </div>

        <div className="flex cursor-default flex-col text-blueberry">
          <div className="grid grid-cols-3 items-center">
            <h1 className="text-3xl font-semibold col-start-1 justify-self-start">Groups</h1>
            <div className="col-start-2 justify-self-center">
              <TaxonomyToggle view={view} setView={(view: ExplorePageView) => setView(view)} />
            </div>
            <div className="col-start-3" />
          </div>

          <Tippy
            content={
              <p>
                This indicates the currently <b>filtered</b> group count compared to the total group count.
              </p>
            }
          >
            <div className={classNames('flex w-fit', filteredAmountOfGroups && totalAmountOfGroups ? 'opacity-100' : 'opacity-0')}>
              <p className="text-xs">
                (Showing {filteredAmountOfGroups} of {totalAmountOfGroups} total)
              </p>
            </div>
          </Tippy>
        </div>
        <div className="mt-4 flex flex-col items-center justify-center">
          <div className="w-4/5 border-b border-gray-300 pb-3 flex flex-row justify-between items-start">
            <FilterManager filterHook={filterHook} pageName={pageName} dataTypeToFilter={'group'} queryStringAppliesToGroupTitle={true} />
            {(user?.isUnwrapper || hasParents) && view === ExplorePageView.Taxonomy ? (
              <div className="justify-end">
                <TaxonomyChildrenToggle expandAll={expandAll} contractAll={contractAll} />
              </div>
            ) : null}
          </div>
          <div className={`mt-2 ${view === ExplorePageView.Flat ? 'flex' : ''} w-4/5 flex-col items-center gap-y-4 pt-2`}>
            {view === ExplorePageView.Flat ? (
              <>
                <div className="mt-2 flex w-full flex-col items-center gap-y-4 pt-2">
                  {!paginating && loadingStatuses.fetchingGroups ? (
                    <LoadingSpinner />
                  ) : (
                    <>
                      {groups.length === 0 ? (
                        <div className="my-8 text-blueberry">
                          <h1>No search groups match the selected filters.</h1>
                        </div>
                      ) : (
                        <>
                          {groups.map((group, index) => {
                            const show = index === groups.length - 2;
                            return (
                              <GroupPreview
                                refProp={show ? ref : undefined}
                                isCondensedView={false}
                                key={group.id}
                                group={group as GroupFull}
                                filterInput={filterHook.filters}
                                page={pageName}
                                discardSearchGroup={() => {
                                  setCurGroupIdToDelete(group.id);
                                }}
                                togglePinGroup={togglePinGroup}
                                replaceOrAddToSearchGroups={replaceOrAddToSearchGroups}
                                getAllGroupSentences={() => loadAllSentences(group.id)}
                                loadingAllSentences={loadingStatuses.loadingAllSentences}
                                updateProgress={updateProgress}
                                deleteSentence={deleteSentence}
                                addSentence={addSentence}
                                groupExport={groupExport}
                                copyLink={(groupId) => copyGroupLink(groupId, filterHook.filters)}
                                onSeeMoreClick={() => {
                                  if (currentGroup?.id !== group.id) {
                                    setCurrentGroup(group);
                                    findSimilarSentences('', 0, pageSize);
                                  }
                                  navigate(getGroupPageUrl(teamId, orgId, group.id, AppRoutes.v3FullPath.explore));
                                }}
                                editTitle={editTitle}
                                openAnnouncementModal={() => setAnnouncementId(group.id)}
                              />
                            );
                          })}
                          {loadingStatuses.fetchingMoreGroups && (
                            <div>
                              <div className="h-8 w-8 animate-spin rounded-full border-t-2 border-b-2 border-blueberry"></div>
                            </div>
                          )}
                        </>
                      )}
                    </>
                  )}
                </div>
              </>
            ) : view === ExplorePageView.Taxonomy ? (
              <TaxonomyView
                replaceOrAddToSearchGroups={replaceOrAddToSearchGroups}
                children={children}
                removeChildFromParent={removeChildFromParent}
                getChildren={getChildren}
                deleteChild={deleteChild}
                pageName={pageName}
                currentGroup={currentGroup}
                tags={tags}
                filterInput={filterHook.filters}
                discardSearchGroup={(groupId) => {
                  setCurGroupIdToDelete(groupId);
                }}
                togglePinGroup={togglePinGroup}
                loadingStatuses={loadingStatuses}
                deleteSentence={deleteSentence}
                addSentence={addSentence}
                findSimilarSentences={findSimilarSentences}
                similarSentences={similarSentences}
                handleCreateTag={handleCreateTag}
                handleRemoveTag={handleRemoveTag}
                handleTagGroup={handleTagGroup}
                groupExport={groupExport}
                updateOwner={updateOwner}
                removeOwner={removeOwner}
                copyGroupLink={(groupId) => copyGroupLink(groupId, filterHook.filters)}
                editTitle={editTitle}
                setCurrentGroup={setCurrentGroup}
                clearSimilarSentences={clearSimilarSentences}
                loadMoreSentences={loadMoreSentences}
                refetchSimilarSentences={refetchSimilarSentences}
                openAnnouncementModal={(id) => setAnnouncementId(id)}
                updateCurrentGroupFilter={updateCurrentGroupFilter}
                getChildCandidates={getChildCandidates}
                childCandidates={childCandidates}
                assignChild={assignChild}
                assignChildren={assignChildren}
                taxonomy={taxonomy}
                openParent={openParent}
                getCurrentGroup={getCurrentGroup}
                discardGroup={discardGroup}
                loadMoreOrphans={loadMoreOrphans}
                updateProgress={updateProgress}
                getChildrenToAssign={getPotentialChildren}
                childrenToAssign={childrenToAssign}
              />
            ) : (
              <>
                <LoadingSpinner />
              </>
            )}
          </div>
        </div>
      </PageWrapper>
    </>
  );
};

const SearchSection = ({
  onConfirmSearch,
  setExactMatchWarning,
  loading,
}: {
  onConfirmSearch: (query?: string) => void;
  setExactMatchWarning: (warning: boolean) => void;
  loading: boolean;
}) => {
  const [searchInput, setSearchInput] = useState('');

  const setQueryString = (query?: string) => {
    if (isExactMatch(query)) setExactMatchWarning(true);
    else setExactMatchWarning(false);
    setSearchInput(query ?? '');
  };

  return (
    <div className="flex flex-row items-center justify-between gap-x-2">
      <div className="w-full">
        <SearchInput
          id="group-create-search"
          placeholder={'e.g: login not working'}
          setQueryString={setQueryString}
          queryString={searchInput}
          onSearch={onConfirmSearch}
        />
      </div>
      <button
        id="group-create-search-button"
        className={classNames(
          loading ? 'bg-blueberry-lighter' : 'bg-blueberry',
          'flex w-1/5 flex-row items-center justify-center gap-x-2 rounded-full py-4 px-6 text-sm text-milk duration-200 hover:bg-blueberry-lighter'
        )}
        onClick={() => !loading && onConfirmSearch(searchInput)}
      >
        {loading ? <SmallWhiteSpinner /> : undefined}
        {loading ? 'Searching...' : isExactMatch(searchInput) ? 'Exact Search' : 'Search'}
      </button>
    </div>
  );
};

const isExactMatch = (query?: string) => {
  // Check for the query string being at least three characters so we don't accidentally trigger this on a ""
  if (!query || query.length < 3) return false;
  if (query[0] === '"' && query[query.length - 1] === '"') return true;
};
