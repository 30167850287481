import { Icon } from '@iconify/react';
import Tippy from '@tippyjs/react';
import 'tippy.js/themes/light.css';
import { useValidTeamAppContext } from '../../v2/contexts/AppContext';
import { logEvent, Events } from '../../v2/AnalyticsUtil';
export const TaxonomyChildrenToggle = ({ expandAll, contractAll }: { expandAll: () => void; contractAll: () => void }) => {
  const { currentTeam, currentOrg } = useValidTeamAppContext();
  return (
    <div className="w-full flex justify-start text-blueberry ">
      <div className="h-9 flex flex-row justify-center gap-x-2 items-center">
        <Tippy theme="light" delay={200} content="Expand all folders">
          <div
            className="flex h-9 w-9 cursor-pointer justify-center items-center gap-x-1 rounded-full bg-silver text-blueberry duration-200 hover:bg-blueberry hover:text-milk"
            onClick={() => {
              expandAll();
              logEvent(Events.TaxonomyToggled, {
                View_ID: currentTeam.id,
                View_Name: currentTeam.name,
                Org_ID: currentOrg.id,
                Org_Name: currentOrg.name,
                Toggle_Type: 'Open All',
              });
            }}
          >
            <Icon icon="mdi:expand-all" width="20" height="20" className="cursor-pointer mt-0.5" />
          </div>
        </Tippy>
        <Tippy theme="light" delay={200} content="Collapse all folders">
          <div
            className="flex h-10 w-10 justify-center  cursor-pointer items-center gap-x-1 rounded-full bg-silver p-1 text-blueberry duration-200 hover:bg-blueberry hover:text-milk"
            onClick={() => {
              contractAll();
              logEvent(Events.TaxonomyToggled, {
                View_ID: currentTeam.id,
                View_Name: currentTeam.name,
                Org_ID: currentOrg.id,
                Org_Name: currentOrg.name,
                Toggle_Type: 'Close All',
              });
            }}
          >
            <Icon icon="mdi:collapse-all" width="20" height="20" className="cursor-pointer" />
          </div>
        </Tippy>
      </div>
    </div>
  );
};
