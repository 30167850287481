import { Auth } from 'aws-amplify';
import { createContext, useState } from 'react';
import { setUser } from '../../applicationTelemetry';

export interface IUser {
  firstName: string;
  lastName: string;
  email: string;
  isUnwrapper: boolean;
}

/**
 * this will refresh any expired token and return an active access token
 * @returns
 */
export const getAuthToken = async () => {
  try {
    return (await Auth.currentSession()).getAccessToken().getJwtToken();
  } catch {}
  return undefined;
};

/**
 * this will refresh any expired token and return an active identity token
 * @returns
 */
export const getIdToken = async () => {
  try {
    return (await Auth.currentSession()).getIdToken().getJwtToken();
  } catch {}
  return undefined;
};

const UserContext = createContext<{ user: IUser | undefined; setCurrentUser: () => Promise<void> }>({
  user: undefined,
  setCurrentUser: () => Promise.resolve(),
});

interface IUserContextProvider {
  children: JSX.Element;
  userInitial: IUser | undefined;
}

export const UserContextProvider = ({ children, userInitial }: IUserContextProvider) => {
  const [user, setUser] = useState<IUser | undefined>(userInitial);

  /**
   * This function just needs to be called anytime the auth context changes i.e. on sign in, sign out
   */
  const setCurrentUser = async () => {
    setUser(await getCurrentUser());
  };

  return <UserContext.Provider value={{ user, setCurrentUser }}>{children}</UserContext.Provider>;
};

export const UserContextConsumer = UserContext.Consumer;

export default UserContext;

/**
 * Currently this is just tied to cognito / amplify auth. It's behind a generic function and if we need to pull in more user data
 * we can do that from our system. We can either wrap up in a context to get more user data or store cached user data in local storage
 * or something like that.
 *
 * But for now we don't need any user data other than name and email. Every thing else can be fetched on the backend from the access token.
 * @returns
 */
const getCurrentUser = async (): Promise<IUser | undefined> => {
  let user: undefined | any = undefined;
  try {
    user = (await Auth.currentSession())?.getIdToken()?.payload;
    // this is in UTC time
    if (user) {
      const toReturn: IUser = {
        firstName: user.given_name,
        lastName: user.family_name,
        email: user.email,
        isUnwrapper: user.email.includes('@unwrap.ai'),
      };
      setUser(toReturn);
      return toReturn;
    }
  } catch (err: any) {}
  return undefined;
};
