import { GroupBase, GroupFull, getGroupPageUrl } from '../../v2/hooks/GroupHook';
import InsightCard from '../components/InsightCard';
import { FilterHook, useFilterHook } from '../hooks/FilterHook';
import { useContext, useState, useEffect } from 'react';
import { ChevronDoubleLeftIcon, ChevronDoubleRightIcon } from '@heroicons/react/24/outline';
import { HomeGroupHook, useHomeGroupHook } from '../hooks/HomeGroupHook';
import GroupPreview from '../components/GroupPreview';
import { NavLink, Routes, Route, useLocation, useNavigate } from 'react-router-dom';
import LoadingSpinner from '../../v2/components/LoadingSpinner';
import { AppRoutes } from '../../Routes';
import { useInView } from 'react-intersection-observer';
import AnnouncementModal from '../components/Modals/AnnouncementModal';
import AppContext, { useValidTeamAppContext } from '../../v2/contexts/AppContext';
import TooltipIcon from '../components/Modals/TooltipIcon';
import Button, { ButtonVariant } from '../baseComponents/Button';
import { GroupDeleter } from '../components/GroupDeleter';
import { classNames } from '../../v2/util';
import { Group_Status, useDiscoverOrgsQuery } from '../../generated/graphql';
import { Events, logEvent } from '../../v2/AnalyticsUtil';
import { OrgDiscoveryList } from '../components/OrgDiscovery';
import GroupPage from './GroupPage';
import { PageWrapper } from './PageWrapper';

interface HomePageProps {
  pageName: string;
  groupHook: HomeGroupHook;
  filterHook: FilterHook;
}
interface HomePageRouterProps {
  pageName: string;
}

export const HomePageRouter = ({ pageName }: HomePageRouterProps) => {
  const pageSize = 9;
  const location = useLocation();
  const { curTeamId: teamId, currentTeam, curOrgId: orgId, currentOrg } = useValidTeamAppContext();
  const filterHook = useFilterHook({ teamId, orgId });
  const groupHook = useHomeGroupHook({
    teamId,
    orgId,
    teamName: currentTeam?.name,
    orgName: currentOrg?.name,
    pageName,
    filterInput: filterHook.filters,
    homePage: true,
    pageSize: pageSize,
    sentencesTake: 10,
    status: Group_Status.Monitored,
    preventDefaultGroupsLoad: true,
  });

  useEffect(() => {
    // we need to unset group when we navigate away from the subroute via any method
    if (!location.pathname.includes('group')) {
      groupHook.setCurrentGroup(undefined);
    }
  }, [location.pathname]);

  return (
    <div>
      <HomePage pageName={pageName} groupHook={groupHook} filterHook={filterHook} />
      <Routes>
        <Route path="/group/:groupId" element={<GroupPage groupHook={groupHook} />} />
      </Routes>
    </div>
  );
};

const HomePageWithNoData = () => {
  const { currentOrg, curOrgId: orgId } = useContext(AppContext);
  const discoverOrgsRes = useDiscoverOrgsQuery({});

  return (
    <div className="flex flex-col items-center text-blueberry h-[90vh]">
      <h1 className="text-4xl font-bold mb-10">Welcome to Unwrap!</h1>
      {discoverOrgsRes.loading ? (
        <div className="flex h-[90vh] w-full flex-col items-center">
          <LoadingSpinner />
        </div>
      ) : (discoverOrgsRes.data?.discoverOrgs ?? []).length > 0 ? (
        <OrgDiscoveryList discoverableOrgs={discoverOrgsRes.data?.discoverOrgs ?? []} />
      ) : (
        <div className="flex flex-col h-full justify-center items-center gap-y-6 text-center">
          <div className="flex flex-row gap-x-1 items-baseline">
            {orgId ? (
              <>
                <h1 className="text-2xl font-semibold">
                  Your organization <b>{currentOrg?.name}</b> currently has no views
                </h1>
                <TooltipIcon
                  tooltipContent={'Views are the way you store, organize, explore and analyze your data. Start by creating one from your Organizations page.'}
                />
              </>
            ) : (
              <h1 className="text-2xl font-semibold">
                You are not part of any organizations. <br />
                You can either ask for an invite from your company's organization admin, or contact Unwrap to create a new organization.
              </h1>
            )}
          </div>
          {orgId ? (
            <NavLink to={`${AppRoutes.v3FullPath.organizations}/${currentOrg?.id}`}>
              <Button variant={ButtonVariant.Primary} text={'Create a view from your Organization page +'} />
            </NavLink>
          ) : null}
        </div>
      )}
    </div>
  );
};

const HomePage = ({ pageName, groupHook, filterHook }: HomePageProps) => {
  const { curTeamId: teamId, curOrgId: orgId, orgsHaveLoaded } = useContext(AppContext);
  // by default, load team's home page if any values are set
  if (orgId && teamId) return <HomePageWithTeam pageName={pageName} groupHook={groupHook} filterHook={filterHook} />;
  // user switched teams, no orgs are in url or local storage, show spinner
  if (!orgsHaveLoaded)
    return (
      <div className="flex h-[90vh] w-full flex-col items-center">
        <LoadingSpinner />
      </div>
    );
  // brand new user case, no orgs are in url or local storage or belong to user, so show no data
  return <HomePageWithNoData />;
};

const HomePageWithTeam = ({ pageName, groupHook, filterHook }: HomePageProps) => {
  const { curTeamId: teamId, curOrgId: orgId, currentTeam, currentOrg } = useValidTeamAppContext();
  const navigate = useNavigate();
  const pageSize = 9;

  const { ref, inView } = useInView({ rootMargin: '100px', threshold: 0 });
  const [paginating, setPaginating] = useState(false);
  const [announcementId, setAnnouncementId] = useState<number | undefined>();
  const [curGroupIdToDelete, setCurGroupIdToDelete] = useState<number | null>(null);

  const loadMoreGroups = async () => {
    setPaginating(true);
    await groupHook.loadMore(groupHook.groups.length, pageSize);
    setPaginating(false);
  };

  useEffect(() => {
    if (inView && !paginating) {
      // load more groups
      loadMoreGroups();
    }
  }, [inView]);
  if (!paginating && groupHook.isLoading && groupHook.groups.length === 0) {
    return (
      <div className="flex h-[90vh] w-full flex-col items-center">
        <LoadingSpinner />
      </div>
    );
  }
  const openGroup = (group: GroupBase) => {
    groupHook.setCurrentGroup(group as GroupFull);
    navigate(getGroupPageUrl(teamId, orgId, group.id, AppRoutes.v3FullPath.home));
  };
  return (
    <PageWrapper title="" styles={`${window.location.pathname.includes('/group/') && 'hidden'}`}>
      <>
        {announcementId != null && (
          <AnnouncementModal
            groupId={announcementId}
            modalOpen={true}
            setModalOpen={() => setAnnouncementId(undefined)}
            teamId={teamId}
            orgId={orgId}
            orgName={currentOrg?.name}
            filterInput={filterHook.filters}
          />
        )}

        {curGroupIdToDelete !== null ? (
          <GroupDeleter
            groupToDelete={curGroupIdToDelete}
            closeCallback={() => setCurGroupIdToDelete(null)}
            deleteGroup={groupHook.discardGroup}
            deleteCallback={() => {
              groupHook.setCurrentGroup(undefined);
              navigate('/dashboard/home', { replace: true });
            }}
            loadingDelete={groupHook.loadingStatuses.discardingGroup}
          />
        ) : null}

        {groupHook.insightGroups.length > 0 ? (
          <div>
            <h1 className="text-2xl font-semibold text-blueberry mb-1">Top Insights This Week</h1>
            <div id="carouselRootElement" className="slide carousel carousel-dark relative" data-bs-ride="carousel">
              <div className="carousel-inner relative w-full overflow-hidden h-full ">
                {groupHook.insightGroups.map((group, index) => {
                  return (
                    <div className={`carousel-item ${index === 0 ? 'active' : ''} relative float-left w-full`} key={index} data-bs-interval="10000">
                      <InsightCard
                        group={group as GroupFull}
                        onClick={() => {
                          openGroup(group);
                          logEvent(Events.InsightCardClicked, {
                            View_ID: currentTeam.id,
                            View_Name: currentTeam?.name,
                            Org_ID: currentOrg.id,
                            Org_Name: currentOrg?.name,
                            Page: pageName,
                            Depth: index,
                          });
                        }}
                      />
                    </div>
                  );
                })}
              </div>

              {groupHook.insightGroups.length > 1 && (
                <>
                  <div className="flex flex-row justify-center pt-3">
                    <div className="group px-5">
                      <button
                        className="flex items-center justify-center h-9 p-1 rounded-full  text-blueberry hover:text-milk bg-silver duration-200 hover:bg-blueberry shadow pr-1.5"
                        data-bs-target="#carouselRootElement"
                        data-bs-slide="prev"
                      >
                        <ChevronDoubleLeftIcon className="inline-block h-6 w-6 stroke-2" />
                        {/* <span className="carousel-control-prev-icon inline-block bg-no-repeat group-hover:text-white" aria-hidden="true"></span> */}
                      </button>
                    </div>

                    <div className="flex flex-col items-center justify-center">
                      <div className="carousel-indicators flex justify-center ">
                        {groupHook.insightGroups.map((_, index) => {
                          return (
                            <button
                              onClick={() =>
                                logEvent(Events.CarouselButtonClicked, {
                                  View_ID: currentTeam.id,
                                  View_Name: currentTeam?.name,
                                  Org_ID: currentOrg.id,
                                  Org_Name: currentOrg?.name,
                                  Page: pageName,
                                  Depth: index,
                                })
                              }
                              key={index}
                              type="button"
                              data-bs-target="#carouselRootElement"
                              data-bs-slide-to={index}
                              className={index === 0 ? 'active' : ''}
                              aria-current="true"
                              aria-label={`Slide ${index}`}
                            ></button>
                          );
                        })}
                      </div>
                    </div>
                    <div className="group px-5">
                      <button
                        className="flex items-center justify-center h-9 p-1 rounded-full  text-blueberry hover:text-milk bg-silver duration-200 hover:bg-blueberry shadow pl-1.5"
                        data-bs-target="#carouselRootElement"
                        data-bs-slide="next"
                      >
                        <ChevronDoubleRightIcon className="inline-block h-6 w-6 stroke-2 " />
                        {/* <span className="carousel-control-prev-icon inline-block bg-no-repeat group-hover:text-white" aria-hidden="true"></span> */}
                      </button>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        ) : null}
        {groupHook.groups.length > 0 ? (
          <>
            <div className="pt-2">
              <h1 className="text-2xl font-semibold text-blueberry">Pinned By Me</h1>
            </div>
            <ul className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
              {groupHook.groups.map((group) => (
                <GroupPreview
                  key={group.id}
                  isCondensedView={true}
                  group={group as GroupFull}
                  filterInput={filterHook.filters}
                  page={pageName}
                  discardSearchGroup={() => {
                    setCurGroupIdToDelete(group.id);
                  }}
                  togglePinGroup={groupHook.togglePinGroup}
                  replaceOrAddToSearchGroups={groupHook.replaceOrAddToSearchGroups}
                  getAllGroupSentences={() => groupHook.loadAllSentences(group.id)}
                  loadingAllSentences={groupHook.loadingStatuses.loadingAllSentences}
                  updateProgress={groupHook.updateProgress}
                  deleteSentence={groupHook.deleteSentence}
                  addSentence={groupHook.addSentence}
                  groupExport={groupHook.groupExport}
                  copyLink={(groupId) => groupHook.copyGroupLink(groupId, filterHook.filters)}
                  onSeeMoreClick={() => {
                    if (groupHook.currentGroup?.id !== group.id) {
                      groupHook.setCurrentGroup(group);
                      groupHook.refetchSimilarSentences('', 0, 10);
                    }
                    navigate(getGroupPageUrl(teamId, orgId, group.id, AppRoutes.v3FullPath.home));
                  }}
                  editTitle={groupHook.editTitle}
                  openAnnouncementModal={() => setAnnouncementId(group.id)}
                />
              ))}
            </ul>

            <div className="flex justify-center items-center" ref={ref}>
              {groupHook.loadingPins && <div className="h-8 w-8 animate-spin rounded-full border-t-2 border-b-2 border-blueberry"></div>}
            </div>
          </>
        ) : !groupHook.initialLoadComplete || groupHook.isLoading ? (
          <LoadingSpinner />
        ) : (
          <div className="grid grid-cols-1 mt-5">
            <div className={classNames('col-span-1 flex flex-col justify-center gap-y-5', groupHook.insightGroups.length > 0 ? 'items-start' : 'items-center')}>
              <h1 className="text-2xl font-semibold text-blueberry ">Pinned Groups</h1>
              <h3 className="font-light">We’ve already created a few groups based on your data. Visit the Explore page to view them.</h3>
              <div className="flex flex-row justify-center">
                <button
                  className={
                    'hover:bg-peachring-lighter flex flex-row items-center justify-center rounded-full bg-peachring py-4 px-10 text-sm font-semibold text-milk duration-200 2xl:text-xl'
                  }
                  onClick={() => navigate(AppRoutes.v3FullPath.explore)}
                >
                  Create & Pin New Groups
                </button>
              </div>
            </div>
            <div
              className={classNames('col-span-1 mt-2 flex h-full max-h-min xl:mt-10', groupHook.insightGroups.length > 0 ? 'justify-start' : 'justify-center')}
            >
              <img src={`${window.location.origin}/HomePageCTA.jpg`} className="w-1/2" />
            </div>
          </div>
        )}
      </>
    </PageWrapper>
  );
};

export default HomePage;
