import { useContext, useEffect, useState } from 'react';
import { Group_Status, Group_Type, TaxonomyElement, useAmountOfGroupsLazyQuery } from '../../generated/graphql';
import { GroupHookProps, useGroupHook, getGroups, sortGroups, getTaxonomy } from '../../v2/hooks/GroupHook';
import AppContext from '../../v2/contexts/AppContext';
import { getTotalPageLoadEvent } from '../../latencyTracker';
export enum ExplorePageView {
  Flat = 'Flat',
  Taxonomy = 'Taxonomy',
}

export type ExploreGroupHook = ReturnType<typeof useExploreGroupHook>;

export const useExploreGroupHook = (props: GroupHookProps) => {
  const { app } = useContext(AppContext);
  const groupHook = useGroupHook(props);
  const [view, setView] = useState<ExplorePageView>(ExplorePageView.Taxonomy);
  const [getAmountOfGroups, amountOfGroups] = useAmountOfGroupsLazyQuery({
    fetchPolicy: 'no-cache',
    variables: { teamId: props.teamId, status: Group_Status.Monitored },
  });

  useEffect(() => {
    if (!app?.currentUuid) {
      getAmountOfGroups();
    }
  }, []);
  const teamHasParents = () => {
    return groupHook.taxonomy && Array.from(groupHook.taxonomy?.values()).some((item) => item.parentId !== null && item.parentId !== undefined);
  };
  useEffect(() => {
    if (props.teamUuid != null) {
      if (!groupHook.previewSearchesQuery.loading && !groupHook.groupsQuery.loading) {
        const searches = groupHook.previewSearchesQuery?.data?.getPreviewPageGroups.filter((q) => q.status === Group_Status.Monitored);
        const clusters = groupHook.listQuery.data?.teamGroups?.groups.filter((g) => g.type === Group_Type.Cluster);
        groupHook.setGroups([...getGroups(searches), ...getGroups(clusters)]);
      }
    }
  }, [groupHook.previewSearchesQuery.loading, groupHook.groupsQuery.loading]);
  useEffect(() => {
    if (groupHook.listQuery.data && (groupHook.groupsQuery.called ? groupHook.groupsQuery.data : true)) {
      // if the skip variable is set we're loading more - check explicitly that skip is greater than zero so we know if we need to reset the groups
      if (groupHook.groupsQuery.called && groupHook.groupsQuery.variables?.skip && groupHook.groupsQuery.variables.skip > 0) {
        let seen = new Set();
        const newGroups = [...groupHook.groups, ...getGroups(groupHook.groupsQuery.data?.getGroups)];
        const filtered = newGroups.filter((group) => {
          return seen.has(group.id) ? false : seen.add(group.id);
        });
        groupHook.setGroups(filtered);
      } else {
        const groups = groupHook.listQuery.data.teamGroups?.groups ?? [];
        groupHook.setGroups([...getGroups(groups).sort(sortGroups)]);
      }
      const event = getTotalPageLoadEvent({ view: 'list' });
      window.dispatchEvent(event);
    }
  }, [groupHook.listQuery, groupHook.groupsQuery]);

  useEffect(() => {
    if (groupHook.taxonomyQuery.data && (groupHook.orphansQuery.called ? groupHook.orphansQuery.data : true)) {
      // these are orphaned groups that are not in the taxonomy
      // the first set we fetch from the unified query, each subsequent fetch is from a separate orphan query
      // NOTE: initial orphan query fetch inside taxonomy query will contain stale data, so we need to:
      // 1.) on first fetch, add initial orphans to the taxonomy
      // 2.) on subsequent fetches, ignore contents of original fetch, rely on current state of taxonomy to be source of truth
      // if we don't do this, it looks like that first orphan fetch 'resets'
      const initialOrphansInTaxonomy = !!groupHook.taxonomy.get(groupHook.taxonomyQuery.data.teamGroups?.groups?.[0]?.id ?? -1);
      const groups = [
        ...(initialOrphansInTaxonomy ? [] : groupHook.taxonomyQuery.data.teamGroups?.groups ?? []),
        // edge case: switching teams, orphansQuery could contain groups from the previous team
        // check for last team used and only add orphans if they match
        ...(props.teamId === groupHook.orphansQuery.variables?.teamId ? groupHook.orphansQuery?.data?.getGroups ?? [] : []),
      ];

      const updatedTaxonomy = new Map([
        ...Array.from(getTaxonomy(groups, groupHook.taxonomy).entries()),
        ...Array.from(getTaxonomy(groupHook.taxonomyQuery.data.teamGroups?.taxonomyGroups as TaxonomyElement[])),
        ...Array.from(groupHook.taxonomy ? groupHook.taxonomy.entries() : []),
      ]);
      groupHook.setTaxonomy(updatedTaxonomy);
      const event = getTotalPageLoadEvent({ view: 'taxonomy' });
      window.dispatchEvent(event);
    }
  }, [groupHook.taxonomyQuery, groupHook.orphansQuery, view]);

  return {
    ...groupHook,
    totalAmountOfGroups: amountOfGroups.data?.amountOfGroups.amount ?? 0,
    view,
    setView,
    hasParents: teamHasParents(),
    loadMore: (startIndex: number, pageSize: number) =>
      //@ts-ignore
      groupHook.getGroupsQuery({ variables: { ...groupHook.groupsQuery.variables, take: pageSize, skip: startIndex, filterInput: props.filterInput } }),
    loadMoreOrphans: async (startIndex: number, pageSize: number) => {
      //@ts-ignore
      groupHook.getOrphans({ variables: { ...groupHook.orphansQuery.variables, take: pageSize, skip: startIndex, filterInput: props.filterInput } });
    },
  };
};
