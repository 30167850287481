import { useState, useContext } from 'react';
import logoUnwrap from '../../../assets/unwrap_logo_MAIN.png';
//@ts-ignore
import { useNavigate } from 'react-router-dom';

import { Auth } from 'aws-amplify';
import UserContext from '../../../v2/contexts/UserContext';
import { logError } from '../../../applicationTelemetry';
import { V3_DASHBOARD } from '../../../Routes';
import Button, { ButtonVariant } from '../../baseComponents/Button';

export default function ForgotPasswordPage() {
  const [emailInput, setEmailInput] = useState('');
  const [passwordInput, setPasswordInput] = useState<undefined | string>(undefined);
  const [confirmPasswordInput, setConfirmPasswordInput] = useState<undefined | string>(undefined);
  const [resetCodeInput, setResetCodeInput] = useState<undefined | string>(undefined);

  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState<string | undefined>(undefined);
  const [successMsg, setSuccessMsg] = useState<string | undefined>(undefined);
  const [codeSent, setCodeSent] = useState<boolean>(false);
  const { setCurrentUser } = useContext(UserContext);

  let navigate = useNavigate();

  const sendResetCode = async () => {
    setLoading(true);
    try {
      await Auth.forgotPassword(emailInput.toLowerCase());
      setCodeSent(true);
      setErrorMsg(undefined);
      setSuccessMsg('Check your email for a password reset code.');
    } catch (err: any) {
      console.error(err.message, err, err.code);
      logError(err);
      setSuccessMsg(undefined);
      setErrorMsg(err.message);
    }
    setLoading(false);
  };

  const resetPassword = async () => {
    setLoading(true);
    try {
      if (!resetCodeInput) {
        throw new Error('Enter Reset Code');
      }
      if (!passwordInput) {
        throw new Error('Enter Password');
      }
      const response = await Auth.forgotPasswordSubmit(emailInput.toLowerCase(), resetCodeInput, passwordInput);
      await Auth.signIn(emailInput.toLowerCase(), passwordInput);
      await setCurrentUser();
      setLoading(false);
      navigate(`/${V3_DASHBOARD}`);
    } catch (err: any) {
      console.error(err.message, err, err.code);
      setSuccessMsg(undefined);
      setErrorMsg(err.message);
      setLoading(false);
    }
  };

  return (
    <div className="flex min-h-screen bg-milk font-sofiapro">
      <div className="flex flex-1 flex-col justify-center">
        <div className="mx-auto w-full max-w-sm lg:w-96">
          <div>
            <img className="h-12 w-auto" src={logoUnwrap} alt="Workflow" />
          </div>

          <div className="mt-8">
            <div className="mt-6">
              <div className="mb-6 flex flex-col">
                {codeSent && emailInput ? (
                  <div>
                    <h1 className="text-xl font-bold">Reset your password</h1>
                    <p>
                      We sent your reset code to <b>{emailInput}</b>. Enter the reset code along with your new password.
                    </p>
                  </div>
                ) : (
                  <div>
                    <h1 className="text-xl font-bold">Forgot your password?</h1>
                    <p>Enter your email to receive a reset code.</p>
                  </div>
                )}
              </div>
              {codeSent && emailInput ? (
                <form
                  className="space-y-6"
                  onSubmit={(e) => {
                    e.preventDefault();
                    setErrorMsg(undefined);
                    if (passwordInput === confirmPasswordInput) {
                      resetPassword();
                    } else {
                      setErrorMsg("Passwords don't match");
                    }
                  }}
                >
                  <div>
                    <label htmlFor="code" className="block text-sm font-medium text-gray-700">
                      Reset Code
                    </label>
                    <div className="mt-1">
                      <input
                        id="code"
                        name="code"
                        type="code"
                        autoComplete="code"
                        required
                        className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                        onChange={(e) => setResetCodeInput(e.target.value)}
                      />
                    </div>
                  </div>

                  <div>
                    <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                      Password
                    </label>
                    <div className="mt-1">
                      <input
                        id="password"
                        name="password"
                        type="password"
                        required
                        className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                        onChange={(e) => setPasswordInput(e.target.value)}
                      />
                    </div>
                  </div>

                  <div>
                    <label htmlFor="confirmpassword" className="block text-sm font-medium text-gray-700">
                      Confirm Password
                    </label>
                    <div className="mt-1">
                      <input
                        id="confirmpassword"
                        name="confirmpassword"
                        type="password"
                        required
                        className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                        onChange={(e) => setConfirmPasswordInput(e.target.value)}
                      />
                    </div>
                  </div>

                  {errorMsg && (
                    <div className="mt-4">
                      <h1 className="text-sm font-medium text-red-600">{errorMsg}</h1>
                    </div>
                  )}

                  {successMsg && (
                    <div className="mt-4">
                      <h1 className="text-sm font-medium text-green-600">{successMsg}</h1>
                    </div>
                  )}

                  <div className="flex flex-col gap-y-4">
                    <Button variant={ButtonVariant.Primary} text={'Confirm New Password'} submit loadingConfirm={loading} expandWidth />
                  </div>
                </form>
              ) : (
                <form
                  className="space-y-6"
                  onSubmit={(e) => {
                    e.preventDefault();
                    e.currentTarget.reset();
                    sendResetCode();
                  }}
                >
                  <div>
                    <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                      Email address
                    </label>
                    <div className="mt-1">
                      <input
                        id="email"
                        name="email"
                        type="email"
                        autoComplete="email"
                        required
                        className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                        onChange={(e) => setEmailInput(e.target.value)}
                      />
                    </div>
                  </div>

                  {errorMsg && (
                    <div className="mt-4">
                      <h1 className="text-sm font-medium text-red-600">{errorMsg}</h1>
                    </div>
                  )}

                  {successMsg && (
                    <div className="mt-4">
                      <h1 className="text-sm font-medium text-green-600">{successMsg}</h1>
                    </div>
                  )}

                  <div className="flex flex-col gap-y-4">
                    <Button variant={ButtonVariant.Primary} text={'Get reset link'} submit loadingConfirm={loading} expandWidth />
                    <Button
                      variant={ButtonVariant.Secondary}
                      text={'Go to Login page'}
                      expandWidth
                      onClick={() => {
                        navigate('/login');
                      }}
                      preventDefault
                    />
                  </div>
                </form>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
