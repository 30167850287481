import { EllipsisVerticalIcon, PlusIcon } from '@heroicons/react/24/outline';
import { useState } from 'react';
import {
  Org_Domains,
  useAddDomainToOrgMutation,
  GetOrganizationQuery,
  useUpdateDomainDiscoveryMutation,
  GetOrganizationDocument,
  useRemoveDomainFromOrgMutation,
} from '../../generated/graphql';
import toast from 'react-hot-toast';
import Button, { ButtonSize, ButtonVariant } from '../baseComponents/Button';
import { useValidTeamAppContext } from '../../v2/contexts/AppContext';
import TooltipIcon from './Modals/TooltipIcon';
import SettingsMenu, { MenuAlign } from '../baseComponents/SettingsMenu';
import Toggle from './Toggle';
import NewTextModal from './Modals/NewTextModal';

export default function OrgDomainsSection({ userIsAdmin, orgData }: { userIsAdmin?: boolean; orgData?: GetOrganizationQuery['getOrganization'] }) {
  const [newDomainModalOpen, setNewDomainModalOpen] = useState(false);
  const [loadingCreatingDomain, setLoadingCreatingDomain] = useState(false);
  const { curOrgId } = useValidTeamAppContext();

  //Maybe this can go inside the org hook
  const [createDomainMutation] = useAddDomainToOrgMutation({
    refetchQueries: [{ query: GetOrganizationDocument, variables: { orgId: curOrgId } }],
  });
  const [updateDomainMutation] = useUpdateDomainDiscoveryMutation({
    refetchQueries: [{ query: GetOrganizationDocument, variables: { orgId: curOrgId } }],
  });
  const [removeDomainMutation] = useRemoveDomainFromOrgMutation({
    refetchQueries: [{ query: GetOrganizationDocument, variables: { orgId: curOrgId } }],
  });

  const onAddDomain = async (domainName: string) => {
    if (orgData?.orgDomains?.some((orgDomain) => orgDomain.domain === domainName)) return toast.error('Domain already associated.');
    setLoadingCreatingDomain(true);
    await createDomainMutation({
      variables: {
        allowDiscovery: true,
        domain: domainName,
        orgId: curOrgId,
      },
      onCompleted(data) {
        setNewDomainModalOpen(false);
        toast.success('Domain successfully added.');
      },
      onError(error) {
        toast.error('Failed to add domain.');
        setNewDomainModalOpen(false);
      },
    });
    setLoadingCreatingDomain(false);
  };

  const onRemoveDomain = async (orgDomainId: number) => {
    await removeDomainMutation({
      variables: {
        orgDomainId,
        orgId: curOrgId,
      },
      onCompleted(data) {
        toast.success('Domain successfully removed.');
      },
      onError(error) {
        toast.error('Failed to remove domain.');
      },
    });
  };

  const toggleDiscovery = (orgDomainId: number, newVal: boolean) => {
    updateDomainMutation({
      variables: {
        allowDiscovery: newVal,
        orgDomainId,
        orgId: curOrgId,
      },
      onCompleted(data) {
        toast.success('Domain discoverability updated.');
      },
      onError(error) {
        toast.error('Failed to update domain discovery.');
      },
    });
  };

  return (
    <div className="flex flex-col gap-y-2">
      {newDomainModalOpen && (
        <NewTextModal
          modalOpen={newDomainModalOpen}
          callbackModal={() => setNewDomainModalOpen(false)}
          loadingConfirm={loadingCreatingDomain}
          confirmButton={(domainName: string) => {
            onAddDomain(domainName);
          }}
          title="Add Domain"
          subtitle={
            <>
              Add a domain related to your organization (eg: <b>unwrap.ai</b>, <b>google.com</b>)
            </>
          }
          textLabel="Domain"
        />
      )}
      <div className="flex flex-row items-center justify-between">
        <div className="flex flex-row gap-x-2 items-baseline">
          <h1 className="text-2xl font-semibold">Domains</h1>
          <div className="">
            <TooltipIcon
              tooltipContent={`Administrators can associate domains with your organization, and let users with emails with these domains find your organization to request an invite, provided they haven't yet been invited.`}
            />
          </div>
        </div>
        {userIsAdmin && (
          <Button
            id="add-org-domain-button"
            variant={ButtonVariant.Tertiary}
            size={ButtonSize.Small}
            onClick={() => {
              setNewDomainModalOpen(true);
            }}
            icon={<PlusIcon className="h-3 w-3" />}
            iconPosition="right"
            text="Add Domain"
          ></Button>
        )}
      </div>
      <div className="grid grid-cols-3 gap-3">
        {orgData?.orgDomains.length === 0 ? (
          <p>No domains associated.</p>
        ) : (
          orgData?.orgDomains?.map((orgDomain) => {
            return (
              <OrgDomainCard
                key={orgDomain.id}
                orgDomain={orgDomain}
                userIsAdmin={userIsAdmin}
                deleteDomain={onRemoveDomain}
                toggleDiscovery={toggleDiscovery}
              />
            );
          })
        )}
      </div>
    </div>
  );
}

const OrgDomainCard = ({
  orgDomain,
  deleteDomain,
  userIsAdmin,
  toggleDiscovery,
}: {
  orgDomain: Org_Domains;
  deleteDomain?: (orgDomainId: number) => void;
  userIsAdmin?: boolean;
  toggleDiscovery?: (orgDomainId: number, newVal: boolean) => void;
}) => {
  const [discoveryEnabled, setDiscoveryEnabled] = useState(orgDomain.allowDiscovery ?? false);
  const handleToggleDiscovery = () => {
    toggleDiscovery?.(orgDomain.id, !discoveryEnabled);
    setDiscoveryEnabled(!discoveryEnabled);
  };
  const settingsItems = [{ id: 0, group: 'remove', name: 'Remove Domain', onClick: () => deleteDomain?.(orgDomain.id) }];
  return (
    <div className="flex flex-row flex-wrap items-center justify-between bg-silver p-4 rounded-md">
      <div className="flex flex-row gap-x-2 items-baseline">
        <h1 className="text-xl font-semibold">{orgDomain.domain}</h1>
      </div>
      {userIsAdmin && (
        <div className="flex flex-row gap-x-2 items-center justify-center" onClick={(e) => userIsAdmin && e.stopPropagation()}>
          <div className="flex flex-row gap-x-2 items-center">
            <div className="flex flex-row gap-x-1 items-center">
              <p className="text-sm">Discoverable</p>
              <TooltipIcon tooltipContent="Lets any user with this domain in their email address find your Organization and request an invite. Any request must be approved by an organization admin before access is granted." />
            </div>
            <Toggle initialState={discoveryEnabled} onSwitch={handleToggleDiscovery} />
          </div>
          <SettingsMenu settings={settingsItems} align={MenuAlign.RIGHT}>
            <EllipsisVerticalIcon className="h-4 w-4" />
          </SettingsMenu>
        </div>
      )}
    </div>
  );
};
