import { Auth } from 'aws-amplify';

interface IAuthConfig {
  issuer: string;
  userPoolId: string;
  userPoolWebClientId: string;
  tenantId?: string;
  cognitoDomain?: string;
  identityProviderId?: string;
  emailDomain?: string;
}

export const STANDARD_ISSUER = 'standard';
export const ISSUER_KEY = 'issuer';
export const TENANT_KEY = 'tenant';

export const AuthConfigs: IAuthConfig[] = [
  {
    /** This is the standard config used by non-okta users */
    issuer: STANDARD_ISSUER,
    userPoolId: process.env.REACT_APP_USER_POOL_ID!,
    userPoolWebClientId: process.env.REACT_APP_USER_POOL_CLIENT_ID!,
    cognitoDomain: process.env.REACT_APP_COGNITO_DOMAIN,
  },
  {
    /** Unwrap account okta config for okta org dev-43763926 member - these are different depending on stage */
    issuer: process.env.REACT_APP_OKTA_ISSUER ?? '',
    userPoolId: process.env.REACT_APP_OKTA_USER_POOL_ID ?? '',
    userPoolWebClientId: process.env.REACT_APP_OKTA_USER_POOL_CLIENT_ID ?? '',
    cognitoDomain: process.env.REACT_APP_OKTA_COGNITO_DOMAIN ?? '',
    identityProviderId: process.env.REACT_APP_OKTA_IDENTITY_PROVIDER_ID ?? '',
    tenantId: 'unwrap-test-member',
  },
  {
    /** Unwrap Prod account okta config for lyft org */
    emailDomain: 'lyft.com',
    issuer: 'lyft.okta.com',
    userPoolId: 'us-east-2_MKxSOINwA',
    userPoolWebClientId: '3vl5d5c9oihiecujip9r143fhr',
    cognitoDomain: 'lyft.auth.prod.unwrap.ai',
    identityProviderId: 'produnwrapservicOktaAuth05E9DE10',
    tenantId: 'lyft-driver-member',
  },
  {
    /** Unwrap Prod account okta config for lyft org */
    emailDomain: 'github.com',
    issuer: 'github.okta.com',
    userPoolId: 'us-east-2_wqlttQ7Ho',
    userPoolWebClientId: 'al612hetb9802i58trettddsi',
    cognitoDomain: 'github.auth.prod.unwrap.ai',
    identityProviderId: 'produnwrapservicOktaAuthD2576B8B',
    tenantId: 'github-member',
  },
];

export const organizationManagedByOkta = (email: string): string | undefined => {
  const emailDomain = email.split('@')[1];
  const authConfig = AuthConfigs.find((authConfig) => authConfig.emailDomain?.toLowerCase() === emailDomain?.toLowerCase());
  return authConfig?.issuer;
};

/**
 * Given an issuer configure the amplify Auth module to create auth tokens against.
 *
 * This caches the issuer and tenant in local storage so that when the user comes back to the tool we can refetch their credentials from
 * the same source.
 *
 * */
export const configureAmplifyAuth = (issuer: string, tenant?: string): IAuthConfig => {
  issuer = issuer.replace('https://', '');
  let authConfig = AuthConfigs.find((authConfig) => authConfig.issuer === issuer && authConfig.tenantId === tenant);
  if (!authConfig) {
    throw new Error(`No auth config found for issuer: ${issuer} and tenant: ${tenant}`);
  }
  if (issuer !== STANDARD_ISSUER) {
    if (!tenant) {
      throw new Error('Configuration error: tenant must be provided when using a non-standard issuer.');
    }
    // if we're using a non standard issuer, store it in local storage so we can load it in next time.
    localStorage.setItem(ISSUER_KEY, issuer);
    localStorage.setItem(TENANT_KEY, tenant ?? '');
  } else {
    // if we're requesting the standard issuer check if we have a stored non-standard issuer and use that instead.
    // this means a person has used the non standard issuer before and we should use that instead.
    const storedIssuer = localStorage.getItem(ISSUER_KEY);
    const storedTenant = localStorage.getItem(TENANT_KEY);
    if (storedIssuer && storedTenant) {
      // check for the stored config and if it exists use it. Otherwise use the standard config.
      const storedAuthConfig = AuthConfigs.find((authConfig) => authConfig.issuer === storedIssuer && authConfig.tenantId === storedTenant);
      if (storedAuthConfig) {
        issuer = storedIssuer;
        authConfig = storedAuthConfig;
      }
    }
  }

  const { cognitoDomain, userPoolId, userPoolWebClientId } = authConfig;
  const config = {
    region: process.env.REACT_APP_REGION,
    userPoolId,
    userPoolWebClientId,
    oauth: {
      domain: cognitoDomain,
      scope: ['openid', 'email', 'profile', 'aws.cognito.signin.user.admin'],
      redirectSignIn: `${window.location.origin}${issuer === STANDARD_ISSUER ? '/auth/google' : '/auth/okta'}`,
      redirectSignOut: window.location.origin,
      responseType: 'code',
    },
  };
  Auth.configure(config);
  return authConfig;
};
