import { ArrowTopRightOnSquareIcon, NewspaperIcon, PlusCircleIcon, TrashIcon } from '@heroicons/react/24/outline';
import { useState } from 'react';
import Button, { ButtonVariant } from '../baseComponents/Button';
import sourcesMap, { ISource } from '../../v2/other/sourcesMap';
import AdjustableLoadingIcon from '../../baseComponents/AdjustableLoadingIcon';
import { Entry, Sentence, SentenceEntry } from '../../v2/hooks/GroupHook';
import 'tippy.js/themes/light.css';
import Tippy from '@tippyjs/react';
import { alphabeticalSort, localDateString } from '../../v2/util';
import { SegmentLabel } from './SegmentLabel';
import { FullConversation } from './FullConversation';
import he from 'he';
interface FeedbackSentenceClusterProps {
  id: number;
  sentences: SentenceEntry[];
  entry: Entry;
  deleteSentence?: (groupId: number, sentenceId: number, cb: () => void) => Promise<void>;
  addSentence?: (groupdId: number, sentence: SentenceEntry, cb: () => void) => Promise<void>;
  loading?: boolean;
  sentenceHasBeenAdded?: boolean;
}

const GroupSentenceEntry = ({ id, sentences: mappedSentences, entry, deleteSentence, addSentence }: FeedbackSentenceClusterProps): JSX.Element => {
  const [expanded, setExpanded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const hasConversation = entry && entry.hasConversation;
  const renderFullText = (entry: Entry | undefined) => {
    const firstMappedSentence = mappedSentences[0];
    if (!entry || !firstMappedSentence) {
      return null;
    } else if (!expanded) {
      return <SentenceWithSurroundingText sentence={firstMappedSentence} entry={entry} />;
    } else if (hasConversation) {
      return (
        <div className="w-full">
          <FullConversation includeTitle entry={entry} title={entry.title ?? ''} />
          <GroupSentenceEntryFooter entry={entry} />
        </div>
      );
    } else {
      return <FullEntry sentences={mappedSentences} entry={entry} />;
    }
  };
  return (
    <div className="group-sentence flex flex-row items-center justify-between gap-x-2 border-b-2 border-gray-200">
      <div
        className="col-span-9 flex min-h-[48px] w-full flex-row items-center justify-start rounded py-0.5 px-0.5 duration-100 transition hover:cursor-pointer hover:bg-gray-100"
        onClick={(e) => {
          const selection = document.getSelection();
          if (selection?.toString()?.length && selection.toString.length >= 1) return;
          setExpanded(!expanded);
        }}
      >
        <div className="flex w-full flex-row items-center justify-between gap-x-2 overflow-hidden">
          <div
            id="entry-sentence-text"
            className={`${(addSentence || deleteSentence) && (!expanded || (expanded && !hasConversation)) ? 'w-11/12' : 'w-full flex-1'} `}
          >
            {renderFullText(entry)}
            {!expanded && entry?.date ? <i className="text-xs font-semibold"> - {localDateString(new Date(entry.date), 'MMM Do')}</i> : null}
          </div>
          {addSentence && (!expanded || (expanded && !hasConversation)) ? (
            <div className={`flex items-center justify-center overflow-hidden group-sentence-hover:opacity-100`}>
              {isLoading ? (
                <div data-tesid="sentence-add-loading">
                  <AdjustableLoadingIcon width={6} height={6} />
                </div>
              ) : (
                <Tippy theme="light" delay={200} content={<p className="text-center">Add this sentence to the group.</p>}>
                  <PlusCircleIcon
                    className="h-6 w-6"
                    id="add-group-sentence"
                    onClick={async (e) => {
                      const sentence: SentenceEntry = mappedSentences[0];
                      e.stopPropagation();
                      setIsLoading(true);
                      await addSentence(id, sentence, () => setIsLoading(false));
                    }}
                  />
                </Tippy>
              )}
            </div>
          ) : null}
          {entry?.source_permalink && (!expanded || (expanded && !hasConversation)) ? (
            <div className="opacity-0 duration-100 transition group-sentence-hover:opacity-100">
              <Tippy
                theme="light"
                delay={200}
                content={
                  <span className="text-center">
                    <p className="text-center">View feedback source</p>
                  </span>
                }
              >
                <a href={entry.source_permalink} target="_blank" rel="noreferrer" onClick={(e) => e.stopPropagation()}>
                  <ArrowTopRightOnSquareIcon className="h-5 w-5 cursor-pointer duration-100 hover:stroke-2" />
                </a>
              </Tippy>
            </div>
          ) : null}
          {entry.distillateText && (
            <Tippy
              theme="light"
              content={
                <div className="text-blueberry">
                  <p className="text-lg mb-2 font-semibold">Summary:</p>
                  <p>{entry.distillateText}</p>
                </div>
              }
            >
              <div className="justify-center items-center flex flex-row opacity-0 duration-100 transition group-sentence-hover:opacity-100">
                <NewspaperIcon className="h-5 w-5 stroke-1.5" />
              </div>
            </Tippy>
          )}
          {deleteSentence && (!expanded || (expanded && !hasConversation)) ? (
            <div className="flex items-center justify-center opacity-0 duration-100 transition group-sentence-hover:opacity-100">
              {isLoading ? (
                <div data-tesid="sentence-remove-loading">
                  <AdjustableLoadingIcon width={5} height={5} />
                </div>
              ) : (
                <Tippy
                  theme="light"
                  delay={200}
                  content={
                    <span className="text-center">
                      <p className="text-center">Remove this sentence from the group.</p>
                    </span>
                  }
                >
                  <TrashIcon
                    className="h-5 w-5 duration-100 hover:stroke-2"
                    id="delete-group-sentence"
                    onClick={async (e) => {
                      e.stopPropagation();
                      setIsLoading(true);
                      for (let i = 0; i < mappedSentences.length; i++) {
                        // we should change this api to take entries
                        await deleteSentence(mappedSentences[i].id, id, () => {});
                      }
                      setIsLoading(false);
                    }}
                  />
                </Tippy>
              )}
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default GroupSentenceEntry;

interface SentenceWithSurroundingTextProps {
  sentence: Sentence;
  entry: Entry;
}

const SentenceWithSurroundingText = ({ sentence, entry }: SentenceWithSurroundingTextProps): JSX.Element => {
  let seenSentence = false;
  let preText = '';
  let postText = '';
  if (!preText && !postText) {
    entry.sentences.forEach((value) => {
      if (value.id === sentence.id) {
        seenSentence = true;
      } else if (seenSentence) {
        postText += value.text + ' ';
      } else {
        preText += value.text + ' ';
      }
    });
  }
  return (
    <>
      {preText.length > 0 && <p className="lg:text-md inline text-sm break-words">...{he.decode(preText.substring(preText.length - 60))}</p>}

      <p className="lg:text-md inline text-sm font-bold break-words">{he.decode(sentence.text ?? '')}</p>
      {preText.length > 0 && <p className="lg:text-md inline text-sm break-words">{he.decode(postText.substring(postText.length - 60))}...</p>}
    </>
  );
};

interface FullEntryProps {
  sentences: Sentence[];
  entry: Entry;
  condensed?: boolean;
}

export const FullEntry = ({ entry, sentences, condensed }: FullEntryProps): JSX.Element => {
  const titleSentence = entry.title;
  return (
    <div className="flex flex-col px-0.5 w-full text-left">
      {titleSentence && titleSentence.length > 0 && (
        <div className="flex flex-row pt-2">
          <h1 className={`inline text-lg ${entry.sentences.some((sentence) => sentence.text === entry.title) ? 'font-bold' : ''}`} key={titleSentence}>
            {he.decode(titleSentence)}
          </h1>
        </div>
      )}
      <div>
        {entry.sentences
          .filter((sent: Sentence) => sent.text !== entry.title)
          .map((sent: Sentence) => {
            if (sentences.map((s) => s.id).includes(sent.id)) {
              return (
                <h1 className="text-md inline break-words font-bold" key={sent.id}>
                  {' '}
                  {he.decode(sent.text ?? '')}
                </h1>
              );
            }
            return (
              <p className="text-md inline break-words" key={sent.id}>
                {' '}
                {he.decode(sent.text ?? '')}
              </p>
            );
          })}
      </div>
      <GroupSentenceEntryFooter entry={entry} condensed={condensed} />
    </div>
  );
};

export const GroupSentenceEntryFooter = ({ entry, condensed }: { entry: Entry; condensed?: boolean }) => {
  const source: ISource | undefined = sourcesMap[entry.source!];
  return (
    <div className={`flex flex-row items-center justify-between  gap-x-2 pt-2  ${condensed ? 'truncate	' : ''}`}>
      <div className="flex flex-row items-center justify-between  gap-x-2 ">
        {source ? <img className="inline-block m-0 h-5 w-5" src={source.logo} /> : <i className="text-sm font-semibold">{entry.source}</i>}
        {entry.submitter ? <span className={`text-sm ${condensed ? 'truncate' : ''}`}>{' - ' + entry.submitter + ' - '}</span> : null}
        {entry?.date ? <i className={`text-sm ${condensed ? 'truncate' : ''}`}> {localDateString(new Date(entry.date), 'MMM Do Y')}</i> : null}
        {!condensed && entry.source_permalink && (
          <>
            {' - '}
            <a onClick={(e) => e.stopPropagation()} href={entry.source_permalink} target="_blank" rel="noreferrer">
              <Button
                variant={ButtonVariant.IconRaw}
                expandWidth
                icon={<ArrowTopRightOnSquareIcon className="inline-block h-3.5 w-3.5 cursor-pointer duration-100 hover:scale-125" />}
              />
            </a>
          </>
        )}
      </div>
      <div className="flex flex-row gap-x-3 ">
        {!condensed && entry.segments && entry.segments.length !== 0
          ? [...entry.segments]
              .sort((a, b) => alphabeticalSort(a.groupName, b.groupName))
              .map((segment, index) => {
                if (index >= 2) return null;
                return <SegmentLabel key={index} segment={segment} />;
              })
          : null}
        <div>{entry.segments && entry.segments.length > 2 ? <SegmentLabel remainingSegments={entry.segments.slice(2)} /> : null}</div>
      </div>
    </div>
  );
};
