import { Dispatch, ReactNode, SetStateAction, useState } from 'react';
import { Dialog } from '@headlessui/react';
import { UserGroupIcon } from '@heroicons/react/24/solid';
import Modal from '../../baseComponents/Modal';
import Button, { ButtonVariant } from '../../baseComponents/Button';

//Generic modal for creating something that just requires a text input and a confirm button.

export default function NewTextModal({
  modalOpen,
  callbackModal,
  confirmButton,
  title,
  subtitle,
  textLabel,
  loadingConfirm,
}: {
  modalOpen: boolean;
  callbackModal: Dispatch<SetStateAction<boolean>>;
  confirmButton?: (inputText: string) => void;
  title: string | ReactNode;
  subtitle?: string | ReactNode;
  textLabel?: string | ReactNode;
  loadingConfirm?: boolean;
}) {
  const [inputText, setInputText] = useState<string>('');
  return (
    <Modal open={modalOpen} setOpen={callbackModal}>
      <div className="px-4">
        <div>
          <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full ">
            <UserGroupIcon className="h-8 w-8 text-blueberry" aria-hidden="true" />
          </div>
          <div className="mt-3 text-center sm:mt-5">
            <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
              {title}
            </Dialog.Title>
            <div className="mt-2">
              <p className="text-sm text-gray-500">{subtitle}</p>
            </div>
          </div>
          <div className="mt-10 flex flex-col gap-y-0">
            <h1 className="text-sm font-semibold">{textLabel}</h1>
            <input
              className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 focus:border-blueberry focus:outline-none focus:ring-blueberry sm:text-sm"
              onChange={(e) => setInputText(e.target.value)}
            />
          </div>
        </div>

        <div className="mt-8 flex  flex-row justify-between gap-x-4 text-center">
          <Button variant={ButtonVariant.Tertiary} onClick={() => callbackModal(false)} text="Cancel"></Button>
          <Button
            variant={ButtonVariant.Primary}
            text="Confirm"
            onClick={() => {
              confirmButton && confirmButton(inputText);
            }}
            loadingConfirm={loadingConfirm}
            disabled={loadingConfirm}
          ></Button>
        </div>
      </div>
    </Modal>
  );
}
