import IntegrationCard from '../components/IntegrationCard';
import { useIntegrationsQuery } from '../../generated/graphql';
import LoadingSpinner from '../../v2/components/LoadingSpinner';
import { PageWrapper } from './PageWrapper';
import { useValidTeamAppContext } from '../../v2/contexts/AppContext';
import { useEffect, useState } from 'react';
import InformationModal from '../components/Modals/InformationModal';
interface IntegrationsPageProps {
  pageName: string;
}

export interface IIntegrationRedirect {
  authToken: string;
  scraperKey: string;
  teamId: number;
  url_zendesk?: string;
  additionalObjects: { [key: string]: string };
}

export default function IntegrationsPage({ pageName }: IntegrationsPageProps) {
  const { curTeamId: teamId } = useValidTeamAppContext();
  const [successModalOpen, setSuccessModalOpen] = useState(false);
  const [redirect, setRedirect] = useState<IIntegrationRedirect | undefined>(undefined);

  const { data, loading, error } = useIntegrationsQuery({
    variables: { teamId: teamId },
  });

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const urlObject = paramsToObject(urlSearchParams.entries());
    // pull out an authToken, teamId, and key from query
    const { authToken, key: scraperKey, teamId: teamIdQuery, ...others } = urlObject;
    // we have a valid redirect if all these be true
    let redir: IIntegrationRedirect | undefined = undefined;
    if (authToken && scraperKey && teamIdQuery && Number(teamIdQuery) === teamId) {
      redir = {
        authToken,
        scraperKey,
        teamId,
        additionalObjects: others,
      };
    }
    const url_zendesk = urlSearchParams.get('url_zendesk');
    if (redir && url_zendesk) redir.url_zendesk = url_zendesk;
    setRedirect(redir);
    //clear url params
    window.history.replaceState({}, '', window.location.pathname);
  }, []);

  if (error) return <>error</>;

  return (
    <PageWrapper title={'Integrations'}>
      <InformationModal
        modalOpen={successModalOpen}
        callbackModal={() => setSuccessModalOpen(false)}
        text={'Integration connected successfully!'}
        subtext={'Your integration was created successfully! You will receive an email from Unwrap when your data is loaded.'}
      />
      {loading ? (
        <LoadingSpinner />
      ) : (
        <div className="grid grid-cols-2 gap-x-8 gap-y-8 pb-4 lg:grid-cols-3 xl:grid-cols-4">
          {data?.integrations?.map((integration, index) => (
            //@ts-ignore
            <IntegrationCard integration={integration} key={index} redirect={redirect} setSuccessModalOpen={setSuccessModalOpen} />
          ))}
        </div>
      )}
    </PageWrapper>
  );
}

function paramsToObject(entries: IterableIterator<[string, string]>) {
  const result: Record<string, string> = {};
  for (const [key, value] of entries) {
    // each 'entry' is a [key, value] tupple
    result[key] = value;
  }
  return result;
}
