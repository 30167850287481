import { PlusIcon, XCircleIcon } from '@heroicons/react/24/solid';
import { Link, Routes, useResolvedPath } from 'react-router-dom';
import { useState, useEffect, useContext } from 'react';
import NewGroupModal from '../components/Modals/NewGroupModal';
import { Route } from 'react-router-dom';
import { classNames } from '../../v2/util';
import { Organization_Users_Role } from '../../generated/graphql';
import OrganizationInfoV3 from '../components/OrganizationInfo';
import YesCancelModal from '../components/Modals/YesCancelModal';
import { logEvent, Events } from '../../v2/AnalyticsUtil';
import UserContext from '../../v2/contexts/UserContext';
import LoadingSpinner from '../../v2/components/LoadingSpinner';
import { PageWrapper } from './PageWrapper';
import Button, { ButtonSize } from '../baseComponents/Button';
import AppContext from '../../v2/contexts/AppContext';
import { useOrganizationHook } from '../../v2/hooks/OrganizationHook';
import Badge, { BadgeSize } from '../../baseComponents/Badge';

const OrganizationPageV3 = ({ pageName }: { pageName: string }) => {
  const [isLoadingData, setIsLoadingData] = useState(true);
  useEffect(() => {
    setIsLoadingData(false);
  }, []);

  return (
    <div className={classNames('flex flex-col gap-y-4')}>
      {isLoadingData ? (
        <LoadingSpinner />
      ) : (
        <Routes>
          <Route path={`/:orgId`} element={<OrganizationInfoV3 pageName={pageName} />} />
          <Route path={'/'} element={<OrganizationsList pageName={pageName} />} />
        </Routes>
      )}
    </div>
  );
};

const OrganizationsList = ({ pageName }: { pageName: string }) => {
  const { curTeamId: teamId, curOrgId: orgId, currentTeam, currentOrg, organizations } = useContext(AppContext);
  const { deleteOrg, addOrganization } = useOrganizationHook();
  const [currentOrgId, setCurrentOrgId] = useState<number>();
  const [newOrgModalOpen, setNewOrgModalOpen] = useState(false);
  const [deleteOrgModalOpen, setDeleteOrgModalOpen] = useState(false);

  const { user } = useContext(UserContext);
  const createNewOrganization = (name: string) => {
    addOrganization(name);
    setNewOrgModalOpen(false);
  };
  useEffect(() => {
    logEvent(Events.PageLoaded, { View_ID: teamId, Org_ID: orgId, View_Name: currentTeam?.name, Org_Name: currentOrg?.name, Page: pageName });
  }, []);
  const deleteOrganization = (currentOrgId: number) => {
    deleteOrg(currentOrgId);
    setDeleteOrgModalOpen(false);
  };

  return (
    <PageWrapper title="Organizations">
      {(organizations && organizations.length > 0) || user?.isUnwrapper ? (
        <>
          <h2 className="text-md mb-4 font-semibold text-blueberry">
            Below are all the organizations you belong to. Click into an organization to manage permissions and create new views of your data.
          </h2>

          <div className="grid grid-cols-3 gap-8">
            {newOrgModalOpen && (
              <NewGroupModal
                modalOpen={newOrgModalOpen}
                callbackModal={() => setNewOrgModalOpen(false)}
                confirmButton={(orgName: string, logoUrl: string, demoMode: string) => {
                  createNewOrganization(orgName);
                  setNewOrgModalOpen(false);
                }}
                type="Organization"
              />
            )}
            {deleteOrgModalOpen && (
              <YesCancelModal
                text={'Are you sure you want to delete this organization?'}
                subtext={'This action cannot be undone.'}
                confirmText="Yes, delete"
                callbackModal={setDeleteOrgModalOpen}
                modalOpen={deleteOrgModalOpen}
                idToPass={currentOrgId}
                confirmButton={() => deleteOrganization(currentOrgId!)}
                closeOnConfirm={false}
                understandCheck={true}
              />
            )}
            {organizations &&
              organizations.map((org) => {
                let userIsAdmin = org.currentUser.role === Organization_Users_Role.Admin;
                return (
                  <OrgCard
                    key={org.id}
                    title={org.name}
                    orgId={org.id}
                    logoUrl={''}
                    userIsAdmin={userIsAdmin}
                    onDeleteClick={() => {
                      setCurrentOrgId(org.id);
                      setDeleteOrgModalOpen(true);
                    }}
                    active={org.id === orgId}
                  />
                );
              })}
            {user?.isUnwrapper && (
              <Button
                text="Add new organization"
                size={ButtonSize.Large}
                expandHeight
                expandWidth
                onClick={() => setNewOrgModalOpen(true)}
                icon={<PlusIcon className="h-4 w-4" />}
              />
            )}
          </div>
        </>
      ) : (
        <h2 className="text-md mb-4 font-semibold text-blueberry">
          You are not part of any organizations. You can either ask for an invite from your company's organization admin, or contact Unwrap to create a new
          organization.
        </h2>
      )}
    </PageWrapper>
  );
};

const OrgCard = ({
  title,
  logoUrl,
  orgId,
  onDeleteClick,
  userIsAdmin,
  active,
}: {
  title: string;
  logoUrl: string | null | undefined;
  orgId: number;
  onDeleteClick: () => void;
  userIsAdmin: boolean;
  active?: boolean;
}) => {
  const url = useResolvedPath('').pathname;
  return (
    <div className="group relative select-none ">
      {userIsAdmin && (
        <>
          <XCircleIcon
            onClick={onDeleteClick}
            className="absolute -right-3 -top-3 hidden h-6 w-6 bg-milk rounded-full cursor-pointer text-red-600 duration-200 hover:text-red-700 group-hover:block"
          />
        </>
      )}
      <Link to={`${url}/${orgId}`}>
        <div
          id="org-card"
          className={classNames(
            'col-span-1 flex cursor-pointer flex-col divide-black rounded-xl p-8 text-center duration-150',
            active ? 'bg-blueberry-lighter text-white' : 'bg-silver text-blueberry hover:bg-blueberry-lighter hover:text-white'
          )}
        >
          {active ? (
            <div className="absolute top-1 right-2">
              <Badge badge={{ id: '1', text: 'Current' }} color="bg-silver" textColor="text-blueberry" capitalize={true} size={BadgeSize.Small} />
            </div>
          ) : null}
          <div className="relative flex flex-row items-center justify-center gap-x-2">
            {logoUrl && <img className="h-6 w-auto flex-shrink-0" src={logoUrl || undefined} alt="" />}
            <h1 className="font-md text-xl">{title}</h1>
          </div>
        </div>
      </Link>
    </div>
  );
};

export default OrganizationPageV3;
