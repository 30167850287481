import { FormEvent, useState } from 'react';
import { Dialog } from '@headlessui/react';
import 'tippy.js/dist/tippy.css';
import Button, { ButtonVariant } from '../../baseComponents/Button';
import Form from '../../../baseComponents/Form';
import Modal from '../../baseComponents/Modal';
import { New_Alerts } from '../../../generated/graphql';
import toast from 'react-hot-toast';
import { SlackChannelSelector } from '../SlackChannelSelector';
import { EmailSelector } from '../EmailSelector';
import { useValidTeamAppContext } from '../../../v2/contexts/AppContext';
import { useFilterHook } from '../../hooks/FilterHook';
import { FilterManager, FilterManagerDisplayMode } from '../../sections/Filters/FilterManager';
import { IFilter } from '../../sections/Filters/FiltersTypes';
import { CreateStandardAlertParams } from '../../hooks/AlertHook';
import { buildFilterInputFromSavedFilterInput } from '../../pages/ChartsPage';
import TooltipIcon from './TooltipIcon';

interface CreateAlertModalProps {
  modalOpen: boolean;
  callbackModal: () => void;
  confirmButton: (standardAlertParams: CreateStandardAlertParams, alertToEditId: number | null) => void;
  loadingConfirm: boolean;
  userEmails?: string[];
  alertToEdit?: New_Alerts;
}

export default function CreateStandardAlertModal({
  modalOpen,
  callbackModal,
  confirmButton,
  loadingConfirm,
  userEmails,
  alertToEdit,
  ...props
}: CreateAlertModalProps) {
  /*
  If there's an "alertToEdit" param set, it'll use its values as the default and call the confirmButton with the edit parameter on.
  Otherwise, it'll start with empty/default values.
  */
  const { curTeamId: teamId, curOrgId: orgId } = useValidTeamAppContext();
  const editFilterInput = alertToEdit?.filterInput ? buildFilterInputFromSavedFilterInput(alertToEdit?.filterInput) : undefined;
  const filterHook = useFilterHook({ teamId, orgId, disableUrlFilters: true, startingFilterInput: editFilterInput });
  const [period, setPeriod] = useState<number | null>(alertToEdit?.period ?? null);
  const [threshold, setThreshold] = useState<number | null>(alertToEdit?.threshold ?? null);
  const [emailList, setEmailList] = useState<string[]>(alertToEdit?.emails.map((email) => email.to_email ?? '') ?? []);
  const [alertName, setAlertName] = useState<string | null>(alertToEdit?.name ?? null);

  //This has the same issue on the backend, potential "multiple" channels. (The frontend doesn't allow that, tho). It'll be fixed in Opex Days or a follow up.
  const [selectedSlackChannelID, setSelectedSlackChannelID] = useState<string | null>(alertToEdit?.slack?.[0]?.slack_channel_ID ?? null);
  const [selectedSlackChannelName, setSelectedSlackChannelName] = useState<string | null>(alertToEdit?.slack?.[0]?.slack_channel_name ?? null);

  //Ugly but necessary until FilterManager gets improved. This shares the shown filters between the two FilterManagers.
  const [sharedFiltersShown, setSharedFiltersShown] = useState<IFilter[]>([]);

  const addEmailToList = (newEmail: string) => setEmailList((eList) => [...eList, newEmail]);
  const removeEmailFromList = (emailToRemove: string) => setEmailList((eList) => eList.filter((e) => e !== emailToRemove));

  const onCreateAlert = (e: FormEvent) => {
    e.preventDefault();
    if (!threshold || threshold < 1 || !period || period < 1) {
      alert('Please choose a valid threshold and period');
      return;
    }
    if (!emailList.length && !selectedSlackChannelID) return toast.error('You must select at least one email or Slack channel');

    confirmButton(
      {
        filterInput: filterHook.filters,
        threshold,
        period,
        emails: emailList,
        slackChannelId: selectedSlackChannelID,
        slackChannelName: selectedSlackChannelName,
        alertName: alertName,
      },
      alertToEdit?.id || null
    );
  };

  const getFormButtons = () => {
    return (
      <div className="mt-8 grid grid-cols-3 justify-end gap-x-4 text-center">
        <div className="col-span-1">
          <Button variant={ButtonVariant.Tertiary} onClick={() => callbackModal()} text="Cancel" expandWidth></Button>
        </div>
        <div className="col-span-2">
          <Button id="create-alert-confirm" submit={true} variant={ButtonVariant.Primary} text="Confirm" expandWidth loadingConfirm={loadingConfirm}></Button>
        </div>
      </div>
    );
  };

  return (
    <Modal open={modalOpen} setOpen={callbackModal}>
      <Form
        bottomRow={getFormButtons()}
        onSubmit={(e) => {
          onCreateAlert(e);
        }}
      >
        <div className="text-blueberry">
          <div>
            <div className="text-center">
              <Dialog.Title as="h1" className="text-xl font-medium leading-6 text-blueberry">
                {!!alertToEdit ? 'Editing' : 'Creating'} volume alarm
              </Dialog.Title>
            </div>
          </div>
          <div className="flex flex-col gap-y-10 mt-6">
            <div className="flex flex-col gap-y-4">
              <FormLineInput
                text={'Name'}
                defaultValue={!!alertToEdit ? alertName : null}
                setValue={(value) => setAlertName(value)}
                type="text"
                required
                placeholder={'eg. Negative App Store Reviews'}
              />
              <FormLineInput
                text={'Number of new entries (Threshold)'}
                defaultValue={!!alertToEdit ? threshold : null}
                setValue={(value) => setThreshold(Number(value))}
                type="number"
                required
              />
              <FormLineInput
                text={'Number of days (Period)'}
                defaultValue={!!alertToEdit ? period : null}
                setValue={(value) => setPeriod(Number(value))}
                type="number"
                required
                tooltipText="If Unwrap detects the inputted number of new entries over this many days, this alarm will trigger."
              />

              <div className="flex flex-col">
                <FormLine text={'Filters'}>
                  <FilterManager
                    filterHook={filterHook}
                    pageName={'Create New Alert'}
                    dataTypeToFilter={'entries'}
                    displayMode={FilterManagerDisplayMode.OnlyButton}
                    parentSetFiltersShown={setSharedFiltersShown}
                    filterButtonText="Add Filters"
                  />
                </FormLine>
                <FilterManager
                  filterHook={filterHook}
                  pageName={'Create New Alert'}
                  dataTypeToFilter={'entries'}
                  displayMode={FilterManagerDisplayMode.OnlyFiltersShown}
                  overridenFiltersShown={sharedFiltersShown}
                />
              </div>
            </div>

            <div className="flex flex-col gap-y-4">
              <EmailSelector totalList={userEmails} selectedList={emailList} removeEmailFromList={removeEmailFromList} addEmailToList={addEmailToList} />

              <div className="mt-4 flex flex-col gap-y-2 pb-4">
                <SlackChannelSelector
                  initialChannelName={selectedSlackChannelName ?? undefined}
                  updateChannelId={(channelId: string | null, channelName: string | null) => {
                    setSelectedSlackChannelID(channelId);
                    setSelectedSlackChannelName(channelName);
                  }}
                />
              </div>

              {threshold && period && (
                <div className="mt-4 flex flex-row">
                  <h1 className="text-sm italic">
                    If Unwrap detects <b className="text-base">{threshold}</b> new entr{threshold > 1 ? 'ies' : 'y'} with the selected filters imported in the
                    last <b className="text-base">{period}</b> day{period > 1 ? 's' : ''}, an alert will be sent. After 24 hours, the alert will start the
                    detection process again.{' '}
                  </h1>
                </div>
              )}
            </div>
          </div>
        </div>
      </Form>
    </Modal>
  );
}

const FormLineInput = ({
  text,
  setValue,
  placeholder,
  type,
  required,
  defaultValue,
  tooltipText,
}: {
  text: string;
  setValue: (value: string) => void;
  placeholder?: string;
  type?: React.HTMLInputTypeAttribute;
  required?: boolean;
  defaultValue?: string | number | null;
  tooltipText?: string;
}) => {
  return (
    <FormLine text={text} tooltipText={tooltipText}>
      <input
        defaultValue={defaultValue ?? undefined}
        autoComplete="new-off"
        aria-autocomplete="none"
        type={type ?? 'text'}
        required={required}
        placeholder={placeholder}
        className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm sm:text-sm"
        onChange={(e) => setValue(e.target.value)}
      />
    </FormLine>
  );
};

const FormLine = ({ text, children, tooltipText }: { text: string; children?: JSX.Element; tooltipText?: string }) => {
  return (
    <div className="relative grid grid-cols-2 items-center">
      <div className="flex flex-row gap-x-2 items-center">
        <h1 className="text-sm font-semibold">{text}</h1>
        {tooltipText ? <TooltipIcon tooltipContent={tooltipText} /> : null}
      </div>
      {children}
    </div>
  );
};
