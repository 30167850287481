import { IDropDownItem } from '../../baseComponents/DropDown';
import { Menu, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import { groupBy } from 'underscore';
import React from 'react';
import { LockClosedIcon } from '@heroicons/react/24/solid';

export interface ISettingsItem extends IDropDownItem {
  icon?: JSX.Element;
  onClick: () => void;
  group: string;
}

export enum MenuAlign {
  RIGHT = 0,
  LEFT = 1,
}

interface SettingsMenuProps {
  settings: ISettingsItem[];
  children: JSX.Element;
  align?: MenuAlign;
  disable?: boolean;
  showLock?: boolean;
  center?: boolean;
}

const SettingsMenu: React.FC<SettingsMenuProps> = ({ settings, align, disable, children, showLock, center }) => {
  const groupedSettings = groupBy(settings, (setting) => setting.group);

  // example for how to capture menu closed https://github.com/tailwindlabs/headlessui/issues/239
  // We might want to do this to darken the screen behind the menu and undarken when menu is closed.

  return (
    <div className={`flex ${center && 'flex items-center justify-center'}`}>
      <div className={`text-right ${center && 'flex items-center justify-center'}`} aria-hidden="true">
        <Menu as="div" className={`relative inline-block text-left ${center && 'flex items-center justify-center'}`}>
          <div className={`${center && 'flex items-center justify-center'}`}>
            <Menu.Button className="inline-flex justify-center" disabled={disable}>
              {children}
            </Menu.Button>
          </div>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items
              className={`${
                align === MenuAlign.RIGHT || !align ? 'right-0 ' : ''
              } absolute top-0 z-50 mt-8 w-52 divide-y divide-gray-100 rounded-md bg-white shadow-xl ring-1 ring-black ring-opacity-5 focus:outline-none lg:w-60 `}
            >
              {Object.keys(groupedSettings).map((key, index) => {
                return (
                  <div key={index} className="px-1 py-1">
                    {groupedSettings[key].map((setting, idx) => {
                      return (
                        <Menu.Item key={idx}>
                          {({ active }) => (
                            <button
                              data-testid={setting.htmlId}
                              name={setting.htmlId}
                              className={`${
                                active ? 'bg-raspberry fill-white stroke-raspberry text-white' : 'stroke-white text-blueberry'
                              } text-md group flex w-full select-none items-center justify-between rounded-md px-2 py-2 duration-100`}
                              onClick={(e) => {
                                setting.onClick();
                              }}
                              key={idx}
                            >
                              <div id={setting.htmlId} className="text-md lg:text-md flex flex-row items-center">
                                {setting.icon && (
                                  <div id={setting.htmlId} className={`${active ? 'text-white' : 'text-blueberry'} mr-2 duration-100`}>
                                    {setting.icon}
                                  </div>
                                )}
                                {setting.name}
                              </div>
                              {showLock ? <LockClosedIcon className="h-4 w-4 opacity-60" /> : undefined}
                            </button>
                          )}
                        </Menu.Item>
                      );
                    })}
                  </div>
                );
              })}
            </Menu.Items>
          </Transition>
        </Menu>
      </div>
    </div>
  );
};

export default SettingsMenu;
