import { SetStateAction, useEffect, useState } from 'react';
import Toggle from './Toggle';
import DropDown, { IDropDownItem } from '../baseComponents/DropDown';
import Button, { ButtonSize, ButtonVariant } from '../baseComponents/Button';
import { DatePicker } from '../baseComponents/DatePicker';
import {
  Digest_Group_Status,
  GetGroupInsightsQuery,
  useCreateGroupInsightMutation,
  useDeleteGroupInsightMutation,
  useEditGroupInsightMutation,
  useGetGroupInsightsLazyQuery,
} from '../../generated/graphql';
import { toast } from 'react-hot-toast';
import { useValidTeamAppContext } from '../../v2/contexts/AppContext';
import { SmallSpinner } from './SmallSpinner';
import moment from 'moment';
import TooltipIcon from './Modals/TooltipIcon';

const groupStatusOptions: IDropDownItem[] = [
  { name: Digest_Group_Status.NeedsImprovement, id: 0, displayName: 'Needs Improvement' },
  { name: Digest_Group_Status.GoingWell, id: 1, displayName: 'Going Well' },
];

export default function GroupInsightsColumn({ groupId, filterStartDate }: { groupId: number; filterStartDate: Date }) {
  const { curTeamId: teamId } = useValidTeamAppContext();
  const [initialLoadComplete, setInitialLoadComplete] = useState(false);
  const [createGroupInsightMutation, { loading: loadingCreatingInsight }] = useCreateGroupInsightMutation({});
  const [deleteGroupInsightMutation, { loading: loadingDeletingInsight }] = useDeleteGroupInsightMutation({});
  const [editGroupInsightMutation] = useEditGroupInsightMutation({});
  const [curGroupInsights, setCurGroupInsights] = useState<GetGroupInsightsQuery['getGroupInsights'][0] | null>(null);
  const [getGroupInsights] = useGetGroupInsightsLazyQuery({
    variables: {
      groupId,
      teamId,
    },
  });

  const validateTitleExists = () => {
    if (curGroupInsights && !curGroupInsights.insightTitle) {
      toast.error('Please enter a title for your insight.');
      return false;
    }
    return true;
  };
  const fetchGroupInsights = () => {
    getGroupInsights({
      variables: {
        groupId,
        teamId,
      },
      fetchPolicy: 'no-cache',
      onCompleted(data) {
        if (data.getGroupInsights.length) {
          setCurGroupInsights(data.getGroupInsights[0]);
        }
        setInitialLoadComplete(true);
      },
    });
  };

  function handleGroupInsightToggle() {
    if (!!!curGroupInsights) {
      toast('Inserting group insight row...');
      createGroupInsightMutation({
        variables: {
          groupId,
          teamId,
          filterStartDate,
        },
        onCompleted(data) {
          toast.success('Group insight row inserted');
          setCurGroupInsights(data.createGroupInsight);
        },
        onError() {
          toast.error('Error inserting group insight row');
        },
      });
    } else {
      toast('Deleting group insight row...');
      deleteGroupInsightMutation({
        variables: {
          groupId,
          teamId,
        },
        onCompleted() {
          toast.success('Group insight row deleted');
          setCurGroupInsights(null);
        },
        onError() {
          toast.error('Error deleting group insight row');
        },
      });
    }
  }

  function handleEditInsight({
    includeInDigest,
    groupStatus,
    expirationDate,
    startDate,
    description,
    includeInReport,
    reportMonthDate,
    title,
  }: {
    includeInDigest?: boolean;
    groupStatus?: Digest_Group_Status;
    expirationDate?: Date;
    startDate?: Date;
    description?: string;
    includeInReport?: boolean;
    reportMonthDate?: Date;
    title?: string;
  }) {
    if (description && !validateTitleExists()) return;
    toast('Editing group insight...');
    editGroupInsightMutation({
      variables: {
        groupId,
        teamId,
        ...(includeInDigest != null && { includeInDigest }),
        ...(groupStatus && { groupStatus }),
        ...(expirationDate && { expirationDate }),
        ...(startDate && { startDate }),
        ...(description && { description }),
        ...(includeInReport != null && { includeInReport }),
        ...(reportMonthDate && { reportMonthDate }),
        ...(title && { title }),
      },
      onCompleted(data) {
        toast.success('Group insight row edited');
        if (data.editGroupInsight) setCurGroupInsights(data.editGroupInsight);
      },
    });
  }

  useEffect(() => {
    if (!initialLoadComplete && groupId) {
      fetchGroupInsights();
    }
  }, []);
  return (
    <div className="flex flex-col w-96 bg-silver rounded-2xl h-96 pt-5 pb-4 shadow-lg">
      <div className="flex flex-col justify-center items-center text-center border-gray-200 border-b-2 pb-3">
        <h1 className="text-xl">Group Insights Configuration</h1>
        <p className="text-sm">(groupId: {groupId})</p>
      </div>
      <div className="flex flex-col items-center overflow-y-auto">
        <div className="group relative flex gap-x-2 pt-2 pb-1 mb-2 border-b-2 border-gray-200 text-blueberry">
          {!initialLoadComplete ? (
            <h1>Loading...</h1>
          ) : (
            <div className="items-center flex flex-row gap-x-3">
              <h1 className="text-sm">Create Insight</h1>
              <Toggle
                initialState={!!curGroupInsights}
                value={!!curGroupInsights}
                elementDisabled={loadingCreatingInsight || loadingDeletingInsight}
                onSwitch={handleGroupInsightToggle}
              />
            </div>
          )}
        </div>
        {loadingCreatingInsight || loadingDeletingInsight ? (
          <SmallSpinner />
        ) : !!curGroupInsights ? (
          <div className="flex flex-col gap-y-1 pt-2 w-full px-4 text-sm text-blueberry overflow-y-scroll">
            <div className="flex justify-between">
              <h1>Include in Digest</h1>
              <Toggle
                initialState={curGroupInsights.includeInDigest}
                value={curGroupInsights.includeInDigest}
                onSwitch={(newVal: boolean) => handleEditInsight({ includeInDigest: !curGroupInsights.includeInDigest })}
              />
            </div>
            <div className="flex items-center justify-between">
              <h1>Status</h1>
              <DropDown
                dropDownData={groupStatusOptions}
                setSelectedItem={(selectedItem: IDropDownItem) => handleEditInsight({ groupStatus: selectedItem.name as Digest_Group_Status })}
                selectedItem={groupStatusOptions.find((item) => item.name === curGroupInsights.digestSection)}
                useDisplayName
              />
            </div>
            <div className="flex items-center justify-between">
              <h1>Start Date</h1>
              <DatePicker
                date={new Date(curGroupInsights.insightStartDate)}
                onChange={(date: Date) => handleEditInsight({ startDate: date })}
                isChanged={false}
              />
            </div>
            <div className="flex items-center gap-x-2 justify-between">
              <h1>Expiration Date</h1>
              <DatePicker
                date={new Date(curGroupInsights.expirationDate)}
                onChange={(date: Date) => handleEditInsight({ expirationDate: date })}
                isChanged={false}
              />
            </div>
            <div className="flex flex-col">
              <h1>Insight Title</h1>
              <Input initialValue={curGroupInsights.insightTitle} onSubmit={(text: string) => handleEditInsight({ title: text })} />
            </div>
            <div className="flex flex-col">
              <h1>Insight Description</h1>
              <TextArea initialValue={curGroupInsights.insightDescription} onSubmit={(text: string) => handleEditInsight({ description: text })} />
            </div>

            <div className="flex justify-between">
              <h1>Include in Report</h1>
              <Toggle
                initialState={curGroupInsights.includeInReport}
                value={curGroupInsights.includeInReport}
                onSwitch={(newVal: boolean) => handleEditInsight({ includeInReport: !curGroupInsights.includeInReport })}
              />
            </div>
            <div className="flex items-center gap-x-2 justify-between">
              <h1>Report Month</h1>
              <TooltipIcon tooltipContent={'Choose any day of the month+year you want.'} />
              <DatePicker
                date={moment([moment.utc(curGroupInsights.reportStartDate).year(), moment.utc(curGroupInsights.reportStartDate).month(), 1]).toDate()}
                onChange={(date: Date) => handleEditInsight({ reportMonthDate: date })}
                isChanged={false}
              />
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
}
const Input = ({ onSubmit, initialValue }: { onSubmit: (text: string) => void; initialValue?: string | null }) => {
  const [text, setText] = useState(initialValue ?? '');
  function handleTextChange(event: { target: { value: SetStateAction<string> } }) {
    if (text.length > 500) return;
    setText(event.target.value);
  }
  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        onSubmit(text);
      }}
      className="relative"
    >
      <div className="flex flex-row justify-between gap-x-2">
        <input
          value={text}
          className="overflow-hidden rounded-lg shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-indigo-600 h-10 p-2 flex-1"
          onChange={handleTextChange}
        />
        <Button variant={ButtonVariant.Primary} size={ButtonSize.Small} submit text={`Save Title`} expandWidth />
      </div>
    </form>
  );
};

const TextArea = ({ onSubmit, initialValue }: { onSubmit: (text: string) => void; initialValue?: string | null }) => {
  const [text, setText] = useState(initialValue ?? '');
  function handleTextChange(event: { target: { value: SetStateAction<string> } }) {
    if (text.length > 500) return;
    setText(event.target.value);
  }
  return (
    <div className="flex items-start space-x-4">
      <div className="min-w-0 flex-1">
        <form
          onSubmit={(e) => {
            e.preventDefault();
            onSubmit(text);
          }}
          className="relative"
        >
          <div className="overflow-hidden rounded-lg shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-indigo-600">
            <textarea
              rows={5}
              name="comment"
              id="comment"
              className="block w-full resize-none border-0 bg-transparent text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:py-1.5 sm:text-sm "
              value={text}
              onChange={handleTextChange}
            />

            {/* Spacer element to match the height of the toolbar */}
            <div className="py-2" aria-hidden="true">
              {/* Matches height of button in toolbar (1px border + 36px content height) */}
              <div className="py-px">
                <div className="h-9" />
              </div>
            </div>
          </div>

          <div className="absolute inset-x-0 bottom-0 flex justify-between py-2 pl-3 pr-2">
            <Button variant={ButtonVariant.Primary} size={ButtonSize.Small} submit text="Save Description" expandWidth />
          </div>
        </form>
      </div>
    </div>
  );
};
