import { useEffect, useState } from 'react';
import { FilterInput, GetCustomChartQuery, GetCustomChartsQuery, useDataForFiltersLazyQuery } from '../../generated/graphql';
import { ArrayElement } from '../../utilities';
import { useValidTeamAppContext } from '../../v2/contexts/AppContext';
import { CustomChart } from './CustomChart';
import { IBadgeFilter, IFilter } from '../sections/Filters/FiltersTypes';
import { getBadgeText, setUiFilters } from '../sections/Filters/FilterManager';
import { useNavigate } from 'react-router-dom';
import { ChartBarSquareIcon, Cog6ToothIcon, LinkIcon, PaperClipIcon, PencilIcon, TrashIcon } from '@heroicons/react/24/solid';
import AdjustableLoadingIcon from '../../baseComponents/AdjustableLoadingIcon';
import Badge from './Badge';
import Tippy from '@tippyjs/react';
import SettingsMenu from '../baseComponents/SettingsMenu';
import toast from 'react-hot-toast';

import { buildFilterInputFromSavedFilterInput } from '../pages/ChartsPage';
import moment from 'moment';

export const CustomChartCard = ({
  customChartData,
  refProp,
  newCard,
  loading,
  filterInput,
  deleteChart,
}: {
  customChartData: ArrayElement<GetCustomChartsQuery['getCharts']> | GetCustomChartQuery['getChart'];
  filterInput: FilterInput;
  refProp?: any;
  newCard: boolean;
  loading?: boolean;
  deleteChart?: () => void;
}) => {
  return (
    <div className="custom-chart-card flex flex-col border border-gray-300 rounded-lg" ref={refProp}>
      <CustomChartCardHeader
        title={customChartData.title}
        chart={customChartData}
        newCard={newCard}
        loading={loading}
        deleteChart={deleteChart}
        filterInput={filterInput}
      />
      <div className="p-3">
        <div className="h-72">
          <CustomChart customChartData={customChartData} />
        </div>
      </div>
    </div>
  );
};

const CustomChartCardHeader = ({
  chart,
  title,
  newCard,
  loading,
  deleteChart,
  filterInput,
}: {
  chart: ArrayElement<GetCustomChartsQuery['getCharts']>;
  title: string;
  newCard: boolean;
  loading?: boolean;
  filterInput: FilterInput;
  deleteChart?: () => void;
}) => {
  const { curTeamId: teamId, curOrgId: orgId, currentTeam } = useValidTeamAppContext();
  const [getDataForFilters] = useDataForFiltersLazyQuery({ variables: { teamId, orgId } });
  const [headerFilters, setHeaderFilters] = useState<IFilter[]>([]);
  const [headerFiltersLoading, setHeaderFiltersLoading] = useState<boolean>(true);
  useEffect(() => {
    const setHeader = async () => {};
    if (chart.seriesData) {
      setHeaderFiltersLoading(true);
      Promise.all(
        chart.seriesData?.map(async (series) => {
          const convertedFilter = buildFilterInputFromSavedFilterInput(series.filterInput ?? {});
          await setUiFilters(
            convertedFilter,
            (filter) => {
              const withTeamName = filter.map((f) => {
                return { ...f, teamName: series.filterInput?.teamName };
              });
              setHeaderFilters((prev) => [...prev, ...withTeamName]);
            },
            () => getDataForFilters({ variables: { teamId: series.team.id, orgId } })
          );
        })
      ).then(() => {
        setHeaderFiltersLoading(false);
      });
    }
    setHeader();
  }, []);
  const handleCopyLink = (e?: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
    e && e.stopPropagation();
    const filter: FilterInput = {
      startDate: filterInput.startDate,
      ...(!moment().endOf('day').isSame(filterInput.endDate) ? { endDate: filterInput.endDate } : {}),
    };
    const url = new URL(window.location.href);
    const urlParams = new URLSearchParams(url.search);
    urlParams.set('teamId', teamId.toString());
    urlParams.set('orgId', orgId.toString());
    urlParams.set('chart', encodeURIComponent(JSON.stringify(filter)));
    const newUrl = window.location.protocol + '//' + window.location.host + window.location.pathname + `/${chart.id}/edit?` + urlParams.toString();
    navigator.clipboard.writeText(newUrl);
    toast.success('Link successfully copied!');
  };
  const navigateToEdit = () => {
    const url = new URL(window.location.href);
    const urlParams = new URLSearchParams(url.search);
    urlParams.set('teamId', teamId.toString());
    urlParams.set('orgId', orgId.toString());
    navigate(`${chart.id}/edit` + '?' + urlParams.toString());
  };

  let navigate = useNavigate();
  const settings = [
    {
      name: 'Edit Chart',
      id: 0,
      group: 'edit',
      htmlId: 'edit-chart',
      onClick: () => navigateToEdit(),
      icon: <PencilIcon className="w-6 h-6" />,
    },
    {
      name: 'Link to Chart',
      id: 1,
      group: 'action',
      htmlId: 'link-chart',
      icon: <PaperClipIcon className="h-6 w-6 " />,

      onClick: () => handleCopyLink(),
    },
    {
      name: 'Delete Chart',
      id: 2,
      group: 'action',
      icon: <TrashIcon className="h-6 w-6" />,
      onClick: () => {
        deleteChart?.();
      },
    },
  ];
  return (
    <div
      className={`bg-blueberry text-white flex flex-col gap-y-2 rounded-t-lg border-b border-gray-300 px-6 py-3.5 ${!newCard ? 'cursor-pointer' : ''}`}
      onClick={() => {
        !newCard && navigateToEdit();
      }}
    >
      <div className="flex flex-row justify-between">
        <div className="flex flex-col gap-y-2">
          <div className="flex flex-row gap-x-3 items-center">
            <ChartBarSquareIcon className="h-6 w-6" />
            <h1 className="font-bold">{title}</h1>
          </div>
          {headerFiltersLoading ? (
            <AdjustableLoadingIcon height={4} width={4} color="#FFFFFF" />
          ) : headerFilters.length > 0 ? (
            <div className="flex flex-row flex-wrap items-center gap-x-2 gap-y-1 font-bold text-blueberry">
              {headerFilters.map((filter, index) => {
                const badgeValue: IBadgeFilter = {
                  text: getBadgeText(filter, new Set(headerFilters?.map((fltr) => fltr.teamName)).size < 2 && filter.teamName === currentTeam.name),
                  id: index.toString(),
                  filter: filter,
                };
                return (
                  <div id="applied-filter" className="" key={index}>
                    <Badge badge={badgeValue} key={index} color="bg-white" textColor="text-blueberry" smaller capitalize={false} />
                  </div>
                );
              })}
            </div>
          ) : null}
        </div>

        <div>
          {!newCard ? (
            <div className="flex flex-row gap-x-2">
              <div className="rounded-full p-2 transition hover:cursor-pointer hover:bg-slate-900/25 " onClick={(e) => e.stopPropagation()}>
                <Tippy theme="light" delay={200} content={<p className="text-center">Copy chart link</p>}>
                  <LinkIcon id="link-chart" className="h-5 w-5 focus:outline-none" onClick={(e) => handleCopyLink(e)} />
                </Tippy>
              </div>
              <div
                id="chart-card-settings-menu"
                className="rounded-full p-2 transition duration-200 hover:cursor-pointer hover:bg-slate-900/25 text-milk"
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <SettingsMenu settings={settings} center>
                  <Cog6ToothIcon className="h-5 w-5" />
                </SettingsMenu>
              </div>
            </div>
          ) : null}
          {loading ? (
            <div className="flex flex-row gap-x-2 items-center text-white">
              <AdjustableLoadingIcon color={'white'} width={4} height={4} />
              <p className="text-md">Updating chart...</p>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};
