import { useState } from 'react';
import React from 'react';

interface SelectOrDragFileProps {
  /**
   * @description set the file in CSVIntegrationModal to the current file
   */
  setFileToUpload: React.Dispatch<React.SetStateAction<File | undefined>>;
}

/**
 * @description Creates a drag/drop or select file field and passes the uploaded file to CSVIntegrationModal
 */
const SelectOrDragFile = ({ setFileToUpload }: SelectOrDragFileProps) => {
  /**
   * @description display the raw name of the file upload
   */
  const [selectedFileName, setSelectedFileName] = useState<string>('');

  /**
   * @description true if a user is in the process of dragging a file in, false otherwise
   */
  const [isDragOver, setIsDragOver] = useState(false);

  /**
   * @description set's drag over to be true and turns the div `blueberry`
   * @param event drag over
   */
  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDragOver(true);
  };

  /**
   * @description turns the div back to normal white color
   */
  const handleDragLeave = () => {
    setIsDragOver(false);
  };

  /**
   * @description handles user action of releasing the file into the modal
   * @param event dragging file and releasing
   */
  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDragOver(false);
    const fileToUpload = event.dataTransfer?.files[0];
    setFileNameAndUpload(fileToUpload);
  };

  /**
   * @description handles user action when pressing the `done` button on browser selection modal
   * @param event selecting a file
   */
  const handleSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    const fileToUpload = event.target.files?.[0];
    setFileNameAndUpload(fileToUpload);
    event.target.value = ''; // this is needed so we can retrigger upload even if the same file is selected
  };

  /**
   * @description set the states for the parent component to access FileName and FilteToUpload
   * @param fileToUpload file from DOM
   */
  function setFileNameAndUpload(fileToUpload: File | undefined) {
    if (fileToUpload) {
      setSelectedFileName(fileToUpload.name);
      setFileToUpload(fileToUpload);
    }
  }

  return (
    <>
      <div className="bg-grey-lighter flex items-center justify-center mb-4" onDragOver={handleDragOver} onDragLeave={handleDragLeave} onDrop={handleDrop}>
        {/* Drag/Drop Field*/}
        <label
          className={`flex w-64 cursor-pointer flex-col items-center rounded-lg border-2 border-blueberry  px-4 py-4 uppercase tracking-wide duration-300 
            ${isDragOver ? 'bg-blueberry text-white' : `hover:bg-blueberry hover:text-white bg-white text-raspberry `}
            `}
        >
          <svg className="h-6 w-6" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
            <path d="M16.88 9.1A4 4 0 0 1 16 17H5a5 5 0 0 1-1-9.9V7a3 3 0 0 1 4.52-2.59A4.98 4.98 0 0 1 17 8c0 .38-.04.74-.12 1.1zM11 11h3l-4-4-4 4h3v3h2v-3z" />
          </svg>
          <input type="file" onChange={handleSelect} className="hidden" />
        </label>
      </div>
    </>
  );
};
export default SelectOrDragFile;
