/* OrgDiscoveryList is a component that contains a list of cards with organizations. These organizations were found by
running the DiscoverOrganizations query, which looks for orgs that match the user's domain and have enabled discovery. */

import { useState } from 'react';
import Button, { ButtonVariant } from '../baseComponents/Button';
import { DiscoverableOrg, useRequestAccessMutation } from '../../generated/graphql';
import toast from 'react-hot-toast';

export const OrgDiscoveryList = ({ discoverableOrgs }: { discoverableOrgs: DiscoverableOrg[] }) => {
  return (
    <div className="flex flex-col gap-y-2">
      <div className="flex flex-col gap-y-1 items-center">
        <h2 className="text-2xl font-semibold">Discover organizations</h2>
        <p className="text-sm text-gray-600">
          We have found {discoverableOrgs.length} organization{discoverableOrgs.length > 1 ? 's' : ''} that match your email domain and can receive requests to
          join.
        </p>
      </div>
      <div className="flex flex-col gap-y-4">
        {discoverableOrgs.map((discoverableOrg) => {
          return <OrgDiscoveryCard key={discoverableOrg.id} discoverableOrg={discoverableOrg} />;
        })}
      </div>
    </div>
  );
};

const OrgDiscoveryCard = ({ discoverableOrg }: { discoverableOrg: DiscoverableOrg }) => {
  const [requestAccessMutation, requestAccessMutationQuery] = useRequestAccessMutation({ variables: { orgId: discoverableOrg.id } });
  const [accessRequested, setAccessRequested] = useState<boolean>(false);
  const handleRequestAccess = async () => {
    await requestAccessMutation();
    setAccessRequested(true);
    toast.success(`Your request to join ${discoverableOrg.name} has been sent. An admin must approve your request before you can access the organization.`);
  };
  return (
    <div className="flex flex-row justify-between bg-silver rounded-lg px-4 py-5 w-full">
      <div className="flex flex-row gap-x-3 items-center">
        {false && (
          <img
            className="w-12 h-12 rounded-xl"
            src="https://images.crunchbase.com/image/upload/c_lpad,h_256,w_256,f_auto,q_auto:eco,dpr_1/vbadlasohytfw8wvg8ok"
          />
        )}
        <div className="text-sm">
          <h1 className="text-2xl font-semibold">{discoverableOrg.name}</h1>
        </div>
      </div>
      <Button
        variant={ButtonVariant.Primary}
        text={accessRequested ? 'Access Requested' : 'Request to join'}
        onClick={handleRequestAccess}
        loadingConfirm={requestAccessMutationQuery.loading}
        disabled={accessRequested}
      />
    </div>
  );
};
