import { useState } from 'react';
import Form from '../../baseComponents/Form';
import { Campaign } from '../../generated/graphql';
import Button, { ButtonVariant, ButtonSize } from '../baseComponents/Button';
import Modal from '../baseComponents/Modal';
import logoUnwrap from '../../assets/unwrap_logo.svg';

export const CampaignModal = ({
  campaign,
  handleAcknowledged,
  handleDismissed,
}: {
  campaign: Campaign;
  handleDismissed: (campaignId: number, cb?: () => void) => void;
  handleAcknowledged: (campaignId: number, cb?: () => void) => void;
}) => {
  const [open, setOpen] = useState(true);
  const [page, setPage] = useState(0);

  const getFormButtons = () => {
    if (campaign.campaignPages.length === 1) {
      return (
        <div className="mt-8 w-full flex justify-center items-center text-center">
          <Button
            id="campaign-modal-next-button"
            expandWidth
            expandHeight
            variant={ButtonVariant.Primary}
            text={'Got It'}
            size={ButtonSize.Large}
            onClick={() => (page !== campaign.campaignPages.length - 1 ? setPage((prev) => prev + 1) : handleAcknowledged?.(campaign.id, () => setOpen(false)))}
          ></Button>
        </div>
      );
    }
    return (
      <div className="mt-8 w-full grid grid-cols-5 justify-end gap-x-20 text-center">
        <div className="col-span-2">
          <Button
            id="campaign-modal-cancel-button"
            expandWidth
            expandHeight
            variant={ButtonVariant.Tertiary}
            onClick={() => handleDismissed?.(campaign.id, () => setOpen(false))}
            text="Cancel"
          ></Button>
        </div>
        <div className="col-span-3 flex flex-row justify-end gap-x-4">
          <div className="w-12 flex justify-end items-center">
            {page !== 0 ? (
              <Button
                id="campaign-modal-back-button"
                text="Back"
                variant={ButtonVariant.Cancel}
                onClick={() => setPage((prev) => prev - 1)}
                loadingConfirm={false}
                className="box-border"
              />
            ) : null}
          </div>
          <Button
            id="campaign-modal-next-button"
            expandWidth
            expandHeight
            variant={ButtonVariant.Primary}
            text={page !== campaign.campaignPages.length - 1 ? 'Next' : 'Got It'}
            onClick={() => (page !== campaign.campaignPages.length - 1 ? setPage((prev) => prev + 1) : handleAcknowledged?.(campaign.id, () => setOpen(false)))}
          ></Button>
        </div>
      </div>
    );
  };
  return (
    <Modal open={open} setOpen={(open) => handleDismissed(campaign.id, () => setOpen(open))}>
      <Form bottomRow={getFormButtons()}>
        <div className="flex flex-col justify-center items-center text-blueberry">
          <img className="h-16 w-auto" src={logoUnwrap} alt="Unwrap Logo" />
          {campaign.campaignPages[page].title ? (
            <h2 id="campaign-modal-title" className="mt-6 text-center text-3xl font-bold ">
              {campaign.campaignPages[page].title}
            </h2>
          ) : null}
          <p id="campaign-modal-description" className="pt-2 text-lg text-center">
            {campaign.campaignPages[page].description}
          </p>
        </div>
      </Form>
    </Modal>
  );
};
