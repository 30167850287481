import { ArrowPathIcon } from '@heroicons/react/24/outline';
import { classNames } from '../../util';
import Tippy from '@tippyjs/react';
import moment from 'moment';

export const StatusIndicator = ({ active, lastDate, loading }: any) => {
  if (loading)
    return (
      <div className="ml-4 flex flex-row items-center justify-between gap-x-2 text-gray-700">
        {/*  <h1 className="font-semibold">Loading...</h1> */}
        <ArrowPathIcon className={classNames('h-4 w-4 animate-spin')} aria-hidden="true" />
      </div>
    );
  if (active)
    return (
      <div className="ml-4 flex flex-row items-center gap-x-2 text-green-700">
        <h1 className="font-semibold">Running</h1>
        <div className="flex items-center">
          <span className="absolute h-2 w-2 animate-ping rounded-full bg-green-600 "></span>
          <span className="absolute inline-flex h-2 w-2 rounded-full bg-green-700"></span>
        </div>
      </div>
    );

  return (
    <Tippy
      content={
        <h1 className="text-xs">
          Last run: {lastDate && lastDate !== -1 ? moment(lastDate).format('dddd, MMMM Do YYYY, h:mm:ss a') : 'Never'}
          {/* Last run: 08/09/2022 10:49 (2hs ago) */}
        </h1>
      }
      placement="right"
      arrow={true}
      //  @ts-ignore
      arrowType="round"
      trigger="mouseenter"
      duration={[200, 100]}
    >
      <div className="ml-4 flex flex-row items-center gap-x-2 text-gray-500">
        <h1 className="font-semibold">Idle</h1>
      </div>
    </Tippy>
  );
};
