import { Campaign } from '../../generated/graphql';

import Joyride, { Step, EVENTS, ACTIONS, STATUS, CallBackProps } from 'react-joyride';
import { useState, useEffect, useRef } from 'react';
import { debounce } from 'lodash';

export const CampaignTooltip = ({
  campaign,
  handleAcknowledged,
  handleDismissed,
}: {
  campaign: Campaign;
  handleDismissed: (campaignId: number, cb?: () => void) => void;
  handleAcknowledged: (campaignId: number, cb?: () => void) => void;
}) => {
  const getSelector = (id: string | undefined | null) => {
    return `#${id ?? 'page-wrapper'}`;
  };
  const setTooltipSteps = (): Step[] => {
    if (!run) return [];
    return campaign.campaignPages.map((page, index, pages) => {
      const currentTargetElement: string = getSelector(page.targetElement); // need to set a default element id, which will be the entire page
      return {
        target: currentTargetElement,
        disableBeacon: true,
        // if the next element is not visible, do not show the next button.
        // if it's the last element, or the next element is visible, show the next button
        hideFooter: index != pages.length - 1 ? (document.querySelector(getSelector(pages[index + 1].targetElement)) ? false : true) : false,
        content: (
          <div className="text-center">
            <h3 className="font-bold text-lg">{page.title}</h3>
            <p className="pt-1">{page.description}</p>
          </div>
        ),
      };
    });
  };

  const [run, setRun] = useState(true);
  const [page, setPage] = useState(0);
  const [steps, setSteps] = useState<Step[]>(setTooltipSteps());

  // NOTE: The following specifically handles a case where we want users to interact with a spotlighted element to trigger the next step
  // The reason why we do this is because PageWrapper knows nothing about the contents its wrapping around.
  // So we cannot tie changing visibility of the footer to when elements become visible in a useEffect.

  // We need to observe the DOM for changes to the document. If an element in the campaign gains/loses visibility, we need to update footer visibility.
  // WE ONLY WANT USERS TO BE ABLE TO CLICK THE NEXT BUTTON IF THE NEXT ELEMENT IS VISIBLE. Otherwise that element is skipped.

  // if this doesn't make sense, yell at Nicole :)

  // We keep track of the observer so we can disconnect when the campaign is over.
  const observerRef = useRef<MutationObserver | null>(null);

  // We debounce here so that rapid changes in the DOM will only cause a single update to the steps state
  const debounceTooltipSteps = debounce(() => {
    setSteps(setTooltipSteps());
  }, 100);

  useEffect(() => {
    // Disconnect and cleanup observer after we're done with the campaign
    if (!run) {
      if (observerRef.current) {
        // Disconnect and cleanup the current observer if it exists
        observerRef.current.disconnect();
        observerRef.current = null;
      }
      return;
    } else {
      observerRef.current = new MutationObserver((mutations) => {
        // rerun query selector
        debounceTooltipSteps();
      });
      observerRef.current.observe(document, { childList: true, subtree: true });
    }

    return () => {
      if (observerRef.current) {
        // Cleanup on unmount
        observerRef.current.disconnect();
      }
    };
  }, [page, run]);
  const buttonText = {
    back: 'Back',
    close: 'Close',
    last: 'Got it',
    next: 'Next',
    skip: 'Skip',
  };

  const handleCallback = (data: CallBackProps) => {
    const { action, index, status, type } = data;
    if (type === EVENTS.STEP_AFTER || type === EVENTS.TARGET_NOT_FOUND) {
      // Update state to advance the tour
      setPage(action === ACTIONS.PREV ? index - 1 : index + 1);
    } else if (action === ACTIONS.CLOSE || status === STATUS.SKIPPED) {
      // user has attempted to ignore the contents of the modal
      // update the campaign status to dismissed
      setRun(false);
      handleDismissed(campaign.id, () => setRun(false));
    } else if (status === STATUS.FINISHED) {
      // user has clicked the "acknowledge" button
      // update the campaign status to acknowledged
      setRun(false);
      handleAcknowledged(campaign.id, () => setRun(false));
    }
  };
  const options = {
    arrowColor: '#fff',
    backgroundColor: '#fff',
    overlayColor: 'rgba(0, 0, 0, 0.5)',
    primaryColor: '#292e5b',
    spotlightShadow: '0 0 15px rgba(0, 0, 0, 0.5)',
    textColor: '#292e5b',
    zIndex: 100,
  };

  return (
    <Joyride
      showSkipButton
      callback={handleCallback}
      scrollToFirstStep
      steps={steps}
      continuous={true}
      disableScrolling
      spotlightPadding={5}
      locale={buttonText}
      run={run}
      stepIndex={page}
      spotlightClicks
      styles={{
        options,
      }}
    />
  );
};
