export const TaxonomyChildToAdd = ({
  checked,
  title,
  numberOfEntries,
  addCheck,
  removeCheck,
}: {
  checked: boolean;
  title: string;
  numberOfEntries: number;
  addCheck: () => void;
  removeCheck: () => void;
}) => {
  return (
    <label className="custom-label flex mt-4 mb-4 ml-3 gap-x-1 items-center">
      <div className="bg-white shadow w-6 h-6 p-1 flex justify-center items-center mr-3 cursor-pointer">
        <input type="checkbox" className="hidden" checked onClick={() => (checked ? removeCheck() : addCheck())} />
        {checked ? (
          <svg className="w-4 h-4 text-blueberry pointer-events-none" viewBox="0 0 172 172">
            <g fill="none" stroke-width="none" stroke-miterlimit="10" font-family="none" font-weight="none" font-size="none" text-anchor="none">
              <path d="M0 172V0h172v172z" />
              <path d="M145.433 37.933L64.5 118.8658 33.7337 88.0996l-10.134 10.1341L64.5 139.1341l91.067-91.067z" fill="currentColor" stroke-width="1" />
            </g>
          </svg>
        ) : (
          <div className="w-4 h-4"></div>
        )}
      </div>
      <span className="select-none font-semibold"> {title}</span>
    </label>
  );
};
