import { useState, useEffect } from 'react';
import 'tippy.js/themes/light.css';
import { FilterInput, GroupDependencies } from '../../generated/graphql';
import 'tippy.js/themes/light.css';
import LoadingSpinner from '../../v2/components/LoadingSpinner';
import GroupModal from './GroupModal';
import { TaxonomyGroup, ITag, GroupBase, GroupFull, getGroupPageUrl } from '../../v2/hooks/GroupHook';
import { useValidTeamAppContext } from '../../v2/contexts/AppContext';
import AnnouncementModal from './Modals/AnnouncementModal';
import { GroupDeleter } from './GroupDeleter';
import { useInView } from 'react-intersection-observer';
import 'tippy.js/themes/light.css';
import { TaxonomyFolder } from './TaxonomyFolder';
import { useNavigate } from 'react-router-dom';
import { AppRoutes } from '../../Routes';

interface TaxonomyViewProps {
  currentGroup: GroupBase | GroupFull | undefined;
  tags: ITag[];
  filterInput?: FilterInput;
  similarSentences?: any[];
  loadingStatuses?: any;
  clearSimilarSentences: () => void;
  setCurrentGroup: (group: GroupBase | GroupFull | undefined) => void;
  deleteSentence?: (groupId: number, sentenceId: number, cb: () => void) => Promise<void>;
  addSentence?: (groupId: number, sentence: any, cb: () => void) => Promise<void>;
  findSimilarSentences: (query: string, page: number, pageSize: number) => void;
  loadMoreOrphans: (page: number, pageSize: number) => Promise<void>;
  handleCreateTag: (groupId: number, name: string, cb: () => void) => void;
  handleRemoveTag: (groupId: number, tagId: number, cb: () => void) => void;
  handleTagGroup: (groupId: number, tagId: number, cb: () => void) => void;
  discardSearchGroup: (groupId: number) => void;
  togglePinGroup: (groupId: number, cb?: () => void) => void;
  groupExport: (groupId: number) => void;
  updateOwner: (groupId: number, userId: number, cb?: () => void) => void;
  removeOwner: (groupId: number, cb?: () => void) => void;
  copyGroupLink: (groupId: number) => void;
  editTitle: (groupId: number, title: string) => void;
  refetchSimilarSentences: (query: string, page: number, pageSize: number, endOfDataSetDb: () => void, cb: () => void, queryChanged?: boolean) => Promise<void>;
  loadMoreSentences: (groupId: number, page: number, pageSize: number, endOfDataSetDb: () => void, cb: () => void) => Promise<void>;
  openAnnouncementModal: (d: number) => void;
  updateCurrentGroupFilter: (currentGroupFilter: FilterInput) => void;
  getChildCandidates: (teamId: number, filterInput: FilterInput, groupId: number, query: string, cb: () => void) => Promise<void>;
  childCandidates: any[];
  getChildren: (teamId: number, filterInput: FilterInput, groupId: number, cb: () => void) => Promise<void>;
  children: any[];
  assignChildren: (teamId: number, filterInput: FilterInput, parentGroupId: number, childGroupIds: number[], cb?: () => void) => Promise<void>;
  assignChild: (
    teamId: number,
    filterInput: FilterInput,
    parentGroupId: number,
    childGroupId: number,
    type?: 'Parent' | 'Child',
    cb?: () => void
  ) => Promise<void>;
  deleteChild: (teamId: number, filterInput: FilterInput, parentGroupId: number, childGroupId: number, cb?: () => void) => Promise<void>;
  pageName: string;
  taxonomy: Map<number, TaxonomyGroup>;
  openParent: (teamId: number, filterInput: FilterInput, groupId: number, cb?: () => void) => Promise<void>;
  getCurrentGroup: (teamId: number, filterInput: FilterInput, groupId: number, cb?: () => void) => Promise<void>;
  discardGroup: (groupId: number, cb?: () => void) => Promise<GroupDependencies | void>;
  updateProgress: (searchGroupId: number, newProgress: number) => void;
  replaceOrAddToSearchGroups: (searchGroup: any) => void;
  removeChildFromParent: (teamId: number, filterInput: FilterInput, parentGroupId: number, childGroupId: number, cb?: () => void) => Promise<void>;
  getChildrenToAssign: (teamId: number, filterInput: FilterInput, groupId: number, cb?: () => void) => Promise<void>;
  childrenToAssign: any;
}

export const TaxonomyView = ({
  tags,
  deleteChild,
  filterInput,
  discardGroup,
  loadingStatuses,
  similarSentences,
  setCurrentGroup,
  clearSimilarSentences,
  loadMoreSentences,
  refetchSimilarSentences,
  deleteSentence,
  addSentence,
  handleCreateTag,
  handleRemoveTag,
  handleTagGroup,
  loadMoreOrphans,
  togglePinGroup,
  groupExport,
  updateOwner,
  removeOwner,
  editTitle,
  updateCurrentGroupFilter,
  getChildCandidates,
  childCandidates,
  assignChildren,
  assignChild,
  taxonomy,
  getCurrentGroup,
  currentGroup,
  openParent,
  findSimilarSentences,
  copyGroupLink,
  children,
  getChildren,
  updateProgress,
  replaceOrAddToSearchGroups,
  removeChildFromParent,
  childrenToAssign,
  getChildrenToAssign,
}: TaxonomyViewProps) => {
  const navigate = useNavigate();
  const { ref, inView } = useInView({ threshold: 0 });
  const { curTeamId: teamId, curOrgId: orgId, currentOrg } = useValidTeamAppContext();
  const [tab, setCurrentTab] = useState<number | undefined>(undefined);
  const [curGroupIdToDelete, setCurGroupIdToDelete] = useState<number | null>(null);
  const [announcementId, setAnnouncementId] = useState<number | undefined>();
  const [activeId, setActiveId] = useState<number | undefined>(undefined);
  const [paginating, setPaginating] = useState(false);
  const [openTaxonomy, setOpenTaxonomy] = useState(false);
  const [loadingChildrenId, setLoadingChildrenId] = useState<number | undefined>();
  const loadMoreGroups = async () => {
    setPaginating(true);
    const orphansLength = Array.from(taxonomy.values()).filter((t: TaxonomyGroup) => t.parentId == null && t.totalDescendents === 0).length;
    await loadMoreOrphans(orphansLength, 10);
    setPaginating(false);
  };
  useEffect(() => {
    if (!currentGroup?.id) {
      setCurrentGroup(undefined);
    }
  }, [currentGroup?.id]);
  useEffect(() => {
    if (inView && !paginating && !loadingStatuses.fetchingParents) {
      // load more groups
      loadMoreGroups();
    }
  }, [inView]);
  const handleToggle = (groupId: number) => {
    setLoadingChildrenId(groupId);
    openParent(teamId, filterInput ?? {}, groupId, () => setLoadingChildrenId(undefined));
  };
  useEffect(() => {
    if (!currentGroup) {
      setCurrentTab(undefined);
      setOpenTaxonomy(false);
    }
  }, [currentGroup]);
  
  const sortTaxonomyGroups = (a: TaxonomyGroup, b: TaxonomyGroup) => {
    // Processed items first
    if (a.processing && !b.processing) return -1;
    if (!a.processing && b.processing) return 1;

    // Then items with descendants
    if (a.totalDescendents > 0 && b.totalDescendents === 0) return -1;
    if (b.totalDescendents > 0 && a.totalDescendents === 0) return 1;

    // Then sort by totalEntries
    if (a.totalEntries !== b.totalEntries) return b.totalEntries - a.totalEntries;
    return b.id - a.id; // sort by ids
  };
  const openGroupModal = (groupId: number, openTaxonomy?: boolean) => {
    navigate(getGroupPageUrl(teamId, orgId, groupId, AppRoutes.v3FullPath.explore, openTaxonomy ? ['taxonomy=true'] : undefined));
  };
  const topLevelGroups = Array.from(taxonomy.values())
    .filter((t: TaxonomyGroup) => t.parentId == undefined)
    .sort((a: TaxonomyGroup, b: TaxonomyGroup) => sortTaxonomyGroups(a, b));
  const renderGroup = (group: TaxonomyGroup, show: boolean, depth: number) => {
    const childItems =
      group.children
        ?.map((child) => child.id)
        .map((id) => taxonomy.get(id) ?? null)
        .filter((c) => c)
        .sort((a, b) => sortTaxonomyGroups(a as TaxonomyGroup, b as TaxonomyGroup))
        .map((c) => renderGroup(c as TaxonomyGroup, false, depth + 1)) ?? [];
    return (
      <TaxonomyFolder
        trending={group.trending}
        replaceOrAddToSearchGroups={replaceOrAddToSearchGroups}
        updateProgress={updateProgress}
        setOpenTaxonomy={(bool) => setOpenTaxonomy(bool ?? false)}
        loadingChildrenId={loadingChildrenId}
        taxonomy={taxonomy.get(group.id)!}
        itemRef={show ? ref : null}
        key={group.id}
        children={childItems}
        handleToggle={handleToggle}
        copyLink={copyGroupLink}
        groupExport={groupExport}
        assignChildren={assignChildren}
        filters={filterInput ?? {}}
        teamId={teamId}
        getChildCandidates={getChildCandidates}
        childCandidates={childCandidates}
        addChild={assignChild}
        activeId={activeId}
        setActiveId={(id) => setActiveId(id)}
        openGroupModal={openGroupModal}
        togglePinGroup={togglePinGroup}
        setAnnouncementId={(id) => setAnnouncementId(id)}
        setCurrentGroupId={(id) => setCurGroupIdToDelete(id)}
        zIndex={activeId === group.id ? 'z-20' : 'z-0'}
        filterInput={filterInput ?? {}}
        depth={depth}
      />
    );
  };

  return (
    <>
      {announcementId != null && (
        <AnnouncementModal
          groupId={announcementId}
          modalOpen={true}
          setModalOpen={() => setAnnouncementId(undefined)}
          teamId={teamId}
          orgId={orgId}
          orgName={currentOrg.name}
          filterInput={filterInput ?? {}}
        />
      )}

      {curGroupIdToDelete !== null ? (
        <GroupDeleter
          groupToDelete={curGroupIdToDelete}
          closeCallback={() => {
            setCurGroupIdToDelete(null);
          }}
          deleteGroup={discardGroup}
          deleteCallback={() => {
            setCurrentGroup(undefined);
            navigate('/dashboard/explore', { replace: true });
          }}
          loadingDelete={loadingStatuses.discardingGroup}
          hasChildren={taxonomy.get(curGroupIdToDelete)?.totalDescendents !== 0 ?? null}
        />
      ) : null}
      <div>
        {loadingStatuses.fetchingParents ? (
          <div className="mt-2 flex flex-col gap-y-4 pt-2 max-h-96">
            <LoadingSpinner />
          </div>
        ) : Array.from(taxonomy.keys()).length === 0 ? (
          <p className="mt-12 flex flex-col items-center gap-y-4 pt-2">No search groups match the selected filters.</p>
        ) : Array.from(taxonomy.keys()).length === 0 ? null : (
          topLevelGroups.map((group, index, arr) => {
            const show: boolean = index === arr.length - 2;
            return <>{renderGroup(group, show, 0)}</>;
          })
        )}
        {loadingStatuses.fetchingOrphans && (
          <div className="flex justify-center items-center w-full">
            <div className="h-8 w-8 animate-spin rounded-full border-t-2 border-b-2 border-blueberry"></div>
          </div>
        )}
      </div>
    </>
  );
};

export default TaxonomyView;
