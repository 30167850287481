import _ from 'underscore';
import { CSVError, CSVRow, HeaderEntry, ParsedCSV, rowIndexOffset } from './csvTypes';

interface ValidationFunctionProps {
  /**
   * @description the parsed CSV from CSVParser
   */
  unvalidatedCSV: ParsedCSV;

  /**
   * @description the name of the file specified by the user which appears on the feedback page
   */
  sourceName: string;
}

/**
 * @description an object responsible for validating whether an unvalidatedCSV from CSVParser is valid
 */
export const useCsvValidatorHook = () => {
  /**
   * @description top level function responsible for validating the csv and populating the errors state in CSVHook
   * @returns the errors generated by the validation function
   */
  function validate({ unvalidatedCSV, sourceName }: ValidationFunctionProps): CSVError[] {
    const maxIDLength: number = 255;
    // const maxSourceNameLengthChars: number = 300; currently removed since this case is rare and the UI currently hides `SourceName` on errors

    let batchedErrors: CSVError[] = [];

    // step 1) ensure source name is not an empty string and under the max length
    batchedErrors = [...batchedErrors, ...getSourceNameErrors(sourceName)];

    // step 2) ensure all required fields are in header
    batchedErrors = [...batchedErrors, ...getMissingHeadersErrors(unvalidatedCSV)];

    // step 3)
    batchedErrors = [...batchedErrors, ...getDuplicateHeadersError(unvalidatedCSV)];

    // step 4) ensure ids are all unique
    batchedErrors = [...batchedErrors, ...getIdErrors(maxIDLength, unvalidatedCSV)];

    // step 5) check that each row follows the field criteria
    batchedErrors = [...batchedErrors, ...getBodyErrors(unvalidatedCSV)];

    return batchedErrors;
  }

  return {
    validate,
  };
};

/**
 * @description verifies that the source name is not empty and valid lengthed
 * @returns true if the source length is less than maxSourceName Length
 */
function getSourceNameErrors(sourceName: string): CSVError[] {
  let error: CSVError[] = [];

  // case 1) source name is empty
  if (sourceName.trim() === '') {
    error.push(new CSVError('Source Name is a required field'));
  }

  return error;
}

/**
 * @description validation function responsible for creating an array of errors for each missing required header
 * @returns array of errors containing missing header fields
 */
function getMissingHeadersErrors(unvalidatedCSV: ParsedCSV): CSVError[] {
  const headersMissingError: CSVError[] = Object.entries(unvalidatedCSV.header)

    // step 1) choose the required rows that are missing
    .filter(([columnName, columnEntry]: [string, NonNullable<HeaderEntry>]) => columnEntry.required && columnEntry.csvIndex == -1)

    // step 2) build an array
    .map(
      (field) =>
        new CSVError(
          field[0] + ' is not present, this is a required field',
          1 - rowIndexOffset, // row is offset for non header rows
          null // col
        )
    );

  return headersMissingError;
}

/**
 * @description validation function responsible for creating an array of errors where the headers are duplicates (only first duplicate is returned)
 * @returns array of errors containing the 1st duplicate header
 */
function getDuplicateHeadersError(unvalidatedCSV: ParsedCSV): CSVError[] {
  // concatenate segment headers and default headers
  const allHeaders: string[] = Object.entries(unvalidatedCSV.header)
    .map(([fieldName, _]) => fieldName)
    .concat(unvalidatedCSV.segmentHeader?.segHeader.map((segment) => segment.fieldTitle) ?? []);

  // check for duplicates
  let headerDuplicateErrors: CSVError[] = [];

  // loop through each header
  for (let i: number = 0; i < allHeaders.length; ++i) {
    const header: string = allHeaders[i];

    // check for dupe
    if (allHeaders.indexOf(header) != i) {
      // dupe found
      headerDuplicateErrors.push(
        new CSVError(
          header + ' is a duplicate but column headers must be unique',
          1 - rowIndexOffset, // row is offset for non header rows
          i // col
        )
      );
    }
  }
  return headerDuplicateErrors;
}

/**
 * @description checks to see if there are duplicate provider unique ids or max length
 * @returns a CSVError[] where each error is a duplicate row or max length
 */
function getIdErrors(maxIDLength: number, unvalidatedCSV: ParsedCSV): CSVError[] {
  // step 1) convert the rows to a map for faster runtime: map of ids to row number
  const idMap = new Map<string, number>();
  let errors: CSVError[] = [];

  // step 2) iterate through each row
  for (let i: number = 0; i < unvalidatedCSV.rows.length; ++i) {
    const row: CSVRow = unvalidatedCSV.rows[i];

    // case 0) id is empty (note id has been decided to be a nullable field)
    if (row.id.trim().length === 0) {
      continue;
    }

    // case 1) id is longer than 255 chars
    if (row.id.length > maxIDLength) {
      errors.push(
        new CSVError('ID is ' + row.id.length + ' characters but must be less than ' + maxIDLength + 'characters long.', i, unvalidatedCSV.header.id.csvIndex)
      );
    }

    // case 2) there exists a duplicate
    const possibleDuplicateRowNum = idMap.get(row.id);
    if (possibleDuplicateRowNum !== undefined) {
      // case 2a) if someone has just copied two rows exactly, let the backend remove the duplicate
      if (_.isEqual(row, unvalidatedCSV.rows[possibleDuplicateRowNum])) {
        continue;
      }
      errors.push(new CSVError(`Row ${possibleDuplicateRowNum + rowIndexOffset} and Row ${i + rowIndexOffset}: duplicate Unique ID "${row.id}"`));
    }
    // append the next id to the set
    idMap.set(row.id, i);
  }
  return errors;
}

/**
 * @description loops through the body of the CSV to validate the contents of the body
 *              avoids looping through numElements^validationChecks times
 * @returns an array containing each CSVError in row order
 */
function getBodyErrors(unvalidatedCSV: ParsedCSV): CSVError[] {
  // maximum values
  const maxTitleLengthChars: number = 300;
  const maxDetailsWords: number = 3000;
  const maxUserNameChars: number = 300;

  let bodyErrors: CSVError[] = [];

  // step 1) validate if that criteria is not being met
  unvalidatedCSV.rows.map((row: CSVRow, rowIndex: number) => {
    bodyErrors = [...bodyErrors, ...getRowsMissingFields(row, rowIndex, unvalidatedCSV)];
    bodyErrors = [...bodyErrors, ...getTitleError(row, rowIndex, maxTitleLengthChars, unvalidatedCSV)];
    bodyErrors = [...bodyErrors, ...getDetailsError(row, rowIndex, maxDetailsWords, unvalidatedCSV)];
    bodyErrors = [...bodyErrors, ...getDateError(row, rowIndex, unvalidatedCSV)];
    bodyErrors = [...bodyErrors, ...getSourceUrlError(row, rowIndex, unvalidatedCSV)];
    bodyErrors = [...bodyErrors, ...getUsernameError(row, rowIndex, maxUserNameChars, unvalidatedCSV)];
    bodyErrors = [...bodyErrors, ...getStarsError(row, rowIndex, unvalidatedCSV)];
  });

  // step 2) return those errors to be added in validate()
  return bodyErrors;
}

/**
 * @description Helper function responsible for checking if a required filed on the rowIndex is empty
 * @param row one CSVRow of the csv body
 * @param rowIndex the row number, with index = 0 as the first row
 * @returns array of errors containing missing fields on rowIndex
 */
function getRowsMissingFields(row: CSVRow, rowIndex: number, unvalidatedCSV: ParsedCSV): CSVError[] {
  let errors: CSVError[] = [];
  if (
    row.details.trim() === '' &&
    // prevent displaying two errors,
    //  since a missing header column garuntees this field is missing on the row
    unvalidatedCSV.header.details.csvIndex != -1
  ) {
    errors.push(new CSVError('Details is missing', rowIndex, unvalidatedCSV.header.details.csvIndex));
  }
  if (row.date.trim() === '' && unvalidatedCSV.header.date.csvIndex != -1) {
    errors.push(new CSVError('Date is missing', rowIndex, unvalidatedCSV.header.date.csvIndex));
  }
  return errors;
}

/**
 * @description validates a row's fullText is valid by ensuring it's less than 3000 words
 * @param row one CSVRow of the csv body
 * @param rowIndex the row number, with index = 0 as the first row
 * @returns an array of CSVErrors
 */
function getDetailsError(row: CSVRow, rowIndex: number, maxDetailsWords: number, unvalidatedCSV: ParsedCSV): CSVError[] {
  let errors: CSVError[] = [];
  // step 1) get number of words
  const numWords = row.details.split(' ').filter((word) => word !== '').length;

  // step 2) check that there are not too many words
  if (numWords > maxDetailsWords) {
    errors.push(
      new CSVError('Details length is ' + numWords + ', but must be less than ' + maxDetailsWords + ' words.', rowIndex, unvalidatedCSV.header.details.csvIndex)
    );
  }

  return errors;
}

/**
 * @description validates a row's date by enforcing the date format and adding HH:MM:SS
 * @param row one CSVRow of the csv body
 * @param rowIndex the row number, with index = 0 as the first row
 * @returns an array of CSVErrors containing malformatted dates
 */
function getDateError(row: CSVRow, rowIndex: number, unvalidatedCSV: ParsedCSV): CSVError[] {
  let errors: CSVError[] = [];

  // case 1) date is empty this case is already taken care of in getRowsMissingFieldsError
  if (row.date === '') {
    return errors;
  }

  const parsedDate: Date = new Date(row.date);

  // case 2) cannot parse the date string
  if (isNaN(parsedDate.getTime())) {
    // Date parsing failed
    errors.push(new CSVError('Date "' + row.date + '" is invalid', rowIndex, unvalidatedCSV.header.date.csvIndex));
    return errors;
  }

  // case 3) parsing successful, parseDate into YYYY-MM-DD HH:MM:SS Format
  row.date = parsedDate.toISOString().replace(/T/, ' ').slice(0, 19);

  return errors;
}

/**
 * @description validates a row's title by ensuring it's less than 3000 chars
 * @param row one CSVRow of the csv body
 * @param rowIndex the row number, with index = 0 as the first row
 * @returns an array of CSVError containing errors over a max length
 */
function getTitleError(row: CSVRow, rowIndex: number, maxTitleLengthChars: number, unvalidatedCSV: ParsedCSV): CSVError[] {
  let errors: CSVError[] = [];
  // case 1) title is empty, no need to validate
  if (row.title === '') return errors;

  // case 2) title is longer than 3000 chars
  if (row.title.length > maxTitleLengthChars) {
    errors.push(
      new CSVError(
        'Title length is ' + row.title.length + ', but must be less than ' + maxTitleLengthChars + ' characters',
        rowIndex,
        unvalidatedCSV.header.title.csvIndex
      )
    );
  }

  // case 3) no errors
  return errors;
}

/**
 * @description validates a row's source url by using a RegEx
 * @param row one CSVRow of the csv body
 * @param rowIndex the row number, with index = 0 as the first row
 * @returns an array of CSVError containing invalid links
 */
function getSourceUrlError(row: CSVRow, rowIndex: number, unvalidatedCSV: ParsedCSV): CSVError[] {
  let errors: CSVError[] = [];
  // case 1) source url is empty, no need to validate
  if (row.source_url === '') return errors;

  // case 2) use a regex to test
  const linkRegex: RegExp = /^(https?:\/\/)?([\w.-]+)\.([a-z]{2,})(\/.*)?$/;
  if (!linkRegex.test(row.source_url)) {
    errors.push(new CSVError('Invalid SourceURL, ' + row.source_url, rowIndex, unvalidatedCSV.header.source_url.csvIndex));
  }

  return errors;
}

/**
 * @description validates a row's username by enforcing a length requirement
 * @param row one CSVRow of the csv body
 * @param rowIndex the row number, with index = 0 as the first row
 * @returns an array of CSVError containing invalid username
 */
function getUsernameError(row: CSVRow, rowIndex: number, maxUserNameChars: number, unvalidatedCSV: ParsedCSV): CSVError[] {
  let errors: CSVError[] = [];
  // case 1) user is empty, no need to validate
  if (row.user === '') return errors;

  // case 2) title is longer than 3000 chars
  if (row.user.length > maxUserNameChars) {
    errors.push(
      new CSVError('Username length is ' + row.user.length + ', but must be less than ' + maxUserNameChars + '.', rowIndex, unvalidatedCSV.header.user.csvIndex)
    );
  }

  return errors;
}

/**
 * @description validates a row's stars and parses it into a number
 * @param row one CSVRow of the csv body
 * @param rowIndex the row number, with index = 0 as the first row
 * @returns true if there are no errors, false and updates ValidationErrors otherwise
 */
function getStarsError(row: CSVRow, rowIndex: number, unvalidatedCSV: ParsedCSV): CSVError[] {
  let errors: CSVError[] = [];
  // case 1) stars is empty, no need to validate
  if (row.stars === undefined || row.stars === '') {
    // if row.stars is an empty string, we just put it as null since db expects Int | undefined
    row.stars = undefined;
    return errors;
  }

  // backup copy to set back for preview table viewing
  const copyOfStarsVal: string | number | undefined = row.stars;

  // case 2) stars exists, convert to an integer
  if (typeof row.stars === 'string') {
    // do not allow decimals
    if (row.stars.indexOf('.') !== -1) {
      errors.push(new CSVError('Stars cannot be a decimal number but is ' + row.stars, rowIndex, unvalidatedCSV.header.stars.csvIndex));
      return errors;
    }

    // convert string to int
    row.stars = parseInt(row.stars);
  }

  // case 2.5) the string is not parsable into an integer
  if (Number.isNaN(row.stars)) {
    row.stars = copyOfStarsVal;
    errors.push(new CSVError('Could not convert value of stars to a number', rowIndex, unvalidatedCSV.header.stars.csvIndex));
    return errors;
  }

  // case 3) validate the range of the int version of stars
  if (row.stars > 5 || row.stars < 0) {
    errors.push(
      new CSVError(
        'Stars is, ' + row.stars + ', but must be in range [0 - 5]',

        // rows are indexed by 0 since the header row starts at one
        rowIndex,
        unvalidatedCSV.header.stars.csvIndex
      )
    );
  }

  return errors;
}
