/**
 * @description creates an object with no parsing erros but possibly data validation errors
 */
export interface ParsedCSV {
  numRows: number;
  header: HeaderCSVRow;
  segmentHeader?: HeaderSegmentsRow;
  rows: CSVRow[];
  // used for entriesUploadMutation
  feedback_integration_id?: number;
}

/**
 * @description contains the name, tootltip description, and column index of
 *              a field in the CSV
 */
export type HeaderCSVRow = {
  [csvColumn in keyof CSVRow]: NonNullable<HeaderEntry>;
};

/**
 * @description create segmentHeaders to parse the CSV for segment values
 *              used only for display for preview table and parsing step
 *              seperate from HeaderCSVRow to not break current functionality
 */
export type HeaderSegmentsRow = {
  segHeader: HeaderEntry[];
};

/**
 * @description each entry in HeaderCSVRow, each of these properties are used for the Preview Table Header
 */
export interface HeaderEntry {
  fieldTitle: string;
  toolTipDescription: string;
  csvIndex: number;
  required: boolean;

  // For Rendering purposes only
  charsTillEllipsis: number; // number of characters to render before replacing with ...

  segmentMetaData?: SegmentMetaData;
}

/**
 * @description models a csv row properties
 */
export interface CSVRow {
  // Required Fields
  details: string;
  date: string;
  id: string;

  // Optional Fields
  title: string;
  source_url: string;
  user: string;
  stars: number | string | undefined;
  segments?: SegmentValue[];
}

/**
 * @description models the data needed for `FeedbackSegmentGroup` Mutation, `FeedbackIntegration` Mutation, and `FeedbackEntriesUpload` Mutation
 */
export interface SegmentMetaData {
  /**
   * @description whether or not the segment is selected
   */
  shouldUpload: boolean;

  /**
   * @description the segmentGroup that maps to the `fieldTitle` property in `HeaderEntry`
   */
  segmentGroup: string;

  /**
   * @description whether or not a new SegmentGroup should be created in `FeedbackSegmentGroup` Mutation
   */
  doesSegGroupExist: boolean;

  /**
   * @description the groupId used in `FeedbackIntegration` Mutation and `FeedbackEntriesUpload` Mutation
   */
  groupId?: number;

  /**
   * @description the configId used in `FeedbackEntriesUpload` Mutation
   */
  configId?: number;
}

/**
 * @description models a segment a user may upload
 */
export interface SegmentValue {
  /**
   * @description the `fieldTitle`/column name of the segment, used for linking a `SegmentValue` to `SegmentMetaData`
   */
  name: string;

  /**
   * @description the value of the segment to be uploaded in `FeedbackEntriesUpload` Mutation
   */
  value: string;
}

/**
 * @description a function that validates a specific criteria of a row
 * @returns an array containing any errors regarding the specific criteria
 */
export type GetRowErrorsFunction = (row: CSVRow, index: number) => CSVError[];

/**
 * @description creates a CSV Error with the row and column number the csv error occured
 */
export class CSVError extends Error {
  public row: number | null | undefined;
  public col: number | null | undefined;

  constructor(message: string, row: number | null | undefined = undefined, col: number | null | undefined = undefined) {
    super(message);
    this.name = this.constructor.name;
    this.row = row;
    this.col = col;

    // columns are 0 based indexed
    if (typeof col === 'number') {
      this.col = col + colIndexOffset;
    }
    if (typeof row === 'number') {
      this.row = row + rowIndexOffset;
    }
  }
}

/**
 * @description translate the csv upload 0 indexing to 1 indexing on Excel
 *              note that this is 2 since the first header row is spliced out
 */
export const rowIndexOffset: number = 2;

/**
 * @description translate the csv upload 0 indexing to 1 indexing on Excel
 */
export const colIndexOffset: number = 1;
