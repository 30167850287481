import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Fragment, useContext, useEffect, useRef, useState } from 'react';
import { useIsMount, alphabeticalSort, capitalizeFirstLetter, getFiltersStatsMode } from '../../v2/util';
import { ExploreGroupHook } from '../hooks/ExploreGroupHook';
import AppContext from '../../v2/contexts/AppContext';
import Button, { ButtonSize, ButtonVariant } from '../baseComponents/Button';
import { AppRoutes, V3_DASHBOARD } from '../../Routes';
import { Cog6ToothIcon, LinkIcon } from '@heroicons/react/24/outline';
import { ArrowDownTrayIcon, BellAlertIcon, PencilIcon, TrashIcon, BookmarkIcon } from '@heroicons/react/24/outline';
import Tippy from '@tippyjs/react';
import GroupTaxonomyEdit from '../components/GroupTaxonomyEdit';
import { FilterHook, useFilterHook } from '../hooks/FilterHook';
import { useClickOutside } from '../../utilities';
import LoadingSpinner from '../baseComponents/LoadingSpinner';
import ClusterSparkChart from '../components/ClusterSparkChart';
import { GROUP_ENTRIES_PAGE_SIZE, GroupFull } from '../../v2/hooks/GroupHook';
import moment from 'moment';
import { FilterManager, FilterManagerDisplayMode } from '../sections/Filters/FilterManager';
import FeedbackEntryCard from '../components/FeedbackEntryCard';
import Badge, { BadgeShape, BadgeSize } from '../../baseComponents/Badge';
import { SmallSpinner } from '../components/SmallSpinner';
import { useInView } from 'react-intersection-observer';
import { GroupSubscriptionPopover } from '../components/Popovers/GroupSubscriptionPopover';
import { GroupDeleter } from '../components/GroupDeleter';
import { BookmarkIcon as BookmarkIconSolid } from '@heroicons/react/24/solid';

import GroupInsightsColumn from '../components/GroupInsightsColumn';
import UserContext from '../../v2/contexts/UserContext';
import { HomeGroupHook } from '../hooks/HomeGroupHook';
import { Popover, Transition } from '@headlessui/react';
import { ComboBox } from '../../baseComponents/ComboBox';
import AdjustableLoadingIcon from '../../baseComponents/AdjustableLoadingIcon';
import { OwnerComboBox } from '../baseComponents/OwnerComboBox';
import { useOrganizationHook } from '../../v2/hooks/OrganizationHook';
import { IDropDownItem } from '../baseComponents/DropDown';
import InlineEditableTextArea from '../../baseComponents/InlineEditableTextArea';
import SearchInput from '../baseComponents/SearchInput';
import { FilterInput } from '../../generated/graphql';
import 'tippy.js/themes/light.css';
import { Icon } from '@iconify/react';
import { PageWrapper } from './PageWrapper';
import { ScrollToTop } from '../baseComponents/ScrollToTop';

interface GroupPageProps {
  groupHook: ExploreGroupHook | HomeGroupHook;
  currentFilters?: FilterInput;
}

const ENTRIES_PAGE_SIZE = 20;
const SENTENCES_PAGE_SIZE = 20;

const GroupPage = ({ groupHook, currentFilters }: GroupPageProps) => {
  const { filteredOrgUsers } = useOrganizationHook();
  const [users, setUsers] = useState<IDropDownItem[]>([{ id: 0, name: '', displayName: 'None' }]);

  const { user } = useContext(UserContext);

  const group = groupHook.currentGroup as GroupFull | undefined; //We need to do something with this to avoid having to check for a valid group every time, but not sure what. Maybe at a higher level?
  const { groupId: URLGroupId } = useParams();
  const { curTeamId, curOrgId } = useContext(AppContext);
  const params = new URLSearchParams(window.location.search);
  const navigate = useNavigate();
  const location = useLocation();
  const openTaxonomyOnLoad = location.search.includes('taxonomy=true');
  const teamId = params.get('teamId') != null ? Number(params.get('teamId')) : curTeamId ?? -1; // default to current teamId, but should check belonging via link for proper access denied redirect
  const filterHook = useFilterHook({
    teamId,
    orgId: curOrgId ?? -1,
    startingFilterInput: location.pathname.includes('group=') ? undefined : group?.filterInput ? group.filterInput : currentFilters,
  });
  const [deleteGroupModalOpen, setDeleteGroupModalOpen] = useState<boolean>(false);
  const [showShadow, setShowShadow] = useState(false);

  const filteredStatsMode = getFiltersStatsMode(filterHook.filters);

  const redirectIfTeamIdSet = () => {
    const params = new URLSearchParams(window.location.search);
    if (params.get('teamId') != null) {
      navigate(`/${V3_DASHBOARD}${AppRoutes.denied}`);
    } else navigate(AppRoutes.v3FullPath.explore);
  };

  const [taxonomyOpen, setTaxonomyOpen] = useState<boolean>(openTaxonomyOnLoad ?? false);
  const taxonomyButtonRef = useRef<HTMLButtonElement>(null);
  const [addLoading, setAddLoading] = useState<boolean>(false);
  const [removedTagId, setRemovedTagId] = useState<number>();
  const [ownerLoading, setOwnerLoading] = useState<boolean>();
  const ref = useRef<HTMLDivElement>(null);
  useClickOutside([taxonomyButtonRef], () => setTaxonomyOpen(false));
  useClickOutside([ref], () => addTagOpen && setAddTagOpen(false));
  const [addTagOpen, setAddTagOpen] = useState(false);
  const [pinLoading, setPinLoading] = useState(false);
  const [popoverKey, setPopoverKey] = useState<number>(0);
  const handlePinGroup = (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
    e.stopPropagation();
    setPinLoading(true);
    if (!group) return;
    groupHook.togglePinGroup(group.id, () => setPinLoading(false));
  };
  const removeTaxonomyOpen = () => {
    let searchParams = new URLSearchParams(window.location.search);
    searchParams.delete('taxonomy');
    navigate({
      pathname: location.pathname,
      search: searchParams.toString(),
    });
  };
  useEffect(() => {
    let isMounted = true;
    const params = new URLSearchParams(window.location.search);
    const teamId = params.get('teamId') != null ? Number(params.get('teamId')) : curTeamId ?? -1; // default to current teamId, but should check belonging via link for proper access denied redirect
    if (isMounted) {
      groupHook.getCurrentGroup(teamId, filterHook.filters, Number(URLGroupId), undefined, redirectIfTeamIdSet);
    }
    return () => {
      isMounted = false;
    };
  }, [URLGroupId]);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setShowShadow(true);
      } else {
        setShowShadow(false);
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    let isMount = true;
    if (filteredOrgUsers && users.length === 1) {
      // only update users once, when we get org users
      const users = filteredOrgUsers
        .map((user) => {
          return { id: user.user?.id ?? -1, name: user.email, displayName: `${user.user?.firstName ?? ''} ${user.user?.lastName ?? ''}` };
        })
        .sort((a, b) => alphabeticalSort(a.displayName, b.displayName));
      isMount && setUsers((prev) => [...users, ...prev]);
    }
    return () => {
      isMount = false;
    };
  }, [filteredOrgUsers, users]);

  useEffect(() => {
    groupHook.updateCurrentGroupFilter?.(filterHook.filters);
    if (!isFirstRender) removeTaxonomyOpen();
  }, [filterHook.filters]);

  const isFirstRender = useIsMount();
  useEffect(() => {
    if (isFirstRender) window.scrollTo(0, 0); //To fix Explore page positioning
  }, []);

  useEffect(() => {
    if (!isFirstRender) navigate(AppRoutes.v3FullPath.explore, { replace: true });
  }, [curTeamId]);

  return (
    <PageWrapper
      title=""
      disableMx
      backButton={
        <div className="mx-8">
          <Button
            size={ButtonSize.Small}
            variant={ButtonVariant.Tertiary}
            text="Back"
            onClick={() => {
              location.pathname.includes('home') ? navigate(AppRoutes.v3FullPath.home) : navigate(AppRoutes.v3FullPath.explore);
            }}
            icon={<p>{'<'}</p>}
            iconPosition="left"
          />
        </div>
      }
    >
      <ScrollToTop />
      {deleteGroupModalOpen && group?.id ? (
        <GroupDeleter
          groupToDelete={group.id}
          closeCallback={() => {
            setDeleteGroupModalOpen(false);
          }}
          deleteGroup={groupHook.discardGroup}
          deleteCallback={() => {
            setDeleteGroupModalOpen(false);
            navigate('/dashboard/explore', { replace: true });
          }}
          loadingDelete={groupHook.loadingStatuses.discardingGroup}
        />
      ) : null}

      <div className="z-10 top-0 text-blueberry flex flex-col gap-y-8">
        {!group ? (
          <div className="flex justify-center items-center ">
            <LoadingSpinner />
          </div>
        ) : (
          <div className="w-full">
            <div className={`sticky top-0 z-20 pr-0 bg-milk transition-all duration-150 ${showShadow ? 'shadow-[0_6px_12px_-12px_rgba(0,0,0,0.6)]' : ''}`}>
              <div className="mx-8 flex flex-row py-2 bg-milk justify-between items-start gap-x-8">
                <div className="flex flex-col gap-y-0 w-full">
                  <div id="group-title" className="flex-grow">
                    <InlineEditableTextArea
                      ignorePadding
                      value={group?.title ?? ''}
                      onEditComplete={(value) => {
                        if (!group) return;
                        groupHook.editTitle(group.id, value);
                      }}
                      additionalClassNames={
                        'lg:text-2xl xl:text-3xl h-auto font-semibold focus:border-none hover:bg-silver focus:bg-silver bg-transparent transition-[background-color] duration-100 rounded-md '
                      }
                    />
                    <div className="flex flex-row gap-x-1">
                      {' '}
                      <p className="">{`${group?.totalEntries.toLocaleString() ?? 0} mention${group?.totalEntries === 1 ? '' : 's'}`}</p>
                      <Tippy
                        theme="light"
                        delay={200}
                        content={
                          <p className="text-center">
                            {filteredStatsMode ? `${(group.relativeShare ?? 0).toFixed(2)}% of filtered feedback, ` : ''}
                            {(group.relativeShareFull ?? 0).toFixed(2)}% of all feedback.
                          </p>
                        }
                      >
                        <p className="">
                          ({((filteredStatsMode ? group.relativeShare : group.relativeShareFull) ?? 0).toFixed(2)}% of {filteredStatsMode ? 'filtered' : ''}{' '}
                          feedback)
                        </p>
                      </Tippy>
                    </div>
                  </div>
                </div>
                <div className="flex flex-col justify-center items-end mt-1">
                  <div className="flex flex-row gap-x-2">
                    <div
                      className="rounded-full  bg-silver  text-blueberry flex flex-row justify-center items-center cursor-pointer h-8 w-8 hover:scale-110 duration-150 hover:shadow-sm"
                      onClick={() => {
                        removeTaxonomyOpen();
                        group && groupHook.copyGroupLink(group.id, filterHook.filters);
                      }}
                    >
                      <Tippy theme="light" content="Link to Group" delay={200}>
                        <div>
                          <LinkIcon className="h-4 w-4 stroke-2" />
                        </div>
                      </Tippy>
                    </div>
                    <Tippy
                      theme="light"
                      delay={200}
                      content={<p className="text-center">{group?.pinnedByUser ? "You've pinned this group" : 'Click to pin this group'}</p>}
                    >
                      <div
                        id="pin-group-icon"
                        className="rrounded-full  bg-silver  text-blueberry flex flex-row justify-center items-center cursor-pointer rounded-full h-8 w-8 hover:scale-110 duration-150 hover:shadow-sm"
                      >
                        {pinLoading ? (
                          <AdjustableLoadingIcon width={5} height={5} color="white" />
                        ) : group?.pinnedByUser ? (
                          <BookmarkIconSolid className="h-5 w-5 self-center" id="unpin-group" onClick={(e) => handlePinGroup(e)} />
                        ) : (
                          <BookmarkIcon className="h-5 w-5 self-center" id="pin-group" onClick={(e) => handlePinGroup(e)} />
                        )}
                      </div>
                    </Tippy>

                    <div
                      className="rounded-full  bg-silver  text-blueberry flex flex-row justify-center items-center cursor-pointer h-8 w-8 hover:scale-110 duration-150 hover:shadow-sm"
                      onClick={() => group && groupHook.groupExport?.(group.id)}
                    >
                      <Tippy theme="light" content="Export to CSV" delay={200}>
                        <div>
                          <ArrowDownTrayIcon className="h-4 w-4 stroke-2" />
                        </div>
                      </Tippy>
                    </div>
                    <div
                      id={`group-subscriptions`}
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                    >
                      <GroupSubscriptionPopover
                        groupId={group?.id ?? -1}
                        key={popoverKey}
                        updateKey={setPopoverKey}
                        customIconElem={
                          <div className="rounded-full  bg-silver  text-blueberry flex flex-row justify-center items-center cursor-pointer h-8 w-8 hover:scale-110 duration-150 hover:shadow-sm focus:outline-none">
                            <Tippy content="Configure Subscriptions" delay={100}>
                              <div>
                                <BellAlertIcon className="h-4 w-4 stroke-2" />
                              </div>
                            </Tippy>
                          </div>
                        }
                      />
                    </div>
                    <Popover>
                      {({ open }) => (
                        <>
                          <Popover.Button
                            ref={taxonomyButtonRef}
                            className="rounded-full bg-silver  text-blueberry flex flex-row justify-center items-center cursor-pointer h-8 w-8 hover:scale-110 duration-150 hover:shadow-sm focus:outline-none"
                          >
                            <Tippy content="Edit Taxonomy" theme="light" delay={200}>
                              <Icon icon="mdi:file-tree" color={'#292e5b'} width="20" height="20" className="cursor-pointer" />
                            </Tippy>
                          </Popover.Button>
                          <Transition
                            as={'div'}
                            show={open || taxonomyOpen}
                            enter="transition ease-out duration-150"
                            enterFrom="opacity-0 translate-y-1"
                            enterTo="opacity-100 translate-y-0"
                            leave="transition ease-in duration-100"
                            leaveFrom="opacity-100 translate-y-0"
                            leaveTo="opacity-0 translate-y-1"
                          >
                            {(open || taxonomyOpen) && (
                              <div>
                                <Popover.Panel static className="absolute right-0 px-8 z-[9999]">
                                  {!!group && (
                                    <GroupTaxonomyEdit
                                      groupId={Number(group?.id)}
                                      parentId={group.id && groupHook.taxonomy ? groupHook.taxonomy.get(group.id)?.parentId : undefined}
                                      filterInput={filterHook.filters}
                                      loadingStatuses={groupHook.loadingStatuses}
                                      assignChild={groupHook.assignChild}
                                      assignChildren={groupHook.assignChildren}
                                      deleteChild={groupHook.deleteChild}
                                      childCandidates={groupHook.childCandidates}
                                      getChildCandidates={groupHook.getChildCandidates}
                                      children={groupHook.children}
                                      getChildren={groupHook.getChildren}
                                      removeChildFromParent={groupHook.removeChildFromParent}
                                      childrenToAssign={groupHook.childrenToAssign}
                                      getChildrenToAssign={groupHook.getPotentialChildren}
                                      taxonomy={groupHook.taxonomy}
                                    />
                                  )}
                                </Popover.Panel>
                              </div>
                            )}
                          </Transition>
                        </>
                      )}
                    </Popover>
                    {user?.isUnwrapper ? (
                      <Popover>
                        <Popover.Button className="rounded-full  bg-silver  text-blueberry flex flex-row justify-center items-center cursor-pointer h-8 w-8 hover:scale-110 duration-150 hover:shadow-sm focus:outline-none">
                          <Tippy content="Edit Digest" delay={100}>
                            <div>
                              <Cog6ToothIcon className="h-4 w-4 stroke-2 focus:outline-none" />
                            </div>
                          </Tippy>
                        </Popover.Button>
                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-150"
                          enterFrom="opacity-0 translate-y-1"
                          enterTo="opacity-100 translate-y-0"
                          leave="transition ease-in duration-100"
                          leaveFrom="opacity-100 translate-y-0"
                          leaveTo="opacity-0 translate-y-1"
                        >
                          <Popover.Panel className="absolute right-0 px-8 z-[9999]">
                            {!!group && <GroupInsightsColumn groupId={Number(URLGroupId) ?? -1} filterStartDate={filterHook.filters.startDate} />}
                          </Popover.Panel>
                        </Transition>
                      </Popover>
                    ) : null}
                    <div
                      className="rounded-full  bg-silver  text-blueberry flex flex-row justify-center items-center cursor-pointer h-8 w-8 hover:scale-110 duration-150 hover:shadow-sm"
                      onClick={() => setDeleteGroupModalOpen(true)}
                    >
                      <Tippy content="Delete Group" delay={100}>
                        <div>
                          <TrashIcon className="h-4 w-4 stroke-2" />
                        </div>
                      </Tippy>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex flex-row justify-between items-start mx-8"></div>
            <div className="flex flex-col mt-4 mx-8">
              <div className="w-full h-[15rem]">
                {groupHook.loadingStatuses.loadingCurrentGroup ? (
                  <div className="pt-6">
                    <LoadingSpinner />
                  </div>
                ) : (
                  <ClusterSparkChart
                    artificialStartDate={undefined}
                    autoHeight
                    aggregateData={group.aggregateData}
                    normalizedData={group.normalizedData}
                    tooltipLabels={group.tooltipLabels}
                    chartLabels={group.chartLabels}
                    filterInput={
                      filterHook.filters
                        ? filterHook.filters
                        : {
                            startDate: moment().subtract(360, 'days').startOf('day').toDate(),
                            endDate: moment().toDate(),
                          }
                    }
                  />
                )}
              </div>
              <div className="grid grid-cols-10 justify-between gap-y-2 mt-4 -mx-1 px-1">
                <div className="flex col-span-10 xl:col-span-6">
                  <FilterManager pageName={''} filterHook={filterHook} dataTypeToFilter={'groupPage'} displayMode={FilterManagerDisplayMode.GroupPage} />
                </div>

                {!group ? null : (
                  <div className="flex flex-row col-span-5 xl:col-span-2 gap-x-1 items-start mt-0.5 ">
                    <p className="font-semibold text-gray-500 mt-0.5">Owner:</p>
                    <div id="owner-change" className="-mt-0.5">
                      {
                        <OwnerComboBox
                          selectedItem={users.find((user) => {
                            if (!group) return;
                            const idToCheck = group.ownerId ?? 0;
                            return user.id === idToCheck;
                          })}
                          comboBoxData={users}
                          loading={ownerLoading || users.length === 1}
                          setSelectedItem={(item) => {
                            setOwnerLoading(true);
                            if (!group) return;
                            if (item == null || item.id === 0) groupHook.removeOwner(group.id, () => setOwnerLoading(false));
                            else {
                              if (!group) return;
                              if (item.id !== -1) groupHook.updateOwner(group.id, item.id, () => setOwnerLoading(false));
                            }
                          }}
                        />
                      }
                    </div>
                  </div>
                )}
                {!group ? null : (
                  <div className="flex flex-row col-span-5 xl:col-span-2 justify-end gap-x-1 items-start gap-y-2 flex-wrap mt-0.5">
                    <p className="font-semibold text-gray-500 mt-0.5">Tags:</p>
                    <div id="group-tags" className="flex flex-row flex-wrap items-center justify-start gap-x-2 "></div>
                    {group?.tags?.map((tag) => {
                      return (
                        <div className="flex-none">
                          <Badge
                            key={tag.id}
                            capitalize={true}
                            badge={{ id: tag.id.toString(), text: tag.name }}
                            onRemove={(item) => {
                              setRemovedTagId(tag.id);
                              groupHook.handleRemoveTag(group.id, tag.id, () => setRemovedTagId(undefined));
                            }}
                            loading={removedTagId === tag.id}
                          />
                        </div>
                      );
                    })}{' '}
                    {addTagOpen ? (
                      !addLoading ? (
                        <div className="flex-none">
                          <ComboBox
                            comboBoxData={
                              groupHook.tags
                                ?.filter((tag) => !group?.tags?.some((t) => t.id === tag.id))
                                .sort((a, b) => alphabeticalSort(a.name, b.name))
                                .map((tag) => {
                                  return { ...tag, name: capitalizeFirstLetter(tag.name) };
                                }) ?? []
                            }
                            defaultOption={true}
                            boxRef={ref}
                            setSelectedItem={(item) => {
                              if (!group) return;

                              if (item) {
                                if (!groupHook.tags?.some((tag) => tag.id === item.id)) {
                                  setAddLoading(true);
                                  groupHook.handleCreateTag(group.id, item.name, () => {
                                    setAddLoading(false);
                                  });
                                } else {
                                  setAddLoading(true);
                                  groupHook.handleTagGroup(group.id, item.id, () => {
                                    setAddLoading(false);
                                  });
                                }
                              }
                            }}
                          />
                        </div>
                      ) : (
                        <AdjustableLoadingIcon width={4} height={4} />
                      )
                    ) : (
                      <Tippy theme="light" content={<p>Add another tag</p>}>
                        <div className="cursor-pointer" onClick={() => setAddTagOpen((prev) => !prev)}>
                          <Badge badge={{ id: '0', text: '+' }} id="add-tag" />
                        </div>
                      </Tippy>
                    )}
                  </div>
                )}
              </div>
            </div>
            <GroupPageEntriesSection groupHook={groupHook} filterHook={filterHook} />
          </div>
        )}
      </div>
    </PageWrapper>
  );
};

const GroupPageEntriesSection = ({ groupHook, filterHook }: { groupHook: ExploreGroupHook | HomeGroupHook; filterHook: FilterHook }) => {
  const group = groupHook.currentGroup as GroupFull | undefined;
  const [page, setPage] = useState(GROUP_ENTRIES_PAGE_SIZE);
  const [addSentencesPage, setAddSentencesPage] = useState(0);
  const [paginating, setPaginating] = useState(false);
  const [endOfEntriesReached, setEndOfEntriesReached] = useState(false);
  const [endOfAddSentencesReached, setEndOfAddSentencesReached] = useState(false);
  const [addSentencesQuery, setAddSentencesQuery] = useState<string>('');
  const { ref: scrollRef, inView } = useInView({
    rootMargin: '300px',
    threshold: 0,
  });

  const [currentTabId, setCurrentTabId] = useState<number>(0);
  const tabs = [
    {
      id: 0,
      name: 'Mentions',
    },
    { id: 1, name: 'Add Sentences +' },
  ];

  useEffect(() => {
    if (!group) return;
    if (currentTabId == 0 && endOfEntriesReached) return;
    if (currentTabId == 1 && endOfAddSentencesReached) return;
    if (inView) {
      setPaginating(true);
      if (currentTabId === 0) {
        groupHook
          .loadMoreSentences(
            group.id,
            page,
            GROUP_ENTRIES_PAGE_SIZE,
            () => {
              setEndOfEntriesReached(true);
            },
            () => {}
          )
          .then(() => {
            setPage((prev) => prev + GROUP_ENTRIES_PAGE_SIZE);
            setPaginating(false);
          });
      } else if (currentTabId === 1) {
        groupHook
          .refetchSimilarSentences(
            addSentencesQuery,
            addSentencesPage,
            SENTENCES_PAGE_SIZE,
            () => setEndOfAddSentencesReached(true),
            () => {
              setPaginating(false);
            }
          )
          .then(() => {
            setAddSentencesPage((prev) => prev + SENTENCES_PAGE_SIZE);
            setPaginating(false);
          });
      }
    }
  }, [inView, currentTabId]);

  const isFirstRender = useIsMount();
  useEffect(() => {
    if (!isFirstRender) {
      setPage(0);
      setEndOfEntriesReached(false);
    }
  }, [filterHook.filters]);

  useEffect(() => {
    if (isFirstRender) groupHook.clearSimilarSentences();
  }, []);

  if (!group) return <></>;
  return (
    <div className="flex flex-col gap-y-2 min-h-screen bg-milk mt-4 mx-8">
      <div className="flex flex-row gap-x-4 items-baseline">
        {tabs.map((tab) => {
          return (
            <p
              key={tab.id}
              id={tab.name.replace(' ', '')}
              className={`${
                currentTabId === tab.id ? 'font-semibold text-blueberry decoration-2 underline underline-offset-4' : ' text-gray-500'
              } cursor-pointer text-lg`}
              onClick={() => setCurrentTabId(tab.id)}
            >
              {tab.name}
            </p>
          );
        })}
      </div>

      {currentTabId === 0
        ? group?.entries.map((entry) => {
            const sentences = group.sentences.filter((sentence) => entry.sentences.map((s) => s.id).includes(sentence.id));
            return <FeedbackEntryCard key={entry.id} entry={entry} compactAvailable={true} compact={true} sentences={sentences} groupHook={groupHook} />;
          })
        : null}
      {currentTabId === 1 && (
        <div className="pt-2 relative">
          <div className="px-1 mb-3">
            <SearchInput
              queryString={addSentencesQuery}
              placeholder={'Add more sentences'}
              setQueryString={(string) => setAddSentencesQuery(string ?? '')}
              onSearch={async (q) => {
                setPaginating(true);
                setAddSentencesPage(0);
                setEndOfAddSentencesReached(false);
                groupHook.clearSimilarSentences();
                await groupHook.refetchSimilarSentences(
                  q ?? '',
                  0,
                  SENTENCES_PAGE_SIZE,
                  () => setEndOfAddSentencesReached(true),
                  () => setPaginating(false),
                  true
                );
              }}
            />
          </div>
          <div className="flex flex-col gap-y-2">
            {!paginating && !groupHook.loadingStatuses.loadingSimilarSentences && groupHook.similarSentences?.length === 0 ? (
              <p className="pt-4">No similar sentences were found.</p>
            ) : (
              groupHook.similarSentences &&
              groupHook.similarSentences.map((sentence) => {
                if (!group) return;
                return (
                  <FeedbackEntryCard
                    key={sentence.id}
                    entry={sentence.entry!}
                    compactAvailable={true}
                    compact={true}
                    sentences={[sentence]}
                    groupHook={groupHook}
                    addMode={true}
                  />
                );
              })
            )}
          </div>
        </div>
      )}
      {paginating ? (
        <div className="flex flex-row items-baseline gap-x-2">
          <SmallSpinner />
          Loading more entries...
        </div>
      ) : null}
      {group.entries ? <div ref={scrollRef} className="mb-4"></div> : null}
    </div>
  );
};

export default GroupPage;
