import { useEffect } from 'react';
import { FilterInput, useAmountOfEntriesLazyQuery, useAmountOfEntriesQuery, useFeedbackEntriesLazyQuery } from '../../generated/graphql';
import { getTotalPageLoadEvent } from '../../latencyTracker';

interface ExploreHookProps {
  teamId: number;
  orgId: number;
  userId?: number;
  filterInput?: FilterInput;
}

export interface FeedbackHook {
  fetchMoreEntries: (amount?: number) => void;
  entries: any[];
  loadingStatuses: {
    fetchingEntries: boolean;
  };
  totalEntriesCount: number;
}

export const useFeedbackHook = ({ teamId, orgId, userId, filterInput }: ExploreHookProps) => {
  const [getFeedbackEntries, feedbackEntries] = useFeedbackEntriesLazyQuery({
    notifyOnNetworkStatusChange: true,
    nextFetchPolicy: 'cache-only',
    variables: { teamId, take: 50, filterInput },
  });

  const { data: origAmountOfEntriesData, loading: origAmountOfEntriesLoading } = useAmountOfEntriesQuery({
    fetchPolicy: 'network-only',
    variables: { teamId },
  });

  const [getAmountOfEntries, amountOfEntries] = useAmountOfEntriesLazyQuery({
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    variables: { teamId, filterInput },
  });

  useEffect(() => {
    Promise.all([getAmountOfEntries(), getFeedbackEntries()]);
    const event = getTotalPageLoadEvent({ view: 'feedback' });
    window.dispatchEvent(event);
  }, []);

  const fetchMoreEntries = (amount?: number) => {
    feedbackEntries.fetchMore({ variables: { skip: feedbackEntries.data?.entries?.length, take: amount } });
  };

  return {
    fetchMoreEntries,
    entries: feedbackEntries.data?.entries ?? [],
    loadingStatuses: {
      fetchingEntries: feedbackEntries.loading,
      fetchingAmounts: amountOfEntries.loading || origAmountOfEntriesLoading,
    },
    entriesCount: amountOfEntries.data?.amountOfEntries.amount ?? 0,
    totalEntriesCount: origAmountOfEntriesData?.amountOfEntries.amount ?? 0,
  };
};
