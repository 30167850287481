import { type ButtonHTMLAttributes, type ReactNode } from 'react';
import { classNames } from '../../v2/util';
import { Events, logEvent, Properties } from '../../v2/AnalyticsUtil';
import Tippy from '@tippyjs/react';

/*
This component should be expanded as we need more variants/sizes/utilities during migration. I setup the basic cases for now.
*/

export enum ButtonVariant {
  Primary,
  Secondary,
  Tertiary,
  Cancel,
  Theme,
  Icon,
  Back,
  PopupConfirm,
  PopupCancel,
  IconRaw,
}

export enum ButtonSize {
  XSmall,
  Small,
  Regular,
  Large,
}

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  size?: ButtonSize;
  variant?: ButtonVariant;
  children?: ReactNode;
  text?: string;
  loadingText?: string;
  icon?: ReactNode;
  expandWidth?: boolean;
  expandHeight?: boolean;
  locked?: boolean;
  iconPosition?: 'left' | 'right';
  disabled?: boolean;
  disabledTooltip?: string;
  id?: string;
  loadingConfirm?: boolean;
  submit?: boolean;
  onClick?: () => void;
  //For logging events
  eventName?: Events | string;
  properties?: Properties;
  logClickEvent?: (eventName: Events, properties: Properties) => void;
  preventDefault?: boolean;
}

const getButtonSizeClass = (size: ButtonSize) => {
  switch (size) {
    case ButtonSize.Large:
      return 'px-5 py-3 text-lg';
    case ButtonSize.Regular:
      return 'px-8 py-3 text-sm';
    case ButtonSize.Small:
      return 'px-4 py-3 text-xs';
    case ButtonSize.XSmall:
      return 'px-3 py-2 text-xs';
  }
};

const getButtonVariantClass = (variant: ButtonVariant) => {
  switch (variant) {
    case ButtonVariant.Primary:
      return 'bg-blueberry text-white enabled:hover:bg-blueberry-darker';
    case ButtonVariant.Secondary:
      return 'bg-raspberry text-white enabled:hover:bg-raspberry-darker';
    case ButtonVariant.Tertiary:
      return 'bg-silver text-blueberry enabled:hover:bg-silver-darker';
    case ButtonVariant.Cancel:
      return 'bg-milk enabled:hover:bg-silver border-blueberry border-2 border text-blueberry';
    case ButtonVariant.Icon:
      return 'bg-silver rounded-full';
    case ButtonVariant.IconRaw:
      return 'text-blueberry';
  }
};

const Spinner = () => {
  return (
    <svg className="mr-2 h-4 w-4 animate-spin" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
      <path
        className="opacity-75"
        fill="currentColor"
        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
      ></path>
    </svg>
  );
};

const Button = ({
  size = ButtonSize.Regular,
  variant = ButtonVariant.Primary,
  children,
  className,
  text,
  loadingText,
  icon,
  expandWidth,
  expandHeight,
  disabled,
  disabledTooltip,
  id,
  submit,
  onClick,
  eventName,
  properties,
  loadingConfirm,
  iconPosition,
  preventDefault,
  ...props
}: ButtonProps) => {
  const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (preventDefault) e.preventDefault();
    onClick?.();
    if (eventName && properties) {
      logEvent(eventName, properties);
    }
  };

  return (
    <Tippy disabled={!disabled || !disabledTooltip} content={<h1>{disabledTooltip}</h1>}>
      <div>
        <button
          id={id}
          name={id}
          type={submit ? 'submit' : 'button'}
          onClick={handleClick}
          className={classNames(
            'flex flex-row items-center justify-center gap-x-2 whitespace-nowrap rounded-xl font-sofiapro font-medium transition-all',
            'disabled:cursor-not-allowed disabled:opacity-50 duration-200',
            variant !== ButtonVariant.IconRaw && getButtonSizeClass(size),
            getButtonVariantClass(variant),
            expandWidth ? 'w-full' : 'w-fit',
            expandHeight ? 'h-full' : 'h-fit'
          )}
          disabled={disabled}
        >
          {icon && iconPosition === 'left' ? icon : null}
          {loadingConfirm && loadingText ? loadingText : text}
          {loadingConfirm ? Spinner() : null}
          {icon && iconPosition !== 'left' ? icon : null}
        </button>
      </div>
    </Tippy>
  );
};

export default Button;
