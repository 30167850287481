import { IBadge } from '../../../baseComponents/Badge';
import { FilterInput, FilterType, SentimentType, Group_Type, DataForFiltersQuery, Group_Status } from '../../../generated/graphql';
import { DenominatorOptions, FilterCategory, ICategoryItem, IFilter, IFilterValue } from './FiltersTypes';
//@ts-ignore
import uuid from 'react-uuid';
import { IDropDownItem } from '../../../baseComponents/DropDown';
import sourcesMap from '../../../v2/other/sourcesMap';
import { IUser } from '../../../v2/contexts/UserContext';

export const getFiltersWithDenominatorMethod = (filters: FilterInput, selectedDenominator: IDropDownItem | undefined): FilterInput => {
  if (!selectedDenominator) return { startDate: filters.startDate ?? undefined, endDate: filters.endDate ?? undefined };
  switch (selectedDenominator?.name) {
    case DenominatorOptions.AllFeedback:
      return {};
    case DenominatorOptions.ClusteredFeedback:
      return { clusteredOnly: true };
    case DenominatorOptions.FilteredFeedback:
      return filters;
    case DenominatorOptions.FilteredClusteredFeedback:
      return { ...filters, clusteredOnly: true };
    case DenominatorOptions.DateFilteredFeedback:
      return { startDate: filters.startDate ?? undefined, endDate: filters.endDate ?? undefined };
    default:
      return { startDate: filters.startDate ?? undefined, endDate: filters.endDate ?? undefined };
  }
};

export const getCategoryDropDown = (data?: DataForFiltersQuery, dataTypeToFilter?: FilterableDataTypes): ICategoryItem[] => {
  const items: ICategoryItem[] = [];
  let id = 0;
  if (dataTypeToFilter !== 'chart') {
    items.push({
      id: id++,
      name: 'Source',
      categoryType: FilterCategory.Source,
    });
    items.push({
      id: id++,
      name: 'Sentiment',
      categoryType: FilterCategory.Sentiment,
    });
    items.push({
      id: id++,
      name: 'Min Stars',
      categoryType: FilterCategory.MinStars,
    });
    items.push({
      id: id++,
      name: 'Max Stars',
      categoryType: FilterCategory.MaxStars,
    });
  }
  if (data?.segments) {
    items.push(
      ...data.segments?.map((segments) => {
        return {
          id: id++,
          name: `Custom Field: ${segments.displayName}`,
          realId: segments.id,
          categoryType: FilterCategory.Segment,
        };
      })
    );
  }
  //TODO: Improve this logic...it's getting ugly.
  if (dataTypeToFilter === 'group') {
    items.push({
      id: id++,
      name: 'Group Type',
      categoryType: FilterCategory.GroupType,
    });
  }
  if (dataTypeToFilter === 'group' || dataTypeToFilter === 'chart' || dataTypeToFilter === 'groupPage') {
    if (data?.getTags && data.getTags.length > 0) {
      items.push({
        id: id++,
        name: 'Tag',
        categoryType: FilterCategory.Tag,
      });
    }
    items.push({
      id: id++,
      name: 'Owner',
      categoryType: FilterCategory.Owner,
    });
  }

  if (dataTypeToFilter === 'group' || dataTypeToFilter === 'chartEditor') {
    items.push({
      id: id++,
      name: 'Group Title',
      categoryType: FilterCategory.GroupTitle,
    });
  }

  return items;
};

export const getSelectedCategoryDropDown = (selectedCategory: ICategoryItem, data?: DataForFiltersQuery, user?: IUser | undefined): IDropDownItem[] => {
  const items: IDropDownItem[] = [];
  // careful here switching on enums. You have to explicitly tell compiler that this is an enum and not a number type. Otherwise all case statements are gonna get hit.
  switch (selectedCategory.categoryType as FilterCategory) {
    case FilterCategory.Segment:
      if (!data?.segments) {
        return [];
      }
      const segment = data.segments.filter((segment) => segment.id === selectedCategory.realId);
      if (segment.length !== 1) {
        throw new Error('Found more than one custom field with id ' + selectedCategory.realId);
      }
      let id = 0;
      return segment[0].distinctValues.map((value) => {
        return {
          name: value,
          id: id++,
        };
      });
    case FilterCategory.Sentiment:
      return [
        {
          name: SentimentType.Positive,
          displayName: 'Positive',
          id: 0,
        },
        {
          name: SentimentType.Negative,
          displayName: 'Negative',
          id: 1,
        },
        {
          name: SentimentType.Neutral,
          displayName: 'Neutral',
          id: 2,
        },
      ];
    case FilterCategory.Source:
      if (!data?.sources) {
        return [];
      }
      let newId = 0;
      return data.sources.map((source) => {
        return {
          name: source,
          id: newId++,
          displayName: sourcesMap[source]?.name ?? source,
        };
      });
    case FilterCategory.GroupType:
      return [
        {
          name: Group_Type.Search,
          displayName: 'User Created',
          id: 0,
        },
        {
          name: Group_Type.ClusterSearch,
          displayName: 'Found by Unwrap',
          id: 1,
        },
      ];
    case FilterCategory.GroupTitle:
      if (!data?.getAllGroups) return [];
      return data.getAllGroups
        .filter((group) => group.status === Group_Status.Monitored)
        .map((group) => {
          return {
            name: group.title ?? '',
            id: group.id,
          };
        });
    case FilterCategory.Tag:
      if (!data?.getTags) {
        return [];
      }
      return data.getTags.map((tag) => {
        return {
          name: tag.name,
          id: tag.id,
        };
      });
    case FilterCategory.Owner:
      if (!data?.getOrganizationUsers) {
        return [];
      }
      return data.getOrganizationUsers
        .filter((orgUser) => (!user?.isUnwrapper ? !orgUser.email.includes('@unwrap.ai') : true) && orgUser.confirmed)
        .map((orgUser) => {
          return {
            name: orgUser.email,
            id: orgUser.user?.id ?? -1,
          };
        });
    case FilterCategory.MinStars:
    case FilterCategory.MaxStars:
      return [1, 2, 3, 4, 5].map((num) => {
        return {
          name: num.toString(),
          displayName: `${num.toString()} ${'⭒'.repeat(num)}`,
          id: num,
        };
      });

    default:
      return items;
  }
};

export const getFilterValue = (selectedCategory: ICategoryItem, selectedFilter: IDropDownItem): IFilterValue => {
  switch (selectedCategory.categoryType as FilterCategory) {
    case FilterCategory.Tag:
      return { title: selectedFilter?.displayName ?? selectedFilter?.name, uiId: uuid(), name: selectedFilter?.name, id: selectedFilter?.id };
    case FilterCategory.Segment:
      return { title: selectedFilter?.displayName ?? selectedFilter?.name, segment: selectedFilter?.name, uiId: uuid() };
    case FilterCategory.Sentiment:
      return { title: selectedFilter?.displayName ?? selectedFilter?.name, sentiment: selectedFilter?.name as SentimentType, uiId: uuid() };
    case FilterCategory.Source:
      return { title: selectedFilter?.displayName ?? selectedFilter?.name, source: selectedFilter?.name, uiId: uuid() };
    case FilterCategory.GroupType:
      return {
        title: selectedFilter?.displayName ?? selectedFilter?.name,
        type: selectedFilter?.name as Group_Type,
        uiId: uuid(),
        displayName: selectedFilter?.displayName,
      };
    case FilterCategory.GroupTitle:
      return { title: selectedFilter?.displayName ?? selectedFilter?.name, id: selectedFilter?.id, uiId: uuid(), displayName: selectedFilter?.displayName };
    case FilterCategory.Owner:
      return { title: selectedFilter?.displayName ?? selectedFilter?.name, name: selectedFilter?.name, id: selectedFilter?.id, uiId: uuid() };
    case FilterCategory.MinStars:
      return { title: selectedFilter?.displayName ?? selectedFilter?.name, uiId: uuid(), id: selectedFilter?.id };
    case FilterCategory.MaxStars:
      return { title: selectedFilter?.displayName ?? selectedFilter?.name, uiId: uuid(), id: selectedFilter?.id };
    default:
      throw new Error('Could not find filter category ' + selectedCategory?.categoryType);
  }
};
export const addFilter = (selectedCategory: ICategoryItem, selectedFilter: IDropDownItem, setFilter: (filter: IFilter) => void, filter?: IFilter) => {
  if (filter) {
    if (filter.filterCategory !== selectedCategory.categoryType) {
      throw new Error("You can't add a filter from one category into another.");
    }
    const newFilter = { ...filter };
    const value = getFilterValue(selectedCategory, selectedFilter);
    const existing = newFilter.values.find((currVal) => {
      return currVal.title === value.title;
    });
    if (!existing) {
      newFilter.values.push(value);
    }

    setFilter(newFilter);
  } else {
    const filter: IFilter = {
      filterCategory: selectedCategory.categoryType,
      // set the category id here. This is how we're saving the feedbackSegmentGroupId
      // could show an error here if this value is null?
      filterCategoryId: selectedCategory.realId,
      filterCategoryTitle: selectedCategory.name,
      filterCondition: FilterType.And,
      uiId: uuid(),
      values: [getFilterValue(selectedCategory, selectedFilter)],
    };
    setFilter(filter);
  }
};
export const removeFilter = (item: IBadge, setFilter: (filter: IFilter) => void, filter: IFilter): void => {
  const index = filter.values.findIndex((value) => {
    return value.uiId === item.id;
  });
  filter.values.splice(index, 1);
  setFilter({ ...filter });
};

export type FilterableDataTypes = 'group' | 'entries' | 'chart' | 'chartEditor' | 'groupPage';
